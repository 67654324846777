import React, { Component } from "react";
import Input from "../../UI/Input/Input";
import { Modal } from "antd";
import functions from "../../../functions";
import Axios from "../../../axios";
import EventBus from "eventbusjs";

export default class extends Component {
  state = {
    recordPaymentForm: {
      invoiceNumber: {
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        validated: false,
        errorMessage: "Invoice number is required"
      },      
    },
    xeroError: null,
    loading: false
  };

  handleforms = value => {
    return value;
  };

  handlePaymentsDialogClose = () => {
    let form = { ...this.state.recordPaymentForm };
    for (var key in form) {
      let element = {
        ...form[key],
        value: "",
        valid: false,
        validated: false
      };
      form[key] = element;
    }
    this.setState({
      recordPaymentForm: form,
      loading: false,
      xeroError: null
    });
    this.props.dialogHandler();
  };

  handlePaymentsForm = (event, name) => {
    const formStateCopy = { ...this.state.recordPaymentForm };
    formStateCopy[name].value = event.target.value;
    formStateCopy[name].valid = functions.checkValidity(
      formStateCopy[name].value,
      formStateCopy[name].validation
    );
    formStateCopy[name].validated = true;
    this.setState({
      recordPaymentForm: formStateCopy
    });
  };

  handleSubmitForm = async () => {
    try {
      this.setState({
        loading: true,
        xeroError: null
      });
      const { data } = await Axios.post("/payments", {
        invoiceNumber: this.state.recordPaymentForm.invoiceNumber.value,
        appId: this.props.appId        
      })
      if (!data.error) {
        this.setState({
          loading: false
        });
        this.props.handleUpdatePayment(data.invoice);
        this.handlePaymentsDialogClose();
      }else{
        throw new Error(data.error)
      }
    } catch (error) {
      this.setState({
        loading: false,
        xeroError: error.message
      })
      EventBus.dispatch("ALERT", this, "An error has ocurred.");
    }          
  };

  render() {
    const { display } = this.props;
    let { invoiceNumber } = this.state.recordPaymentForm;
    return (
      <Modal
        visible={display}
        close={this.handlePaymentsDialogClose}
        title="Add New Payment"
        onOk={this.handleSubmitForm}
        confirmLoading={this.state.loading}
        onCancel={this.handlePaymentsDialogClose}
      >
        <Input
          {...invoiceNumber}
          placeholder="Invoice Number"
          onChange={event => this.handlePaymentsForm(event, "invoiceNumber")}
        />    
        {          
          this.state.xeroError ? 
          <p style={{ fontSize: "12px", color: "red", padding: "5px", marginTop: "5px" }}>
            {this.state.xeroError}
          </p> 
          : null
        }    
      </Modal>
    );
  }
}
