import React from "react";
import { Card } from "antd";
import { LIST_COUNTRIES } from "../../../constants";

export default ({ countries, loading }) => {
  if (countries && countries.length) {
    let mapData = [["Country", "Popularity"]];
    countries
      .map((it) => [
        it.country
          ? LIST_COUNTRIES.find((c) => c.code === it.country)["name"]
          : "Not Set",
        it.count,
      ])
      .filter((it) => it[0] !== "Not Set")
      .forEach((it) => mapData.push(it));

    window.google.charts.load("current", {
      packages: ["geochart"],
      mapsApiKey: "AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY",
    });

    window.google.charts.setOnLoadCallback(() => {
      var data = window.google.visualization.arrayToDataTable(mapData);
      var options = {
        colorAxis: { colors: ["rgb(219, 236, 171)", "rgb(97, 132, 0)"] },
      };
      var chart = new window.google.visualization.GeoChart(
        document.getElementById("map")
      );
      chart.draw(data, options);
    });
  }

  return (
    <Card title="Location Map" loading={loading}>
      <div style={{ maxWidth: "100%", overflowX: "scroll" }} id="map" />
    </Card>
  );
};
