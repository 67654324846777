import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { getManagers } from "../../../services/accountManagersService";
import { capitalizeFirstLetter } from "../../../functions";
const { Option } = Select;
export default ({ accountManager, setAccountManager, team = false }) => {
  let [loading, setLoading] = useState(false);
  let [managers, setManagers] = useState([]);
  const getData = async () => {
    setLoading(true);
    let { data } = await getManagers();
    if (data && data.result) {
      let format = data.result.map((it) => {
        let result = {};
        if (it === "TEAM") {
          if (team) {
            result = { value: "all", label: capitalizeFirstLetter(it) };
          } else {
            result = {};
          }
        } else {
          result = { value: it, label: capitalizeFirstLetter(it) };
        }
        return result;
      });
      format = format.filter((e) => e.value);
      setManagers(format);
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Select
      disabled={loading}
      loading={loading}
      value={accountManager}
      style={{ width: "100%" }}
      placeholder="Please select"
      onChange={(value) => {
        setAccountManager(value);
      }}
    >
      {[...managers, { value: null, label: "Not Set" }].map((it) => (
        <Option key={it.value} value={it.value} label={it.label}>
          {it.label}
        </Option>
      ))}
    </Select>
  );
};
