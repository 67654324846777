import React, { useState } from "react";
import ScriptTracking from "./ScriptTracking/ScriptTracking";
import PageTracking from "./PageTracking/PageTracking";
import { Tabs } from "antd";

const { TabPane } = Tabs;

const Badge = ({match}) => {
  
  let [currentTab, setTab] = useState(match.params.section);

  return (
    <div>
      <Tabs activeKey={currentTab} onChange={(tab) => setTab(tab)}>
        <TabPane tab="Page Tracking" key="page-tracking">
          <PageTracking />
        </TabPane>
        <TabPane tab="Script Tracking" key="script-tracking">
          <ScriptTracking />
        </TabPane>        
      </Tabs>
    </div>
  );
};
export default Badge;
