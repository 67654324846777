import React from "react";
import { Chart, Geom, Axis, Tooltip, Coord, Label, Legend } from "bizcharts";
import DataSet from "@antv/data-set";
import classes from "./PieChart.module.less";

export default class Innerlabel extends React.Component {
  render() {
    const { DataView } = DataSet;
    const data = this.props.data;
    const dv = new DataView();
    dv.source(data).transform({
      type: "percent",
      field: "total",
      dimension: "source",
      as: "percent"
    });
    const cols = {
      percent: {
        formatter: val => {
          val = (val * 100).toFixed(2) + "%";
          return val;
        }
      }
    };

    return data.length > 0 ? (
      <div className={classes.chart}>
        <Chart data={dv} scale={cols} height={350} width={350}>
          <Coord type="theta" radius={0.75} />
          <Axis name="percent" />
          <Legend position="bottom-center" offsetY={-40} offsetX={30} />
          <Tooltip
            showTitle={false}
            itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
          />
          <Geom
            type="intervalStack"
            position="percent"
            color={["source", ["#94d137", "#0e9dec"]]}
            tooltip={[
              "source*percent",
              (source, percent) => {
                percent = (percent * 100).toFixed(2) + "%";
                return {
                  name: source,
                  value: percent
                };
              }
            ]}
            style={{
              lineWidth: 1,
              stroke: "#fff"
            }}
          >
            <Label
              content="percent"
              offset={-40}
              textStyle={{
                rotate: 0,
                textAlign: "center",
                shadowBlur: 2,
                shadowColor: "rgba(0, 0, 0, .45)"
              }}
            />
          </Geom>
        </Chart>
      </div>
    ) : null;
  }
}
