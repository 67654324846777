import axios from "../axios";
import EventBus from "eventbusjs";
export async function newCustomTask(values) {
  let response = null;
  try {
    response = await axios.post("/custom-task", { ...values });
    EventBus.dispatch("GET_NOTIFICATIONS", this);
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
export async function deleteTask(id) {
  let response = null;
  try {
    response = await axios.delete("/task/" + id);
    EventBus.dispatch("GET_NOTIFICATIONS", this);
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
export async function updateTaskCompletion(values) {
  let response = null;
  try {
    console.log(values);
    response = await axios.put("/task/", { ...values });
    if (values.value === "COMPLETED") {
      EventBus.dispatch("GET_NOTIFICATIONS", this);
    } else {
      EventBus.dispatch("UPDATE_NOTIFICATION", this, values);
    }
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
