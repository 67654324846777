import React, { useState, useEffect } from "react";
import classes from "./AppDocuments.module.less";
import { Button, Row, Table, notification, Tag } from "antd";
import NewDocumentModal from "./NewDocumentModal/NewDocumentModal";
import moment from "moment";
import AttachmentCard from "./AttachmentCard/AttachmentCard";
import { getAppDocuments } from "../../services/appDistributionService";
export default ({ appId }) => {
  let [displayModal, setDisplayModal] = useState(false);
  let [loading, setLoading] = useState(true);
  let [docs, setDocuments] = useState({});

  const getData = async () => {
    if (appId) {
      let { data } = await getAppDocuments(appId);
      if (data && data.docs) {
        setDocuments(data.docs);
      }
      setLoading(false);
    }
  };
  let columns = [
    {
      key: "createdAt",
      dataIndex: "createdAt",
      title: "Date",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    { key: "uploader", dataIndex: "uploader", title: "Uploader" },
    {
      key: "description",
      dataIndex: "description",
      title: "Description",
    },
    {
      key: "internal_notes",
      dataIndex: "internal_notes",
      title: "Internal Notes",
    },
  ];
  useEffect(() => {
    getData();
  }, [appId]);

  const removeAttachmentFromState = (id_doc, category, id_remove) => {
    const dataCopy = docs[category];
    const index = dataCopy.findIndex((item) => item.PK_App_Doc === id_doc);
    const newAttachments = dataCopy[index].app_documents_attachments.filter(
      (item) => item.id != id_remove
    );
    dataCopy[index] = {
      ...dataCopy[index],
      app_documents_attachments: newAttachments,
    };
    let updateData = { ...docs, [category]: dataCopy };
    notification.info({
      message: "Attachment removed.",
    });
    setDocuments({ ...updateData });
  };

  const { COPYRIGHT, AUTHORIZATION, ...otherDocs } = docs;
  return (
    <div className={classes.container}>
      <Row className={classes.header} type="flex" justify="space-between">
        <h3>
          Copyright <Tag color="blue">Admin Only</Tag>
        </h3>

        <Button
          onClick={() => setDisplayModal(true)}
          disabled={loading}
          type="primary"
          icon="plus"
        >
          Add New
        </Button>
      </Row>
      <Table
        loading={loading}
        pagination={
          COPYRIGHT && COPYRIGHT.length > 15 ? { pageSize: 15 } : false
        }
        expandedRowRender={(record) => (
          <div style={{ margin: 0 }}>
            <AttachmentCard
              title="Admin Attachments"
              deleteHandler={(id_remove) =>
                removeAttachmentFromState(
                  record.PK_App_Doc,
                  record.type,
                  id_remove
                )
              }
              data={record.app_documents_attachments}
            />
            <AttachmentCard
              title="Client Attachments"
              deleteHandler={(id_remove) =>
                removeAttachmentFromState(
                  record.PK_App_Doc,
                  record.type,
                  id_remove
                )
              }
              data={record.app_documents_attachments_clients}
            />
          </div>
        )}
        columns={columns}
        dataSource={COPYRIGHT}
        size="small"
      />
      <Row
        style={{ marginTop: 10 }}
        className={classes.header}
        type="flex"
        justify="start"
      >
        <h3>Authorization</h3>{" "}
      </Row>
      <Table
        loading={loading}
        pagination={
          AUTHORIZATION && AUTHORIZATION.length > 15 ? { pageSize: 15 } : false
        }
        expandedRowRender={(record) => (
          <div style={{ margin: 0 }}>
            <AttachmentCard
              title="Admin Attachments"
              deleteHandler={(id_remove) =>
                removeAttachmentFromState(
                  record.PK_App_Doc,
                  record.type,
                  id_remove
                )
              }
              data={record.app_documents_attachments}
            />
            <AttachmentCard
              title="Client Attachments"
              deleteHandler={(id_remove) =>
                removeAttachmentFromState(
                  record.PK_App_Doc,
                  record.type,
                  id_remove
                )
              }
              data={record.app_documents_attachments_clients}
            />
          </div>
        )}
        columns={columns}
        dataSource={AUTHORIZATION}
        size="small"
      />
      {otherDocs
        ? Object.entries(otherDocs).map(([title, categoryDocs]) => (
            <>
              <Row
                style={{ marginTop: 10 }}
                className={classes.header}
                type="flex"
                justify="start"
              >
                <h3>{title}</h3>
              </Row>
              <Table
                loading={loading}
                pagination={categoryDocs.length > 15 ? { pageSize: 15 } : false}
                columns={columns}
                dataSource={categoryDocs}
                size="small"
                expandedRowRender={(record) => (
                  <div style={{ margin: 0 }}>
                    <AttachmentCard
                      title="Admin Attachments"
                      deleteHandler={(id_remove) =>
                        removeAttachmentFromState(
                          record.PK_App_Doc,
                          record.type,
                          id_remove
                        )
                      }
                      data={record.app_documents_attachments}
                    />
                    <AttachmentCard
                      title="Client Attachments"
                      deleteHandler={(id_remove) =>
                        removeAttachmentFromState(
                          record.PK_App_Doc,
                          record.type,
                          id_remove
                        )
                      }
                      data={record.app_documents_attachments_clients}
                    />
                  </div>
                )}
              />
            </>
          ))
        : null}
      <NewDocumentModal
        visible={displayModal}
        close={() => setDisplayModal(false)}
        actualData={docs}
        updateData={setDocuments}
        appId={appId}
      />
    </div>
  );
};
