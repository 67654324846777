import React, { Component } from "react";
import GoogleAppsDialog from "../GoogleAppsDialog/GoogleAppsDialog";
import Input from "../../UI/Input/Input";
import Axios from "axios";
import AxiosCustom, { publicInstance } from "../../../axios";
import _ from "underscore";
import functions from "../../../functions";
import { Modal, Button } from "antd";

export default class extends Component {
  state = {
    appname: {
      value: "",
      validation: {
        required: true
      },
      valid: false,
      validated: false,
      error: null
    },
    addingApp: false,
    loadingGoogle: false,
    googleApps: [],
    showDialog: false
  };

  handleFieldChange = (event, name) => {
    let field = {
      ...this.state[name],
      validated: false,
      value: event.target.value
    };
    this.setState({ [name]: field });
  };

  searchInGoogle = () => {
    let appname = {
      ...this.state.appname,
      validated: true
    };
    appname.valid = functions.checkValidity(appname.value, appname.validation);
    appname.valid
      ? (appname.error = null)
      : (appname.error = "Enter a valid app name");
    this.setState({ appname: appname });
    if (appname.valid) {
      this.setState({
        showDialog: true,
        addingApp: true
      });
      this.searchGoogleApps();
    }
  };

  searchGoogleApps = () => {
    let url = `google-search/${this.state.appname.value}`;
    publicInstance
      .get(url)
      .then(response => {
        if (
          response.data &&
          response.data.length &&
          typeof response.data === "object"
        ) {
          this.setState({ googleApps: response.data });
        } else {
          this.props.error();
          throw response;
        }
      })
      .catch(error => {
        console.log("error while scraping google", error);
      });
  };

  getAppDetails = () => {
    this.setState({
      loadingGoogle: true
    });
    let headers = { "Content-Type": "text/plain" };
    Axios.get(
      "https://appinchina.space/aicon-scrapers/googleDetails?url=" +
        this.state.link.value,
      { headers: headers }
    )
      .then(response => {
        this.setState({ loadingGoogle: false });
        if (response.data.error) {
          console.log("invalid url");
        } else if (typeof response.data !== "object") {
          this.props.error();
        } else {
          this.handleAppSelected(
            {
              name: _.unescape(response.data.name),
              package: response.data.package
            },
            response.data.name
          );
        }
      })
      .catch(error => {
        this.setState({ loadingGoogle: false });
      });
  };

  handleAddApp = () => {
    let appname = {
      ...this.state.appname,
      validated: true
    };
    appname.valid = functions.checkValidity(appname.value, appname.validation);
    appname.valid
      ? (appname.error = null)
      : (appname.error = "Enter a valid app name");
    this.setState({
      appname: appname
    });
    if (appname.valid) {
      this.setState({
        addingApp: true
      });
      AxiosCustom.post("/apps", {
        name: this.state.appname.value,
        authorized: true
      })
        .then(response => {
          if (response.data.app && typeof response.data.app === "object") {
            this.props.handleAppAdded(response.data.app);
          } else throw response;
        })
        .catch(err => {
          console.log(err);
          this.setState({ addingApp: false });
        });
    }
  };

  handleAppSelected = app => {
    let url = `google-details/${app.package}`;
    publicInstance
      .get(url)
      .then(response => {
        if (response.data && typeof response.data === "object") {
          AxiosCustom.post("/apps", {
            image_data: app.icon,
            name: app.title,
            authorized: true,
            main_information: {
              company_name: app.developer,
              package_name: app.package,
              industry: functions.splitAndCapitalize(response.data.category[0])
            }
          })
            .then(response => {
              if (response.data.app && typeof response.data.app === "object") {
                this.props.handleAppAdded(response.data.app);
              } else throw response;
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          this.props.error();
          throw response;
        }
      })
      .catch(error => {
        console.log("error while scraping google details", error);
      });
  };

  appStateReset = () => {
    const appname = {
      ...this.state.appname,
      validated: false,
      value: "",
      error: null,
      valid: false
    };
    this.setState({
      appname: appname,
      addingApp: false
    });
  };

  handleGoogleAppSelected = app => {
    this.setState({ showDialog: false });
    this.handleAppSelected(app, this.state.appname.value);
  };

  handleNoGoogleAppSelected = () => {
    this.setState({
      showDialog: false,
      addingApp: false
    });
  };

  handleDialogClose = () => {
    this.appStateReset();
    this.props.handleDialog();
  };

  render() {
    const { display } = this.props;
    return (
      <Modal
        visible={display}
        title="Adding new app, what will be the name of the app ?"
        onCancel={this.handleDialogClose}
        okButtonProps={{ disabled: true }}
        footer={[
          <Button
            onClick={this.searchInGoogle}
            type="primary"
            width="170px"
            margin="20px"
            loading={this.state.addingApp}
          >
            Search In Google
          </Button>,
          <Button
            onClick={this.handleAddApp}
            type="primary"
            width="170px"
            loading={this.state.addingApp}
          >
            Add Manually
          </Button>
        ]}
      >
        <Input
          name="appname"
          placeholder="App name"
          value={this.state.appname.value}
          onChange={event => this.handleFieldChange(event, "appname")}
          valid={this.state.appname.valid}
          validated={this.state.appname.validated}
          errormessage={this.state.appname.error}
        />
        <GoogleAppsDialog
          display={this.state.showDialog}
          apps={this.state.googleApps}
          select={this.handleGoogleAppSelected}
          cancel={this.handleNoGoogleAppSelected}
        />
      </Modal>
    );
  }
}
