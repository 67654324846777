import React from "react";
import { Form, Select, Input, Checkbox } from "antd";
import {
  APP_DETAILS_OPTIONS,
  LIST_COUNTRIES,
  LIST_INDUSTRIES,
  ICP_STATUS,
} from "../../../constants";
import classes from "./DistributionSection.module.less";
import moment from "moment";
import { compareVersions } from "../../../functions";
const { Item } = Form;
const { Option } = Select;
const DistributionSection = ({
  app,
  loading,
  getFieldDecorator,
  handleAppStatus,
}) => {
  return (
    <>
      <div className={classes.container}>
        <Item label="App Form Status" style={{ margin: 0, width: "100%" }}>
          {getFieldDecorator(`app.main_information.app_form_status`, {
            initialValue: app.main_information.app_form_status,
          })(
            <Select style={{ width: "100%" }}>
              {APP_DETAILS_OPTIONS.app_form_status.map((it) => (
                <Option key={it.value} value={it.value} label={it.label}>
                  {it.label}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item label="Company Form Status" style={{ margin: 0, width: "100%" }}>
          {getFieldDecorator(`app.main_information.business_form_status`, {
            initialValue: app.main_information.business_form_status,
          })(
            <Select style={{ width: "100%" }}>
              {APP_DETAILS_OPTIONS.business_form_status.map((it) => (
                <Option key={it.value} value={it.value} label={it.label}>
                  {it.label}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item
          label="Copyright Form Status"
          style={{ margin: 0, width: "100%" }}
        >
          {getFieldDecorator(`app.main_information.copyright_form_status`, {
            initialValue: app.main_information.copyright_form_status,
          })(
            <Select style={{ width: "100%" }}>
              {APP_DETAILS_OPTIONS.copyright_form_status.map((it) => (
                <Option key={it.value} value={it.value} label={it.label}>
                  {it.label}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item
          label="Security Assessment Form Status"
          style={{ margin: 0, width: "100%" }}
        >
          {getFieldDecorator(`app.main_information.security_form_status`, {
            initialValue: app.main_information.security_form_status,
          })(
            <Select style={{ width: "100%" }}>
              {APP_DETAILS_OPTIONS.security_form_status.map((it) => (
                <Option key={it.value} value={it.value} label={it.label}>
                  {it.label}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item
          label="Privacy Policy Status"
          style={{ margin: 0, width: "100%" }}
        >
          {getFieldDecorator(`app.main_information.privacy_policy_status`, {
            initialValue: app.main_information.privacy_policy_status,
          })(
            <Select style={{ width: "100%" }}>
              {APP_DETAILS_OPTIONS.security_form_status.map((it) => (
                <Option key={it.value} value={it.value} label={it.label}>
                  {it.label}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item
          label="Authorization Letter Status"
          style={{ margin: 0, width: "100%" }}
        >
          {getFieldDecorator(
            `app.main_information.authorization_letter_status`,
            {
              initialValue: app.main_information.authorization_letter_status,
            }
          )(
            <Select style={{ width: "100%" }}>
              {APP_DETAILS_OPTIONS.security_form_status.map((it) => (
                <Option key={it.value} value={it.value} label={it.label}>
                  {it.label}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item label="APK Reclaim Status" style={{ margin: 0, width: "100%" }}>
          {getFieldDecorator(`app.main_information.apk_reclaim_status`, {
            initialValue: app.main_information.apk_reclaim_status,
          })(
            <Select style={{ width: "100%" }}>
              {APP_DETAILS_OPTIONS.security_form_status.map((it) => (
                <Option key={it.value} value={it.value} label={it.label}>
                  {it.label}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item label="Country" style={{ margin: 0, width: "100%" }}>
          {getFieldDecorator(`app.main_information.country`, {
            initialValue: app.main_information.country,
          })(
            <Select style={{ width: "100%" }}>
              {LIST_COUNTRIES.map((it) => (
                <Option key={it.code} value={it.code} label={it.name}>
                  {it.name}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item label="City" style={{ margin: 0, width: "100%" }}>
          {getFieldDecorator(`app.main_information.city`, {
            initialValue: app.main_information.city,
          })(<Input />)}
        </Item>
        <Item label="Phone Number" style={{ margin: 0, width: "100%" }}>
          {getFieldDecorator(`app.main_information.telephone`, {
            initialValue: app.main_information.telephone,
          })(<Input />)}
        </Item>

        <Item label="Google Play Category" style={{ margin: 0, width: "100%" }}>
          {getFieldDecorator(`app.main_information.industry`, {
            initialValue: app.main_information.industry,
          })(
            <Select style={{ width: "100%" }}>
              {LIST_INDUSTRIES.map((it) => (
                <Option key={it.code} value={it.code} label={it.name}>
                  {it.name}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item label="Google Play Link" style={{ margin: 0, width: "100%" }}>
          {getFieldDecorator(`app.main_information.google_play_link`, {
            initialValue: app.main_information.google_play_link,
          })(<Input />)}
        </Item>

        <Item label="Package Name" style={{ margin: 0, width: "100%" }}>
          {getFieldDecorator(`app.main_information.package_name`, {
            initialValue: app.main_information.package_name,
          })(<Input />)}
        </Item>
        <Item label="ICP Status" style={{ margin: 0, width: "100%" }}>
          {getFieldDecorator(`app.main_information.icp_status`, {
            initialValue: app.main_information.icp_status,
          })(
            <Select style={{ width: "100%" }}>
              {ICP_STATUS.map((it) => (
                <Option key={it.value} value={it.value} label={it.label}>
                  {it.label}
                </Option>
              ))}
            </Select>
          )}
        </Item>

        <Item label="ICP Domain" style={{ margin: 0, width: "100%" }}>
          {getFieldDecorator(`app.main_information.icp_domain`, {
            initialValue: app.main_information.icp_domain,
          })(<Input />)}
        </Item>

        <Item label="ICP Number" style={{ margin: 0, width: "100%" }}>
          {getFieldDecorator(`app.main_information.icp_number`, {
            initialValue: app.main_information.icp_number,
          })(<Input />)}
        </Item>

        <Item label="PSB Number" style={{ margin: 0, width: "100%" }}>
          {getFieldDecorator(`app.main_information.psb_number`, {
            initialValue: app.main_information.psb_number,
          })(<Input />)}
        </Item>
      </div>

      <Item label="" className={classes.checkbox}>
        {getFieldDecorator(`app.main_information.badge_pages_apk`, {
          initialValue: app.id ? app.main_information.badge_pages_apk : false,
          valuePropName: "checked",
        })(<Checkbox></Checkbox>)}
        <p> Include AppInChina APK in the Badge Pages</p>
      </Item>
    </>
  );
};

export default DistributionSection;
