import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import AppDistribution from "../NewAppDistribution/NewAppDistribution";
import AppDetailsDistribution from "../AppDetailsDistribution/AppDistribution";
import AppDocuments from "../AppDocuments/AppDocuments";
import AppHistory from "../AppHistory/AppHistory";
import ApkReclaim from "../ApkReclaim/ApkReclaim";
import AppLocalization from "../AppLocalization/AppLocalization";
import PaymentsAndAnalytics from "../PaymentsAndAnalytics";
import CloudStore from "../CloudStore/CloudStore";
import history from "../../history";
import classes from "./AppDetails.module.less";
import AppHeader from "./AppHeader/AppHeader";
import AppProjects from "../AppProjects/AppProjects";
import { getAppDetails } from "../../services/appDistributionService";
import { getAppHistory } from "../../services/appHistory";
const TabPane = Tabs.TabPane;

const AppDetails = ({ ...props }) => {
  let [loading, setLoading] = useState(true);
  let [app, setApp] = useState({});
  let [appForms, setAppForms] = useState([]);
  let [documents, setDocuments] = useState([]);
  let [apk_reclaim, setApkReclaim] = useState([]);
  let [loadingHistory, setLoadingHistory] = useState(false);
  const getHistory = async () => {
    setLoadingHistory(true);
    try {
      if (app.id) {
        const { data } = await getAppHistory(app.id);
        if (data && data.result) {
          setAppForms([...data.result.app_form]);
          setDocuments([...data.result.documents]);
          setApkReclaim([...data.result.apk]);
        }
        setLoadingHistory(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleTabChange = async (tab) => {
    let { id } = props.match.params;
    history.push(`/apps/${id}/${tab}`);
  };
  let { section } = props.match.params;
  const getApp = async () => {
    let { id } = props.match.params;
    setLoading(true);
    let { data } = await getAppDetails(id);
    if (data && data.app) {
      setApp(data.app);
    }
    setLoading(false);
  };

  useEffect(() => {
    getApp();
  }, [props.match.params.id]);

  return (
    <div className={classes.container}>
      <AppHeader
        getHistory={getHistory}
        loadingApp={loading}
        app={app}
        getApp={getApp}
        {...props}
      />
      <Tabs
        defaultActiveKey="details"
        onChange={handleTabChange}
        activeKey={section}
      >
        <TabPane tab="Details" key="details">
          <AppDetailsDistribution
            {...props}
            appInfo={app}
            loadingApp={loading}
            getApp={getApp}
            updateAppInfo={setApp}
          />
        </TabPane>
        <TabPane tab="Projects" key="projects">
          <AppProjects {...props} appId={app.id} />
        </TabPane>
        <TabPane tab="History" key="history">
          <AppHistory
            getHistory={getHistory}
            appForms={appForms}
            documents={documents}
            apk_reclaim={apk_reclaim}
            loadingHistory={loadingHistory}
            appId={app.id}
            {...props}
          />
        </TabPane>
        <TabPane tab="App Stores" key="distribution">
          <AppDistribution loadingApp={loading} {...props} appInfo={app} />
        </TabPane>
        <TabPane tab="Documents" key="documents">
          <AppDocuments {...props} appId={app.id} loadingApp={loading} />
        </TabPane>
        <TabPane tab="APK Reclaim" key="apk-reclaim">
          <ApkReclaim {...props} appId={app.id} loadingApp={loading} />
        </TabPane>
        <TabPane tab="Localization" key="localization">
          <AppLocalization {...props} />
        </TabPane>
        <TabPane tab="Payments & Analytics" key="analytics_payments">
          <PaymentsAndAnalytics appInfo={app} {...props} />
        </TabPane>
        <TabPane tab="Cloud Store" key="cloud-store">
          <CloudStore appId={props.match.params.id} {...props} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default AppDetails;
