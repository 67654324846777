import React, { Component } from "react";
import classes from "./QRDialog.module.css";
import { QRCode } from "react-qr-svg";
import { Button, Modal } from "antd";

class ForgotPasswordDialog extends Component {
  state = {};

  render() {
    return (
      <Modal
        visible={this.props.display}
        onCancel={this.props.close}
        width="500px"
        footer={[
          <a
            href={`https://apps.appinchina.com.cn/details/${this.props.name}`}
            rel="noopener noreferrer"
            target="_blank"
            className={classes.link}
          >
            <Button type="primary" icon="android">Open</Button>
          </a>
        ]}
      >
        <div className={classes.container}>
          <QRCode
            bgColor="#FFFFFF"
            fgColor="#000000"
            level="Q"
            style={{ width: 256 }}
            value={`https://apps.appinchina.com.cn/details/${this.props.name}`}
          />
        </div>
      </Modal>
    );
  }
}

export default ForgotPasswordDialog;
