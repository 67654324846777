import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import Apps from "../Apps";
import AppsPending from "../../AppsPending/AppsPending";
import AppsRemoved from "../AppsRemoved";
import { getManagers } from "../../../services/accountManagersService";
import LoadingSpin from "../../UI/LoadingSpin/LoadingSpin";
const TabPane = Tabs.TabPane;
export default ({ ...props }) => {
  let [loading, setLoading] = useState(false);
  let [activeTab, setActiveTab] = useState("under-distribution");
  let [accountManager, setAccountManager] = useState("");
  const callback = (key) => {
    props.history.push(`/apps-details/${key}`);
  };
  const getAccountManager = async () => {
    let section = props.match.params.section
      ? props.match.params.section
      : "under-distribution";
    setActiveTab(section);
    let name = localStorage.getItem("login-email")
      ? localStorage
          .getItem("login-email")
          .split("@")[0]
          .toUpperCase()
      : "";
    let managers = [];
    setLoading(true);
    let { data } = await getManagers();
    if (data && data.result) {
      managers = data.result;
    }
    setLoading(false);
    let result = managers.find((e) => e === name) ? name : "all";
    setAccountManager(result);
  };
  useEffect(() => {
    getAccountManager();
  }, []);

  return loading ? (
    <LoadingSpin />
  ) : (
    <>
      <Tabs defaultActiveKey={activeTab} onChange={callback}>
        <TabPane tab="Under Distribution" key="under-distribution">
          <Apps />
        </TabPane>
        <TabPane tab="Authorization Pending" key="authorization-pending">
          <AppsPending />
        </TabPane>
        {accountManager !== "" ? (
          <TabPane tab="My Apps" key="my-apps">
            <Apps manager={accountManager} />
          </TabPane>
        ) : null}
        <TabPane tab="Apps Removed" key="removed">
          <AppsRemoved />
        </TabPane>
      </Tabs>
    </>
  );
};
