import React, { useState, useEffect } from "react";
import {
  getAlertsTable,
  markAsSolved,
} from "../../services/paymentsAlerts";
import { Table, Button, Tag, Popconfirm, message } from "antd";

export default () => {
  const [loading, setLoading] = useState(false);
  const [alertData, setAlertData] = useState([]);
  
  const fetchData = async () => {
    try {
      setLoading(true);
      const { data } = await getAlertsTable();
      setAlertData(data.result);
      setLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);
  
  const alertColumns = [
    {
      title: "App Name",
      dataIndex: "app",
      key: "app",
    },
    {
      title: "Date Alert Happen",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Percentage Drop",
      dataIndex: "percentageDrop",
      key: "percentageDrop",
    },
    {
      title: "Amount (7 day average)",
      dataIndex: "amount7Days",
      key: "amount7Days",
    },
    {
      title: "Amount when Alert Happen",
      dataIndex: "amountAlertHappen",
      key: "amountAlertHappen",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) =>
        status ? (
          <Tag color="green">Solved</Tag>
        ) : (
          <Popconfirm
            placement="top"
            title="Are you sure to mark as solved?"
            onConfirm={() => markAlertAsSolved(record.PK_Alert)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">Mark as solved</Button>
          </Popconfirm>
        ),
    },
  ];

  const markAlertAsSolved = async (id) => {
    try {
      const { data } = await markAsSolved(id);
      if (data.message === "success") {
        const alertDataCopy = alertData;
        const index = alertDataCopy.map(({ PK_Alert }) => PK_Alert).indexOf(id);
        alertDataCopy[index] = {
          ...alertDataCopy[index],
          status: true,
        };
        setAlertData([...alertDataCopy]);
        message.success("Information updated");
      }
    } catch (error) {
      message.error("An error has ocurred");
    }
  };
  
  return (
    <Table
      style={{ marginTop: 15 }}
      rowKey="PK_Alert"
      columns={alertColumns}
      dataSource={alertData}
      loading={loading}
    />
  );
};
