import React, { Component } from 'react';
import classes from './GoogleAppsDialog.module.css';
import Dialog from '../../UI/Dialog/Dialog';
import Preloader from '../../UI/Preloader/Preloader';

class GoogleAppsDialog extends Component {
	render() {
		let apps = this.props.apps.map((it, index) => {
			return (
				<div key={index} className={classes.Row} onClick={() => this.props.select(it)}>
					<img src={it.icon.replace("appinchina.xyz","appinchina.space")} alt="App Icon" />
					<div>
						<span>{it.title}</span>
						<span className={classes.Company}>{it.developer}</span>
					</div>
				</div>
			)
		})
		let loading = !(this.props.apps && this.props.apps.length)
		return (
			<Dialog display={this.props.display} width="400px">
				<h2 className={classes.Title}>Which one is your app?</h2>
				<div style={{ display: (loading ? "none" : "block"), maxHeight: 345, overflowY: "scroll" }}>
					{apps}
				</div>
				<div style={{ display: (loading ? "block" : "none") }} className={classes.Loader}>
					<Preloader size={3} />
					<p>Searching...</p>
				</div>
				<div className={[classes.Row, classes.NotMyApp].join(' ')} onClick={() => this.props.cancel()}>
					<div>
						<span>The app isn't on this list</span>
					</div>
				</div>
			</Dialog>
		)
	}
}

export default GoogleAppsDialog;