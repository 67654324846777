import React, { Fragment } from "react";
import { Badge } from "antd";

export default ({ children, enabled }) =>
  enabled ? (
    <Badge
      count={
        <span
          style={{
            border: "1px solid red",
            color: "red",
            height: "19px",
            lineHeight: "17px",
            width: "38px",
            textAlign: "center",
            fontWeight: "bold",
            borderRadius: "4px",
            fontSize: "10px",
          }}
        >
          NEW
        </span>
      }
    >
      {children}
    </Badge>
  ) : (
    <Fragment>{children}</Fragment>
  );
