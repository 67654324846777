import React, { Component, Fragment } from 'react';
import classes from './Login.module.css';
import LoginForm from './LoginForm/LoginForm'
import Button from '../UI/Button/Button';
import Auth from '../../auth';
const auth = new Auth();

class Login extends Component {

	state = {
		loading: false,
		displayForgotPassword: false,
	}

	render() {
		return (
			<Fragment>
				<LoginForm submit={this.login}>
					<h2 className={classes.Title}>Welcome</h2>
					<Button width="100%" click={this.login} loading={this.state.loading}>Login with Google</Button>
				</LoginForm>
			</Fragment>
		)
	}

	login = (event) => {
		event.preventDefault()
		this.setState({ loading: true })
		auth.login()
	}

}

export default Login;