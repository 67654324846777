import React, { useState } from "react";
import { Table, Button, Row, Icon, Tag } from "antd";
import moment from "moment";
import Preview from "../Preview/Preview";
export default ({ loading, appForms, appId }) => {
  let [modified, setModified] = useState([]);
  let [actual, setActual] = useState({});
  const getTag = (item) => {
    if (item.is_draft === false && item.is_apk_update) {
      return <Tag color="green">New Version</Tag>;
    } else if (item.is_apk_update === false) {
      return <Tag color="cyan">App Information</Tag>;
    } else if (item.is_draft === true) {
      return <Tag color="green">New Version</Tag>;
    }
  };
  let columns = [
    {
      key: "submission",
      dataIndex: "submission",
      title: "Submission",
      render: (date) => (date ? moment(date).format("YYYY-MM-DD HH:mm") : ""),
    },
    {
      key: "version",
      dataIndex: "version",
      title: "Version",
      render: (version) => (version ? version : "N/A"),
    },
    {
      key: "appname_en",
      dataIndex: "appname_en",
      title: "App Name",
    },
    {
      key: "type",
      dataIndex: "type",
      title: "Type",
      render: (_, item) => getTag(item),
    },
    {
      key: "submitted_by",
      dataIndex: "submitted_by",
      title: "Submitted By",
    },
    {
      key: "options",
      dataIndex: "options",
      title: "",
      render: (_, item) => (
        <Button
          onClick={() => {
            if (item.original_app_form !== null) {
              console.log("original_app_form is set", appForms);
              const originalForm = appForms.find(
                ({ Form_id }) => Form_id === item.original_app_form
              );
              let result = [];
              for (let key in originalForm) {
                if (item[key] !== originalForm[key]) {
                  result.push(key);
                }
              }
              setModified(result);
            } else {
              let actIndex = appForms
                .map((x) => {
                  return x.Form_id;
                })
                .indexOf(item.Form_id);
              if (appForms[actIndex + 1] !== undefined) {
                let data = appForms[actIndex + 1];
                let result = [];
                for (let key in data) {
                  if (item[key] !== data[key]) {
                    result.push(key);
                  }
                }
                setModified(result);
              } else {
                setModified([]);
              }
            }
            setActual(item);
          }}
          type="link"
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <div>
      {actual.Form_id ? (
        <Row type="flex" justify="start">
          <Button
            disabled={loading}
            style={{ marginBottom: 20 }}
            onClick={() => setActual({})}
          >
            <Icon type="left" />
            Go back
          </Button>
        </Row>
      ) : null}

      {actual.Form_id ? (
        <Preview modified={modified} data={actual} />
      ) : (
        <div>
          <Table
            loading={loading}
            dataSource={appForms}
            columns={columns}
            scroll={{ x: 800 }}
            size="small"
            pagination={appForms.length > 10 ? { pageSize: 10 } : false}
          />
        </div>
      )}
    </div>
  );
};
