import React, { useState } from "react";
import {
  Modal,
  Button,
  Select,
  Form,
  Row,
  Tag,
  Input,
  Tooltip,
  Icon,
  Checkbox,
} from "antd";
import { ROLES } from "../../../../constants";
import { newClient } from "../../../../services/clientService";
import classes from "../ClientsLinked.module.less";
const { Option } = Select;
export default Form.create({ name: "newClientApp" })(
  ({ form, appForm, ...props }) => {
    const [loading, setLoading] = useState(false);
    const { getFieldDecorator } = form;
    const makeRequest = async ({ role, ...values }) => {
      setLoading(true);
      let { data } = await newClient(values);
      if (data && data.client) {
        let appUsers = appForm.getFieldValue(`app.users`);
        appForm.setFieldsValue({
          "app.users": [
            ...appUsers,
            {
              id: data.client.id,
              email: data.client.email,
              first_name: data.client.first_name,
              last_name: data.client.last_name,
              position: data.client.position,
              clients_apps: { role: role },
            },
          ],
        });
      }
      form.resetFields();
      props.close();
      setLoading(false);
    };
    const handleSubmitUser = () => {
      form.validateFields((err, values) => {
        if (!err) {
          makeRequest(values);
        }
      });
    };

    return (
      <Modal
        width={500}
        title="Add New Client Linked"
        visible={props.display}
        onCancel={props.close}
        footer={null}
      >
        <div>
          <h2>ROLES:</h2>
          <p>
            <Tag color="blue">Owner</Tag> Provides full access to the account.
            Owners can invite new users, edit information and submit updates.
          </p>
          <p>
            <Tag color="cyan">Admin</Tag> Provides full access to the account.
            Admins can edit information and submit updates but they can not
            invite new users.
          </p>
          <p>
            <Tag color="green">Viewer</Tag> The user can view all data in the
            account but can not make any changes.
          </p>
          <p>
            <Tag color="purple">Payments Manager</Tag> The user can interact 
            exclusively with the AppInChina Pay data.
          </p>
        </div>
        <Form hideRequiredMark={true}>
          <Form.Item label="Email">
            {getFieldDecorator(`email`, {
              rules: [{ required: props.display, message: "Required" }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="First Name">
            {getFieldDecorator(`first_name`, {
              rules: [{ required: props.display, message: "Required" }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Last Name">
            {getFieldDecorator(`last_name`, {
              rules: [{ required: props.display, message: "Required" }],
            })(<Input />)}
          </Form.Item>
          <Form.Item
            className={classes.blocked}
            label={
              <Tooltip title="This will prevent the user from logging into the clients dashboard. Useful for user acquisition promoters.">
                Blocked <Icon type="question-circle" />
              </Tooltip>
            }
          >
            {getFieldDecorator(`blocked`, { valuePropName: "checked" })(
              <Checkbox />
            )}
          </Form.Item>
          <Form.Item label="Position">
            {getFieldDecorator(`position`, {
              rules: [{ required: props.display, message: "Required" }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Location">
            {getFieldDecorator(`location`, {
              rules: [{ required: props.display, message: "Required" }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Role">
            {getFieldDecorator(`role`, {
              rules: [{ required: props.display, message: "Required" }],
              initialValue: "OWNER",
            })(
              <Select style={{ width: "100%" }}>
                {ROLES.map(({ value, label }) => (
                  <Option key={value} value={value} label={label}>
                    {label}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Row type="flex" justify="center" align="middle">
            <Button
              loading={loading}
              disable={loading}
              type="primary"
              onClick={() => handleSubmitUser()}
              style={{ marginTop: 10 }}
            >
              Add
            </Button>
          </Row>
        </Form>
      </Modal>
    );
  }
);
