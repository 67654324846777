import React from "react";
import classes from "./GeneralInfoCard.module.less";
import { Card, Form, DatePicker, Select, Checkbox } from "antd";
import { compareVersions } from "../../../functions";
import moment from "moment";
const { Item } = Form;
const { Option } = Select;
export default ({ getFieldDecorator, appInfo }) => {
  return (
    <Card title="General Information" style={{ marginTop: 20 }}>
      <div className={classes.container}>
        <Item label="Last Contact Date" style={{ margin: 0, width: "100%" }}>
          {getFieldDecorator(`app.main_information.last_contact_date`, {
            initialValue: appInfo.main_information.last_contact_date
              ? moment(appInfo.main_information.last_contact_date)
              : null,
          })(<DatePicker style={{ width: "100%" }} />)}
        </Item>
        <Item
          label="Contact Reminder Interval"
          style={{ margin: 0, width: "100%" }}
        >
          {getFieldDecorator(`app.main_information.contact_reminder_interval`, {
            initialValue: appInfo.main_information.contact_reminder_interval,
          })(
            <Select
              placeholder="Days before adding a reminder"
              style={{ width: "100%" }}
            >
              <Option value={30}>30 days since last contact</Option>
              <Option value={60}>60 days since last contact</Option>
              <Option value={90}>90 days since last contact</Option>
            </Select>
          )}
        </Item>
        <Item label="Current Live Version" style={{ margin: 0, width: "100%" }}>
          {getFieldDecorator(`app.main_information.current_live_version`, {
            initialValue: appInfo.main_information.current_live_version,
          })(
            <Select
              placeholder="Select an option..."
              style={{ width: "100%" }}
              disabled={!appInfo.app_forms || !appInfo.app_forms.length}
            >
              <Option value={null}>Select an option...</Option>
              {!!appInfo.app_forms &&
                !!appInfo.app_forms.length &&
                appInfo.app_forms.map((it) => (
                  <Option value={it.PK_App_Form}>{`${it.version} (${moment(
                    it.updatedAt
                  ).format("YYYY-MM-DD")}) - ${
                    it.is_apk_update ? "New APK" : "App Form Update"
                  }`}</Option>
                ))}
            </Select>
          )}
        </Item>
      </div>
      <Item style={{ margin: 0, textAlign: "left" }}>
        {getFieldDecorator(`app.main_information.publish_on_tencent`, {
          initialValue: appInfo.id
            ? appInfo.main_information.publish_on_tencent
            : false,
          valuePropName: "checked",
        })(
          <Checkbox>
            Publish on Tencent. This will make the Privacy Policy (Detailed){" "}
            <b>required</b> on the client's app form.
          </Checkbox>
        )}
      </Item>
    </Card>
  );
};
