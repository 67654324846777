import React, { useState } from "react";
import { Row, Col, Form, Select, Checkbox, Button } from "antd";
import AdvancedFilter from "./AdvancedFilters";
import classes from "./Mailing.module.less";
import { mailingSearch, multiSelectSearch } from "../../services/globalSearch";
import { getAppsUnderDistribution } from "../../services/appsService";
const { Option } = Select;

export default Form.create()(({ form, nextStep, initialValues = {} }) => {
  const [checked, setChecked] = useState(false);
  const [loadingOptions, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const { getFieldDecorator } = form;
  const [emailOptions, setemailOptions] = useState([]);
  const getUsers = async (values) => {
    setLoading(true);
    const { results } = await mailingSearch({ apps: values });
    setLoading(false);
    let emails = [];
    if (results && results[0].users) {
      results.map(({ users }) =>
        users.map(({ email }) => {
          if (emails.indexOf(email) === -1) {
            emails.push(email);
          }
        })
      );
    }

    form.setFieldsValue({ emails });
  };
  const filterClientsApps = (values) => {
    if (values.length > 0) {
      getUsers(values);
    } else {
      clearFilters();
    }
  };

  const getFilteredApps = (filters = {}) => {
    let allFilters = "";
    let business_form_status =
      filters.business_form_status && filters.business_form_status.length > 0
        ? filters.business_form_status.join(",")
        : null;
    business_form_status
      ? (allFilters =
          allFilters + `business_form_status=${business_form_status}&`)
      : (allFilters = allFilters);
    let app_form_status =
      filters.app_form_status && filters.app_form_status.length > 0
        ? filters.app_form_status.join(",")
        : null;
    app_form_status
      ? (allFilters = allFilters + `app_form_status=${app_form_status}&`)
      : (allFilters = allFilters);
    let copyright_form_status =
      filters.copyright_form_status && filters.copyright_form_status.length > 0
        ? filters.copyright_form_status.join(",")
        : null;
    copyright_form_status
      ? (allFilters =
          allFilters + `copyright_form_status=${copyright_form_status}&`)
      : (allFilters = allFilters);
    let scc_owner =
      filters.scc_owner && filters.scc_owner.length > 0
        ? filters.scc_owner.join(",")
        : null;
    scc_owner
      ? (allFilters = allFilters + `scc_owner=${scc_owner}&`)
      : (allFilters = allFilters);
    let scc_status =
      filters.scc_status && filters.scc_status.length > 0
        ? filters.scc_status.join(",")
        : null;
    scc_status
      ? (allFilters = allFilters + `scc_status=${scc_status}&`)
      : (allFilters = allFilters);
    let targeted_store =
      filters.targeted_store && filters.targeted_store.length > 0
        ? filters.targeted_store.join(",")
        : null;
    targeted_store
      ? (allFilters = allFilters + `targeted_store=${targeted_store}&`)
      : (allFilters = allFilters);
    let account_manager =
      filters.account_manager && filters.account_manager.length > 0
        ? filters.account_manager.join(",")
        : null;
    account_manager
      ? (allFilters = allFilters + `account_manager=${account_manager}&`)
      : (allFilters = allFilters);
    let confidential =
      filters.confidential && filters.confidential.length > 0
        ? filters.confidential.join(",")
        : null;
    confidential
      ? (allFilters = allFilters + `confidential=${confidential}&`)
      : (allFilters = allFilters);
    let country =
      filters.country && filters.country.length > 0
        ? filters.country.join(",")
        : null;
    country
      ? (allFilters = allFilters + `country=${country}&`)
      : (allFilters = allFilters);
    let industry =
      filters.industry && filters.industry.length > 0
        ? filters.industry.join(",")
        : null;
    industry
      ? (allFilters = allFilters + `industry=${industry}&`)
      : (allFilters = allFilters);
    fetchFilterApps(allFilters);
  };
  const fetchFilterApps = async (all) => {
    setLoading(true);
    let { data: response } = await getAppsUnderDistribution({ all });
    setLoading(false);
    if (response && response.appsWithData) {
      let result = response.appsWithData.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setOptions([...result]);
    }
  };

  const nextStepHandler = () => {
    form.validateFields((err, values) => {
      if (!err) {
        nextStep(values);
      }
    });
  };

  const clearFilters = () => {
    form.setFieldsValue({ emailApps: [], emails: [] });
  };
  const handleSearch = (value) => {
    fetchData(value);
  };

  const fetchData = async (text) => {
    try {
      setLoading(true);
      const { results } = await multiSelectSearch({ text });
      setOptions(results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const fetchUsers = async (text) => {
    try {
      setLoading(true);
      const { results } = await multiSelectSearch({ text, target: "users" });
      setemailOptions(results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div style={{ marginTop: 20 }}>
      <Checkbox
        checked={checked}
        onChange={(ev) => setChecked(ev.target.checked)}
      >
        Show Advanced Filters
      </Checkbox>
      {checked ? (
        <AdvancedFilter clear={clearFilters} filterApps={getFilteredApps} />
      ) : null}
      <Form>
        <b className={classes.labelModal}>Selected Apps:</b>
        <Form.Item>
          {getFieldDecorator("emailApps", {
            initialValue: initialValues.emailApps,
          })(
            <Select
              mode="multiple"
              onSearch={handleSearch}
              onChange={(apps) => filterClientsApps(apps)}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              disabled={loadingOptions}
            >
              {[{ value: "all", label: "All apps" }, ...options].map(
                ({ value, label }) => {
                  return (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  );
                }
              )}
            </Select>
          )}
        </Form.Item>
        <b className={classes.labelModal}>Selected Emails:</b>
        <Form.Item>
          {getFieldDecorator("emails", {
            rules: [
              {
                required: true,
                message: "Required!",
              },
            ],
            initialValue: initialValues.email,
          })(
            <Select
              disabled={loadingOptions}
              mode="multiple"
              showSearch
              onSearch={fetchUsers}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {emailOptions.map(({ value, label }) => (
                <Option key={value} value={label} label={label}>
                  {label}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Form>
      <Row gutter={16} type="flex" justify="end">
        <Col xs={24} md={8} lg={4}>
          <Button
            style={{ width: "100%", margin: "20px 0px" }}
            icon="step-forward"
            onClick={nextStepHandler}
          >
            Next Step
          </Button>
        </Col>
      </Row>
    </div>
  );
});
