import React from "react";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";
import functions from "../../functions";

export default ({
  data = [],
  color = "app",
  position = "hour*average",
  x = "hour",
  y = "average",
  tooltip = [
    "hour*average",
    (hour, average) => {
      average = functions.styleAsNumber(average.toFixed(2));
      return {
        title: "Average Per Hour",
        name: `${hour} hrs`,
        value: average,
      };
    },
  ],
}) => {
  const cols = {
    event: {
      range: [0, 1],
    },
  };
  return (
    <div>
      <Chart height={410} padding={70} forceFit data={data} scale={cols}>
        <Legend />
        <Axis
          name={x}
          label={{ offset: 12, textStyle: { rotate: 60, textAlign: " left " } }}
        />
        <Axis
          name={y}
          label={{
            formatter: (val) => `${functions.styleAsNumber(val)}`,
          }}
        />
        <Tooltip
          crosshairs={{
            type: "y",
          }}
        />
        <Geom
          type="line"
          position={position}
          size={2}
          color={color}
          tooltip={tooltip}
        />
      </Chart>
    </div>
  );
};
