import axios from "../axios";

export async function getHelpArticles() {
  let response = null;
  try {
    response = await axios.get("/help");
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
export async function getHelpArticleById(id) {
  let response = null;
  try {
    response = await axios.get(`/help/${id}`);
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
export async function newHelpArticle(values) {
  let response = null;
  try {
    response = await axios.post("/help-article", {
      ...values,
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
export async function newHelpCategory(values) {
  let response = null;
  try {
    response = await axios.post("/help-category", {
      ...values,
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}

export async function deleteHelpCategory(id) {
  let response = null;
  try {
    response = await axios.delete(`/help-category/${id}`);
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
export async function helpAdvancedSearch({ search }) {
  let response = null;
  try {
    response = await axios.get(`/help-search?search=${search}`);
  } catch (error) {
    console.log("error", error);
  }
  return response;
}

export async function updateHelpCategory(categoryId, values) {
  let response = null;
  try {
    response = await axios.put("/help-category", {
      categoryId,
      ...values,
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
export async function updateHelpArticle(id, values) {
  let response = null;
  try {
    response = await axios.put("/help-article", {
      id,
      ...values,
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}

export async function deleteHelpArticle(id) {
  let response = null;
  try {
    response = await axios.delete(`/help-article/${id}`);
  } catch (error) {
    console.log("error", error);
  }
  return response;
}

export async function newHelpFileAttachment(values) {
  let response = null;
  try {
    response = await axios.post("/help-files", {
      ...values,
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}

export async function updateHelpArticlePositions(data) {
  let response = null;
  try {
    response = await axios.put("/help-article-position", {
      data,
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
export async function updateHelpCategoryPositions(data) {
  let response = null;
  try {
    response = await axios.put("/help-category-position", {
      data,
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
