import history from "./history";
import auth0 from "auth0-js";
import EventBus from "eventbusjs";
import { AUTH_CONFIG } from "./auth0-variables";
import Axios from "./axios";

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    responseType: "token id_token",
    scope: "openid"
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }

  login() {
    return new Promise(resolve => {
      this.auth0.authorize({ connection: "google-oauth2" });
    });
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult, true);
        EventBus.dispatch("GET_DATA", this);
        EventBus.dispatch("ALERT", this, "Login successful");
        Axios.get("/update");
      } else if (err) {
        console.log(`Error: ${err.error}.`, err);
        history.replace("/login");
      }
    });
  }

  setSession(authResult, redirect = false) {
    // console.log("setting session, values: ", authResult);
    // Set the time that the access token will expire at (7 days from the day it was issued)
    let expiresAt = JSON.stringify(604800 * 1000 + new Date().getTime());
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
    localStorage.setItem(
      "imageLocation",
      authResult.idTokenPayload["https://www.appinchina.co/picture"]
    );
    localStorage.setItem(
      "login-email",
      authResult.idTokenPayload["https://www.appinchina.co/email"]
    );
    if (window.mixpanel) {
      window.mixpanel.identify(
        authResult.idTokenPayload["https://www.appinchina.co/email"]
      );
      window.mixpanel.people.set({
        "$email": authResult.idTokenPayload["https://www.appinchina.co/email"],
        "$last_login": new Date()
      });
    }
    // navigate to the home route
    if (redirect) {
      history.replace("/");
    }
  }

  logout() {
    localStorage.clear();
    history.replace("/login");
  }

  isAuthenticated() {
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return (
      new Date().getTime() < expiresAt &&
      window.localStorage.id_token &&
      !window.location.pathname.includes("login")
    );
  }

  refreshToken() {
    if (!this.isAuthenticated()) {
      if (
        window.mixpanel &&
        !!localStorage.getItem("login-email") &&
        localStorage.getItem("login-email") !== "undefined"
      ) {
        window.mixpanel.identify();
        window.mixpanel.people.set({
          "$email": localStorage.getItem("login-email"),
          "$last_login": new Date()
        });
      }
      return new Promise(resolve => {
        this.auth0.checkSession({}, (err, result) => {
          if (!err) {
            this.setSession(result);
          }
          resolve();
        });
      });
    }
  }
}
