import React, { useState, useEffect, Fragment } from "react";
import classes from "../AppLocalization/AppLocalization.module.less";
import {
  Modal,
  Table,
  Button,
  Input,
  Form,
  notification,
  Icon,
  DatePicker,
} from "antd";
import Axios from "../../axios";
import File from "../UI/File/File";
import moment from "moment";
import StatementReportModal from "./StatementReportModal/StatementReportModal";
const { confirm } = Modal;

export default Form.create()(({ ...props }) => {
  const [showReportDialog, setShowReportDialog] = useState(false);
  const [showTransferHistory, setShowTransferDialog] = useState(false);
  const [reports, setReports] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [loadingAcc, setLoadingAcc] = useState(true);
  const [loadingApp, setLoadingApp] = useState(true);
  const [loadingRep, setLoadingRep] = useState(true);
  const [paymentsId, setPaymentsId] = useState({});
  const [uploading, setUploading] = useState({
    report: false,
    transfers: false,
    app: false,
  });
  const [showStatementReport, setShowStatementReport] = useState(false);

  useEffect(() => {
    getAppsChinas();
    getAccountDetails();
    getReports();
  }, []);

  const getAppsChinas = () => {
    Axios.get(`/chinese-apps/${props.match.params.id}`)
      .then((response) => {
        setPaymentsId(response.data.payments);
        setLoadingApp(false);
      })
      .catch((err) => {
        notification.error({
          message: "Error connecting with Chinese Database",
          placement: "bottomLeft",
        });
      });
  };

  const getAccountDetails = () => {
    Axios.get(`/account-details/${props.match.params.id}`)
      .then((response) => {
        if (response.data.account) {
          const { transfer_histories } = response.data.account;
          setTransfers(transfer_histories);
        } else {
          setTransfers([]);
        }
        setLoadingAcc(false);
      })
      .catch((err) => console.log(err));
  };

  const getReports = () => {
    Axios.get(`/statements-reports/${props.match.params.id}`)
      .then((response) => {
        setLoadingRep(false);

        setReports(response.data.statements);
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
    },
    {
      title: "Report",
      dataIndex: "link",
      key: "link",
      render: (text) => (
        <a href={`https://appinchina.space/download/${text}`}>Download</a>
      ),
    },
  ];

  const deleteTransfer = (id) => {
    confirm({
      title: "Do you want to delete this record ?",
      async onOk() {
        try {
          const { data } = await Axios.delete("/transfer-history", {
            data: {
              id,
            },
          });
          if (data.message === "success") {
            const newTransfers = transfers.filter((item) => item.id != id);
            setTransfers(newTransfers);
            notification.success({
              message: "Information Updated",
              placement: "bottomLeft",
            });
          }
        } catch (error) {
          notification.error({
            message: "An error has ocurred",
            placement: "bottomLeft",
          });
        }
      },
    });
  };

  const columnsTransfer = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "110px",
      render: (date) =>
        (!!date && !!date.split("T") && date.split("T").shift()) || "",
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      render: (details) => (
        <p>
          {details.split("\n").map((it) => (
            <span style={{ display: "block" }}>{it}</span>
          ))}
        </p>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: "50px",
      render: (id) => (
        <Icon
          style={{ color: "red" }}
          type="delete"
          onClick={() => deleteTransfer(id)}
        />
      ),
    },
  ];

  const submitNewReport = () => {
    props.form.validateFields(["details", "report"], async (error, values) => {
      try {
        if (!error) {
          setUploading({
            ...uploading,
            report: true,
          });
          await Axios.post("/statements-reports", {
            FK_App: props.match.params.id,
            ...values,
          });
          setReports([
            ...reports,
            {
              date: new Date(),
              details: values.details,
              link: values.report,
            },
          ]);
          props.form.resetFields(["details", "report"]);
          setUploading({
            ...uploading,
            report: false,
          });
          setShowReportDialog(false);
        }
      } catch (error) {}
    });
  };

  const submitNewTransfer = () => {
    props.form.validateFields(
      ["amount", "amount_details", "date"],
      async (error, values) => {
        if (!error) {
          try {
            if (!error) {
              setUploading({
                ...uploading,
                transfers: true,
              });
              Axios.post("/transfer-history", {
                FK_App: props.match.params.id,
                amount: values.amount,
                details: values.amount_details,
                date: values.date.format("YYYY-MM-DD"),
              });
              setTransfers([
                {
                  date: values.date.format("YYYY-MM-DD"),
                  amount: values.amount,
                  details: values.amount_details,
                },
                ...transfers,
              ]);
              props.form.resetFields(["amount", "amount_details", "date"]);
              setUploading({
                ...uploading,
                transfers: false,
              });
              setShowTransferDialog(false);
            }
          } catch (error) {}
        }
      }
    );
  };

  const { getFieldDecorator } = props.form;
  return (
    <div className={classes.container} style={{ textAlign: "left" }}>
      <h2 style={{ marginBottom: 8 }}>Transfer History</h2>
      <Fragment>
        <Button
          style={{ marginBottom: 8 }}
          onClick={() => setShowTransferDialog(true)}
        >
          Add New Transfer
        </Button>
        <Table
          columns={columnsTransfer}
          dataSource={transfers}
          loading={loadingAcc}
          size="small"
        />
      </Fragment>
      <h2>Statements and Reports</h2>
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <Button
          style={{ margin: "10px 0" }}
          onClick={() => setShowReportDialog(true)}
          disabled={loadingRep}
        >
          Submit Report
        </Button>
        <Button
          style={{ margin: 10 }}
          onClick={() => setShowStatementReport(true)}
          type="primary"
          icon="file-pdf"
          disabled={loadingApp}
        >
          Generate Statement Report
        </Button>
      </div>

      <StatementReportModal
        {...props}
        appId={props.match.params.id}
        showStatementReport={showStatementReport}
        setShowStatementReport={setShowStatementReport}
        paymentsId={paymentsId}
      />

      <Table
        columns={columns}
        dataSource={reports}
        loading={loadingRep}
        size="small"
      />
      <Modal
        title="Add New Report"
        visible={showReportDialog}
        onOk={submitNewReport}
        onCancel={() => setShowReportDialog(false)}
        okButtonProps={{
          loading: uploading.report,
          disabled: uploading.report,
        }}
      >
        <Form>
          <Form.Item label="Details">
            {getFieldDecorator(`details`, {
              rules: [{ required: true, message: "Required" }],
            })(<Input.TextArea />)}
          </Form.Item>
          <Form.Item label="File">
            {getFieldDecorator(`report`, {
              rules: [{ required: true, message: "Required" }],
            })(
              <File
                name="report"
                submitFile={({ name, value }) =>
                  props.form.setFieldsValue({ [name]: value })
                }
              />
            )}
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="New Transfer"
        visible={showTransferHistory}
        onOk={submitNewTransfer}
        onCancel={() => setShowTransferDialog(false)}
        okButtonProps={{
          loading: uploading.transfers,
          disabled: uploading.transfers,
        }}
      >
        <Form hideRequiredMark={true}>
          <Form.Item label="Amount">
            {getFieldDecorator(`amount`, {
              rules: [{ required: true, message: "Required" }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Date">
            {getFieldDecorator(`date`, {
              rules: [{ required: true, message: "Required" }],
            })(<DatePicker style={{ width: "100%" }} />)}
          </Form.Item>
          <Form.Item label="Details">
            {getFieldDecorator(`amount_details`, {
              rules: [{ required: true, message: "Required" }],
            })(<Input.TextArea autosize={{ minRows: 5, maxRows: 10 }} />)}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});
