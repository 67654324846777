import settings from "./assets/settings.svg";
import companyLogo from "./assets/logo.png";
import companyLogoWhite from "./assets/logo-white.png";
import preloader from "./assets/infinite-loader.gif";
import imgIcon from "./assets/image.svg";
import fileIcon from "./assets/file.svg";
import lockIcon from "./assets/lock.svg";
import warningIcon from "./assets/warning.svg";
import infoIcon from "./assets/info.svg";
import closeIcon from "./assets/close.svg";
import addUsers from "./assets/add-user.svg";
import back from "./assets/back.svg";
import companyLogoSmallWhite from "./assets/logo-w.png";
import call from "./assets/call.png";
import documentHand from "./assets/documentHand.png";
import bankPermit from "./assets/bankPermit.jpg";
import defaultApp from "./assets/defaultApp.png";
import defaultUser from "./assets/defaultUser.png";
import notFoundImage from "./assets/notFoundImage.png";
import logo_w from "./assets/logo-w.png";
import videoIcon from "./assets/video.svg";
import logoGreen from "./assets/logo-green.png";

const allImages = {
  preloader,
  settings,
  companyLogo,
  companyLogoWhite,
  fileIcon,
  imgIcon,
  lockIcon,
  warningIcon,
  closeIcon,
  addUsers,
  back,
  companyLogoSmallWhite,
  call,
  infoIcon,
  bankPermit,
  documentHand,
  defaultApp,
  defaultUser,
  notFoundImage,
  logo_w,
  videoIcon,
  logoGreen,
};

export {
  preloader,
  settings,
  companyLogo,
  companyLogoWhite,
  fileIcon,
  imgIcon,
  lockIcon,
  warningIcon,
  closeIcon,
  addUsers,
  back,
  companyLogoSmallWhite,
  call,
  infoIcon,
  bankPermit,
  documentHand,
  defaultApp,
  defaultUser,
  notFoundImage,
  logo_w,
  videoIcon,
  logoGreen,
};

export default allImages;
