import React, { useState } from "react";
import classes from "./CardDetailsForm.module.less";
import { Card, Input, Form, Button, notification, Popconfirm } from "antd";
import {
  updateProject,
  deleteProject,
} from "../../../services/projectsService";
import history from "../../../history";
export default Form.create()(({ form, loading, data }) => {
  let [saving, setSaving] = useState(false);
  const updateProjectRequest = async (values) => {
    setSaving(true);
    let { data: response } = await updateProject({
      projectId: data.id,
      ...values,
    });
    setSaving(false);
    if (response && response.message === "success") {
      notification.success({
        message: "Information saved.",
      });
    }
  };

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (!err) {
        updateProjectRequest(values);
      } else {
        notification.error({
          message: "Fill all the required fields.",
          placement: "bottomRight",
        });
      }
    });
  };
  const deleteProjectRequest = async () => {
    let { data: response } = await deleteProject({ projectId: data.id });
    if (response && response.message === "success") {
      notification.success({
        message: "Project removed!",
      });
      history.push("/projects");
    }
  };
  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
    labelAlign: "left",
  };

  const { getFieldDecorator } = form;

  return (
    <Card
      loading={loading}
      title="Project Details"
      size="small"
      extra={
        <div className={classes.actions}>
          <Popconfirm
            title="Are you sure you want to save this information?"
            onConfirm={() => handleSubmit()}
          >
            <Button
              loading={saving}
              icon={saving ? "loading" : "save"}
              type="primary"
            >
              Save Changes
            </Button>
          </Popconfirm>
          <Popconfirm
            title="Are you sure you want to delete this project?"
            onConfirm={() => deleteProjectRequest()}
          >
            <Button
              loading={saving}
              icon={saving ? "loading" : "delete"}
              type="danger"
              ghost
            >
              Delete
            </Button>
          </Popconfirm>
        </div>
      }
    >
      <Form hideRequiredMark={true} {...formItemLayout}>
        <Form.Item label="Title">
          {getFieldDecorator(`title`, {
            initialValue: data.title,
            rules: [{ required: true, message: "Required" }],
          })(<Input disabled={saving} />)}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator(`description`, {
            initialValue: data.description,
            rules: [{ required: true, message: "Required" }],
          })(<Input.TextArea rows={4} disabled={saving} />)}
        </Form.Item>
        <Form.Item label="Date">
          {getFieldDecorator(`date_`, {
            initialValue: data.date,
          })(<Input disabled />)}
        </Form.Item>
      </Form>
    </Card>
  );
});
