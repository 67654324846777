import React, { useState, useEffect } from "react";
import classes from "./UserAnalytics.module.less";
import { notification, Skeleton } from "antd";
import Axios from "../../axios";
import AppPromotionCard from "./AppPromotionCard/AppPromotionCard";

export default ({ clients, ...props }) => {
  const [loading, setLoading] = useState(true);
  const [allStores, setAllStores] = useState([]);
  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    try {
      const { data } = await Axios.get(
        `/analitycs-accounts/${props.match.params.id}`
      );
      if (data.allStores) {
        const { allStores } = data;
        setLoading(false);
        setAllStores(allStores);
      }
    } catch (error) {
      notification.error({
        message: "An error has ocurred conecting to the server.",
        placement: "bottomLeft",
      });
    }
  };
  
  return (
    <div className={classes.container}>
      <Skeleton loading={loading}>
        <AppPromotionCard appId={props.match.params.id} allStores={allStores} />
      </Skeleton>
    </div>
  );
};
