import React, { useState } from "react";
import { Tabs } from "antd";
import SOP from "../SOP/sop";
import Help from "../Help/help";
import classes from "./Documentation.module.less";
const { TabPane } = Tabs;

export default ({ ...props }) => {
  const [activeKey, setActiveKey] = useState("1");
  const onChange = (key) => {
    setActiveKey(key);
  };
  return (
    <div className={classes.container}>
      <Tabs onChange={onChange} activeKey={activeKey} tabPosition="top">
        <TabPane tab="Operations Procedures" key="1">
          <SOP {...props} />
        </TabPane>
        <TabPane tab="Clients Help" key="2">
          <Help {...props} />
        </TabPane>
      </Tabs>
    </div>
  );
};
