import React, { Fragment, useEffect, useState } from "react";
import { Button, Modal, Spin, Table, Col, Row, Form, Input } from "antd";
import { getStores } from "../../../../../services/storesServices";
import moment from "moment";
import { QRCode } from "react-qr-svg";
const { Item } = Form;
const MultiApk = ({ data, }) => {
    const [loadingData, setLoadingData] = useState(false);
    const [stores, setStores] = useState([]);
    
    useEffect(() => {
        getStoresRequest();
    }, [data]);
    
    const getStoresRequest = async () => {
        setLoadingData(true);
        let { data } = await getStores();
        if (data) {
          setStores(data);
        }
        setLoadingData(false);
    };
    
    const getStoreName = (id) => {  
        let value = stores.find((store) => store.id === id);
        if (value) {
            return value.fullname;            
        }
    };
    
    const showQrCode = (item) => {
    Modal.info({
      title: "The file can be downloaded with the following QR code",
      content: (
        <QRCode
          bgColor="#FFFFFF"
          fgColor="#000000"
          level="Q"
          style={{ width: 276, margin: "20px 0px 0px", display: "block" }}
          value={`https://appinchina.space/uploads/${item.url_apk}`}
        />
      ),
      onOk() {},
    });
  };

  const columns = [
    {
      title: "APK for Store",
      dataIndex: "store_id",
      key: "store_id",
      render: (store_id) => getStoreName(store_id),
    },
    {
      title: "Date Added",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "left",
      render: (createdAt) => moment(createdAt).format("YYYY-MM-DD"),
    },

    {
      title: "",
      dataIndex: "url_apk",
      key: "url_apk",
      render: (url_apk) =>
        url_apk ? (
          <a
            rel="noopener noreferrer"
            href={`https://appinchina.space/download/${url_apk}`}
            target="_blank"
          >
            <Button icon="cloud-download">Download</Button>
          </a>
        ) : (
          "No file attached"
        ),
    },
    {
      title: "",
      dataIndex: "PK_Multi_Apk",
      key: "PK_Multi_Apk",
      render: (_, item) => (
        <div>
          <Button
            onClick={() => showQrCode(item)}
            icon="qrcode"
            style={{ marginRight: "8px" }}
          />          
        </div>
      ),
    },
  ];
  
  return !loadingData ? (
    <Fragment>
        <Row gutter={16}>
            <Col span={8}>
              <Item label="Version Number">
                <Input defaultValue={data.version} disabled={true} />
              </Item>
            </Col>
            <Col span={8}>
              <Item label="Version Code">
                <Input defaultValue={data.version_code} disabled={true} />
              </Item>
            </Col>
            <Col span={8}>
              <Item label="Package Name">
                <Input defaultValue={data.package_name} disabled={true} />
              </Item>
            </Col>
        </Row>
        {data.multi_apks && data.multi_apks.length > 0 ? (
        <Table
          style={{ marginTop: 10 }}
          dataSource={data.multi_apks}
          columns={columns}
          size="small"
          pagination={data.multi_apks.length > 5 ? { pageSize: 5 } : false}
        />
      ) : (
        <Table columns={columns} size="small" />
      )}
    </Fragment>
  ) : (
    <Spin
      tip="Loading..."
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  );
};

export default MultiApk;
