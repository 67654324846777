import React, { Component, Fragment } from "react";
import { Route, Switch, Router, Redirect } from "react-router-dom";
import Login from "../Login/Login";
import AICON from "../AICON/AICON";
import Welcome from "../Welcome/Welcome";
import AppDetails from "../AppDetails/AppDetails";
import Clients from "../NewClientsSection/NewClientsSection";
import EventBus from "eventbusjs";
import history from "../../history";
import ProfileLayout from "../Layouts/ProfileLayout";
import AppForm from "../AppForm/AppForm";
import CompanyForm from "../CompanyForm/CompanyForm";
import SecurityForm from "../SecurityForm/SecurityForm";
import CopyrightForm from "../CopyrightForm/CopyrightForm";
import Notifications from "../Notifications/Notifications";
import ApkDetails from "../ApkDetails/ApkDetails";
import AppsHome from "../Apps/AppsHome/AppsHome";
import SalesHome from "../Sales/SalesHome";
import Auth from "../../auth";
// import SOP from "../SOP/sop";
import Documentation from "../Documentation/Documentation";
import SdksSection from "../SdksSection/SdksSection";
import MailChimp from "../MailChimp/MailChimp";
import Mailing from "../Mailing/Mailing";
import TicketsHome from "../Tickets/TicketsHome";
import UserFeedbacks from "../UserFeedbacks/UserFeedbacks";
import Bulletin from "../Bulletins/Bulletin";
import Badge from "../Badge/Badge";
import BadgeDetails from "../Badge/BadgeDetails/BadgeDetails";
import BadgePagesDetails from "../Badge/PageTracking/PageDetails";
import Companies from "../Companies/Companies";
import CompanyDetails from "../CompanyDetails/CompanyDetails";
import Projects from "../Projects/Projects";
import ProjectDetails from "../ProjectsDetails/ProjectDetails";
import ClientDetails from "../ClientDetails/ClientDetails";
import PaymentsAlerts from "../PaymentsAlerts";
import OperationsKPI from "../OperationsKPI/OperationsKPI";
import OperationsKPIManager from "../OperationsKPI/AppsDistributionTime/AppsDistributionTime";
import OperationsKPIApp from "../OperationsKPI/VersionsDistributionTime/VersionsDistributionTime";
import "./Home.css";

const auth = new Auth();
class Home extends Component {
  handleAuthentication = () => {
    if (/access_token|id_token|error/.test(window.location.hash)) {
      auth.handleAuthentication();
    } else if (auth.isAuthenticated()) {
    } else {
      history.replace("/login");
    }
  };

  render() {
    let content = (
      <Switch>
        <Route
          key="login"
          path="/login"
          exact
          render={(props) => <Login {...props} />}
        />
        <Route path="/notifications" render={() => <Notifications />} />
        <Route
          key="home"
          path="/"
          exact
          render={(props) => <Welcome {...props} />}
        />
        <Route
          key="appDetails"
          path="/apps-details/:section"
          render={(props) => <AppsHome {...props} />}
        />
        <Route
          key="appDetails"
          path="/apps-details"
          render={(props) => <AppsHome {...props} />}
        />

        <Route
          key="appDetails"
          path="/apps/:id/:section"
          component={AppDetails}
        />

        <Route
          key="appDetails"
          path="/apps/:id"
          render={(props) => (
            <Redirect to={`/apps/${props.match.params.id}/details`} />
          )}
        />

        <Route
          key="clients"
          path="/clients"
          exact
          render={(props) => <Clients {...props} />}
        />
        <Route
          key="clientDetails"
          path="/clients/:id"
          exact
          render={(props) => (
            <ClientDetails {...props} clientId={props.match.params.id} />
          )}
        />
        <Route
          key="aicon"
          path="/search"
          exact
          render={(props) => <AICON {...props} />}
        />
        <Route
          key="companyForm"
          path="/business-information/:id"
          exact
          render={(props) => <CompanyForm {...props} />}
        />
        <Route
          key="companyForm"
          path="/security-information/:id"
          exact
          render={(props) => <SecurityForm {...props} />}
        />
        <Route
          key="appForm"
          path="/app-information/:id"
          exact
          render={(props) => <AppForm {...props} />}
        />
        <Route
          key="appForm"
          path="/apk-inspector/"
          exact
          render={(props) => <ApkDetails {...props} />}
        />
        <Route
          key="copyrightForm"
          path="/copyright-information/:id"
          exact
          render={(props) => <CopyrightForm {...props} />}
        />
        <Route
          key="sales"
          path="/sales/:section"
          render={(props) => <SalesHome {...props} />}
        />
        <Route
          key="sales"
          path="/sales"
          render={(props) => <SalesHome {...props} />}
        />
        <Route
          key="documentation"
          path="/documentation"
          render={(props) => <Documentation {...props} />}
        />
        <Route key="mailchimp" path="/mailchimp" component={MailChimp} />
        <Route
          key="mailing"
          path="/mailing"
          render={(props) => <Mailing {...props} apps={[]} clients={[]} />}
        />
        <Route
          key="feedbacks"
          path="/feedbacks"
          render={(props) => <UserFeedbacks {...props} />}
        />
        <Route key="sdks" path="/sdks" component={SdksSection} />
        <Route
          key="tickets"
          path="/tickets/:section/:id"
          component={TicketsHome}
        />
        <Route key="tickets" path="/tickets" component={TicketsHome} />
        <Route key="bulletin" path="/bulletin" component={Bulletin} />
        <Route
          key="badge"
          path="/badge-tracking/script-tracking/:id"
          component={BadgeDetails}
        />
        <Route
          key="badge"
          path="/badge-tracking/page-tracking/:id"
          component={BadgePagesDetails}
        />
        <Route key="badge" path="/badge-tracking/:section" component={Badge} />
        <Route key="companies" path="/companies" exact component={Companies} />
        <Route
          key="companyDetails"
          path="/companies/:id"
          exact
          component={CompanyDetails}
        />
        <Route key="projects" path="/projects" exact component={Projects} />
        <Route
          key="projectDetails"
          path="/projects/:id"
          exact
          component={ProjectDetails}
        />
        <Route
          key="paymentsAlerts"
          path="/payments-alerts"
          exact
          component={PaymentsAlerts}
        />
        <Route
          key="operationsKPIApp"
          path="/kpi/app/:manager/:appName/:appId"
          exact
          render={(props) => <OperationsKPIApp {...props} />}
        />
        <Route
          key="operationsKPIManager"
          path="/kpi/manager/:manager"
          exact
          render={(props) => <OperationsKPIManager {...props} />}
        />
        <Route
          key="operationsKPI"
          path="/kpi/overall"
          exact
          render={(props) => <OperationsKPI {...props} />}
        />
        <Route key="404" path="/kpi" render={(props) => <Redirect to="/kpi/overall" />} />
        <Route key="404" path="*" render={(props) => <Redirect to="/" />} />
      </Switch>
    );

    return (
      <Fragment>
        <Router history={history}>
          {!window.location.pathname.includes("login") ? (
            <ProfileLayout>{content}</ProfileLayout>
          ) : (
            content
          )}
        </Router>
      </Fragment>
    );
  }

  logoutHandler = () => {
    history.replace("/login");
    this.setState({
      isAuth: false,
      updating: false,
    });
    window.localStorage.clear();
  };

  deleteNotification = (event, id) => {
    const notificationsStateCopy = [...this.state.notifications];
    const index = notificationsStateCopy.findIndex((it) => it.id === id);
    notificationsStateCopy.splice(index, 1);
    this.setState({
      notifications: notificationsStateCopy,
    });
    EventBus.dispatch("ALERT", this, "Task deleted");
  };

  componentWillMount() {
    this.handleAuthentication();
  }

  updateNotification = (event, { id, value }) => {
    this.setState((prevState) => {
      let notificationsStateCopy = prevState.notifications.map((it) => ({
        ...it,
        completion: it.id === id ? value : it.completion,
      }));
      return { notifications: notificationsStateCopy };
    });
    setTimeout(
      () => console.log("notifications state", this.state.notifications),
      1000
    );
    EventBus.dispatch("ALERT", this, "Task updated correctly");
  };

  componentDidMount() {
    EventBus.addEventListener("LOGOUT", this.logoutHandler, this);
  }
}

export default Home;
