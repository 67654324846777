import React, { useState } from "react";
import {
  Modal,
  Form,
  Button,
  Row,
  Input,
  Select,
  notification,
  Upload,
  Icon,
} from "antd";
import Axios from "axios";
import { compareVersions } from "../../../functions";
import { DISTRIBUTION_STATUS } from "../../../constants";

export default Form.create()(
  ({ form, update, historyData, infoId, saving, appInfo, ...props }) => {
    const [uploadingImg, setUploadingImg] = useState(false);
    const uploadImage = async ({ file, onSuccess, onProgress, onError }) => {
      const timeStamp = new Date().getTime();
      const formData = new FormData();
      const result = `${timeStamp}/${file.name}`;
      formData.set("uploads[]", file);
      formData.set("name", file.name);
      formData.set("timestamp", timeStamp);
      setUploadingImg(true);
      let { data } = await Axios.post(
        "https://appinchina.space/upload",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onProgress({
              percent: percentCompleted,
            });
          },
        }
      );
      setUploadingImg(false);
      if (data) {
        onSuccess(result);
      } else {
        onError("error");
      }
    };
    const uploadProps = {
      listType: "picture",
    };

    const { Option } = Select;
    const handleSubmit = () => {
      form.validateFields(async (err, values) => {
        if (!err) {
          try {
            let history_attachments = values.files
              ? values.files.fileList.map(({ name, size, response }) => ({
                  file_name: name,
                  file_size: size,
                  url: response,
                }))
              : [];
            let format = { ...values, history_attachments };
            await update({ ...format });
            form.resetFields();
          } catch (error) {
            console.log(error);
            notification.error({
              message: "An error has ocurred.",
              placement: "bottomRight",
            });
          }
        }
      });
    };

    const { getFieldDecorator } = form;
    return (
      <div>
        <Modal
          title="Updating App Store Status"
          visible={props.visible}
          footer={null}
          onCancel={props.close}
          confirmLoading={saving}
          destroyOnClose={true}
        >
          <Form hideRequiredMark={true}>
            <Form.Item label="Description">
              {getFieldDecorator(`description`, {
                rules: [
                  {
                    required: true,
                    message: "Required. Max Length: 400 characters.",
                    max: 400,
                  },
                ],
              })(<Input.TextArea />)}
            </Form.Item>
            <Form.Item label="Status">
              {getFieldDecorator(`status`, {
                rules: [{ required: true, message: "Required" }],
              })(
                <Select>
                  {DISTRIBUTION_STATUS.map((it) => (
                    <Option key={it.value} value={it.value} label={it.label}>
                      {it.label}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Internal Notes">
              {getFieldDecorator(`internal_notes`, {
                rules: [
                  {
                    required: false,
                    message: "Max Length: 400 characters.",
                    max: 400,
                  },
                ],
              })(<Input.TextArea />)}
            </Form.Item>
            <Form.Item label="Version Number">
              {getFieldDecorator(`version_number`, {
                rules: [{ required: true, message: "Required" }],
              })(
                <Select
                  placeholder="Select an option..."
                  style={{ width: "100%" }}
                  disabled={!appInfo.app_forms || !appInfo.app_forms.length}
                >
                  <Option value={null}>Select an option...</Option>
                  {!!appInfo.app_forms &&
                    !!appInfo.app_forms.length &&
                    appInfo.app_forms
                      .sort((a, b) => compareVersions(b.version, a.version))
                      .map((it) => (
                        <Option value={it.version}>{it.version}</Option>
                      ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(`files`)(
                <Upload
                  onRemove={(file) => setUploadingImg(false)}
                  disabled={saving}
                  {...uploadProps}
                  customRequest={uploadImage}
                >
                  <Button loading={uploadingImg}>
                    <Icon type={uploadingImg ? "loading" : "upload"} />
                    {uploadingImg
                      ? "Uploading File"
                      : " Click to Upload New File(s)"}
                  </Button>
                </Upload>
              )}
            </Form.Item>
            <Row type="flex" justify="center" align="middle">
              <Button
                loading={saving}
                disabled={uploadingImg}
                type="primary"
                onClick={() => handleSubmit()}
                style={{ marginTop: 10 }}
              >
                Save History
              </Button>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
);
