import React, { Component, Fragment } from "react";
import classes from "./GoogleSearcher.module.css";
import { Icon, Button } from "antd";
import Input from "../UI/Input/Input";
import GoogleAppsDialog from "../Dialogs/GoogleAppsDialog/GoogleAppsDialog";
import axios from "axios";
import AxiosCustom, { publicInstance } from "../../axios";
import functions from "../../functions.js";
import _ from "underscore";

class GoogleSearcher extends Component {
  state = {
    appname: {
      value: "",
      validation: {
        required: true
      },
      valid: false,
      validated: false
    },
    link: {
      value: "",
      validation: {
        required: true
      },
      valid: false,
      validated: false
    },
    googleApps: [],
    showDialog: false,
    loadingGoogle: false
  };

  render() {
    return (
      <Fragment>
        <GoogleAppsDialog
          display={this.state.showDialog}
          apps={this.state.googleApps}
          select={this.handleGoogleAppSelected}
          cancel={this.handleNoGoogleAppSelected}
        />
        <p className={classes.Text}>
          Type the name of the app below and choose the correct one from the
          given options
          <br />
          (This may take a few seconds)
        </p>
        <div className={classes.Form}>
          <form className={classes.AppnameContainer} onSubmit={this.submitForm}>
            <Input
              name="appname"
              type="text"
              placeholder="App Name"
              value={this.state.appname.value}
              onChange={event => this.handleFieldChange(event, "appname")}
              valid={this.state.appname.valid}
              validated={this.state.appname.validated}
              errormessage="Field required"
            />
            <Button onClick={this.submitForm} type="primary">
              SEARCH <Icon type="search" />
            </Button>
          </form>
        </div>
        <p className={classes.Text}>
          Or add the link to the app on Google Play
        </p>

        <div className={classes.Form}>
          <form
            className={classes.AppnameContainer}
            onSubmit={this.useGoogleLink}
          >
            <Input
              name="link"
              type="text"
              placeholder="Link"
              value={this.state.link.value}
              onChange={event => this.handleFieldChange(event, "link")}
              valid={this.state.link.valid}
              validated={this.state.link.validated}
              errormessage="Field required"
            />
            <Button
              onClick={this.useGoogleLink}
              type="primary"
              loading={this.state.loadingGoogle}
            >
              CONTINUE
            </Button>
          </form>
        </div>
      </Fragment>
    );
  }
  handleFieldChange = (event, inputName) => {
    let field = {
      ...this.state[inputName],
      validated: false,
      value: event.target.value
    };
    this.setState({ [inputName]: field });
  };
  submitForm = event => {
    event.preventDefault();
    let appname = {
      ...this.state.appname,
      validated: true
    };
    appname.valid = functions.checkValidity(appname.value, appname.validation);
    this.setState({ appname: appname });
    if (appname.valid) {
      this.setState({ showDialog: true });
      this.searchGoogleApps();
    }
  };
  searchGoogleApps = () => {
    this.setState({ googleApps: [] });
    let url = `https://tc5hc8xzq2.execute-api.us-east-2.amazonaws.com/test/google?app=${this.state.appname.value}`;
    axios.get(url)
      .then(response => {
        if (
          response.data &&
          response.data.length &&
          typeof response.data === "object"
        ) {
          this.setState({ googleApps: response.data });
        } else {
          this.props.error();
          throw response;
        }
      })
      .catch(error => {
        console.log("error while scraping google", error);
      });
  };
  useGoogleLink = event => {
    event.preventDefault();
    let link = {
      ...this.state.link,
      validated: true
    };
    link.valid = functions.checkValidity(link.value, link.validation);
    this.setState({ link: link });
    if (link.valid) {
      this.getAppDetails();
    }
  };
  handleGoogleAppSelected = app => {
    this.setState({ showDialog: false });
    this.props.select(app, this.state.appname.value);
  };
  handleNoGoogleAppSelected = () => {
    this.setState({ showDialog: false });
  };
  getAppDetails = () => {
    this.setState({
      showDialog: false,
      loadingGoogle: true
    });
    let headers = { "Content-Type": "text/plain" };
    axios
      .get(
        "https://tc5hc8xzq2.execute-api.us-east-2.amazonaws.com/test/google?url=" +
          this.state.link.value,
        { headers: headers }
      )
      .then(response => {
        this.setState({ loadingGoogle: false });
        if (response.data.error) {
          console.log("invalid url");
        } else if (typeof response.data !== "object") {
          this.props.error();
        } else {
          this.props.select(
            {
              ...response.data              
            },
          );
        }
      })
      .catch(error => {
        console.log("google error", error);
        this.setState({ loadingGoogle: false });
      });
  };
}
export default GoogleSearcher;
