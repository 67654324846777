import React from "react";
import { Spin } from "antd";
import classes from "./LineCard.module.css";

const lineCard = ({
  fullWidthTable,
  style,
  title,
  description,
  children,
  shadow,
  className,
  loading
}) => {
  let styles = className ? className : "";
  return (
    <div
      className={[
        styles,
        classes.Card,
        fullWidthTable ? classes.FullWidthTable : "",
        shadow ? classes.Shadow : ""
      ].join(" ")}
      style={style}
    >
      {loading ? (
        <div className={classes.loader}>
          <Spin />
        </div>
      ) : null}
      <h4>{title}</h4>
      {description ? (
        <p className={classes.description}>{description}</p>
      ) : null}
      {children}
    </div>
  );
};

export default lineCard;
