import { appInChinaPay } from "../axios";
import axios from "../axios";

const getTransactions = async ({ month, year }) => {
  try {
    const data = await appInChinaPay.get(`/balance/${month}/${year}`);
    return data;
  } catch (error) {
    return {};
  }
};

const getDataSinceBegining = async ({ month, year }) => {
  try {
    const response = await axios.get("/aic-pay/servers");
    return new Promise((resolve) => {
      Promise.all(
        response.data.servers.map(async (it) =>
          appInChinaPay.get(`${it.data_endpoint}/${process.env.REACT_APP_API_PREFIX}/begining/${month}/${year}`)
        )
      )
        .then((results) => {
          let data = results
            .map((it) => it.data)
            .reduce(
              (acc, curr) => {
                console.log("acc,curr", acc, curr);
                return {
                  adminDashbordFromBegining: {
                    balance_from_begining:
                      acc.adminDashbordFromBegining.balance_from_begining +
                      curr.adminDashbordFromBegining
                        ? curr.adminDashbordFromBegining.balance_from_begining
                        : 0,
                    transactions_from_begining:
                      acc.adminDashbordFromBegining.transactions_from_begining +
                      curr.adminDashbordFromBegining
                        ? curr.adminDashbordFromBegining
                            .transactions_from_begining
                        : 0,
                  },
                  pieChart: [
                    {
                      source: "WeChat",
                      total:
                        acc.pieChart[0].total +
                        (curr.pieChart.length ? curr.pieChart[0].total : 0),
                    },
                    {
                      source: "Alipay",
                      total:
                        acc.pieChart[1].total +
                        (curr.pieChart.length ? curr.pieChart[1].total : 0),
                    },
                  ],
                };
              },
              {
                adminDashbordFromBegining: {
                  balance_from_begining: 0,
                  transactions_from_begining: 0,
                },
                pieChart: [
                  {
                    source: "WeChat",
                    total: 0,
                  },
                  {
                    source: "Alipay",
                    total: 0,
                  },
                ],
              }
            );
          resolve(data);
        })
        .catch((e) => {
          resolve(null);
        });
    });
  } catch (error) {
    return {};
  }
};

const getChineseApps = async (server) => {
  try {
    const { data } = await appInChinaPay.get(
      `${server.data_endpoint}/${process.env.REACT_APP_API_PREFIX}/apps`
    );
    return data;
  } catch (error) {
    return {};
  }
};

export { getTransactions, getChineseApps, getDataSinceBegining };
