import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Row, Upload, Icon } from "antd";
import Axios from "axios";
export default Form.create()(({ form, appId, updateData, ...props }) => {
  let [saving, setSaving] = useState(false);
  const [uploadingImg, setUploadingImg] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (!err) {
        let format = values.files.fileList.map(({ name, size, response }) => ({
          file_name: name,
          file_size: size,
          file: response,
        }));
        setSaving(true);
        updateData({ files: format });
        setSaving(false);
        form.resetFields();
        props.close();
      }
    });
  };
  const uploadImage = async ({ file, onSuccess, onProgress, onError }) => {
    const timeStamp = new Date().getTime();
    const formData = new FormData();
    const result = `${timeStamp}/${file.name}`;
    formData.set("uploads[]", file);
    formData.set("name", file.name);
    formData.set("timestamp", timeStamp);
    setUploadingImg(true);
    let { data } = await Axios.post("https://appinchina.space/upload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onProgress({
          percent: percentCompleted,
        });
      },
    });
    setUploadingImg(false);
    if (data) {
      onSuccess(result);
    } else {
      onError("error");
    }
  };
  const validateFields = () => {
    let values = Object.entries(form.getFieldsValue()).map((it) => it[1]);
    if (
      values.findIndex((e) => e === undefined) === -1 &&
      (form.getFieldValue("files") &&
        form.getFieldValue("files").fileList &&
        form.getFieldValue("files").fileList.length > 0)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  useEffect(() => {
    validateFields();
  }, [form]);
  const uploadProps = {
    listType: "picture",
  };
  const { getFieldDecorator } = form;
  return (
    <div>
      <Modal
        title="New Attachment"
        visible={props.visible}
        footer={null}
        destroyOnClose={true}
        onCancel={props.close}
        confirmLoading={saving}
      >
        <Form hideRequiredMark={true}>
          <Form.Item>
            {getFieldDecorator(`files`, {
              rules: [{ required: true, message: "Required" }],
            })(
              <Upload
                onRemove={(file) => setUploadingImg(false)}
                {...uploadProps}
                customRequest={uploadImage}
              >
                <Button loading={uploadingImg}>
                  <Icon type={uploadingImg ? "loading" : "upload"} />
                  {uploadingImg
                    ? "Uploading File"
                    : " Click to Upload New File(s)"}
                </Button>
              </Upload>
            )}
          </Form.Item>
          <Row type="flex" justify="center" align="middle">
            <Button
              loading={saving}
              disabled={disabled || uploadingImg}
              type="primary"
              onClick={() => handleSubmit()}
              style={{ marginTop: 10 }}
            >
              Save Attachment
            </Button>
          </Row>
        </Form>
      </Modal>
    </div>
  );
});
