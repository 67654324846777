import React, { useState } from "react";
import { compareVersions } from "../../../functions";
import { Modal, Form, Button, Row, Input, Select, notification } from "antd";
export default Form.create()(
  ({ form, update, options, saving, appInfo, ...props }) => {
    const { Option } = Select;
    const handleSubmit = () => {
      form.validateFields(async (err, values) => {
        if (!err) {
          try {
            await update({ ...values });
            form.resetFields();
          } catch (error) {
            console.log(error);
            notification.error({
              message: "An error has ocurred.",
              placement: "bottomRight",
            });
          }
        }
      });
    };

    const { getFieldDecorator } = form;
    return (
      <div>
        <Modal
          title="Updating App Stores Versions"
          visible={props.visible}
          footer={null}
          onCancel={props.close}
          confirmLoading={saving}
          destroyOnClose={true}
        >
          <Form hideRequiredMark={true}>
            <Form.Item label="App Stores">
              {getFieldDecorator(`app_stores`, {
                rules: [{ required: true, message: "Required" }],
              })(
                <Select mode="multiple">
                  {options.map((it) => (
                    <Option key={it.value} value={it.value} label={it.label}>
                      {it.label}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Live Version">
              {getFieldDecorator(`version`, {
                rules: [{ required: true, message: "Required" }],
              })(
                <Select
                  placeholder="Select an option..."
                  style={{ width: "100%" }}
                  disabled={!appInfo.app_forms || !appInfo.app_forms.length}
                >
                  <Option value={null}>Select an option...</Option>
                  {!!appInfo.app_forms &&
                    !!appInfo.app_forms.length &&
                    appInfo.app_forms
                      .sort((a, b) => compareVersions(b.version, a.version))
                      .map((it) => (
                        <Option value={it.version}>{it.version}</Option>
                      ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Version Being Submitted">
              {getFieldDecorator(`version_being_submitted`, {
                rules: [{ required: true, message: "Required" }],
              })(
                <Select
                  placeholder="Select an option..."
                  style={{ width: "100%" }}
                  disabled={!appInfo.app_forms || !appInfo.app_forms.length}
                >
                  <Option value={null}>Select an option...</Option>
                  {!!appInfo.app_forms &&
                    !!appInfo.app_forms.length &&
                    appInfo.app_forms
                      .sort((a, b) => compareVersions(b.version, a.version))
                      .map((it) => (
                        <Option value={it.version}>{it.version}</Option>
                      ))}
                </Select>)}
            </Form.Item>

            <Row type="flex" justify="center" align="middle">
              <Button
                loading={saving}
                type="primary"
                onClick={() => handleSubmit()}
                style={{ marginTop: 10 }}
              >
                Save Versions
              </Button>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
);
