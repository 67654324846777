import React, { Component } from "react";
import { companyLogoWhite, defaultUser, logo_w } from "../../../images";
import EventBus from "eventbusjs";
import SearchBar from "../../SearchBar/SearchBar";
import { NavLink } from "react-router-dom";
import classes from "./NavBar.module.less";
import BadgeTasks from "../../UI/BadgeTasks/BadgeTasks";
import { Modal, Dropdown, Avatar, Menu } from "antd";
const confirm = Modal.confirm;

export default class extends Component {
  state = {
    displayConfirmationDialog: false,
    screen_width: window.outerWidth,
  };

  closeConfirmationDialog = () => {
    this.setState((prevState) => ({
      displayConfirmationDialog: !prevState.displayConfirmationDialog,
    }));
  };

  confirmLogOut = () => {
    let { handleLogout } = this;
    confirm({
      title: "Are you sure you want to logout?",
      onOk() {
        handleLogout();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  handleLogout = () => {
    EventBus.dispatch("LOGOUT", this);
  };

  options = (
    <Menu>
      <Menu.Item onClick={this.confirmLogOut}>Log Out</Menu.Item>
    </Menu>
  );

  updateWidth = () => {
    this.setState(() => ({
      screen_width: window.outerWidth,
    }));
    return this.state.screen_width;
  };

  componentWillMount() {
    this.updateWidth();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  }

  render() {
    return (
      <nav className={classes.nav}>
        <img
          className={classes.logo}
          src={this.state.screen_width <= 770 ? logo_w : companyLogoWhite}
          alt="Company Logo"
        />
        <SearchBar />
        <span className={classes.notifications}>
          <NavLink to="/notifications">
            <BadgeTasks />
          </NavLink>
        </span>
        <span className={classes.menuOption}>
          <Dropdown overlay={this.options}>
            <Avatar
              src={
                localStorage.imageLocation
                  ? localStorage.imageLocation
                  : defaultUser
              }
            />
          </Dropdown>
        </span>
        <Modal
          visible={this.state.displayConfirmationDialog}
          onCancel={this.closeConfirmationDialog}
          footer={null}
        />
      </nav>
    );
  }
}
