import React, { useEffect, useState } from "react";
import classes from "./AppProjects.module.less";
import {
  getProjectsByApp,
  deleteAppFromProject,
} from "../../services/projectsService";
import { Table, Button, Popconfirm, notification, Row, Col } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import AddAppModal from "./AddAppModal/AddAppModal";
export default ({ appId }) => {
  let [loading, setLoading] = useState(false);
  let [data, setData] = useState([]);
  let [display, setDisplay] = useState(false);

  let columns = [
    {
      key: "title",
      dataIndex: "title",
      title: "Title",
      render: (title, item) => <Link to={`/projects/${item.id}`}>{title}</Link>,
    },
    {
      key: "date",
      dataIndex: "date",
      title: "Date",
      render: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
    },
    {
      key: "description",
      dataIndex: "description",
      title: "Description",
      render: (description) =>
        description ? (
          <Ellipsis length={60} tooltip>
            {description}
          </Ellipsis>
        ) : (
          ""
        ),
    },
    {
      key: "id",
      dataIndex: "id",
      title: "",
      render: (id) => (
        <Popconfirm
          title="Are you sure you want to remove this project from app?"
          onConfirm={() => removeAppFromProject({ app: appId, project: id })}
        >
          <Button icon="delete" ghost type="danger" shape="circle" />
        </Popconfirm>
      ),
    },
  ];

  const removeAppFromProject = async (values) => {
    setLoading(true);
    let { data: response } = await deleteAppFromProject({ ...values });
    setLoading(false);
    getData();
    if (response && response.message === "success") {
      notification.success({
        message: "Information saved.",
      });
    }
  };

  let getData = async () => {
    setLoading(true);
    let { data: response } = await getProjectsByApp(appId);
    setLoading(false);
    if (response && response.details) {
      setData(response.details.projects);
    }
  };

  useEffect(() => {
    if (appId) {
      getData();
    }
  }, [appId]);

  return (
    <div className={classes.container}>
      <Row gutter={16} type="flex" justify="end">
        <Col span={4}>
          <Button
            disabled={loading}
            onClick={() => setDisplay(true)}
            style={{ width: "100%" }}
            type="primary"
            icon="plus"
          >
            Add Project
          </Button>
        </Col>
      </Row>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        size="small"
        style={{ marginTop: 15 }}
      />
      <AddAppModal
        updateData={getData}
        projectsConnected={data}
        visible={display}
        close={() => setDisplay(false)}
        appId={appId}
      />
    </div>
  );
};
