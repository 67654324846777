import React, { useState } from "react";
import { Table, Button, Modal, notification, Tag } from "antd";
import { verifyPayment } from "../../../services/cloudStoreService";
import moment from "moment";
import functions from "../../../functions";
import classes from "./CloudBilling.module.less";
const { confirm } = Modal;
const CloudBilling = ({ data }) => {
  let payments = data;
  let [loading, setLoading] = useState(false);
  let columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: date => moment(date).format("YYYY-MM-DD")
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
      render: method => (method ? functions.splitAndCapitalize(method) : "")
    },
    {
      title: "Due",
      dataIndex: "amount",
      key: "amount"
    },
    {
      title: "Account Balance Discount",
      dataIndex: "account_balance_disccount",
      key: "account_balance_disccount"
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (_, values) => values.amount - values.account_balance_disccount
    },
    {
      title: "",
      dataIndex: "verified",
      key: "verified",
      align: "center",
      render: (verified, values) => {
        let isVerified = verified || loading;
        return (
          <Button
            icon="check"
            disabled={isVerified}
            onClick={() => confirmPayment(values.id)}
            type="primary"
          >
            VERIFY
          </Button>
        );
      }
    }
  ];
  let orderColumns = [
    { title: "Service", dataIndex: "title", key: "title" },
    {
      title: "Monthly Price",
      dataIndex: "monthly_price",
      key: "monthly_price"
    },
    {
      title: "Months Paid",
      dataIndex: "months_paid",
      key: "months_paid",
      render: (_, item) => item.cloud_order_payment.months_paid
    },

    {
      title: "One Time Fee",
      dataIndex: "onetime_fee",
      key: "onetime_fee",
      render: (_, item) => item.cloud_order_payment.onetime_fee
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (_, item) => item.cloud_order_payment.total
    }
  ];
  const requestVerify = async id => {
    setLoading(true);
    let { data } = await verifyPayment(id);
    if (data && data.message === "success") {
      notification.info({
        message: "Payment verified!"
      });
    }
  };
  const confirmPayment = id => {
    confirm({
      title: "Are you sure you want to verify this payment?",
      onOk() {
        requestVerify(id);
      },
      onCancel() {}
    });
  };

  return (
    <div>
      <Table
        expandedRowRender={record => (
          <div className={classes.rowTable}>
            <Table
              size="small"
              dataSource={record.cloud_orders}
              columns={orderColumns}
              pagination={false}
            />
          </div>
        )}
        size={"small"}
        dataSource={payments}
        columns={columns}
      />
    </div>
  );
};

export default CloudBilling;
