import React, { useState } from "react";
import { Modal, Select, notification as antnotification, Spin } from "antd";
import { REPLIES_OPTIONS, NOTIFICATION_OPTIONS } from "../../../constants";
import { updateTaskCompletion } from "../../../services/taskService";
const Option = Select.Option;
const { confirm } = Modal;
const SelectCompletion = ({ notification, onChange }) => {
  const [loading, setLoading] = useState(false);
  const openNotificationWithIcon = (type, message) => {
    antnotification[type]({
      message: message,
      placement: "bottomLeft",
    });
  };
  const updateNotification = async (value, id) => {
    setLoading(true);
    let { data } = await updateTaskCompletion({
      id,
      value,
    });
    if (data && data.message === "success") {
      openNotificationWithIcon("info", "Task Completion Updated.");
    } else {
      openNotificationWithIcon("warning", "An error has ocurred");
    }
    setLoading(false);
    onChange();
  };
  const handleCompletion = (option, notificationId) => {
    if (option === "COMPLETED") {
      showConfirm(option, notificationId);
    } else {
      updateNotification(option, notificationId);
    }
  };
  const showConfirm = (value, id) => {
    confirm({
      title:
        "Are you sure? This will mark the task as completed and it will be removed.",
      onOk() {
        confirmDeleteTask(id);
      },
      onCancel() {},
    });
  };
  const confirmDeleteTask = (id) => {
    updateNotification("COMPLETED", id);
  };
  return (
    <div>
      {loading ? (
        <Spin />
      ) : (
        <Select
          value={notification.completion}
          style={{ width: "90%" }}
          onChange={(value) => {
            handleCompletion(value, notification.id);
          }}
        >
          {notification.category === "reviews" ||
          notification.category === "monetization" ||
          notification.category === "analytics"
            ? REPLIES_OPTIONS.completion.map((it) => (
                <Option key={it.value} value={it.value} label={it.label}>
                  {it.label}
                </Option>
              ))
            : notification.category === "custom"
            ? [
                { value: "NOT_STARTED", label: "Not Started" },
                { value: "ONGOING", label: "Ongoing" },
                { value: "COMPLETED", label: "Completed" },
              ].map((it) => (
                <Option key={it.value} value={it.value} label={it.label}>
                  {it.label}
                </Option>
              ))
            : NOTIFICATION_OPTIONS.completion.map((it) => (
                <Option key={it.value} value={it.value} label={it.label}>
                  {it.label}
                </Option>
              ))}
        </Select>
      )}
    </div>
  );
};

export default SelectCompletion;
