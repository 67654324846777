import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Row, Select, notification } from "antd";
import {
  connectAppToProject,
  getAllProjects,
} from "../../../services/projectsService";
const { Option } = Select;
export default Form.create()(
  ({ form, data, updateData, projectsConnected, appId, ...props }) => {
    let [saving, setSaving] = useState(false);
    let [options, setOptions] = useState([]);
    let [disabled, setDisabled] = useState(true);
    const handleSubmit = () => {
      form.validateFields((err, values) => {
        if (!err) {
          const appsToInsert = values.app.map(({ key }) => {
            return {
              app: appId,
              project: key,
            };
          });
          makeRequest(appsToInsert);
        }
      });
    };
    const makeRequest = async (values) => {
      setSaving(true);
      let { data: response } = await connectAppToProject(values);
      setSaving(false);
      if (response && response.message === "success") {
        await updateData();
        notification.success({
          message: "Information saved.",
        });
      }
      form.resetFields();
      props.close();
    };
    const getProjects = async () => {
      let { data: response } = await getAllProjects(1);
      if (response) {
        let projectIDs = projectsConnected.map(({ id }) => id);
        let result = response.projects.filter(
          (project) => !projectIDs.includes(project.id)
        );
        setOptions(result);
      }
      setDisabled(false);
    };
    useEffect(() => {
      getProjects();
    }, [projectsConnected]);
    const { getFieldDecorator } = form;
    return (
      <div>
        <Modal
          title="New Project"
          visible={props.visible}
          footer={null}
          destroyOnClose={true}
          onCancel={props.close}
          confirmLoading={saving}
        >
          <Form hideRequiredMark={true}>
            <Form.Item label="Select Apps:">
              {getFieldDecorator(`app`, {
                rules: [{ required: true, message: "Required" }],
              })(
                <Select
                  disabled={disabled || saving}
                  showSearch
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select an app..."
                  optionFilterProp="children"
                  labelInValue
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {options.map(({ id, title }) => (
                    <Option key={id} value={id} label={title}>
                      {title}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Row type="flex" justify="center" align="middle">
              <Button
                disabled={disabled || saving}
                loading={saving}
                type="primary"
                onClick={() => handleSubmit()}
                style={{ marginTop: 10 }}
              >
                Save
              </Button>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
);
