import React, { useEffect, useState } from "react";
import classes from "../Badge.module.less";
import moment from "moment";
import Chart from "../BadgeDetails/Chart";
import { getBadgePagesByApp } from "../../../services/badgeTracking";
import HeatMap from "./HeatMap";
import RegionsMap from "./RegionsMap";
import TrendsChart from "./TrendsChart";

import functions from "../../../functions";
import { Table, DatePicker, Row, Col, Button, Spin } from "antd";
import LineCard from "../../UI/LineCard/LineCard";
import { Link } from "react-router-dom";

const { RangePicker } = DatePicker;

const PageBadge = ({ match }) => {
  let [data, setData] = useState({});
  let [start_date, setStartDate] = useState("");
  let [end_date, setEndDate] = useState("");
  let [loading, setLoading] = useState(false);

  const getData = async (start, end) => {
    setLoading(true);
    let { data } = await getBadgePagesByApp(start, end, match.params.id);
    if (data) {
      setData({
        ...data,
        traficByReferrer: traficByReferrer(data.traficByReferrer)
      });
      setLoading(false);
    }
  };

  const traficByReferrer = data => {
    return Object.entries(data).map(([referrer, values]) => ({
      referrer,
      ...values
    }));
  };

  const onChangeDate = (_, [start, end]) => {
    setStartDate(start);
    setEndDate(end);
    getData(start, end);
  };

  useEffect(() => {
    getData(
      moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD")
    );
    setStartDate(
      moment()
        .startOf("month")
        .format("YYYY-MM-DD")
    );
    setEndDate(moment().format("YYYY-MM-DD"));
  }, []);

  let columns = [
    {
      title: "Referrer",
      dataIndex: "referrer",
      key: "referrer"
    },
    {
      title: "Visitors",
      dataIndex: "sessions",
      key: "sessions",
      render: sessions => functions.styleAsNumber(sessions)
    }
  ];
  let regionColumns = [
    {
      title: "Region",
      dataIndex: "regionName",
      key: "regionName"
    },
    {
      title: "Page Views",
      dataIndex: "value",
      key: "value"
    }
  ];

  let countryColumns = [
    {
      title: "Contry Name",
      dataIndex: "countryName",
      key: "countryName"
    },
    {
      title: "Page Views",
      dataIndex: "visitors",
      key: "visitors"
    }
  ];

  let clicksColumns = [
    {
      title: "Store",
      dataIndex: "store",
      key: "store"
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks"
    }
  ];

  return (
    <div className={classes.container}>
      <Row type="flex" gutter={16} style={{ marginBottom: 20 }}>
        <Col>
          <Link to="/badge-tracking/page-tracking">
            <Button icon="left">Go Back</Button>
          </Link>
        </Col>
        <Col>
          <RangePicker
            value={
              start_date !== "" && end_date !== ""
                ? [moment(start_date), moment(end_date)]
                : [moment().startOf("month"), moment()]
            }
            style={{ width: "100%" }}
            onChange={onChangeDate}
            format={"YYYY-MM-DD"}
            disabled={loading}
          />
        </Col>
      </Row>
      {loading ? (
        <Spin />
      ) : (
        <>
          <Row
            type="flex"
            align="middle"
            justify="space-between"
            style={{ paddingBottom: "12px" }}
          >
            <h2 style={{ margin: "0px" }}>{data.name} </h2>
            <a
              href={`https://apps.appinchina.com.cn/details/${match.params.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <Button icon="link">Open Badge Page</Button>
            </a>
          </Row>
          <table className={classes.summaryTable}>
            <tbody>
              <tr>
                <td>Traffic Today</td>
                <td>
                  {functions.styleAsNumber(
                    data.summary ? data.summary.todayTraffic : 0
                  )}
                </td>
              </tr>
              <tr>
                <td>Page Views (Pv)</td>
                <td>
                  {functions.styleAsNumber(
                    data.summary ? data.summary.page_views : 0
                  )}
                </td>
              </tr>
              <tr>
                <td>Visitors (Uv)</td>
                <td style={{ color: "#2db72d" }}>
                  {functions.styleAsNumber(
                    data.summary ? data.summary.visitors : 0
                  )}
                </td>
              </tr>
              <tr>
                <td>Avg. Session Duration</td>
                <td>
                  {functions.styleAsTime(
                    data.summary ? data.summary.avg_session_duration / 1000 : 0
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <Table
            style={{ marginTop: 15 }}
            dataSource={data.traficByReferrer}
            loading={loading}
            size="small"
            columns={columns}
          />
          <LineCard title="Visits per Day" style={{  marginTop: 15, marginBottom: "24px" }}>
            <Chart data={data.table} />
          </LineCard>
          <TrendsChart data={data.trendChart} />
          <LineCard
            title="Visitors Per Region (China)"
            style={{ textAlign: "center" }}
          >
            {data.visitorsByRegion ? (
              <HeatMap data={data.visitorsByRegion} />
            ) : null}
          </LineCard>
          <Table
            style={{ marginTop: 15 }}
            dataSource={data.visitorsByRegion}
            loading={loading}
            size="small"
            columns={regionColumns}
          />
          <RegionsMap countries={data.visitorsByCountry} loading={loading} />
          <Table
            style={{ marginTop: 15 }}
            dataSource={data.visitorsByCountry}
            loading={loading}
            size="small"
            columns={countryColumns}
          />
          <Table
            style={{ marginTop: 15 }}
            dataSource={data.clicks}
            loading={loading}
            size="small"
            columns={clicksColumns}
          />
        </>
      )}
    </div>
  );
};
export default PageBadge;
