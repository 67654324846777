import React, { useState, useEffect } from "react";
import { getPaymentsAlertsChart } from "../../services/paymentsAlerts"
import { Card } from "antd";
import Spin from "../UI/LoadingSpin/LoadingSpin";
import Chart from "./Chart";

export default () => {
    const [fetchingChart, setFetchingChart] = useState(false);
    const [chartData, setChartData] = useState([]);

    const fetchData = async () => {
        try {
            setFetchingChart(true);
            const { data } = await getPaymentsAlertsChart()
            setChartData(data.result)
            setFetchingChart(false);
        } catch (error) {
            setFetchingChart(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Card style={{ marginBottom: 15 }}>
            {
                fetchingChart ? (
                    <Spin />
                ) : chartData ? (
                    <Chart data={chartData} />
                ) : null
            }
        </Card>
    )
}