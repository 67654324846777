import React, { useState, Fragment } from "react";
import classes from "./NewKefuModal.module.less";
import { Modal, Button, Input, Form, notification } from "antd";
import { newKefu } from "../../../../services/TicketsServices";
const NewKefuModal = ({ form, onAdd }) => {
  const { getFieldDecorator } = form;
  let [modalOpen, setModalOpen] = useState(false);
  let [loading, setLoading] = useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        newKefuRequest(values);
      }
    });
  };
  const newKefuRequest = async values => {
    setLoading(true);
    let { data } = await newKefu(values);
    setLoading(false);
    if (data && data.message === "success") {
      notification.info({
        message: "New Kefu added",
        placement: "bottomLeft",
        duration: 2
      });
      setModalOpen(false);
      form.resetFields();  
      onAdd({ name: values.name, kefuId: data.id });
    } else {
      notification.warning({
        message: `${data.error}`,
        placement: "bottomLeft",
        duration: 2
      });
    }
  };
  return (
    <Fragment>
      <div className={classes.button}>
        <Button
          onClick={() => setModalOpen(true)}
          type="primary"
          icon="plus"
          ghost
        >
          Add New Kefu
        </Button>
      </div>
      <Modal
        title="New Kefu"
        loading={loading}
        visible={modalOpen}
        onOk={handleSubmit}
        onCancel={() => { 
          form.resetFields();
          setModalOpen(false);
        }}
        okButtonProps={{
          loading,
          disabled: loading
        }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <Form layout="vertical" onSubmit={handleSubmit}>
          <Form.Item label="Name">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Please input name."
                }
              ]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Email">
            {getFieldDecorator("email", {
              rules: [
                {
                  type: "email",
                  message: "The input is not valid E-mail!"
                },
                {
                  required: true,
                  message: "Please input your E-mail!"
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default Form.create()(NewKefuModal);
