import React from "react";
import { Form, Select, Input } from "antd";
import { APP_DETAILS_OPTIONS } from "../../../constants";
import classes from "./OperationsDetailsSection.module.less";
import ManagerSelect from "../../UI/ManagerSelect/ManagerSelect";
const { Item } = Form;
const { Option } = Select;
const DistributionSection = ({
  form,
  app,
  loading,
  getFieldDecorator,
  handleAppStatus,
}) => {
  return (
    <div className={classes.container}>
      <Item label="Stores to upload" style={{ margin: 0, width: "100%" }}>
        {getFieldDecorator(`app.main_information.stores_to_upload`, {
          initialValue: app.main_information.stores_to_upload,
        })(
          <Select style={{ width: "100%" }}>
            {APP_DETAILS_OPTIONS.stores_to_upload.map((it) => (
              <Option key={it.value} value={it.value} label={it.label}>
                {it.label}
              </Option>
            ))}
          </Select>
        )}
      </Item>
      <Item label="Number of Updates" style={{ margin: 0, width: "100%" }}>
        {getFieldDecorator(`app.main_information.amount_updates`, {
          rules: [
            {
              pattern: /^[0-9][0-9]*$/,
              message: "Must be a positive integer",
            },
          ],
          initialValue: app.main_information.amount_updates,
        })(<Input />)}
      </Item>

      <Item label="Updates Left" style={{ margin: 0, width: "100%" }}>
        {getFieldDecorator(`app.main_information.updates_left`, {
          rules: [
            {
              pattern: /^[0-9][0-9]*$/,
              message: "Must be a positive integer",
            },
          ],
          initialValue: app.main_information.updates_left,
        })(<Input type="number" min="0" />)}
      </Item>

      <Item label="Account Manager" style={{ margin: 0, width: "100%" }}>
        {getFieldDecorator(`app.main_information.account_manager`, {
          initialValue: app.main_information.account_manager,
        })(
          <ManagerSelect
            setAccountManager={(val) =>
              form.setFieldsValue({
                "app.main_information.account_manager": val,
              })
            }
            accountManager={form.getFieldValue(
              `app.main_information.account_manager`
            )}
          />
        )}
      </Item>

      <Item
        label="Secondary Account Manager"
        style={{ margin: 0, width: "100%" }}
      >
        {getFieldDecorator(`app.main_information.secondary_account_manager`, {
          initialValue: app.main_information.secondary_account_manager,
        })(
          <ManagerSelect
            setAccountManager={(val) =>
              form.setFieldsValue({
                "app.main_information.secondary_account_manager": val,
              })
            }
            accountManager={form.getFieldValue(
              `app.main_information.secondary_account_manager`
            )}
          />
        )}
      </Item>

      <Item
        label="Tertiary Account Manager"
        style={{ margin: 0, width: "100%" }}
      >
        {getFieldDecorator(`app.main_information.tertiary_account_manager`, {
          initialValue: app.main_information.tertiary_account_manager,
        })(
          <ManagerSelect
            setAccountManager={(val) =>
              form.setFieldsValue({
                "app.main_information.tertiary_account_manager": val,
              })
            }
            accountManager={form.getFieldValue(
              `app.main_information.tertiary_account_manager`
            )}
          />
        )}
      </Item>
    </div>
  );
};

export default DistributionSection;
