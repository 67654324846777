import React, { useState, useEffect } from "react";
import classes from "./Accounts.module.less";
import CardAccount from "../../../CardAccount/CardAccount";
import { Table } from "antd";
const Accounts = ({ accounts, onUpdate, ...props }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(accounts);
  }, [accounts]);
  const deleteAccount = item => {
    let result =
      data.length > 1
        ? data.filter(e => `${e.account_id}` !== `${item.account_id}`)
        : [];

    onUpdate(result);
  };
  const updateAccountInfo = item => {
    let copyData = data;
    let foundIndex = data.findIndex(x => x.account_id === item.account_id);
    copyData[foundIndex] = item;
    onUpdate(copyData);
  };
  return (
    <div className={classes.Cards}>
      {data && data.length > 0 ? (
        data.map(it => (
          <CardAccount
            account={it}
            {...props}
            deleteItem={e => deleteAccount(e)}
            updateAccount={e => updateAccountInfo(e)}
          />
        ))
      ) : (
        <Table className={classes.table} />
      )}
    </div>
  );
};

export default Accounts;
