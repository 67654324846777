import React from "react";
import { Modal, Form, Input, Select } from "antd";
export default ({
  addModal,
  handleSubmitCategory,
  setAddModal,
  loadingUpload,
  getFieldDecorator,
  categories,
}) => {
  const { Option } = Select;
  return (
    <Modal
      title="Add New Category"
      visible={addModal}
      onOk={handleSubmitCategory}
      onCancel={() => setAddModal(false)}
      okButtonProps={{
        disabled: loadingUpload,
        loading: loadingUpload,
      }}
      cancelButtonProps={{
        disabled: loadingUpload,
        loading: loadingUpload,
      }}
    >
      <Form>
        <Form.Item label="Category">
          {getFieldDecorator("category", {
            rules: [
              {
                required: true,
                message: "Required!",
              },
            ],
          })(<Input />)}
        </Form.Item>
        {categories && categories.length > 0 ? (
          <Form.Item label="Parent Category">
            {getFieldDecorator("parent_category", { initialValue: null })(
              <Select>
                {[
                  { id: null, category: "None, this is a root Category" },
                  ...categories,
                ].map(({ category, id }) => (
                  <Option key={id} value={id}>
                    {category}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        ) : null}
      </Form>
    </Modal>
  );
};
