import React, { useState, useEffect } from "react";
import classes from "./KefuDetails.module.less";
import Input from "../../../UI/Input/Input";
import Accounts from "../KefuDetails/Accounts/Accounts";
import AddWechatAccount from "../../AddWechatAccountModal/AddWechatAccountModal";
import { Form, Spin } from "antd";
import { getKefuById } from "../../../../services/TicketsServices";
const KefuDetails = ({
  form,
  idKefu,
  handleSubmit,
  updateKefu,
  disabled,
  ...props
}) => {
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const [infoKefu, setInfoKefu] = useState({});
  const getData = async () => {
    setLoading(true);
    let { data } = await getKefuById(idKefu);
    setLoading(false);
    if (data && data.kefu) {
      setInfoKefu(data.kefu);
    }
  };
  useEffect(() => {
    getData();
  }, [idKefu]);
  useEffect(() => {
    updateKefu(infoKefu);
  }, [infoKefu]);
  return loading ? (
    <Spin
      tip="Loading..."
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    />
  ) : (
    <div>
      <div>
        <Form hideRequiredMark={true} layout="vertical" onSubmit={handleSubmit}>
          <Form.Item label="Name">
            {getFieldDecorator("name", {
              initialValue: infoKefu.name,
              onChange: e => setInfoKefu({ ...infoKefu, name: e.target.value }),
              rules: [
                {
                  required: true,
                  message: "Please input name."
                }
              ]
            })(<Input disabled={disabled} />)}
          </Form.Item>
          <Form.Item label="Email">
            {getFieldDecorator("email", {
              initialValue: infoKefu.email,
              onChange: e =>
                setInfoKefu({ ...infoKefu, email: e.target.value }),
              rules: [
                {
                  type: "email",
                  message: "The input is not valid E-mail!"
                },
                {
                  required: true,
                  message: "Please input your E-mail!"
                }
              ]
            })(<Input disabled />)}
          </Form.Item>
        </Form>
      </div>
      <div className={classes.title}>
        <b>Wechat Accounts with Access</b>

        <AddWechatAccount
          accounts={infoKefu.wechat_accounts}
          onAdd={e =>
            setInfoKefu({
              ...infoKefu,
              wechat_accounts: [...infoKefu.wechat_accounts, e]
            })
          }
        />
      </div>
      <div>
        <Accounts
          accounts={infoKefu.wechat_accounts}
          handleSubmit={handleSubmit}
          {...props}
          onUpdate={e => setInfoKefu({ ...infoKefu, wechat_accounts: e })}
        />
      </div>
    </div>
  );
};

export default Form.create()(KefuDetails);
