import axios from "../axios";

export async function getApkTestingRequests(app_id) {
  let response = null;
  try {
    response = await axios.post("/localization/apk-files", { app_id });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function updateApkReport(id_apk, report) {
  let response = null;
  try {
    response = await axios.post("/update-apk-file", {
      id_apk,
      values: {
        report,
      },
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function updateTranslationReport(id_translation, report) {
  let response = null;
  try {
    response = await axios.post("/update-translation", {
      id_translation,
      values: {
        report,
      },
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function updateSdkList(values) {
  let response = null;
  try {
    response = await axios.post("/update-sdks", {
      values,
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function sendIntegrationReportEmail(account_manager, emails) {
  let response = null;
  try {
    response = await axios.post("/send-integration-report", {
      account_manager,
      emails,
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function getEmailInfo(app_id) {
  let response = null;
  try {
    response = await axios.post("/email-info", {
      app_id,
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function getAddendumRequests(app_id) {
  let response = null;
  try {
    response = await axios.post("/localization/addendum-files", { app_id });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function newAddendumFile({ ...values }) {
  console.log(values);
  let response = null;
  try {
    response = await axios.post("/addendum-file", { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function editContract({ ...values }) {
  let response = null;
  try {
    response = await axios.put("/addendum-file", { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function deleteContract(id) {
  let response = null;
  try {
    response = await axios.delete(`/addendum-file/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function getWebHostingRequests(appId) {
  let response = null;
  try {
    response = await axios.post("get-icp", { appId });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function getIntellectualPropertyRequests(appId) {
  let response = null;
  try {
    response = await axios.post("/get-intellectual-property", { appId });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function getCompanyIncorporationRequests(appId) {
  let response = null;
  try {
    response = await axios.post("/get-company-incorporation", { appId });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function getTranslationRequests(app_id) {
  let response = null;
  try {
    response = await axios.post("/get-translations", { app_id });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
