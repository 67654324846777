import React from "react";
import classes from "./Input.module.css";
import { Input } from "antd";
const { TextArea } = Input;

const getInput = (onChange, value, disabled, type) => {
  switch (type) {
    case "password":
      return (
        <Input.Password
          onChange={onChange}
          value={value}
          disabled={disabled}
        />
      );
    case "textarea":
      return (
        <TextArea
          onChange={onChange}
          value={value}
          disabled={disabled}
          autosize={{ minRows: 2, maxRows: 6 }}
        />
      );
    default:
      return (
        <Input
          onChange={onChange}
          value={value}
          disabled={disabled}
        />
      );
  }
};

const customInput = ({
  value,
  valid,
  validated,
  errorMessage,
  onChange,
  placeholder,
  className,
  disabled,
  type
}) => {
  return (
    <div className={`${!valid && validated ? classes.error : ""} ${className}`}>
      <p className={classes.label}>{placeholder}</p>
      {getInput(onChange, value, disabled, type)}
      {!valid && validated && !!errorMessage ? <p>{errorMessage}</p> : null}
    </div>
  );
};
export default customInput;
