import React, { useState, useEffect } from "react";
import { getDistributionPerformance } from "../../../services/welcomePageService";
import { Card } from "antd";
import ModalApps from "./ModalApps/ModalApps";
export default ({ month, year }) => {
  let [loading, setLoading] = useState(true);
  let [activeClients, setActiveClients] = useState(0);
  let [targetRate, setTargetRate] = useState(0);
  let [totalRate, setTotalRate] = useState(0);
  let [appsWithoutAcc, setAppsWithoutAcc] = useState([]);
  let [averageNps, setAverageNps] = useState(0);
  let [showAccManagerModal, setShowAccManagerModal] = useState(false);
  let [appsWithoutLastContact, setAppsWithoutLastContact] = useState([]);
  let [showLastContactModal, setShowLastContactModal] = useState(false);

  const getData = async () => {
    setLoading(true);
    let { data } = await getDistributionPerformance(month, year);
    setLoading(false);

    setAppsWithoutAcc(data.appsWithoutAcc);
    setAppsWithoutLastContact(data.appsWithoutLastContact);
    setAverageNps(data.averageNps);
  };

  useEffect(() => {
    setTimeout(getData, 100);
  }, [month, year]);

  return (
    <Card title="Distribution Performance" loading={loading}>
      <ModalApps
        display={showAccManagerModal}
        setDisplay={setShowAccManagerModal}
        apps={appsWithoutAcc}
      />
      <ModalApps
        display={showLastContactModal}
        setDisplay={setShowLastContactModal}
        apps={appsWithoutLastContact}
      />
      <table>
        <tbody>
          <tr>
            <td>Apps Without Acc Manager</td>
            {appsWithoutAcc ? (
              <td
                style={{
                  cursor: "pointer",
                  color: "#97c549",
                }}
                onClick={() => setShowAccManagerModal(true)}
              >
                {appsWithoutAcc.length}
              </td>
            ) : null}
          </tr>
          <tr>
            <td>Apps Without Last Contact Date</td>
            {appsWithoutLastContact ? (
              <td
                style={{
                  cursor: "pointer",
                  color: "#97c549",
                }}
                onClick={() => setShowLastContactModal(true)}
              >
                {appsWithoutLastContact.length}
              </td>
            ) : null}
          </tr>
          <tr>
            <td>Average NPS:</td>
            <td>{averageNps || 0}</td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
};
