import React, { Component, Fragment } from "react";
import {
  Skeleton,
  Table,
  Button,
  Modal,
  Select,
  notification,
  Input
} from "antd";
import moment from "moment";
import {
  getApkTestingRequests,
  updateSdkList,
  sendIntegrationReportEmail,
  getEmailInfo
} from "../../../services/localizationService";
import { APP_TESTING_CATEGORIES, WORKS_IN_CHINA } from "../../../constants";
const Option = Select.Option;
export default class ApkTesting extends Component {
  state = {
    loadingApks: true,
    apkTestingRequests: null,
    currentSDK: null,
    sdkModalVisible: false,
    savingSdk: false,
    sendingReport: false,
    displayRecommendation: true,
    account_manager: null,
    emails: null,
    selectedEmails: null
  };
  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: "bottomLeft"
    });
  };
  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    let apkTestingRequests = await getApkTestingRequests(this.props.appId);
    let emailInfo = await getEmailInfo(this.props.appId);
    this.setState({
      apkTestingRequests: apkTestingRequests.data,
      loadingApks: false,
      account_manager: emailInfo.data.account_manager,
      emails: emailInfo.data.emails
    });
  };
  updateSdk = async () => {
    this.setState({
      savingSdk: true
    });
    let response = await updateSdkList(this.state.currentSDK);

    if (response.data === "success") {
      this.getData();
      this.setState({
        savingSdk: false
      });
      this.closeModal();
      this.openNotificationWithIcon("info", "Information saved.");
    }
  };

  sendReport = async () => {
    if (this.state.selectedEmails && this.state.selectedEmails.length > 0) {
      this.setState({
        sendingReport: true
      });
      let response = await sendIntegrationReportEmail(
        this.state.account_manager,
        this.state.selectedEmails
      );
      if (response.status === 200) {
        this.updateSdk();
        this.setState({
          sendReport: false
        });
        this.closeModal();
        this.openNotificationWithIcon(
          "info",
          "Integration report has been sent successfully."
        );
      }
    } else {
      this.openNotificationWithIcon(
        "warning",
        "Please select at least one user to send report"
      );
    }
  };
  closeModal = () => {
    this.setState({
      sdkModalVisible: false,
      currentSDK: null
    });
  };
  sdkModal = () => (
    <Modal
      width={this.state.displayRecommendation ? 800 : 600}
      title="SDK List"
      visible={this.state.sdkModalVisible}
      onOk={this.closeModal}
      onCancel={this.closeModal}
      footer={null}
    >
      {
        <Fragment>
          <div>
            <Table
              dataSource={this.state.currentSDK}
              columns={this.columns_sdk_list()}
              size="small"
              pagination={
                this.state.currentSDK && this.state.currentSDK.length > 6
                  ? { pageSize: 5 }
                  : false
              }
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "25px"
              }}
            >
              {this.state.emails ? (
                <Select
                  style={{ width: "100%" }}
                  mode="multiple"
                  placeholder="Please select at least one user to send report."
                  onChange={value => this.setState({ selectedEmails: value })}
                >
                  {this.state.emails.map(it => (
                    <Option key={it} value={it} label={it}>
                      {it}
                    </Option>
                  ))}
                </Select>
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px"
              }}
            >
              <Button
                loading={this.state.savingSdk}
                onClick={() => this.updateSdk()}
                type="primary"
                style={{ marginRight: "20px" }}
              >
                Save Information
              </Button>
              <Button
                loading={this.state.savingSdk}
                onClick={() => this.sendReport()}
                type="primary"
                ghost
              >
                Save and Notify Clients
              </Button>
            </div>
          </div>
        </Fragment>
      }
    </Modal>
  );
  showSDKContent = element => {
    this.setState({
      currentSDK: element,
      sdkModalVisible: true
    });
  };
  parseCategory = value => {
    let result = APP_TESTING_CATEGORIES.filter(e => e.value == value);
    return result.length > 0 ? result[0].label : value;
  };
  handleChangeSdkList = (value, elem, name) => {
    const sdkListCopy = [...this.state.currentSDK];
    const sdkUpdated = { ...elem, [name]: value };
    let res = sdkListCopy.map(obj =>
      obj.PK_Sdk != sdkUpdated.PK_Sdk ? obj : sdkUpdated
    );
    this.setState({
      currentSDK: res
    });
  };
  getSelect = elem => {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ margin: "10px" }}>
          <Select
            defaultValue={elem.works_in_china}
            onChange={value =>
              this.handleChangeSdkList(value, elem, "works_in_china")
            }
            style={{ width: "150px", marginLeft: "30px" }}
          >
            {WORKS_IN_CHINA.map(it => (
              <Option key={it.value} value={it.value} label={it.label}>
                {it.label}
              </Option>
            ))}
          </Select>
        </div>
        {elem.works_in_china == false && this.state.displayRecommendation ? (
          <Fragment>
            <div style={{ margin: "10px" }}>
              <Input
                value={elem.recommended_alternative_name || ""}
                placeholder="Alternative"
                onChange={e =>
                  this.handleChangeSdkList(
                    e.target.value,
                    elem,
                    "recommended_alternative_name"
                  )
                }
              />
            </div>
            <div style={{ margin: "10px" }}>
              <Input
                value={elem.recommended_alternative || ""}
                placeholder="Link"
                onChange={e =>
                  this.handleChangeSdkList(
                    e.target.value,
                    elem,
                    "recommended_alternative"
                  )
                }
              />
            </div>
          </Fragment>
        ) : (
          ""
        )}
      </div>
    );
  };
  apksColumns = () => [
    {
      title: "App Name",
      dataIndex: "app_name"
    },
    {
      title: "Package Name",
      dataIndex: "pkg_name"
    },
    {
      title: "Version Number",
      dataIndex: "version",
      align: "center"
    },
    {
      title: "Version Code",
      dataIndex: "version_code",
      align: "center"
    },
    {
      title: "Upload Date",
      dataIndex: "createdAt",
      defaultSortOrder: "ascend",
      sortDirections: ["descend", "ascend"],
      align: "center",
      sorter: (a, b) => {
        if (a.createdAt > b.createdAt) return -1;
        else if (a.createdAt < b.createdAt) return 1;
        return 0;
      },
      render: createdAt => moment(createdAt).format("YYYY-MM-DD")
    },
    {
      title: "File",
      dataIndex: "file_url",
      align: "center",
      render: apk_url =>
        apk_url ? (
          <a
            rel="noopener noreferrer"
            href={`https://appinchina.space/download/${apk_url}`}
            target="_blank"
          >
            <Button icon="cloud-download">Download</Button>
          </a>
        ) : (
          "No file attached"
        )
    },
    {
      title: "SDK List",
      dataIndex: "sdks",
      align: "center",
      render: sdks =>
        sdks.length > 0 ? (
          <Button icon="eye" onClick={() => this.showSDKContent(sdks)}>
            View SDK List
          </Button>
        ) : null
    }
  ];
  columns_sdk_list = () => [
    {
      title: "SDK Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: category => this.parseCategory(category)
    },
    {
      title: "Supported in China",
      dataIndex: "works_in_china",
      key: "works_in_china",
      align: "center",
      render: (works_in_china, elem) => this.getSelect(elem)
    },
  ];

  render() {
    return (
      <Skeleton
        loading={this.state.loadingApks}
        paragraph={{
          width: "100%",
          rows: 3
        }}
      >
        {this.sdkModal()}
        <Table
          dataSource={this.state.apkTestingRequests}
          columns={this.apksColumns()}
          size="small"
          pagination={{ pageSize: 5 }}
        />
      </Skeleton>
    );
  }
}
