import React, { Component } from "react";
import Axios from "../../axios";
import LineCard from "../UI/LineCard/LineCard";
import Input from "../UI/Input/Input";
import classes from "./AppForm.module.css";
import Preloader from "../UI/Preloader/Preloader";
import { Button, Icon } from "antd";
import FilePreview from "../UI/FilePreview/FilePreview";
import DownloadAsZip from "../DownloadZip/downloadZip";
import functions from "../../functions";
import { getStores } from "../../services/storesServices";
class AppForm extends Component {
  state = {
    app: [],
    loading: true,
    sendingFeedback: false,
    pillSelected: null,
    showingOldForm: false,
    currentVersion: null,
    modified_fields: [],
    emails: [],
    stores: [],
    multiApks_modified: []
  };
  getStoresRequest = async () => {
    let { data } = await getStores();
    if (data) {
      this.setState({ stores: data });
    }
  };
  getStoreName = id => {
    let value =
      this.state.stores.length > 0
        ? this.state.stores.find(store => store.id === id)
        : "";
    return value.fullname || "";
  };

  componentDidMount() {
    Axios.get(`/app-information/${this.props.match.params.id}`).then(result => {
      let filter = result.data.app_form.filter(
        it => !it.is_draft && it.is_draft != null
      );
      if (filter.length > 0) {
        //filter = filter.filter(it => it.version != null);
        filter = filter.map(it => ({
          ...it,
          version: it.version ? it.version : "N/A"
        }));
        const [current_version, ...rest] = filter;
        const version =
          filter[0].version === "" ? [...rest, current_version] : filter;
        this.getStoresRequest();
        this.setState({
          app: version,
          loading: false,
          emails: result.data.emails
        });
        this.showForm(version[version.length - 1], version.length - 1);
      } else {
        this.setState({
          app: filter,
          loading: false,
          emails: result.data.emails
        });
      }
    });
  }

  showForm = (form, pillIndex) => {
    if (form) {
      //should display an old version
      functions.setStateFromDraft(form, this);
      this.setState({
        showingOldForm: false,
        pillSelected: pillIndex
      });
      pillIndex > 0
        ? this.getModifiedFields(form, this.state.app[pillIndex - 1])
        : this.setState({ modified_fields: [] });
    } else {
      //displaying current draft
      functions.setStateFromDraft(form, this);
      //state must be updated now
      this.setState({
        showingOldForm: false,
        pillSelected: null
      });
    }
  };

  getPills = () => {
    return this.state.app && this.state.app.length ? (
      <div className={classes.Pills}>
        {this.state.app.map((it, index) => (
          <span
            key={index}
            onClick={() => this.showForm(it, index)}
            className={
              this.state.pillSelected === index ? classes.PillSelected : ""
            }
          >
            {it.version == "" ? "Current Version" : it.version}
          </span>
        ))}
      </div>
    ) : null;
  };

  getModifiedMultiApk = (v1, v2) => {
    let modified;
    if (v2.length > 0) {
      modified = v1.filter(
        item1 =>
          !v2.some(
            item2 =>
              item2.store_id === item1.store_id &&
              item2.url_apk === item1.url_apk
          )
      );
    } else {
      modified = v1;
    }

    return modified;
  };
  getModifiedFields = (v1, v2) => {
    const modified = [];
    let apkModified = this.getModifiedMultiApk(v1.multi_apks, v2.multi_apks);
    const object1 = Object.entries(v1).map(item =>
      item[1] === null ? [item[0], ""] : item
    );
    const object2 = Object.entries(v2).map(item =>
      item[1] === null ? [item[0], ""] : item
    );
    object1.forEach((item, index) => {
      if (item[1] != object2[index][1]) {
        modified.push(item[0]);
      }
    });

    this.setState({
      modified_fields: modified,
      multiApks_modified: apkModified
    });
  };

  render() {
    const { loading, currentVersion } = this.state;
    const data = !loading
      ? {
          ...currentVersion
        }
      : {};
    return !loading ? (
      data ? (
        <div className={classes.linecard}>
          <div style={{ margin: "10px 0px ", display: "flex" }}>
            <Button
              style={{ margin: "0px 10px 0 0" }}
              type="ghost"
              onClick={() => window.history.back()}
            >
              <Icon type="left" />
              Go back
            </Button>
            <DownloadAsZip data={data} form={"AppForm"} />
          </div>
          {
            <div>
              <LineCard title="App Form Information" margin="20px">
                {this.getPills()}
                <div className={classes.container}>
                  <Input
                    placeholder="Name"
                    value={data.appname_en}
                    disabled={true}
                    modified={this.state.modified_fields.includes("appname_en")}
                  />
                  <Input
                    placeholder="Short Name"
                    value={data.app_short_name}
                    disabled={true}
                    modified={this.state.modified_fields.includes(
                      "app_short_name"
                    )}
                  />
                  <Input
                    placeholder="Version"
                    value={data.version}
                    type="text"
                    disabled={true}
                    modified={this.state.modified_fields.includes("version")}
                  />
                  <Input
                    placeholder="App ONE-SENTENCE Description"
                    type="textarea"
                    value={data.appdesc_short}
                    disabled={true}
                    modified={this.state.modified_fields.includes(
                      "appdesc_short"
                    )}
                  />
                  <Input
                    placeholder="App LONG Description"
                    type="textarea"
                    value={data.appdesc_long}
                    disabled={true}
                    modified={this.state.modified_fields.includes(
                      "appdesc_long"
                    )}
                  />
                  <Input
                    placeholder="App Category"
                    value={data.app_category}
                    disabled={true}
                    modified={this.state.modified_fields.includes(
                      "app_category"
                    )}
                  />
                  <Input
                    placeholder="Test Username"
                    value={data.test_user}
                    disabled={true}
                    modified={this.state.modified_fields.includes("test_user")}
                  />
                  <Input
                    placeholder="Test Password"
                    value={data.test_password}
                    disabled={true}
                    modified={this.state.modified_fields.includes(
                      "test_password"
                    )}
                  />
                  <Input
                    placeholder="Tags"
                    value={data.app_tags}
                    disabled={true}
                    modified={this.state.modified_fields.includes("app_tags")}
                  />
                  <Input
                    placeholder="App Store iTunes Download Link"
                    value={data.itunes_link}
                    disabled={true}
                    modified={this.state.modified_fields.includes(
                      "itunes_link"
                    )}
                  />
                  <Input
                    placeholder="New Features"
                    value={data.new_features}
                    type="text"
                    disabled={true}
                    modified={this.state.modified_fields.includes(
                      "new_features"
                    )}
                  />
                  <Input
                    placeholder="Package Name"
                    value={data.package_name}
                    type="textarea"
                    disabled={true}
                    modified={this.state.modified_fields.includes(
                      "package_name"
                    )}
                  />
                  <Input
                    placeholder="Special Notes"
                    value={data.special_notes}
                    type="textarea"
                    disabled={true}
                    modified={this.state.modified_fields.includes(
                      "special_notes"
                    )}
                  />
                  <FilePreview
                    fileName={data.screenshot1}
                    placeholder="Screenshot 1 (480x800px)"
                    modified={this.state.modified_fields.includes(
                      "screenshot1"
                    )}
                  />
                  <FilePreview
                    fileName={data.screenshot2}
                    placeholder="Screenshot 2 (480x800px)"
                    modified={this.state.modified_fields.includes(
                      "screenshot2"
                    )}
                  />
                  <FilePreview
                    fileName={data.screenshot3}
                    placeholder="Screenshot 3 (480x800px)"
                    modified={this.state.modified_fields.includes(
                      "screenshot3"
                    )}
                  />
                  <FilePreview
                    fileName={data.screenshot4}
                    placeholder="Screenshot 4 (480x800px)"
                    modified={this.state.modified_fields.includes(
                      "screenshot4"
                    )}
                  />
                  <FilePreview
                    fileName={data.screenshot5}
                    placeholder="Screenshot 5 (480x800px)"
                    modified={this.state.modified_fields.includes(
                      "screenshot5"
                    )}
                  />
                  <FilePreview
                    fileName={data.screenshot6}
                    placeholder="Screenshot 6 (480x800px)"
                    modified={this.state.modified_fields.includes(
                      "screenshot6"
                    )}
                  />
                  <FilePreview
                    fileName={data.screenshot1_1}
                    placeholder="Screenshot 1 (1080x1920px)"
                    modified={this.state.modified_fields.includes(
                      "screenshot1_1"
                    )}
                  />
                  <FilePreview
                    fileName={data.screenshot2_1}
                    placeholder="Screenshot 2 (1080x1920px)"
                    modified={this.state.modified_fields.includes(
                      "screenshot2_1"
                    )}
                  />
                  <FilePreview
                    fileName={data.screenshot3_1}
                    placeholder="Screenshot 3 (1080x1920px)"
                    modified={this.state.modified_fields.includes(
                      "screenshot3_1"
                    )}
                  />
                  <FilePreview
                    fileName={data.screenshot4_1}
                    placeholder="Screenshot 4 (1080x1920px)"
                    modified={this.state.modified_fields.includes(
                      "screenshot4_1"
                    )}
                  />
                  <FilePreview
                    fileName={data.screenshot5_1}
                    placeholder="Screenshot 5 (1080x1920px)"
                    modified={this.state.modified_fields.includes(
                      "screenshot5_1"
                    )}
                  />
                  <FilePreview
                    fileName={data.screenshot6_1}
                    placeholder="Screenshot 6 (1080x1920px)"
                    modified={this.state.modified_fields.includes(
                      "screenshot6_1"
                    )}
                  />
                  <FilePreview
                    fileName={data.console_screenshot}
                    placeholder="Console Screenshot"
                    modified={this.state.modified_fields.includes(
                      "console_screenshot"
                    )}
                  />
                  <FilePreview
                    fileName={data.apk}
                    placeholder="APK"
                    modified={this.state.modified_fields.includes("apk")}
                  />
                  {data.multi_apks && data.multi_apks.length > 0
                    ? data.multi_apks.map(it => (
                        <FilePreview
                          fileName={it.url_apk}
                          placeholder={`Apk for ${this.getStoreName(
                            it.store_id
                          )}`}
                          modified={this.state.multiApks_modified.includes(it)}
                        />
                      ))
                    : null}
                  <FilePreview
                    fileName={data.icon}
                    placeholder="Icon (512x152px)"
                    modified={this.state.modified_fields.includes("icon")}
                  />
                  <FilePreview
                    fileName={data.icon_2}
                    placeholder="Icon (16x16px)"
                    modified={this.state.modified_fields.includes("icon_2")}
                  />
                  <FilePreview
                    fileName={data.icon_3}
                    placeholder="Icon (216x216px)"
                    modified={this.state.modified_fields.includes("icon_3")}
                  />
                  <FilePreview
                    fileName={data.statement}
                    placeholder="Authorization Statement"
                    modified={this.state.modified_fields.includes("statement")}
                  />
                  <FilePreview
                    fileName={data.preview_video}
                    placeholder="Preview Video"
                    modified={this.state.modified_fields.includes(
                      "preview_video"
                    )}
                  />
                  <Input
                    placeholder="Free, Freemium or Paid?"
                    value={data.app_cost}
                    type="text"
                    disabled={true}
                    modified={this.state.modified_fields.includes("app_cost")}
                  />
                  <Input
                    placeholder="Revenue Model"
                    value={data.revenue_model}
                    type="text"
                    disabled={true}
                    modified={this.state.modified_fields.includes(
                      "revenue_model"
                    )}
                  />
                  <Input
                    placeholder="Tablet Devices"
                    value={
                      data.tablet_devices === true || data.tablet_devices === 1
                        ? "Yes"
                        : data.tablet_devices === false ||
                          data.tablet_devices === 0
                        ? "No"
                        : ""
                    }
                    type="text"
                    disabled={true}
                    modified={this.state.modified_fields.includes(
                      "tablet_devices"
                    )}
                  />
                  <Input
                    placeholder="Includes Advertising?"
                    value={
                      data.advertising === true || data.advertising === 1
                        ? "Yes"
                        : data.advertising === false || data.advertising === 0
                        ? "No"
                        : ""
                    }
                    type="text"
                    disabled={true}
                    modified={this.state.modified_fields.includes(
                      "advertising"
                    )}
                  />
                  <Input
                    placeholder="Requires Internet?"
                    value={
                      data.internet === true || data.internet === 1
                        ? "Yes"
                        : data.internet === false || data.internet === 0
                        ? "No"
                        : ""
                    }
                    type="text"
                    disabled={true}
                    modified={this.state.modified_fields.includes("internet")}
                  />
                  <Input
                    placeholder="Privacy Policy"
                    value={data.privacy_policy}
                    type="textarea"
                    disabled={true}
                    modified={this.state.modified_fields.includes(
                      "privacy_policy"
                    )}
                  />
                  <Input
                    placeholder="Target Audience"
                    value={data.target_audience}
                    type="textarea"
                    disabled={true}
                    modified={this.state.modified_fields.includes(
                      "target_audience"
                    )}
                  />
                </div>
              </LineCard>
            </div>
          }{" "}
        </div>
      ) : (
        <div className={classes.NoResults}>
          <span>
            <b>Information not found</b>
            <Button
              onClick={() => {
                window.history.back();
              }}
              ghost
              width="100px"
              margin="auto"
            >
              Go back
            </Button>
          </span>
        </div>
      )
    ) : (
      <div className={classes.preloadercontainer}>
        <Preloader size={3} />
      </div>
    );
  }
}

export default AppForm;
