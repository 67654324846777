import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  notification,
  Modal,
  List,
  Card,
  Input,
  Form,
} from "antd";
import {
  getDistributionDetails,
  updateDistributionInfo,
  createDistributionEvent,
  createDistributionEventMultiple,
  updateMultipleVersions,
} from "../../services/appDistributionService";
import classes from "./NewAppDistribution.module.less";
import LoadingSpin from "../UI/LoadingSpin/LoadingSpin";
import NewHistoryModal from "./NewHistoryModal/NewHistoryModal";
import NewHistoryModalMultiple from "./NewHistoryModalMultiple/NewHistoryModalMultiple";
import UpdateMultipleVersions from "./UpdateMultipleVersions/UpdateMultipleVersions";
import HistoryTable from "./HistoryTable/HistoryTable";
import GeneralInfoCard from "./GeneralInfoCard/GeneralInfoCard";
const { confirm } = Modal;

const AppDistribution = ({ form, appInfo }) => {
  const [loadingApp, setLoadingApp] = useState(true);
  const [filter, setFilter] = useState("");
  let [loadingSaving, setLoadingSaving] = useState(false);
  let [data, setData] = useState([]);
  let [display, setDisplay] = useState(false);
  let [displayMultiple, setDisplayMultiple] = useState(false);
  let [updateVersions, setUpdateVersions] = useState(false);
  let [infoId, setInfoId] = useState(null);
  const { getFieldDecorator } = form;

  useEffect(() => {
    fetchData();
  }, [appInfo]);

  const fetchData = async () => {
    try {
      const { id } = appInfo;
      if (id) {
        const { data } = await getDistributionDetails(id);
        if (data.result) {
          setData(data.result);
          setLoadingApp(false);
        }
      }
    } catch (error) {}
  };

  const updateRow = ({ field, value, id }) => {
    const dataCopy = [...data];
    const index = dataCopy.findIndex((item) => item.id === id);
    dataCopy[index] = {
      ...dataCopy[index],
      [field]: value,
      viewed: false,
    };
    setData(dataCopy);
  };

  const updateCompleteRow = ({ values, id, store }) => {
    const dataCopy = data;
    const index = dataCopy.findIndex((item) => item.id === store);
    const newHistoryIndex = dataCopy[index].distribution_histories.findIndex(
      (item) => item.id === id
    );
    dataCopy[index].distribution_histories[newHistoryIndex] = {
      ...dataCopy[index].distribution_histories[newHistoryIndex],
      ...values,
    };
    setData(dataCopy);
  };

  const handleSubmit = async () => {
    try {
      confirm({
        title: "Are you sure you want to save all new information?",
        loading: loadingSaving,
        async onOk() {
          setLoadingSaving(true);
          const appInformation = form.getFieldsValue();

          const { id } = appInfo;
          const { data: response } = await updateDistributionInfo(
            id,
            data,
            appInformation.app
          );

          if (response.message !== "success") {
            notification.error({
              message: "An error has ocurred",
              placement: "bottomRight",
            });
          } else {
            notification.success({
              message: "Information saved",
              placement: "bottomRight",
            });
          }
          setLoadingSaving(false);
        },
      });
    } catch (error) {
      notification.error({
        message: "Fill all the required fields.",
        placement: "bottomRight",
      });
    }
  };
  const updateVersionsRequest = async (values) => {
    setLoadingSaving(true);
    try {
      const { data: response } = await updateMultipleVersions({
        ...values,
      });
      setLoadingSaving(false);
      if (response && response.message === "success") {
        fetchData();
        notification.success({ message: "Information Saved!" });
        setUpdateVersions(false);
      }
    } catch (error) {
      setDisplay(false);
      notification.error({
        message: "An error has ocurred.",
        placement: "bottomRight",
      });
    }
  };
  const createMultipleEvent = async (values) => {
    setLoadingSaving(true);
    try {
      const { data: response } = await createDistributionEventMultiple({
        ...values,
      });
      setLoadingSaving(false);
      if (response && response.message === "success") {
        fetchData();
        notification.success({ message: "Information Saved!" });
        setDisplayMultiple(false);
      }
    } catch (error) {
      setDisplay(false);
      notification.error({
        message: "An error has ocurred.",
        placement: "bottomRight",
      });
    }
  };
  const createNewEvent = async (values) => {
    setLoadingSaving(true);
    try {
      const { data: response } = await createDistributionEvent({
        ...values,
        distributionId: infoId,
      });
      if (response && response.message === "success") {
        setLoadingSaving(false);
        fetchData();
        setInfoId(null);
        setDisplay(false);
      }
    } catch (error) {
      setLoadingSaving(false);
      setDisplay(false);
      notification.error({
        message: "An error has ocurred.",
        placement: "bottomRight",
      });
    }
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  return loadingApp ? (
    <LoadingSpin />
  ) : (
    <div className={classes.container}>
      <Button
        loading={loadingSaving}
        width="200px"
        icon="save"
        type="primary"
        className={[classes.globalSave, "login-form-button"].join(" ")}
        onClick={handleSubmit}
      >
        Save
      </Button>
      <Row type="flex" justify="space-between">
        <Input
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Search Store..."
          className={classes.storeFilter}
        />
        <Button onClick={() => setUpdateVersions(true)} style={{ width: 200 }}>
          Update Multiple Versions
        </Button>
        <Button onClick={() => setDisplayMultiple(true)} style={{ width: 200 }}>
          Update Multiple Statuses
        </Button>
      </Row>
      <Form {...formItemLayout} style={{ width: "100%" }}>
        <GeneralInfoCard
          appInfo={appInfo}
          getFieldDecorator={getFieldDecorator}
        />
      </Form>
      <NewHistoryModal
        update={createNewEvent}
        saving={loadingSaving}
        historyData={[]}
        infoId={infoId}
        close={() => setDisplay(false)}
        visible={display}
        appInfo={appInfo}
      />
      <List
        itemLayout="vertical"
        size="small"
        rowKey={(it) => it.store.shortname}
        dataSource={
          data && data.length
            ? data.filter((it) =>
                it.store.name
                  .toLowerCase()
                  .replace(/\s/g, "")
                  .includes(filter.toLowerCase().replace(/\s/g, ""))
              )
            : []
        }
        pagination={{
          pageSize: 10,
        }}
        renderItem={(it) => (
          <Card
            className={classes.card}
            title={`${
              it.store.real_ranking <= 30 ? it.store.real_ranking + ". " : ""
            }${it.store.name}`}
            extra={
              <Button
                onClick={() => {
                  setInfoId(it.id);
                  setDisplay(true);
                }}
                type="primary"
                ghost
              >
                Update Status
              </Button>
            }
          >
            <HistoryTable
              updateRow={updateRow}
              updateCompleteRow={fetchData}
              data={it}
            />
          </Card>
        )}
      />
      <NewHistoryModalMultiple
        update={createMultipleEvent}
        saving={loadingSaving}
        options={data.map((it) => ({ value: it.id, label: it.store.name }))}
        close={() => setDisplayMultiple(false)}
        visible={displayMultiple}
        appInfo={appInfo}
      />
      <UpdateMultipleVersions
        update={updateVersionsRequest}
        saving={loadingSaving}
        options={data.map((it) => ({ value: it.id, label: it.store.name }))}
        close={() => setUpdateVersions(false)}
        visible={updateVersions}
        appInfo={appInfo}
      />
    </div>
  );
};

export default Form.create()(AppDistribution);
