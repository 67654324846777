import React from "react";
import { Form } from "antd";
import UploadApk from "./UploadApk/UploadApk";
import AppInformation from "./AppInformation/AppInformation";
import Screenshots from "./Screenshots/Screenshots";
import DistributionDetails from "./DistributionDetails/DistributionDetails";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
const Preview = ({ data, modified, form }) => {
  console.log("modified fields", modified);
  const { getFieldDecorator } = form;
  return (
    <div>
      <UploadApk
        modified={modified}
        data={data}
        disabled={true}
        getFieldDecorator={getFieldDecorator}
      />
      <AppInformation
        modified={modified}
        data={data}
        disabled={true}
        getFieldDecorator={getFieldDecorator}
      />
      <DistributionDetails
        modified={modified}
        data={data}
        disabled={true}
        getFieldDecorator={getFieldDecorator}
      />
      <Screenshots
        modified={modified}
        data={data}
        disabled={true}
        getFieldDecorator={getFieldDecorator}
      />
      <PrivacyPolicy
        modified={modified}
        data={data}
        disabled={true}
        getFieldDecorator={getFieldDecorator}
      />
    </div>
  );
};
export default Form.create()(Preview);
