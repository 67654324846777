import axios from "../axios";

export async function getAppHistory(app_id) {
  let response = null;
  try {
    response = await axios.get(`/app-history/${app_id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
