import React, { useState } from "react";
import { Select, Spin } from "antd";
import { multiSelectSearch } from "../../../services/globalSearch";
const { Option } = Select;

export default ({
  target = "users",
  placeholder = "Search User",
  multiple = false,
  change,
  excluded = [],
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleChange = (value) => {
    change(value);
  };

  const handleSearch = (value) => {
    fetchData(value);
  };

  const fetchData = async (text) => {
    try {
      setLoading(true);
      const { results } = await multiSelectSearch({ text, target });
      let filtered = results.filter((item) => {
        return excluded.indexOf(item.value) === -1;
      });
      setOptions(filtered);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const mode = multiple ? { mode: "multiple" } : {};

  return (
    <Select
      showSearch
      showArrow={false}
      filterOption={false}
      style={{ width: "100%" }}
      placeholder={placeholder}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={loading ? <Spin size="small" /> : null}
      {...mode}
      {...props}
    >
      {options.map(({ value, label }) => (
        <Option key={value} value={value}>
          {label}
        </Option>
      ))}
    </Select>
  );
};
