import React, { useEffect, useState } from "react";
import classes from "./ClientDetails.module.less";
import history from "../../history";
import LineCard from "../UI/LineCard/LineCard";
import {
  Form,
  Icon,
  Button,
  Modal,
  notification,
  Row,
  Card,
  Input,
  Tooltip,
  Checkbox,
} from "antd";
import {
  getClient,
  updateClient,
  deleteUser,
} from "../../services/clientService";
import AppsLinked from "./AppsLinked/AppsLinked";
const { Item } = Form;
const { confirm } = Modal;
const ClientCard = ({ form, clientId, ...props }) => {
  const { getFieldDecorator } = form;
  let [loading, setLoading] = useState(false);
  let [options, setOptions] = useState([]);
  let [loadingSaving, setLoadingSaving] = useState(false);
  let [user, setUser] = useState({});
  const getData = async () => {
    setLoading(true);
    let { data } = await getClient(clientId);
    setLoading(false);
    if (data && data.client) {
      setUser({ ...data.client, last_login: data.last_login });
    }
  };
  const deletePerson = async () => {
    let { data: response } = await deleteUser(clientId);
    if (response && response.message === "success") {
      notification.success({ message: "User Removed." });
      history.push("/clients");
    }
  };
  const confirmDeleteClient = () => {
    confirm({
      title: "Are you sure you want to delete this client?",
      onOk() {
        deletePerson();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const viewAsClient = () => {
    window.open(
      `${process.env.REACT_APP_CLIENTS_URL}/?jwt=${
        window.localStorage.id_token
      }&email_to_impersonate=${encodeURIComponent(user.email)}`,
      "_blank"
    );
  };
  const handleSaveClient = async (values) => {
    setLoadingSaving(true);
    let { data } = await updateClient({
      id: clientId,
      clientInfo: {
        ...values,
        id: clientId,
        apps:
          values.apps.length > 0
            ? values.apps.map(({ id, name, clients_apps }) => ({
                id: id,
                email: name,
                role: clients_apps.role,
              }))
            : [],
      },
    });
    setLoadingSaving(false);
    if (data && data.message === "success") {
      notification.success({
        message: "User Information Saved.",
        placement: "bottomRight",
      });
    } else {
      notification.error({
        message: "There was an error while connecting to the server.",
        placement: "bottomRight",
      });
    }
  };

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (!err) {
        // console.log("values==========>", values);
        confirm({
          title: "Are you sure you want to save all new information?",
          onOk() {
            handleSaveClient(values);
          },
          onCancel() {},
        });
      } else {
        notification.error({
          message: "Fill all the required fields.",
          placement: "bottomRight",
        });
      }
    });
  };
  useEffect(() => {
    getData();
    let optionsApps =
      props && props.options
        ? props.options.map(({ id, name }) => ({ id, name }))
        : [];
    setOptions(optionsApps);
  }, [clientId]);
  const formLayout = { labelCol: { span: 5 }, wrapperCol: { span: 24 } };
  return (
    <div className={classes.container}>
      <Row type="flex" justify="start">
        <Button
          className={classes.goBack}
          type="ghost"
          onClick={() => window.history.back()}
        >
          <Icon type="left" />
          Go back
        </Button>
      </Row>
      <Card
        loading={loading}
        title="Client Details"
        size="small"
        extra={
          <Row className={classes.Buttons} type="flex" justify="space-between">
            <Button
              onClick={confirmDeleteClient}
              icon="delete"
              shape="circle"
              type="danger"
              ghost
              disabled={loading || loadingSaving}
            />

            <Button
              disabled={loading || loadingSaving}
              onClick={viewAsClient}
              icon="eye"
            >
              View as client
            </Button>

            <Button
              disabled={loading}
              loading={loadingSaving}
              width="200px"
              icon="save"
              type="primary"
              onClick={() => handleSubmit()}
            >
              {loadingSaving ? "Saving Information..." : "Save Information"}
            </Button>
          </Row>
        }
      >
        <Form onSubmit={handleSubmit} {...formLayout}>
          <LineCard
            title="Main Information"
            description="This is the general information for the selected client"
          >
            <Item label="First Name" style={{ margin: 0 }}>
              {getFieldDecorator(`first_name`, {
                initialValue: user.first_name,
              })(<Input disabled={loadingSaving} />)}
            </Item>
            <Item label="Last Name" style={{ margin: 0 }}>
              {getFieldDecorator(`last_name`, {
                initialValue: user.last_name,
              })(<Input disabled={loadingSaving} />)}
            </Item>
            <Item
              className={classes.blocked}
              label={
                <Tooltip title="This will prevent the user from logging into the clients dashboard. Useful for user acquisition promoters.">
                  Blocked <Icon type="question-circle" />
                </Tooltip>
              }
            >
              {getFieldDecorator(`blocked`, {
                initialValue: user.blocked,
                valuePropName: "checked",
              })(<Checkbox />)}
            </Item>
            <Item label="Email" style={{ margin: 0 }}>
              {getFieldDecorator(`email`, {
                initialValue: user.email,
              })(<Input disabled />)}
            </Item>
            <Item label="Company" style={{ margin: 0 }}>
              {getFieldDecorator(`client_company`, {
                initialValue: user.client_company,
              })(<Input disabled={loadingSaving} />)}
            </Item>
            <Item label="Position" style={{ margin: 0 }}>
              {getFieldDecorator(`position`, {
                initialValue: user.position,
              })(<Input disabled={loadingSaving} />)}
            </Item>
            <Item label="Location" style={{ margin: 0 }}>
              {getFieldDecorator(`location`, {
                initialValue: user.location,
              })(<Input disabled={loadingSaving} />)}
            </Item>
            <Item label="Last Login" style={{ margin: 0 }}>
              {getFieldDecorator(`last_login`, {
                initialValue: user.last_login,
              })(<Input disabled />)}
            </Item>
          </LineCard>
          <LineCard
            style={{ marginTop: 20 }}
            title="Apps Linked"
            description="These are the apps that this client will be able to see after logging in."
          >
            <AppsLinked
              client={user}
              getFieldDecorator={getFieldDecorator}
              options={options}
              form={form}
            />
          </LineCard>
        </Form>
      </Card>
    </div>
  );
};
export default Form.create()(ClientCard);
