import React, { useState, useEffect } from "react";
import classes from "./Apps.module.less";
import AppCard from "./AppCard/AppCard";
import { Link } from "react-router-dom";
import { Table, Tag } from "antd";
import functions from "../../functions";
import AdvancedFilter from "../UI/AdvancedFilter/AdvancedFilter";
import AppImage from "../UI/AppImage/AppImage";
import { getRemovedApps } from "../../services/appsService";
import moment from "moment";
export default () => {
  let [grid, setGrid] = useState(false);
  let [filteredApps, setFiltered] = useState([]);
  let [data, setData] = useState([]);
  let [loading, setLoading] = useState(false);
  let [order, setOrder] = useState("date");
  let columns = [
    {
      title: "#",
      dataIndex: "appID",
      key: "appID",
      align: "center",
      width: "100px",
      fixed: "left",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => {
        if (a.appID > b.appID) return -1;
        else if (a.appID < b.appID) return 1;
        return 0;
      },
    },
    {
      title: "Image",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (id) => <AppImage id={id} />,
    },
    {
      title: "App Name",
      dataIndex: "name",
      key: "name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => {
        if (a.name > b.name) return -1;
        else if (a.name < b.name) return 1;
        return 0;
      },
      render: (name, elem) => (
        <Link
          to={"/apps/" + elem.id}
          style={{ "text-decoration": "none" }}
          className={classes.link}
        >
          <b style={{ color: "#8dbf40" }}>{name}</b>
        </Link>
      ),
    },
    {
      title: "Downloads",
      dataIndex: "total_downloads",
      key: "total_downloads",
      align: "left",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => {
        if (+a.total_downloads > +b.total_downloads) return -1;
        else if (+a.total_downloads < +b.total_downloads) return 1;
        return 0;
      },
      render: (total_downloads) => (
        <span>{functions.styleAsNumber(total_downloads)}</span>
      ),
    },
    {
      title: "Distribution",
      dataIndex: "distribution",
      key: "distribution",
      align: "center",
      render: (distribution, elem) => (
        <span>
          {` ${elem.uploaded_stores_count ? elem.uploaded_stores_count : 0} / ${
            elem.stores_to_upload ? elem.stores_to_upload : 0
          }`}
        </span>
      ),
    },
    {
      title: "Completion",
      dataIndex: "completion",
      key: "completion",
      align: "center",
      render: (completion) => getCompletionTag(completion),
    },
  ];
  const getCompletionTag = (completion) => {
    switch (completion) {
      case "COMPLETED":
        return <Tag color="green">Completed</Tag>;
      case "REMOVED":
        return <Tag color="red">Removed</Tag>;
      case "SUSPENDED":
        return <Tag color="volcano">Suspended</Tag>;
      case "ONGOING":
        return <Tag color="orange">In Progress</Tag>;
      case "NEW_APP":
        return <Tag color="purple">New App</Tag>;
      default:
        return <Tag color="volcano">Suspended</Tag>;
    }
  };
  const getFilteredApps = (filters = {}) => {
    let result = data.filter(
      (app) =>
        (filters.country && filters.country.length > 0
          ? filters.country.some((it) => `${app.country}` === it)
          : app) &&
        (filters.industry && filters.industry.length > 0
          ? filters.industry.some((it) => `${app.industry}` === it)
          : app) &&
        (filters.business_form_status && filters.business_form_status.length > 0
          ? filters.business_form_status.some(
              (it) => `${app.business_form_status}` === it
            )
          : app) &&
        (filters.app_form_status && filters.app_form_status.length > 0
          ? filters.app_form_status.some(
              (it) => `${app.app_form_status}` === it
            )
          : app) &&
        (filters.copyright_form_status &&
        filters.copyright_form_status.length > 0
          ? filters.copyright_form_status.some(
              (it) => `${app.copyright_form_status}` === it
            )
          : app) &&
        (filters.scc_status && filters.scc_status.length > 0
          ? filters.scc_status.some((it) => `${app.scc_status}` === it)
          : app) &&
        (filters.scc_owner && filters.scc_owner.length > 0
          ? filters.scc_owner.some((it) => `${app.scc_owner}` === it)
          : app) &&
        (filters.targeted_store && filters.targeted_store.length > 0
          ? filters.targeted_store.some((it) => `${app.targeted_store}` === it)
          : app) &&
        (filters.account_manager && filters.account_manager.length > 0
          ? filters.account_manager.some(
              (it) => `${app.account_manager}` === it
            )
          : app) &&
        (filters.confidential && filters.confidential.length > 0
          ? filters.confidential.some((it) => `${app.confidential}` === `${it}`)
          : app) &&
        app.name.toLowerCase().includes(filters.search.toLowerCase())
    );

    setFiltered(result);
  };
  const orderArray = () => {
    let result;
    if (order === "date") {
      result = filteredApps
        .map((app) => ({
          ...app,
          date: moment(app.date).format("YYYY-MM-DD"),
        }))
        .sort((a, b) => b.date - a.date);
    } else if (order === "name") {
      result = filteredApps.sort((a, b) => {
        if (a.name > b.name) return -1;
        else if (a.name < b.name) return 1;
        return 0;
      });
    } else if (order === "downloads") {
      result = filteredApps
        .map((app) => ({
          ...app,
          totalDownloads: +app.total_downloads,
        }))
        .sort((a, b) => b.total_downloads - a.total_downloads);
    }
    setFiltered(result);
  };

  const getData = async () => {
    setLoading(true);
    let { data } = await getRemovedApps();
    setLoading(false);
    if (data && data.result) {
      setData(data.result);
      setFiltered(data.result);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    orderArray();
  }, [order]);
  return (
    <>
      <div className={classes.grid}>
        <div>
          <div className={classes.Header}>
            {<h3>Apps Removed({filteredApps.length})</h3>}
            <div className={classes.removedFilter}>
              <AdvancedFilter
                setOrder={setOrder}
                loading={loading}
                search={getFilteredApps}
                filterApps={getFilteredApps}
                appsView={({ grid }) => setGrid(grid)}
              />
            </div>
          </div>
        </div>

        {grid ? (
          filteredApps.length > 0 ? (
            <div className={classes.AppsGrid}>
              {filteredApps.map((app, index) => (
                <AppCard
                  appId={app.id}
                  key={index}
                  appName={app.name}
                  completion={app.completion}
                  targetStores={app.stores_to_upload}
                  targetStoresCompleted={app.uploaded_stores_count}
                  active={app.active}
                  downloads={app.total_downloads}
                />
              ))}{" "}
            </div>
          ) : (
            <div className={classes.NoResults}>
              <span>No results found</span>
            </div>
          )
        ) : (
          <div style={{ padding: 20 }}>
            <Table
              loading={loading}
              rowKey={(record) => record.id}
              size="small"
              dataSource={filteredApps}
              columns={columns}
              scroll={{ x: 700 }}
              pagination={filteredApps.length > 20 ? { pageSize: 20 } : false}
              style={{ background: "white" }}
            />
          </div>
        )}
      </div>
    </>
  );
};
