import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Row, notification } from "antd";
import File from "../../File/File";
import { updateAppInformation } from "../../../../services/appDistributionService";
export default Form.create()(({ form, appId, getApp, ...props }) => {
  const [disabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (!err) {
        let newImage = `https://appinchina.space/uploads/${values.image}`;
        makeRequest(newImage);
      }
    });
  };

  const makeRequest = async (newImage) => {
    setSaving(true);
    let { data } = await updateAppInformation({
      id: appId,
      field: "image_data",
      value: newImage,
    });
    setSaving(true);
    if (data && data.message === "success") {
      notification.info({ message: "Image Saved." });
      props.close();
      getApp();
    }
  };
  const validateFields = () => {
    let values = Object.entries(form.getFieldsValue()).map((it) => it[1]);
    if (values.findIndex((e) => e === undefined) === -1 && values.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  useEffect(() => {
    validateFields();
  }, [form]);

  const { getFieldDecorator } = form;
  return (
    <div>
      <Modal
        title="New Image"
        visible={props.visible}
        footer={null}
        destroyOnClose={true}
        onCancel={props.close}
      >
        <Form hideRequiredMark={true}>
          <Form.Item>
            {getFieldDecorator(`image`, {
              rules: [{ required: true, message: "Required" }],
            })(
              <File
                type="File"
                validation={{ fileRequired: true }}
                title="App Image *"
                onDelete={() => {
                  form.setFieldsValue({ image: null });
                }}
                submitFile={(file) => {
                  form.setFieldsValue({ image: file.value });
                }}
              />
            )}
          </Form.Item>
          <Row type="flex" justify="center" align="middle">
            <Button
              disabled={disabled}
              type="primary"
              onClick={() => handleSubmit()}
              style={{ marginTop: 10 }}
              loading={saving}
            >
              Save Image
            </Button>
          </Row>
        </Form>
      </Modal>
    </div>
  );
});
