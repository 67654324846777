import axios from "../axios";

export async function getClient(id) {
  let response = null;
  try {
    response = await axios.get(`/clients/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function updateClient(values) {
  console.log("values", values);
  let response = null;
  try {
    response = await axios.put(`/clients`, { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function newClient(values) {
  let response = null;
  try {
    response = await axios.post(`/clients`, { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function deleteUser(id) {
  let response = null;
  try {
    response = await axios.delete("/clients", { data: { id: id } });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
export const getAsyncClients = async ({
  page = 1,
  results = 10,
  sort = "desc",
  field = "email",
  search,
}) => {
  let response = null;
  try {
    response = await axios.get(
      `/async-clients?page=${page}&sort=${sort}&field=${field}&results=${results}${
        search !== "" ? `&search=${search}` : ""
      }`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
