import React, { useState } from "react";
import { Button, Icon, Modal, notification as antnotification } from "antd";
import history from "../../../history";
import { deleteTask } from "../../../services/taskService";
const confirm = Modal.confirm;
const Actions = ({ notification, update }) => {
  const [loading, setLoading] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [localizationModal, setLocalizationModal] = useState(false);
  const [reviewsModal, setReviewsModal] = useState(false);
  const [customModal, setCustomModal] = useState(false);
  const [servicesModal, setServicesModal] = useState(false);
  const openNotificationWithIcon = (type, message) => {
    antnotification[type]({
      message: message,
      placement: "bottomLeft",
    });
  };
  const confirmDelete = async (id) => {
    setLoading(true);
    let { data } = await deleteTask(id);
    if (data && data.message === "success") {
      openNotificationWithIcon("info", "Notification deleted");
    } else {
      openNotificationWithIcon("warning", "An error has ocurred");
    }
    update();
    setLoading(false);
  };
  const showConfirm = (id) => {
    confirm({
      title: "Are you sure you want to delete this task?",
      onOk() {
        confirmDelete(id);
      },
      onCancel() {},
    });
  };
  const deleteNotification = (id) => {
    showConfirm(id);
  };

  const getAcquisitionModal = (notification) => {
    return (
      <Modal
        title="User Acquisition"
        footer={null}
        onCancel={() => setUserModal(false)}
        visible={userModal}
      >
        <p style={{ textAlign: "center" }}>
          <b>Details:</b> {notification.description}
        </p>
      </Modal>
    );
  };
  const getLocalizationModal = (notification) => {
    return (
      <Modal
        title="Localization"
        footer={null}
        onCancel={() => setLocalizationModal(false)}
        visible={localizationModal}
      >
        <p style={{ textAlign: "center" }}>
          <b>Details:</b> {notification.title}
        </p>
        <p style={{ textAlign: "center" }}>
          <a
            onClick={() =>
              history.push({
                pathname: `/apps/${notification.FK_App}/localization`,
              })
            }
          >
            View App
          </a>
        </p>
      </Modal>
    );
  };
  const getMonetizationAnalyticsMondal = (notification) => {
    return (
      <Modal
        visible={servicesModal}
        title="Review Info"
        onCancel={() => setServicesModal(false)}
        footer={[]}
      >
        <p>
          <b>Request from: </b>
          {notification.email_request}
        </p>
      </Modal>
    );
  };
  const getReviewModal = (notification) => {
    return (
      <Modal
        visible={reviewsModal}
        title="Review Info"
        onCancel={() => setReviewsModal(false)}
        footer={[]}
      >
        <p>
          <b>Store: </b>
          {notification.store_name}
        </p>
        <p>
          <b>User: </b>
          {notification.user}
        </p>
        <p>
          <b>Reply: </b> {notification.reply}
        </p>
      </Modal>
    );
  };
  const getCustomModal = (notification) => {
    return (
      <Modal
        visible={customModal}
        title="Custom Task Info"
        onCancel={() => setCustomModal(false)}
        footer={[]}
      >
        <p>
          <b>Title: </b>
          {notification.title}
        </p>
        <p>
          <b>Description: </b>
          {notification.description}
        </p>

        <p>
          <b>Created by: </b>
          {notification.user_email}
        </p>
      </Modal>
    );
  };
  const showDetails = (notification) => {
    if (notification.category === "acquisition") {
      setUserModal(true);
    } else if (notification.category === "localization") {
      setLocalizationModal(true);
    } else if (notification.category === "reviews") {
      setReviewsModal(true);
    } else if (notification.category === "custom") {
      setCustomModal(true);
    } else if (
      notification.category === "monetization" ||
      notification.category === "analytics"
    ) {
      setServicesModal(true);
    } else {
      getLink(notification);
    }
  };

  const getLink = (notification) => {
    let link = "";
    if (notification.category === "company") {
      link = "business-information";
    } else if (notification.category === "app") {
      link = "app-information";
    } else if (notification.category === "copyright") {
      link = "copyright-information";
    } else if (notification.category === "security") {
      link = "security-information";
    } else {
      link = "apps";
    }
    history.push({
      pathname: `/${link}/${notification.FK_App}`,
      state: { modified_fields: notification.modified_fields },
    });
  };

  return (
    <div>
      {" "}
      <Button
        ghost
        type="primary"
        style={{ margin: 10 }}
        onClick={() => showDetails(notification)}
      >
        <Icon type="eye" />
      </Button>
      <Button
        ghost
        type="danger"
        onClick={() => deleteNotification(notification.id)}
      >
        <Icon type={loading ? "loading" : "delete"} />
      </Button>
      {getAcquisitionModal(notification)}
      {getLocalizationModal(notification)}
      {getReviewModal(notification)}
      {getCustomModal(notification)}
      {getMonetizationAnalyticsMondal(notification)}
    </div>
  );
};

export default Actions;
