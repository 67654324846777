import React, { useEffect, useState } from "react";
import AppImage from "../../UI/AppImage/AppImage";
import classes from "./GridApps.module.less";
import { Row, Col, Tooltip } from "antd";
import { Link } from "react-router-dom";
export default ({ data }) => {
  let [appsToShow, setApps] = useState([]);
  let [extraApps, setExtra] = useState([]);
  useEffect(() => {
    setApps(data.slice(0, 4));
    setExtra(data.slice(4).map(({ name }) => name));
  }, []);
  return (
    <div className={classes.flex}>
      {appsToShow.map((it) => (
        <Tooltip placement="bottomLeft" title={it.name}>
          <Link to={`/apps/${it.id}/details`}>
            <AppImage id={it.id} />
          </Link>
        </Tooltip>
      ))}
      {extraApps.length > 0 ? (
        <Tooltip
          title={extraApps.map((it) => (
            <p>{it}</p>
          ))}
        >
          <div className={classes.more}>
            <p>+{extraApps.length}</p>
          </div>
        </Tooltip>
      ) : null}
    </div>
  );
};