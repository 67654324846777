import React, { useState } from "react";
import { Card, DatePicker } from "antd";
import Chart from "./Chart/Chart";
import moment from "moment";
const { RangePicker } = DatePicker;
const UpdatesCompleted = ({ updatesPerMonth, loading }) => {
  const [range, setRange] = useState([
    moment().subtract(6, "months"),
    moment(),
  ]);

  console.log("range", range);

  return (
    <div>
      <Card
        loading={loading}
        size="small"
        title="Updates Completed"
        extra={
          <RangePicker
            loading={loading}
            disabled={loading}
            placeholder={["Start month", "End month"]}
            format="YYYY-MM"
            mode={["month", "month"]}
            value={range}
            onPanelChange={(val, mode) => {
              setRange(val);
            }}
          />
        }
      >
        <Chart
          data={updatesPerMonth
            .filter((it) =>
              moment(it.month + "-02").isBetween(
                range[0].clone().subtract(1, "month"),
                range[1]
              )
            )
            .map((it) =>
              it.manager === "TEAM"
                ? { ...it, updatesCompleted: it.updatesCompleted / 4 }
                : it
            )}
        />
      </Card>
    </div>
  );
};
export default UpdatesCompleted;
