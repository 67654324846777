import React, { Fragment, useState } from "react";
import { Modal, Form, Input, Button } from "antd";
const { Item } = Form;

export default Form.create()(({ form, listInfo, createMember, listName }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const submitHandler = () => {
    form.validateFields((err, values) => {
      if (!err) {
        setLoading(true);
        createMember(
          {
            ...values,
            list_id: listInfo.id
          },
          () => {
            setLoading(false);
            setVisible(false);
            form.resetFields();
          }
        );
      }
    });
  };

  const { getFieldDecorator } = form;

  return (
    <Fragment>
      <Button
        icon="plus"
        type="primary"
        style={{ width: "100%" }}
        onClick={() => setVisible(true)}
      >
        Add Member
      </Button>
      <Modal
        visible={visible}
        okText="Save"
        onOk={submitHandler}
        onCancel={() => setVisible(false)}
        okButtonProps={{
          loading: loading,
          disabled: loading
        }}
        cancelButtonProps={{
          loading: loading,
          disabled: loading
        }}
      >
        <Form>
          <Item label="Email">
            {getFieldDecorator("email", {
              rules: [{ required: true, message: "Required" }]
            })(<Input />)}
          </Item>
          <Item label="First Name">
            {getFieldDecorator("fname", {
              rules: [{ required: true, message: "Required" }]
            })(<Input />)}
          </Item>
          <Item label="Last Name">{getFieldDecorator("lname")(<Input />)}</Item>
          <Item label="List"><p>{listName}</p></Item>
        </Form>
      </Modal>
    </Fragment>
  );
});
