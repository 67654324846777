import axios from "../axios";

export async function getAllProjects(page) {
  let response = null;
  try {
    response = await axios.get(`/projects?page=${page}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function getProjectById(id) {
  let response = null;
  try {
    response = await axios.get(`/projects/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function connectAppToProject(values) {
  let response = null;
  try {
    response = await axios.put(`/projects`, { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function newProject(values) {
  let response = null;
  try {
    response = await axios.post(`/projects`, { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function deleteAppFromProject(values) {
  let response = null;
  try {
    response = await axios.delete(`/projects`, {
      data: { ...values },
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function updateProject(values) {
  let response = null;
  try {
    response = await axios.put(`/project`, { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function deleteProject(values) {
  let response = null;
  try {
    response = await axios.delete(`/project`, {
      data: { ...values },
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function getProjectsByApp(id) {
  let response = null;
  try {
    response = await axios.get(`/projects-app/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}