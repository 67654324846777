import React, { useState, useEffect } from "react";
import classes from "./AppName.module.less";
import NewNameModal from "./NewNameModal/NewNameModal";

export default ({ id, name }) => {
  let [display, setDisplay] = useState(false);
  let [appName, setAppName] = useState("");
  useEffect(() => {
    setAppName(name);
  }, [id]);

  let onChange = (name) => {
    setDisplay(false);
    setAppName(name);
  };

  let handleClose = () => {
    console.log("clossing...")
    setDisplay(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.name} onClick={() => setDisplay(true)}>
        <input value={appName} disabled />
      </div>
      <NewNameModal
        visible={display}
        onChange={onChange}
        appId={id}
        value={appName}
        close={handleClose}
      />
    </div>
  );
};
