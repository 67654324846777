import React, { Fragment, useState, useEffect } from "react";
import { Modal, Input, Select, Button } from "antd";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import classes from "../AppDistribution.module.less";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  sendRejectionReasonEmail,
  getRejectionsReasons
} from "../../../services/operationsService";
const Option = Select.Option;

export default ({
  emailModal,
  clients_linked,
  account_manager,
  //rejectionReasons,
  openNotificationWithIcon,
  closeModal
}) => {
  const [state, setState] = useState({
    sendingEmail: false,
    selectedRejectionReasons: null,
    emailContent: EditorState.createEmpty(),
    selectRejections: true,
    showReset: false,
    selectedEmails: null
  });
  let [loading, setLoading] = useState(false);
  let [rejectionReasons, setRejections] = useState(null);
  const getRejections = async () => {
    let { data } = await getRejectionsReasons();
    if (data && data.rejections) {
      setRejections(data.rejections);
    }
  };
  const resetInfo = () => {
    setState({
      selectedRejectionReasons: null,
      emailContent: EditorState.createEmpty(),
      selectRejections: true,
      showReset: false,
      selectedEmails: null
    });
  };

  useEffect(() => {
    getRejections();
  }, []);

  const updateRejections = values => {
    let accountManagerInfo = account_manager
      ? ` ${account_manager.charAt(0).toUpperCase() +
          account_manager
            .slice(1)
            .toLowerCase()} at <a href="mailto:${account_manager.toLowerCase()}@appinchina.co">${account_manager.toLowerCase()}@appinchina.co</a>`
      : "";
    let html = `<p>Hello,<br/>Your app has been rejected from some of the Chinese app stores for the reasons listed below. Please fix these issues and then <a href="https://clients.appinchina.co" target="_blank">click here</a> to resubmit your app information.</p>
    ${`<ul>
    ${values.map(it => `<li margin-block-end: 1em">${it}</li>`)}
     </ul>`.replace(/>,</g, "><")}
    <p>If you require more information then please contact your account manager${accountManagerInfo}.</p>
    <img src="https://www.appinchina.co/images/logo-green.png" alt="undefined" style="float:left;height: auto;width: 200px"/>
    <p></p>`;
    let contentBlock = htmlToDraft(html);
    if (contentBlock) {
      let contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      let editorState = EditorState.createWithContent(contentState);
      setState({
        ...state,
        emailContent: editorState,
        selectedRejectionReasons: values
      });
    }
  };

  const sendEmail = async () => {
    if (
      state.selectedEmails &&
      state.selectedEmails.length > 0 &&
      state.selectedRejectionReasons &&
      state.selectedRejectionReasons.length > 0
    ) {
      const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      let isValid = true;
      state.selectedEmails.map(it => (isValid = pattern.test(it) && isValid));
      if (isValid) {
        setState({
          ...state,
          sendingEmail: true
        });
        let content = draftToHtml(
          convertToRaw(state.emailContent.getCurrentContent())
        );
        let response = await sendRejectionReasonEmail(
          content,
          state.selectedEmails
        );
        setState({
          ...state,
          sendingEmail: false
        });
        if (response.status === 200) {
          resetInfo();
          closeModal();
          openNotificationWithIcon("info", "Email has been successfully sent.");
        }
      } else {
        openNotificationWithIcon(
          "warning",
          "Please introduce valid email values."
        );
      }
    } else {
      openNotificationWithIcon(
        "warning",
        "Please select at least one client and one rejection reason to send email."
      );
    }
  };

  return (
    <Modal
      width={850}
      title="Rejection Reasons Email"
      visible={emailModal}
      onOk={() => resetInfo()}
      onCancel={() => {
        resetInfo();
        closeModal();
      }}
      footer={null}
    >
      <div>
        <Fragment>
          <div style={{ padding: "20px" }}>
            <b className={classes.labelModal}>From:</b>
            <Input disabled value={"operations@appinchina.co"} />

            <b style={{ marginTop: "10px" }} className={classes.labelModal}>
              Clients Selected
            </b>
            <Select
              style={{ width: "100%" }}
              mode="tags"
              value={
                state.selectedEmails && state.selectedEmails.length > 0
                  ? state.selectedEmails
                  : []
              }
              placeholder="Please select at least one user to send rejection reasons email."
              onChange={value => setState({ ...state, selectedEmails: value })}
            >
              {clients_linked.map(it => (
                <Option key={it.email} value={it.email} label={it.email}>
                  {it.email}
                </Option>
              ))}
            </Select>
            <b style={{ marginTop: "10px" }} className={classes.labelModal}>
              Rejection Reasons Selected
            </b>
            <Select
              style={{ width: "100%" }}
              mode="multiple"
              loading={loading}
              value={
                state.selectedRejectionReasons &&
                state.selectedRejectionReasons.length > 0
                  ? state.selectedRejectionReasons
                  : []
              }
              placeholder="Please select at least one rejection reason."
              onChange={value => updateRejections(value)}
              disabled={!state.selectRejections}
            >
              {rejectionReasons
                ? rejectionReasons.map(it => (
                    <Option
                      key={it.title}
                      value={it.rejection}
                      label={it.title}
                    >
                      {it.title}
                    </Option>
                  ))
                : null}
            </Select>
            {state.selectRejections ? null : (
              <div className={classes.editor}>
                {" "}
                <Editor
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  editorState={state.emailContent}
                  toolbar={{
                    options: ["inline", "list", "textAlign", "link"]
                  }}
                  onEditorStateChange={editorState =>
                    setState({
                      ...state,
                      emailContent: editorState
                    })
                  }
                />
              </div>
            )}
          </div>
        </Fragment>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px"
        }}
      >
        {state.selectedRejectionReasons &&
        state.selectedRejectionReasons.length > 0 ? (
          !state.showReset ? (
            <Button
              onClick={() =>
                setState({ ...state, selectRejections: false, showReset: true })
              }
            >
              Next Step
            </Button>
          ) : (
            <Fragment>
              <Button
                style={{ marginRight: 16 }}
                onClick={() =>
                  setState({
                    ...state,
                    selectedRejectionReasons: null,
                    emailContent: EditorState.createEmpty(),
                    selectRejections: true,
                    showReset: false
                  })
                }
              >
                Reset Email Content
              </Button>
              <Button
                type="primary"
                loading={state.sendingEmail}
                onClick={() => sendEmail()}
              >
                Send Email
              </Button>
            </Fragment>
          )
        ) : null}
      </div>
    </Modal>
  );
};
