import React from "react";
import { Button, Form, Modal, Select, Row } from "antd";
import AsyncSearchUser from "../../../UI/AsyncSearch/AsyncSearch";
const { Option } = Select;
const { Item } = Form;
export default Form.create()(
  ({
    form,
    visible,
    cancel,
    allStores = [],
    actualUsers = [],
    data,
    updateData,
  }) => {
    const { getFieldDecorator } = form;
    const saveInformation = async () => {
      form.validateFields(async (err, values) => {
        if (!err) {
          updateData([
            ...data,
            {
              email: values.user.label,
              id: values.user.key,
              stores: values.stores.join(","),
            },
          ]);
          cancel();
          form.resetFields();
        }
      });
    };
    return (
      <>
        <Modal
          title="Add User"
          footer={null}
          visible={visible}
          onCancel={() => cancel()}
        >
          <Form hideRequiredMark={true}>
            <Item label="User">
              {getFieldDecorator("user", {
                rules: [{ required: true, message: "Required" }],
              })(
                <AsyncSearchUser
                  target="users"
                  excluded={actualUsers}
                  placeholder="Select an user..."
                  change={(value) => form.setFieldsValue({ user: value })}
                  labelInValue={true}
                />
              )}
            </Item>
            <Item label="Stores">
              {getFieldDecorator("stores", {
                rules: [{ required: true, message: "Required" }],
              })(
                <Select
                  showSearch
                  placeholder="Select Stores..."
                  mode="multiple"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {allStores.map((it) => (
                    <Option key={it.id} value={it.id}>
                      {it.fullname}
                    </Option>
                  ))}
                </Select>
              )}
            </Item>
            <Row type="flex" justify="center">
              <Button type="primary" onClick={() => saveInformation()}>
                Save
              </Button>
            </Row>
          </Form>
        </Modal>
      </>
    );
  }
);
