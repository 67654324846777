import React, { useState } from "react";
import { Modal, Form, Button, Row, notification } from "antd";
import { connectAppToProject } from "../../../services/projectsService";
import AsyncSearchApp from "../../UI/AsyncSearch/AsyncSearch";
export default Form.create()(
  ({ form, data, updateData, apps, appsConnected, ...props }) => {
    let [saving, setSaving] = useState(false);
    //let appIDs = appsConnected.map(({ id }) => id);
    //let options = apps.filter((app) => !appIDs.includes(app.id));
    const handleSubmit = () => {
      form.validateFields((err, values) => {
        if (!err) {
          const appsToInsert = values.app.map(({ key }) => {
            return {
              app: key,
              project: props.id,
            };
          });
          makeRequest(appsToInsert);
        }
      });
    };
    const makeRequest = async (values) => {
      setSaving(true);
      let { data: response } = await connectAppToProject(values);
      setSaving(false);
      if (response && response.message === "success") {
        await updateData();
        notification.success({
          message: "Information saved.",
        });
      }
      form.resetFields();
      props.close();
    };

    const { getFieldDecorator } = form;
    return (
      <div>
        <Modal
          title="New App"
          visible={props.visible}
          footer={null}
          destroyOnClose={true}
          onCancel={props.close}
          confirmLoading={saving}
        >
          <Form hideRequiredMark={true}>
            <Form.Item label="Select Apps:">
              {getFieldDecorator(`app`, {
                rules: [{ required: true, message: "Required" }],
              })(
                <AsyncSearchApp
                  target="apps"
                  multiple={true}
                  placeholder="Select an app..."
                  change={(value) => form.setFieldsValue({ app: value })}
                  labelInValue={true}
                />
              )}
            </Form.Item>
            <Row type="flex" justify="center" align="middle">
              <Button
                loading={saving}
                type="primary"
                onClick={() => handleSubmit()}
                style={{ marginTop: 10 }}
              >
                Save
              </Button>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
);
