import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Row, notification, Input } from "antd";
import { updateAppInformation } from "../../../../services/appDistributionService";
export default Form.create()(
  ({ form, value, appId, onChange, visible, close }) => {
    console.log(value);
    const [disabled, setDisabled] = useState(true);
    const [saving, setSaving] = useState(false);
    const handleSubmit = () => {
      form.validateFields((err, values) => {
        if (!err) {
          makeRequest(values.name);
        }
      });
    };

    const makeRequest = async (newName) => {
      setSaving(true);
      let { data } = await updateAppInformation({
        id: appId,
        field: "name",
        value: newName,
      });
      setSaving(true);
      if (data && data.message === "success") {
        notification.info({ message: "App Name Updated." });
        if (onChange) onChange(newName);
      }
    };
    const validateFields = () => {
      let values = Object.entries(form.getFieldsValue()).map((it) => it[1]);
      if (
        values.findIndex((e) => e === undefined) === -1 &&
        values.length > 0
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };
    useEffect(() => {
      validateFields();
    }, [form]);

    const { getFieldDecorator } = form;
    return (
      <div>
        <Modal
          title="New App Name"
          visible={visible}
          footer={null}
          destroyOnClose={true}
          onCancel={close}
        >
          <Form hideRequiredMark={true}>
            <Form.Item>
              {getFieldDecorator(`name`, {
                initialValue: value,
                rules: [{ required: true, message: "Required" }],
              })(<Input />)}
            </Form.Item>
            <Row type="flex" justify="center" align="middle">
              <Button
                disabled={disabled}
                type="primary"
                onClick={() => handleSubmit()}
                style={{ marginTop: 10 }}
                loading={saving}
              >
                Save
              </Button>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
);
