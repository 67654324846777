import React, { Fragment, useState } from "react";
import { Table, Row, Button, Tag, Popconfirm } from "antd";
import moment from "moment";
import { APP_TESTING_CATEGORIES } from "../../constants";
export default ({ dataSource, setSdkInfo, testSdk, deleteSdk }) => {
  let [filteredInfo, setFilteredInfo] = useState(null);

  let getCategoryFilters = () => {
    let categories = [];
    dataSource.forEach((sdk) => {
      if (
        !categories.find((cat) => sdk.category === cat.value) &&
        sdk.category
      ) {
        categories.push({
          text: sdk.category,
          value: sdk.category,
        });
      }
    });
    return categories.sort((a, b) => (a.text > b.text ? 1 : -1));
  };

  const columns = [
    {
      fixed: "left",
      width: "100px",
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      filters: getCategoryFilters(),
      filteredValue: (filteredInfo || {}).category || null,
      onFilter: (value, record) => record.category === value,
      render: (category) => {
        let result = APP_TESTING_CATEGORIES.find((it) => it.value === category);
        return result ? result.label : category;
      },
    },
    {
      title: "Works In China",
      dataIndex: "works_in_china",
      key: "works_in_china",
      filters: [
        { text: "Yes", value: "1" },
        { text: "No", value: "0" },
        { text: "Not Tested", value: "null" },
      ],
      filteredValue: (filteredInfo || {}).works_in_china || null,
      onFilter: (value, record) => {
        return (
          (value === "1" && record.works_in_china === 1) ||
          (value === "0" && record.works_in_china === 0) ||
          (value === "null" && record.works_in_china === null)
        );
      },
      render: (value) => {
        if (value === null) {
          return <Tag color="blue">Not Tested</Tag>;
        } else {
          return value ? (
            <Tag color="green">Yes</Tag>
          ) : (
            <Tag color="red">No</Tag>
          );
        }
      },
    },
    {
      title: "Endpoint Domain",
      dataIndex: "test_endpoint",
      key: "test_endpoint",
      filters: [
        { text: "Endpoint Set", value: "yes" },
        { text: "Endpoint Not Set", value: "no" },
      ],
      filteredValue: (filteredInfo || {}).test_endpoint || null,
      onFilter: (value, record) => {
        return (
          (value === "yes" && !!record.test_endpoint) ||
          (value === "no" && !record.test_endpoint)
        );
      },
    },
    {
      title: "Test date",
      dataIndex: "test_date",
      key: "test_date",
      render: (date) => (date ? moment(date).format("YYYY-MM-DD") : "-"),
    },
    {
      title: "",
      dataIndex: "testing",
      key: "testing",
      render: (testing, item) => (
        <Button
          onClick={() => testSdk(item)}
          disabled={testing}
          icon={testing ? "loading" : "cloud-sync"}
        >
          Test Now
        </Button>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id, item) => (
        <Row type="flex" justify="space-around">
          <Button shape="circle" icon="edit" onClick={() => setSdkInfo(item)} />
          <Popconfirm
            title="Are you sure you want to remove this SDK?"
            onConfirm={() => deleteSdk(id)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon="delete" type="danger" shape="circle" />
          </Popconfirm>
        </Row>
      ),
    },
  ];

  let handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
  };

  return (
    <Table
      rowKey={(record) => record.id}
      size="small"
      dataSource={dataSource}
      columns={columns}
      onChange={handleChange}
      scroll={{ x: 900 }}
    />
  );
};
