import React, { useState, useEffect } from "react";
import classes from "./kefus.module.less";
import { Menu, Icon, Button, notification, Form, Spin, Modal } from "antd";
import KefuDetails from "../Kefus/KefuDetails/KefuDetails";
import NewKefuModal from "./NewKefuModal/NewKefuModal";
import { getKefus, updateKefu } from "../../../services/TicketsServices";
import history from "../../../history";

const Kefus = ({ form, ...props }) => {
  const { confirm } = Modal;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [actualKefu, setActualKefu] = useState({});
  const [id, setId] = useState(props.match.params.id);

  const getData = async () => {
    setLoading(true);
    let { data } = await getKefus();
    setLoading(false);
    if (data && data.kefus) {
      setData(data.kefus);
    } else {
      notification.warning({
        message: "Error while connecting to the server",
        placement: "bottomLeft",
        duration: 2
      });
    }
  };

  const showConfirm = () => {
    confirm({
      title: "Are you sure you want to save this information?.",
      onOk() {
        saveGlobal();
      },
      onCancel() {}
    });
  };

  const saveGlobal = async () => {
    setLoadingSave(true);
    let { data } = await updateKefu(actualKefu);
    setLoadingSave(false);
    if (data && data.message === "success") {
      notification.info({
        message: "Information saved.",
        placement: "bottomLeft",
        duration: 2
      });
    } else {
      notification.warning({
        message: "Error while connecting to the server",
        placement: "bottomLeft",
        duration: 2
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return loading ? (
    <Spin
      tip="Loading..."
      style={{
        display: "flex",
        height: "calc(100vh - 120px)",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    />
  ) : (
    <div className={classes.container}>
      {" "}
      <div>
        <Menu
          style={{ width: 256, height: "100%", textAlign: "left" }}
          selectedKeys={[
            id || ""
          ]}
        >
          {data.map(it => (
            <Menu.Item key={it.id} onClick={() => {
              setId(it.id)
              history.push(`/tickets/kefus/${it.id}`)
            }}>
                <Icon type="user" />
                {it.name}
            </Menu.Item>
          ))}
        </Menu>
      </div>
      <div className={classes.details}>
        <div className={classes.buttons}>
          <Button
            htmlType="submit"
            style={{ marginRight: 10 }}
            type="primary"
            icon="save"
            disabled={!id}
            loading={loadingSave}
            onClick={() => showConfirm()}
          >
            Save
          </Button>
          <NewKefuModal 
            onAdd={({name, kefuId}) => {
              setData([
                ...data,
                {
                  id: kefuId,
                  name
                }
              ])
              setId(kefuId)
              history.replace(`/tickets/kefus/${kefuId}`)
            }} 
          />
        </div>
        {id ? (
          <KefuDetails
            {...props}
            idKefu={props.match.params.id}
            disabled={loadingSave}
            updateKefu={e => setActualKefu(e)}
          />
        ) : (
          <div className={classes.text}>
            {" "}
            Select an user to see more information.
          </div>
        )}
      </div>
    </div>
  );
};

export default Form.create()(Kefus);
