import axios from "../axios";
export const getCloudByApp = async id => {
  try {
    return await axios.get(`/cloud-store/${id}`);
  } catch (error) {
    return null;
  }
};

export const verifyAccountBalance = async id => {
  try {
    return await axios.put(`/account-balance`, { ...id });
  } catch (error) {
    return null;
  }
};
export const updateOrder = async values => {
  try {
    return await axios.put(`/cloud-order`, { ...values });
  } catch (error) {
    return null;
  }
};

export const newAccountBalance = async values => {
  try {
    return await axios.post(`/account-balance`, { ...values });
  } catch (error) {
    console.log("error", error);
    return null;
  }
};

export const verifyPayment = async id => {
  try {
    return await axios.get(`/confirm-payment/${id}`);
  } catch (error) {
    return null;
  }
};
