import React, { useState, useEffect } from "react";
import { Popover, Icon, Card } from "antd";
import functions, { formatTransactions } from "../../../functions";
import { getFinanceSummary } from "../../../services/welcomePageService";
import { getTransactions } from "../../../services/aicPay";
import classes from "../Welcome.module.less";

export default ({
  month,
  year,
  handlePaymentsReceived,
  handleMonetizationReceived,
}) => {
  let [loading, setLoading] = useState(true);
  let [paymentsBalance, setPaymentsBalance] = useState({});
  let [revenue, setRevenue] = useState({});
  let [prospect, setProspect] = useState({});
  let [monthlyChurnRate, setMonthlyChurnRate] = useState({});
  let [yearlyChurnRate, setYearlyChurnRate] = useState({});

  const getData = async () => {
    try {
      setLoading(true);
      /*const { data: response, status } = await getTransactions({ month, year });*/
      let { data } = await getFinanceSummary(month, year);
      //if (status == 200) {
      if (data) {
        /*const { monetization_table, ...aicPaymentsData } = parseData(
          data.paymentsBalance.ids,
          response
        );*/
        setLoading(false);
        setPaymentsBalance(data.paymentsBalance);
        setRevenue(data.revenue);
        setProspect(data.prospect);
        setMonthlyChurnRate(data.monthlyChurnRate);
        setYearlyChurnRate(data.yearlyChurnRate);
        handlePaymentsReceived(data.payments);
        //handleMonetizationReceived(monetization_table);
        //setPaymentsBalance(aicPaymentsData);
      } else {
        setLoading(false);
        setPaymentsBalance(data.paymentsBalance);
        setRevenue(data.revenue);
        setProspect(data.prospect);
        setMonthlyChurnRate(data.monthlyChurnRate);
        setYearlyChurnRate(data.yearlyChurnRate);
        handlePaymentsReceived(data.payments);
        handleMonetizationReceived([]);
        setPaymentsBalance({});
      }
    } catch (error) {
      console.log(error);
    }
  };

  const parseData = (ids, data) => {
    try {
      const monetization_table = ids.reduce((prev, curr) => {
        const app = data.adminDashboardGroupedTransactions
          .filter(
            ({ app_id }) =>
              app_id == curr.alipay_id ||
              app_id == curr.wechat_id ||
              app_id == curr.seccond_alipay_id ||
              app_id == curr.seccond_wechat_id
          )
          .reduce(
            (prev, curr) => ({
              ...prev,
              transactions: prev.transactions + curr.number_of_transactions,
              balance: prev.balance + curr.balance,
            }),
            {
              transactions: 0,
              balance: 0,
            }
          );
        return [
          ...prev,
          {
            app: curr.name,
            transactions: app.transactions,
            balance: app.balance,
            percentaje: curr.payments_percentaje,
          },
        ];
      }, []);
      const something = ids.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.name]: {
            transactions: data.balance
              .filter(
                ({ app_id }) =>
                  app_id == curr.alipay_id ||
                  app_id == curr.wechat_id ||
                  app_id == curr.seccond_alipay_id ||
                  app_id == curr.seccond_wechat_id
              )
              .reduce((prev, curr) => {
                return prev + curr.balance;
              }, 0),
            percentaje: curr.payments_percentaje,
          },
        };
      }, {});
      const aic = Object.values(something).reduce((prev, curr) => {
        return prev + (curr.transactions * (+curr.percentaje / 100)) / 100;
      }, 0);
      const total = data.balance.reduce((prev, curr) => {
        return prev + curr.balance / 100;
      }, 0);

      return {
        monetization_table,
        aic,
        total,
        number_of_transactions:
          data.adminDashbordTransactions[0].number_of_transactions || 0,
      };
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTimeout(getData, 100);
  }, [month, year]);

  return (
    <Card
      loading={loading}
      title={paymentsBalance ? <p>Finance Summary</p> : null}
    >
      <table>
        <tbody>
          {/*<tr>
            <td>Payments Platform Total:</td>
            <td>
              <span>
                ¥
                {paymentsBalance
                  ? functions.formatMoney(paymentsBalance.total)
                  : 0}
              </span>
            </td>
          </tr>
          <tr>
            <td>Payments AIC Revenue:</td>
            <td>
              <span>
                ¥
                {paymentsBalance
                  ? functions.formatMoney(paymentsBalance.aic)
                  : 0}
              </span>
            </td>
          </tr>
          <tr>
            <td>Number of Transactions:</td>
            <td>
              <span>
                {paymentsBalance
                  ? formatTransactions(paymentsBalance.number_of_transactions)
                  : 0}
              </span>
            </td>
                </tr>*/}
          <tr>
            <td>Revenue:</td>
            <td>
              <span className={classes.EmphasisDark}>
                ${revenue ? functions.formatMoney(revenue) : 0}
              </span>
            </td>
          </tr>
          <tr>
            <td>Prospect:</td>
            <td>
              <span className={classes.EmphasisLight}>
                ${prospect ? functions.formatMoney(prospect) : 0}
              </span>
            </td>
          </tr>
          <tr>
            <td>Monthly Churn Rate</td>
            <td>
              {monthlyChurnRate ? (
                <Popover
                  title="Details"
                  placement="bottom"
                  content={
                    <div>
                      <p>
                        Initial Customers:{" "}
                        <b>{monthlyChurnRate.amountClientsStart}</b>
                        <br />
                        Customers who Churned:{" "}
                        <b>{monthlyChurnRate.amountOldChurned}</b>
                        <br />
                        {monthlyChurnRate.listChurned ? (
                          <span className={classes.churnedList}>
                            {monthlyChurnRate.listChurned}
                          </span>
                        ) : null}
                      </p>
                    </div>
                  }
                >
                  {monthlyChurnRate.rate}%{" "}
                  <Icon type="info-circle" theme="filled" />
                </Popover>
              ) : (
                "-"
              )}
            </td>
          </tr>
          <tr>
            <td>Yearly Churn Rate</td>
            <td>
              {yearlyChurnRate ? (
                <Popover
                  title="Details"
                  placement="bottom"
                  content={
                    <div>
                      <p>
                        Initial Customers:{" "}
                        <b>{yearlyChurnRate.amountClientsStart}</b>
                        <br />
                        Customers who Churned:{" "}
                        <b>{yearlyChurnRate.amountOldChurned}</b>
                        <br />
                        {yearlyChurnRate.listChurned ? (
                          <span className={classes.churnedList}>
                            {yearlyChurnRate.listChurned}
                          </span>
                        ) : null}
                      </p>
                    </div>
                  }
                >
                  {" "}
                  {yearlyChurnRate.rate}%{" "}
                  <Icon type="info-circle" theme="filled" />
                </Popover>
              ) : (
                "-"
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
};
