import React, { useEffect, useState } from "react";
import classes from "../Badge.module.less";
import { Table, DatePicker, Button, Col, Row, Icon } from "antd";
import { getAllBadge } from "../../../services/badgeTracking";
import functions from "../../../functions";
import LineCard from "../../UI/LineCard/LineCard";
import moment from "moment";
import Chart from "./Chart";
const { RangePicker } = DatePicker;
const BadgeDetails = ({ ...props }) => {
  let [data, setData] = useState([]);
  let [start_date, setStartDate] = useState("");
  let [end_date, setEndDate] = useState("");
  let [loading, setLoading] = useState(false);
  let [chartData, setChartData] = useState([]);
  const getData = async (start, end) => {
    let { id } = props.match.params;
    setLoading(true);
    let { data } = await getAllBadge(start, end, id);
    setLoading(false);
    if (data && data.table) {
      setData(data.table);
      setChartData(data.chart);     
    }
  };
  const onChangeDate = (_, [start, end]) => {
    setStartDate(start);
    setEndDate(end);
    getData(start, end);
  };
  useEffect(() => {
    getData(
      moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD")
    );
    setStartDate(
      moment()
        .startOf("month")
        .format("YYYY-MM-DD")
    );
    setEndDate(moment().format("YYYY-MM-DD"));
  }, []);
  let columns = [
    {
      title: "Page",
      dataIndex: "page",
      key: "page"
    },
    {
      title: "Views",
      dataIndex: "views",
      key: "views",
      render: views => functions.styleAsNumber(views)
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      render: clicks => functions.styleAsNumber(clicks)
    },
    {
      title: "Click Rate",
      dataIndex: "click_rate",
      key: "click_rate",
      render: (_, val) => `${(val.clicks / val.views) * 100}%`
    }
  ];
  return (
    <div className={classes.container}>
      <Row type="flex" gutter={16} style={{ marginBottom: 20 }}>
        <Col>
          <Button
            style={{ margin: "0px 10px 0 0" }}
            type="ghost"
            onClick={() => window.history.back()}
          >
            <Icon type="left" />
            Go back
          </Button>
        </Col>
        <Col>
          <RangePicker
            value={
              start_date !== "" && end_date !== ""
                ? [moment(start_date), moment(end_date)]
                : [moment().startOf("month"), moment()]
            }
            style={{ width: "100%" }}
            onChange={onChangeDate}
            format={"YYYY-MM-DD"}
            disabled={loading}
          />
        </Col>
      </Row>
      <Table
        loading={loading}
        dataSource={data}
        size="small"
        columns={columns}
      />
      <LineCard title="Results per day">
        <Chart loading={loading} data={chartData} />
      </LineCard>
    </div>
  );
};
export default BadgeDetails;
