import axios, { appInChinaPay } from "../axios";

export async function getPaymentsAlerts() {
  let response = null;
  try {
    response = await axios.get("/payments-alerts");
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function getPaymentsAlertsChart() {
  let response = null;
  try {
    response = await appInChinaPay.get("/payments-summary");
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function getAlertsCharts(app) {
    let response = null;
    try {
      response = await axios.get(`/payments-alerts-chart?app=${app}`);
    } catch (e) {
      console.log("error", e);
    }
    return response;
}

export async function getAlertsTable() {
  let response = null;
  try {
    response = await axios.get(`/payments-alerts-table`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}


export async function markAsSolved(id) {
  let response = null;
  try {
    response = await axios.put(`/payments-alerts`, {
      id
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}