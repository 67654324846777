import React, { useState, Fragment, useEffect } from "react";
import classes from "./AdvancedFilter.module.css";
import functions from "../../../functions";
import ExportAppsDialog from "../../Dialogs/ExportAppsDialog/ExportAppsDialog";
import AddAppDialog from "../../Dialogs/AddAppDialog/AddAppDialog";
import ServiceTags from "../../AppDetailsDistribution/SalesSection/ServiceTags/ServiceTags";
import {
  Button,
  Select,
  Icon,
  Popover,
  Radio,
  Row,
  Input as AntInput,
} from "antd";
import {
  LIST_INDUSTRIES,
  LIST_COUNTRIES,
  APP_DETAILS_OPTIONS,
} from "../../../constants";
import Input from "../../UI/Input/Input";
import ManagerSelect from "../ManagerSelect/ManagerSelect";
const Option = Select.Option;
const RadioGroup = Radio.Group;
const AdvancedFilter = ({
  filterApps,
  AddApp,
  AppStateFilter,
  appsView,
  updateAppAdded,
  appIds,
  search,
  loading,
  setOrder,
  completion,
}) => {
  const [filters, setFilters] = useState({
    suspended: true,
    ongoing: true,
    completed: true,
    delayed: true,
    newApp: true,
    business_form_status: [],
    app_form_status: [],
    copyright_form_status: [],
    scc_owner: [],
    scc_status: [],
    icp_status: [],
    targeted_store: [],
    account_manager: [],
    confidential: [],
    country: [],
    industry: [],
    search: "",
    order: "date",
    stores_published: [],
    completion: [],
    telephone: "",
    appType: "",
    serviceTags: [],
    revenue_model: [],
  });
  const [grid, setGrid] = useState(false);
  const [titles, setTitles] = useState([
    "business_form_status",
    "app_form_status",
    "copyright_form_status",
    "SCC_status",
    "SCC_owner",
    "icp_status",
    "targeted_store",
    "ACCOUNT_MANAGER",
    "CONFIDENTIAL",
    "REVENUE_MODEL",
  ]);
  const [advancedsearch, setAdvancedSearch] = useState(false);
  const [addAppDialog, setAddAppDialog] = useState(false);

  useEffect(() => {
    clearFilter();
  }, []);
  useEffect(() => {
    if (completion) {
      setTitles([...titles, "completion"]);
    }
  }, [completion]);

  const handleAppAdded = (app) => {
    updateAppAdded(app);
    setAddAppDialog(!addAppDialog);
  };

  const handleAdvancedSearch = (option, name) => {
    console.log("handleAdvancedSearch", option, name);
    setFilters({
      ...filters,
      [name]: option,
    });
    filterApps({
      ...filters,
      [name]: option,
    });
  };
  const handleCheckbox = (value) => {
    let copy = { ...filters };
    setFilters({
      ...filters,
      [value]: !copy[value],
    });
    filterApps({
      ...filters,
      [value]: !copy[value],
    });
  };
  const handleSearchChange = (event) => {
    setFilters({
      ...filters,
      search: event.target.value,
    });
    filterApps({
      ...filters,
      search: event.target.value,
    });
  };
  const clearFilter = () => {
    setFilters({
      suspended: true,
      ongoing: true,
      completed: true,
      delayed: true,
      newApp: true,
      business_form_status: [],
      app_form_status: [],
      copyright_form_status: [],
      scc_owner: [],
      scc_status: [],
      icp_status: [],
      targeted_store: [],
      account_manager: [],
      confidential: [],
      country: [],
      industry: [],
      search: "",
      order: "date",
      stores_published: [],
      telephone: "",
      serviceTags: [],
      revenue_model: [],
    });
    filterApps({
      suspended: true,
      ongoing: true,
      completed: true,
      delayed: true,
      newApp: true,
      business_form_status: [],
      app_form_status: [],
      copyright_form_status: [],
      scc_owner: [],
      scc_status: [],
      icp_status: [],
      targeted_store: [],
      account_manager: [],
      confidential: [],
      country: [],
      industry: [],
      search: "",
      order: "date",
      stores_published: [],
      telephone: "",
      appType: [],
      serviceTags: [],
      revenue_model: [],
    });
  };
  const handleView = () => {
    setGrid(!grid);
    appsView({ grid: !grid });
  };

  let content_radio = (
    <RadioGroup
      onChange={(e) => {
        handleAdvancedSearch(e.target.value, "order");
        setOrder(e.target.value);
      }}
      value={filters.order}
    >
      <Radio value={"date"}>Date</Radio>
      <br />
      <Radio value={"name"}>Name</Radio>
      <br />
      <Radio value={"downloads"}>Downloads</Radio>
    </RadioGroup>
  );
  let list_icon = (
    <Button disabled={loading} onClick={handleView}>
      <Icon type="appstore-o" />
    </Button>
  );
  let grid_icon = (
    <Button disabled={loading} onClick={handleView}>
      <Icon type="bars" />
    </Button>
  );
  let view_icon = !grid ? list_icon : grid_icon;

  return (
    <div>
      <div>
        <div className={classes.Container} gutter={16}>
          <div className={classes.SearchContainer}>
            {search ? (
              <AntInput.Search
                disabled={loading}
                value={filters.search}
                placeholder={loading ? "Searching..." : "Search for an app..."}
                onChange={(event) => handleSearchChange(event)}
                enterButton
                allowClear
                onSearch={() => search()}
              />
            ) : (
              <Input
                name="search"
                value={filters.search}
                placeholder="Search for an app..."
                onChange={(event) => handleSearchChange(event)}
              />
            )}
          </div>
          <div className={classes.filterButtons}>
            <Row style={{ width: "100%" }} type="flex" justify="space-between">
              <ExportAppsDialog apps={appIds} />
              {AddApp ? (
                <Button
                  disabled={loading}
                  onClick={() => setAddAppDialog(!addAppDialog)}
                >
                  <Icon type="plus" />
                </Button>
              ) : null}
              {view_icon}
              {grid ? (
                <div>
                  <Popover content={content_radio} placement="bottomRight">
                    <Button disabled={loading}>
                      <Icon type="sort-ascending" />
                    </Button>
                  </Popover>
                </div>
              ) : null}{" "}
            </Row>
          </div>
        </div>
        <p
          onClick={() => setAdvancedSearch(!advancedsearch)}
          className={classes.advanced}
        >
          <b style={{ color: "var(--aic-green)" }}>Advanced Search</b>
        </p>
        <div style={{ marginTop: "15px" }}>
          {advancedsearch ? (
            <Fragment>
              <h3>Filter by:</h3>
              <div
                className={[
                  classes.filtercontainer,
                  classes.advancedsearch,
                ].join(" ")}
              >
                <span>
                  <b>Country</b>
                  <Select
                    disabled={loading}
                    showSearch
                    mode="multiple"
                    value={filters.country}
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    onChange={(value) => {
                      handleAdvancedSearch(value, "country");
                    }}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {[...LIST_COUNTRIES, { code: "null", name: "Not Set" }].map(
                      (it) => (
                        <Option key={it.code} value={it.code} label={it.name}>
                          {it.name}
                        </Option>
                      )
                    )}
                  </Select>
                </span>
                <span>
                  <b>Category</b>
                  <Select
                    disabled={loading}
                    showSearch
                    mode="multiple"
                    value={filters.industry}
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    onChange={(value) => {
                      handleAdvancedSearch(value, "industry");
                    }}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {[
                      ...LIST_INDUSTRIES,
                      { code: "null", name: "Not Set" },
                    ].map((it) => (
                      <Option key={it.code} value={it.code} label={it.name}>
                        {it.name}
                      </Option>
                    ))}
                  </Select>
                </span>
                {titles.map((name) => (
                  <span>
                    <b>
                      {name == "SCC_status" || name == "SCC_owner"
                        ? functions.splitAndCapitalize(name, false)
                        : functions.splitAndCapitalize(name)}
                    </b>
                    {name === "ACCOUNT_MANAGER" ? (
                      <ManagerSelect
                        accountManager={filters[name.toLowerCase()]}
                        setAccountManager={(value) =>
                          handleAdvancedSearch([value], name.toLowerCase())
                        }
                      />
                    ) : (
                      <Select
                        disabled={loading}
                        mode="multiple"
                        value={filters[name.toLowerCase()]}
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          handleAdvancedSearch(value, name.toLowerCase());
                        }}
                      >
                        {[
                          ...APP_DETAILS_OPTIONS[name],
                          { value: "null", label: "Not Set" },
                        ].map((it) => (
                          <Option
                            key={it.value}
                            value={it.value}
                            label={it.label}
                          >
                            {it.label}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </span>
                ))}

                <span>
                  <b>Telephone</b>
                  <AntInput
                    disabled={loading}
                    mode="multiple"
                    value={filters.telephone}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      handleAdvancedSearch(e.target.value, "telephone");
                    }}
                  />
                </span>
                <span>
                  <b>App Type</b>
                  <Select
                    disabled={loading}
                    showSearch
                    mode="multiple"
                    value={filters.appType}
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    onChange={(value) => {
                      handleAdvancedSearch(value, "appType");
                    }}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {[...APP_DETAILS_OPTIONS.app_type].map((it) => (
                      <Option key={it.value} value={it.value} label={it.label}>
                        {it.label}
                      </Option>
                    ))}
                  </Select>
                </span>
                <span>
                  <b>Service Tags</b>
                  <ServiceTags
                    addServiceTag={(value) =>
                      handleAdvancedSearch(value, "serviceTags")
                    }
                  />
                </span>
                {search ? (
                  <span style={{ marginTop: "18px" }}>
                    <Row type="flex" justify="space-between">
                      <Button
                        loading={loading}
                        icon={loading ? "loading" : "search"}
                        type="primary"
                        disabled={loading}
                        style={{ width: "48%" }}
                        onClick={() => search()}
                      >
                        {loading ? "Searching..." : "Search"}
                      </Button>
                      <Button
                        onClick={clearFilter}
                        disabled={loading}
                        style={{
                          width: "48%",
                        }}
                      >
                        Clear All
                      </Button>
                    </Row>
                  </span>
                ) : null}
              </div>
            </Fragment>
          ) : null}
        </div>
      </div>

      <AddAppDialog
        display={addAppDialog}
        handleDialog={() => setAddAppDialog(!AddAppDialog)}
        handleAppAdded={handleAppAdded}
      />
    </div>
  );
};
export default AdvancedFilter;
