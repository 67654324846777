import Axios from "../axios";
import functions from "../functions";

export const getSdks = async () => {
  let response = null;
  try {
    response = await Axios.get("/sdks");
  } catch (error) {
    console.log("error", error);
  }
  return response;
};

export const searchForSdks = async () => {
  let response = null;
  try {
    response = await Axios.get("/search-sdks");
  } catch (error) {
    console.log("error", error);
  }
  return response;
};

export const testSdk = async values => {
  let response = null;
  try {
    response = await Axios.post("/test-single-sdk", {
      ...values
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
};

export const deleteSdk = async id => {
  try {
    await Axios.delete("/sdks", {
      data: { id }
    });
  } catch (error) {
    console.log("error", error);
  }
};

export const createSdkService = async values => {
  let response = null;
  try {
    response = await Axios.post("/sdks", {
      ...values,
      test_endpoint: functions.getDomainFromUrl(values.test_endpoint)
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
};

export const updateSdkService = async values => {
  let response = null;
  try {
    response = await Axios.put("/sdks", {
      ...values,
      test_endpoint: functions.getDomainFromUrl(values.test_endpoint)
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
};
