import React, { useState, useEffect } from "react";
import classes from "./Notifications.module.less";
import { Tag, Table, Button, Radio, DatePicker, Row, Col, Popover } from "antd";
import SelectCompletion from "./SelectCompletion/SelectCompletion";
import Actions from "./Actions/Actions";
import moment from "moment";
import { getAsyncNotifications } from "../../services/notificationsService";
import { NOTIFICATION_CATEGORIES } from "../../constants";
import ManagerSelect from "../UI/ManagerSelect/ManagerSelect";
import { getManagers } from "../../services/accountManagersService";
const { RangePicker } = DatePicker;

export default () => {
  let [tasks, setTasks] = useState([]);
  let [loading, setLoading] = useState(false);
  let [accountManager, setAccountManager] = useState("");
  let [taskType, setTaskType] = useState("INCOMPLETE");
  let [dateRange, setDateRange] = useState([]);
  let [filters, setFilters] = useState({});
  let [pageSize, setPageSize] = useState(10);
  let [current, setCurrent] = useState(1);
  let [total, setTotalItems] = useState(0);
  let [sort, setSort] = useState({
    columnKey: "createdAt",
    order: "descend",
  });
  const getAccountManager = async () => {
    let name = localStorage.getItem("login-email")
      ? localStorage
          .getItem("login-email")
          .split("@")[0]
          .toUpperCase()
      : "";
    let managers = [];
    setLoading(true);
    let { data } = await getManagers();
    if (data && data.result) {
      managers = data.result;
    }
    setLoading(false);
    let result = managers.find((e) => e === name) ? name : "all";
    setAccountManager(result);
  };
  const getRole = (account, secondaryAccount, tertiaryAccount) => {
    if (accountManager !== "all") {
      return (
        <>
          {account && account == accountManager ? (
            <Tag color="green">MANAGER</Tag>
          ) : null}
          {secondaryAccount && secondaryAccount == accountManager ? (
            <Tag color="blue">SECONDARY MANAGER</Tag>
          ) : null}
          {tertiaryAccount && tertiaryAccount == accountManager ? (
            <Tag color="volcano">TERTIARY MANAGER</Tag>
          ) : null}
        </>
      );
    } else {
      return (
        <>
          {account ? (
            <Popover content={account}>
              <Tag color="green">MANAGER</Tag>
            </Popover>
          ) : null}
          {secondaryAccount ? (
            <Popover content={secondaryAccount}>
              <Tag color="blue">SECONDARY MANAGER</Tag>
            </Popover>
          ) : null}
          {tertiaryAccount ? (
            <Popover content={tertiaryAccount}>
              <Tag color="volcano">TERTIARY MANAGER</Tag>{" "}
            </Popover>
          ) : null}
        </>
      );
    }
  };
  const getCategoryTag = (category) => {
    switch (category) {
      case "app":
        return <Tag color="magenta">New APK</Tag>;
      case "app_info":
        return <Tag color="magenta">App Info Update</Tag>;
      case "copyright":
        return <Tag color="orange">Copyright</Tag>;
      case "company":
        return <Tag color="red">Company</Tag>;
      case "security":
        return <Tag color="purple">Security Assessment</Tag>;
      case "app_deactivation":
        return <Tag color="geekblue">App Deactivated</Tag>;
      case "acquisition":
        return <Tag color="cyan">User Acquisition</Tag>;
      case "localization":
        return <Tag color="blue">Localization</Tag>;
      case "reviews":
        return <Tag color="geekblue">Reviews</Tag>;
      case "custom":
        return <Tag color="green">Custom</Tag>;
      case "monetization":
        return <Tag color="volcano">Monetization</Tag>;
      case "analytics":
        return <Tag color="gold">Analytics</Tag>;
      case "sdk_credentials":
        return <Tag color="lime">SDK Credentials</Tag>;
      case "authorization_form":
        return <Tag color="purple">Authorization Form</Tag>;
      default:
        return <Tag color="green">{category.toUpperCase()}</Tag>;
    }
  };
  const columns = () => [
    {
      title: "Date",
      dataIndex: "createdAt",
      width: "100px",
      align: "center",
      sorter: true,
      fixed: "left",
      sortOrder: sort.columnKey === "createdAt" && sort.order,
      render: (createdAt) => moment(createdAt).format("YYYY-MM-DD"),
    },
    {
      title: "App",
      dataIndex: "appname",
      // width: "20%",
      sorter: true,
      render: (appname) => (appname ? <b>{appname}</b> : <b>N/A</b>),
    },
    {
      title: "Category",
      dataIndex: "category",
      align: "center",
      // width: "20%",
      sorter: true,
      filters: NOTIFICATION_CATEGORIES,
      filteredValue: filters.category || null,
      sortDirections: ["descend", "ascend"],
      render: (category) => (category ? getCategoryTag(category) : ""),
    },
    {
      title: "Completion",
      dataIndex: "completion",
      // width: "20%",
      align: "center",
      filters:
        taskType === "INCOMPLETE"
          ? [
              { value: "NOT_STARTED", text: "Not Started" },
              { value: "ONGOING", text: "Ongoing" },
              { value: "REJECTED", text: "Rejected" },
            ]
          : [],
      filteredValue: filters.completion || null,
      sorter: true,
      render: (_, item) => getSelect(item),
    },
    {
      title: `${accountManager === "all" ? "Account Managers" : "Role"}`,
      dataIndex: "role",
      align: "center",
      width: "200px",
      render: (_, item) =>
        getRole(
          item.account_manager,
          item.secondary_account_manager,
          item.tertiary_account_manager
        ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      align: "center",
      width: "200px",
      render: (_, item) => (item ? getActions(item) : ""),
    },
  ];

  const updateAllTasks = () => {
    console.log("UPDATING ALL TASKS");
    sendSearchRequest({
      page: current,
      results: pageSize,
      category: filters.category ? filters.category.join(",") : null,
      completion: filters.completion ? filters.completion.join(",") : taskType,
      ...sort,
    });
  };

  const getSelect = (item) => {
    return <SelectCompletion notification={item} onChange={updateAllTasks} />;
  };

  const getActions = (item) => {
    return <Actions notification={item} update={updateAllTasks} />;
  };

  const resetFilters = () => {
    setTaskType("INCOMPLETE");
    setDateRange([]);
    setAccountManager("all");
    setFilters({});
    setSort({ columnKey: "createdAt", order: "descend" });
  };
  const getAllFilters = () => {
    let allFilters = "";
    if (accountManager !== "all") {
      allFilters += `account_manager=${accountManager}&secondary_account_manager=${accountManager}&tertiary_account_manager=${accountManager}&`;
    }
    dateRange.length > 0
      ? (allFilters =
          allFilters +
          `start=${moment(dateRange[0]).format("YYYY-MM-DD")}&end=${moment(
            dateRange[1]
          ).format("YYYY-MM-DD")}&`)
      : (allFilters = allFilters);
    return allFilters;
  };
  const sendSearchRequest = async (values) => {
    let all = getAllFilters();
    setLoading(true);
    let { data: response } = await getAsyncNotifications({ ...values, all });
    setLoading(false);
    if (response && response.tasks) {
      setTasks(response.tasks);
      setTotalItems(response.totalResults);
    }
  };
  const handleChange = (pagination, filters, sorter) => {
    setFilters(filters);
    setSort({ columnKey: sorter.columnKey, order: sorter.order });
    setCurrent(pagination.current);
    setPageSize(pagination.pageSize);
    let actSort = {};
    if (sorter && sorter.order) {
      actSort = {
        sort: sorter.order === "ascend" ? "asc" : "desc",
        field: sorter.field,
      };
    } else {
      actSort = {
        field: "createdAt",
        sort: "desc",
      };
    }
    sendSearchRequest({
      page: pagination.current,
      results: pagination.pageSize,
      category: filters.category ? filters.category.join(",") : null,
      completion: filters.completion ? filters.completion.join(",") : taskType,
      ...actSort,
    });
  };

  useEffect(() => {
    sendSearchRequest({
      page: current,
      results: pageSize,
      completion: taskType,
      ...sort,
    });
  }, [accountManager, dateRange, taskType, current]);
  useEffect(() => {
    getAccountManager();
  }, []);
  return (
    <>
      <div className={classes.container}>
        <div className={classes.Filter}>
          <Row type="flex" justify="start">
            <h3>Tasks ({total})</h3>
          </Row>
          <Row className={classes.filters} gutter={[16, 16]}>
            <Col xs={24} sm={12} md={12} lg={7}>
              <Radio.Group
                style={{ width: "100%" }}
                disabled={loading}
                onChange={(e) => {
                  setTaskType(e.target.value);
                }}
                value={taskType}
              >
                <Radio.Button value="INCOMPLETE">Incomplete</Radio.Button>
                <Radio.Button value="COMPLETED">Completed</Radio.Button>
              </Radio.Group>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} className={classes.managers}>
              <b>Account Manager</b>
              <ManagerSelect
                accountManager={accountManager}
                setAccountManager={setAccountManager}
                team
              />
            </Col>
            <Col xs={24} sm={12} md={12} lg={7}>
              <RangePicker
                disabled={loading}
                style={{ width: "100%" }}
                value={
                  dateRange.length > 0
                    ? [moment(dateRange[0]), moment(dateRange[1])]
                    : []
                }
                onChange={(_, string) => {
                  setDateRange(string);
                }}
                format={"YYYY-MM-DD"}
                allowClear={false}
              />
            </Col>
            <Col xs={24} sm={12} md={12} lg={4}>
              <Button
                icon="undo"
                disabled={loading}
                style={{ marginBottom: 20, width: "100%" }}
                onClick={() => resetFilters()}
              >
                Reset
              </Button>
            </Col>
          </Row>
        </div>
        <Table
          dataSource={tasks}
          columns={columns()}
          size="small"
          loading={loading}
          scroll={{ x: 800 }}
          pagination={{
            total,
            pageSize,
            current,
            showSizeChanger: true,
            pageSizeOptions: ["10"],
          }}
          onChange={handleChange}
        />
      </div>
    </>
  );
};
