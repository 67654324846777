import React from 'react';
import classes from './Button.module.css';
import Preloader from '../Preloader/Preloader';

export default (props) => {

	const click = (event) => {
		if (!props.loading) {
			if (props.click) props.click(event)
		}
		else {
			event.preventDefault()
		}
	}

	let type = props.type ? classes[props.type] : null
	let loading = props.loading ? classes.Loading : null
	let content = props.loading ? <Preloader size={3} inverse /> : props.children
	return (
		<button
			onClick={click}
			className={[classes.Button, loading, type].join(' ')}
			style={{ width: props.width, margin: props.margin, ...props.style }}>
			{content}
		</button>
	)

}
