import React, { useState, useEffect } from "react";
import classes from "./AppPromotionCard.module.less";
import {
  Card,
  Select,
  Button,
  Popconfirm,
  notification,
  Form,
  Checkbox,
  Table,
} from "antd";
import {
  getAppPromotion,
  updateAppPromotion,
} from "../../../services/appPromotionService";
import AddNewUser from "./AddNewUser/AddNewUser";

const { Option } = Select;
export default Form.create()(({ allStores = [], appId, form }) => {
  let [loading, setLoading] = useState(false);
  let [saving, setSaving] = useState(false);
  let [promoters, setPromoters] = useState([]);
  let [analyticsEnabled, setAnalyticsEnabled] = useState(false);
  let [addModal, setAddModal] = useState(false);
  const { getFieldDecorator } = form;

  const updateStores = (userId, newStores) => {
    let promotersCopy = [...promoters];
    let index = promotersCopy.findIndex((it) => it.id === userId);
    promotersCopy[index].stores = newStores.join(",");
    setPromoters(promotersCopy);
  };
  const removeAccess = (userId) => {
    let promotersCopy = [...promoters];
    promotersCopy = promotersCopy.filter((e) => e.id !== userId);
    setPromoters(promotersCopy);
  };

  const saveInformation = async () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        setSaving(true);
        const { analytics_enabled } = values;
        let { data } = await updateAppPromotion({
          appId,
          promoters,
          analytics_enabled,
        });
        if (data && data.message === "success") {
          notification.success({
            message: "Success",
            description: "The information has been updated in the database.",
            placement: "bottomRight",
          });
        } else {
          notification.warning({
            message: "An error has ocurred.",
            placement: "bottomRight",
          });
        }
        setSaving(false);
      }
    });
  };

  const getData = async () => {
    setLoading(true);
    let { data } = await getAppPromotion(appId);
    setLoading(false);
    if (data && data.users) {
      setPromoters(data.users);
      setAnalyticsEnabled(data.analytics_enabled);
    }
  };
  let columns = [
    {
      key: "id",
      dataIndex: "id",
      title: "",
      render: (id) => (
        <Popconfirm
          placement="top"
          title="Do you want to remove this user?"
          onConfirm={() => removeAccess(id)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="danger" shape="circle" icon="delete" size="small" />
        </Popconfirm>
      ),
    },
    {
      key: "email",
      dataIndex: "email",
      title: "User",
    },
    {
      key: "stores",
      dataIndex: "stores",
      title: "Stores",
      render: (stores, item) => {
        let storesId = stores && stores.length > 0 ? stores.split(",") : [];
        return (
          <Select
            disabled={loading || saving}
            showSearch
            placeholder="Select Stores..."
            onChange={(value) => updateStores(item.id, value)}
            defaultValue={storesId}
            mode="multiple"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {allStores.map((it) => (
              <Option key={it.id} value={it.id}>
                {it.fullname}
              </Option>
            ))}
          </Select>
        );
      },
    },
  ];
  useEffect(() => {
    getData();
  }, [appId]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={classes.container}>
      <Form>
        <Popconfirm
          title="Are you sure you want to save this information?"
          onConfirm={() => saveInformation()}
          okText="Yes"
          cancelText="No"
          placement="bottom"
          disabled={loading}
          className={classes.globalSave}
        >
          <Button
            loading={saving}
            disabled={loading}
            icon={saving ? "loading" : "save"}
            type="primary"
          >
            {saving ? "Saving..." : "Save"}
          </Button>
        </Popconfirm>
        {getFieldDecorator("analytics_enabled", {
          valuePropName: "checked",
          initialValue: analyticsEnabled,
        })(<Checkbox>Enable Analytics Section</Checkbox>)}
        <Card
          extra={
            <Button
              onClick={() => setAddModal(true)}
              type="primary"
              icon="plus"
            >
              Add User
            </Button>
          }
          style={{ marginTop: 10 }}
          title="App Promotion Dashboard"
        >
          <h3>Promoters</h3>
          <Table
            loading={loading}
            dataSource={promoters}
            size="small"
            columns={columns}
            rowKey={(record) => record.id}
          />
          <AddNewUser
            visible={addModal}
            cancel={() => setAddModal(false)}
            allStores={allStores}
            actualUsers={promoters.map(({ id }) => id)}
            data={promoters}
            updateData={setPromoters}
          />
        </Card>
      </Form>
    </div>
  );
});
