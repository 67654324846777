import axios from "../axios";

export async function getAppPromotion(appId) {
  let response = null;
  try {
    response = await axios.get(`/promoters/${appId}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function updateAppPromotion({ ...values }) {
  let response = null;
  try {
    response = await axios.post(`/promoters`, { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
