import React, { useState, useEffect } from "react";
import classes from "./AICON.module.css";
import GoogleSearcher from "../GoogleSearcher/GoogleSearcher";
import LineCard from "../UI/LineCard/LineCard";
import Axios from "axios";
import { AICON_STORES } from "../../constants";
import { notFoundImage } from "../../images";
import { chineseText, isANumber } from "../../functions";
import axios from "axios";
import { Button, Alert, Tag, Card, Statistic, List, Avatar, Icon } from "antd";
const { Grid } = Card;
const gridStyle = {
  width: "50%",
  textAlign: "center",
};

export default () => {
  const [inGooglePlay, setInGooglePlay] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingChinese, setLoadingChinese] = useState(false);
  const [totalDownloads, setTotalDownloads] = useState(0);
  const [liveStores, setLiveStores] = useState(0);
  const [aiconStores, setAiconStores] = useState([]);
  const [chineseName, setChineseName] = useState(null);
  const [results, setResults] = useState({});
  const [searchedChinese, setSearchedInChinese] = useState({});
  const searchAgain = () => {
    formatStores();
    setInGooglePlay(null);
    setTotalDownloads(0);
    setLiveStores(0);
    setChineseName(null);
  };

  useEffect(() => {
    formatStores();
  }, []);

  const formatStores = () => {
    let format = JSON.parse(JSON.stringify(AICON_STORES)).map((it, index) => ({
      ...it,
      ranking: index + 1,
      loading: true,
      searchedInChinese: false,
    }));
    setAiconStores(format);
  };

  const handleAiconError = () => {
    setInGooglePlay(false);
  };

  const handleAppSelected = (app) => {
    const data = {
      image_data: app.icon,
      appname: app.title || app.name,
      company: app.developer,
      package: app.package,
      url: app.url,
    };
    if (inGooglePlay) {
      setInGooglePlay(null);
      setTotalDownloads(0);
      setLiveStores(0);
    }
    setInGooglePlay(data);
    searchData({
      information: data,
      searchInChinese: false,
    });
  };

  const handleAiconResult = () => {
    try {
      const stateCopy = [...aiconStores];
      Object.keys(results).map((key, i) => {
        let index = aiconStores.findIndex((e) => e.store === key);
        let response = results[key].result || results[key];
        if (response == "NOT_FOUND") {
          stateCopy[index] = {
            ...stateCopy[index],
            text: "NOT_FOUND",
            found: false,
          };
          setAiconStores(stateCopy);
        } else if (response && response == "error") {
          stateCopy[index] = {
            ...stateCopy[index],
            text: "STORE_ERROR",
            found: false,
          };
          setAiconStores(stateCopy);
        } else {
          if (response && response.appname !== undefined) {
            stateCopy[index] = {
              ...stateCopy[index],
              ...response,
              downloads: parseInt(response.downloads) ? +response.downloads : 0,
              text: "FOUND",
              found: true,
              loading: false,
            };
            stateCopy[index].loading = false;
            setAiconStores(stateCopy);
            if (chineseName === null) {
              let isChineseName = chineseText(response.appname);
              if (isChineseName === true) {
                setChineseName(response.appname);
              }
            }
          } else if (response && response == "SEARCH_CHINESE") {
            if (chineseName == null && response == "SEARCH_CHINESE") {
              stateCopy[index] = {
                ...stateCopy[index],
                text: "NOT_FOUND",
                found: false,
              };
              setAiconStores(stateCopy);
            } else {
              let waitingChinese =
                searchedChinese[key] !== undefined
                  ? searchedChinese[key]
                  : false;
              if (searchedChinese[key] !== undefined) {
                if (waitingChinese === true) {
                  stateCopy[index] = {
                    ...stateCopy[index],
                    text: "CHINESE_SEARCHING",
                    found: false,
                  };
                } else {
                  stateCopy[index] = {
                    ...stateCopy[index],
                    text: "NOT_FOUND",
                    found: false,
                  };
                }
                setAiconStores(stateCopy);
              } else {
                if (
                  chineseName != null &&
                  stateCopy[index].text != "CHINESE_SEARCHING"
                ) {
                  stateCopy[index].text = "CHINESE_SEARCHING";
                  searchChineseData({
                    information: inGooglePlay,
                    data: { ...stateCopy[index] },
                  });
                }
              }
            }
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const searchData = ({ information }) => {
    let myObject = {};
    let storesToSearch = aiconStores.filter((e) => e.scrapeable === true);
    const search = information.appname.toLowerCase();
    let allRequests = storesToSearch.map((it, i) => {
      let url = `https://tc5hc8xzq2.execute-api.us-east-2.amazonaws.com/test/aicon?store=${it.store}&search=${search}&package=${information.package}&image=${information.image_data}`;

      return axios
        .get(url)
        .then((response) => {
          if (response.status == 200) {
            if (response.data["response"] !== null) {
              const highestMatch = parseData(response.data["response"]);
              if (it.store == "huawei") {
                console.log(highestMatch);
              }
              myObject = highestMatch;
            } else {
              myObject = "NOT_FOUND";
            }
          } else {
            myObject = "error";
          }
          setResults((results) => ({ ...results, [it.store]: myObject }));
        })
        .catch((error) => {
          myObject = "error";
          setResults((results) => ({ ...results, [it.store]: myObject }));
        });
    });
    setLoading(true);
    Axios.all([...allRequests])
      .then(
        Axios.spread((...responses) => {
          setLoading(false);
        })
      )
      .catch((errors) => {
        setLoading(false);
      });
  };

  const parseData = (array) => {
    try {
      if (array.length > 0) {
        const packageMatch = array.find((it) => it.packageMatch === true);
        if (packageMatch) {
          return packageMatch;
        } else {
          const [highestMatch] = array.sort(
            (a, b) => a.imageMatch - b.imageMatch
          );
          if (
            highestMatch.packageMatch == false &&
            highestMatch.imageMatch == 0 &&
            highestMatch.package != ""
          ) {
            return "SEARCH_CHINESE";
          }
          return highestMatch;
        }
      }
      return "NOT_FOUND";
    } catch (error) {
      return null;
    }
  };

  const parseDataChinese = (array) => {
    try {
      const packageMatch = array.find((it) => it.packageMatch === true);
      if (packageMatch) {
        return packageMatch;
      } else {
        const [highestMatch] = array.sort(
          (a, b) => a.imageMatch - b.imageMatch
        );
        if (
          highestMatch.packageMatch == false &&
          highestMatch.imageMatch == 0 &&
          highestMatch.package != ""
        ) {
          return "NOT_FOUND";
        }
        return highestMatch;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const searchChineseData = ({ information, data = {} }) => {
    console.log(data);
    let url = `https://tc5hc8xzq2.execute-api.us-east-2.amazonaws.com/test/aicon?store=${data.store}&search=${chineseName}&package=${information.package}&image=${information.image_data}`;
    let result = {};
    setLoadingChinese(true);
    setSearchedInChinese((data) => ({
      ...data,
      [data.store]: true,
    }));
    return axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          if (response.data["response"] !== null) {
            const highestMatch = parseDataChinese(response.data["response"]);
            result = highestMatch;
          }
        } else {
          result = "error";
        }
        setSearchedInChinese((data) => ({
          ...data,
          [data.store]: false,
        }));
        setLoadingChinese(false);
        setResults((results) => ({ ...results, [data.store]: result }));
      })
      .catch((error) => {
        setSearchedInChinese((data) => ({
          ...data,
          [data.store]: false,
        }));
        setLoadingChinese(false);
        setResults((results) => ({ ...results, [data.store]: "error" }));
      });
  };

  const updateSummary = () => {
    let copy = [...aiconStores];
    copy = copy.filter((e) => e.text && e.text === "FOUND");
    const downloads = copy.reduce((prev, curr) => {
      return (
        prev +
        (curr.downloads && isANumber(curr.downloads) ? +curr.downloads : 0)
      );
    }, 0);
    const liveStoresCount = copy.reduce((prev, curr) => {
      return prev + (curr.url ? 1 : 0);
    }, 0);
    setTotalDownloads(downloads);
    setLiveStores(liveStoresCount);
  };

  useEffect(() => {
    handleAiconResult();
  }, [results]);

  useEffect(() => {
    updateSummary();
  }, [aiconStores]);

  let image =
    inGooglePlay && inGooglePlay.image_data
      ? `${inGooglePlay.image_data}`
      : notFoundImage;

  const getTag = {
    STORE_ERROR: <Tag color="volcano">App Store Error</Tag>,
    SEARCHING: <Tag color="blue">Searching...</Tag>,
    CHINESE_SEARCHING: <Tag color="magenta">Searching in Chinese...</Tag>,
    FOUND: <Tag color="green">Found</Tag>,
    NOT_FOUND: <Tag color="purple">Not Found</Tag>,
  };

  return (
    <div className={classes.Container}>
      {!inGooglePlay ? (
        <div className={classes.Search}>
          <h3 className={classes.Text}>
            Type the name of the app you want to search
          </h3>
          <GoogleSearcher
            select={handleAppSelected}
            cancel={() => setInGooglePlay(null)}
            error={handleAiconError}
          />
        </div>
      ) : (
        <div className={classes.Results}>
          <div className={classes.Content}>
            <div className={classes.SelectedApp}>
              <img
                src={image.replace("appinchina.xyz", "appinchina.space")}
                alt="Store Icon"
              />
              <p>
                <span>{inGooglePlay.appname}</span>
                <span>
                  {inGooglePlay.company
                    ? inGooglePlay.company
                    : "(Company name not found on Google Play)"}
                </span>
              </p>
            </div>
            <Card title="Summary">
              <Grid hoverable={false} style={gridStyle}>
                <Statistic title="Stores Found" value={liveStores} />
              </Grid>
              <Grid hoverable={false} style={gridStyle}>
                <Statistic title="Downloads" value={totalDownloads} />
              </Grid>
            </Card>
            {chineseName && loadingChinese ? (
              <Alert
                className={classes.Alert}
                message={<p>Searching with chinese name: {chineseName}</p>}
                showIcon
              />
            ) : null}
            <LineCard title="Results" style={{ marginTop: "20px" }}>
              <List
                itemLayout="horizontal"
                dataSource={aiconStores}
                renderItem={(store) =>
                  store.scrapeable ? (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src={store.icon} />}
                        title={
                          <>
                            <span className={classes.StoreName}>
                              {store.name}{" "}
                            </span>
                            <span>
                              {store.text
                                ? getTag[store.text]
                                : getTag["SEARCHING"]}
                            </span>
                          </>
                        }
                        description={
                          store.text == "FOUND" ? (
                            <div className={classes.StoreDetails}>
                              <span>
                                <Icon type="bulb" />
                                {" Version: "}
                                {store.version && store.version !== ""
                                  ? store.version
                                  : "N/A"}
                              </span>
                              <span style={{ marginLeft: 20 }}>
                                <Icon type="cloud-download" />
                                {" Downloads: "}
                                {store.downloads
                                  ? store.downloads.toLocaleString("en")
                                  : "N/A"}
                              </span>
                              <a
                                href={store.url}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                <Icon type="link" />
                                {" View in store"}
                              </a>
                            </div>
                          ) : (
                            <> </>
                          )
                        }
                      />
                    </List.Item>
                  ) : (
                    <> </>
                  )
                }
              />
            </LineCard>
          </div>
          {loading || loadingChinese ? null : (
            <Button type="primary" icon="search" onClick={() => searchAgain()}>
              Continue Searching
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
