import { chineseInstance } from "../axios";

export async function newKefu(values) {
  let response = null;
  try {
    response = await chineseInstance.post("/api/admin/kefus", { ...values });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
export async function newWechatAccount(values) {
  let response = null;
  try {
    response = await chineseInstance.post("/api/admin/wechat", { ...values });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
export async function getKefus() {
  let response = null;
  try {
    response = await chineseInstance.get("/api/admin/kefus");
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
export async function getWechatAccounts() {
  let response = null;
  try {
    response = await chineseInstance.get("/api/admin/wechat");
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
export async function updateKefu(values) {
  let response = null;
  try {
    response = await chineseInstance.put("/api/admin/kefus", { ...values });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
export async function updateWechatAccount(values) {
  let response = null;
  try {
    response = await chineseInstance.put("/api/admin/wechat", { ...values });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
export async function getKefuById(id) {
  let response = null;
  try {
    response = await chineseInstance.get(`/api/admin/kefus/${id}`);
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
export async function getWechatAccountById(id) {
  let response = null;
  try {
    response = await chineseInstance.get(`/api/admin/wechat/${id}`);
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
