import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Button, Select, List, Avatar, Modal, Popover, Form } from "antd";
import { ROLES } from "../../../../constants";
const { Option } = Select;
const { confirm } = Modal;
const { Item } = Form;
const AppCard = ({
  apps,
  clientId,
  getFieldDecorator,
  allUsers,
  form,
  ...props
}) => {
  let [data, setData] = useState([]);
  let deleteAccess = async val => {
    let clientsLinked = form.getFieldValue(`apps`);
    let filter = clientsLinked.filter(e => e.id !== val);
    form.setFieldsValue({
      apps: [...filter]
    });
    setData(filter);
  };

  const showConfirmDeleteAccess = user => {
    confirm({
      title: `Are you sure you want to remove access to ${user.name} ?`,
      onOk() {
        deleteAccess(user.id);
      },
      onCancel() {}
    });
  };

  useEffect(() => {
    setData(apps);
  }, [apps]);

  return (
    <div>
      <Item style={{ margin: 0 }}>
        {getFieldDecorator(`apps`, {
          initialValue: data
        })(
          <List
            dataSource={data}
            renderItem={(item, index) => (
              <List.Item key={item.id}>
                <Row
                  type="flex"
                  style={{ width: "100%" }}
                  align="middle"
                  justify="space-between"
                >
                  <List.Item.Meta
                    title={
                      <Link to={`/apps/${item.id}/distribution`}>
                        <Button type="link">{item.name}</Button>
                      </Link>
                    }
                  />
                  <Item style={{ margin: 0 }}>
                    {getFieldDecorator(`app_roles[${index}].role`, {
                      initialValue: item.clients_apps.role
                    })(
                      <Select
                        onChange={val => {
                          let appUsers = form.getFieldValue(`apps`);
                          let act = appUsers ? appUsers[index] : {};
                          appUsers[index] = {
                            ...act,
                            clients_apps: { role: val }
                          };
                          form.setFieldsValue({
                            apps: [...appUsers]
                          });
                        }}
                        style={{ width: 200, marginRight: 20 }}
                      >
                        {ROLES.map(({ value, label }) => (
                          <Option key={value} value={value} label={label}>
                            {label}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Item>

                  <Popover content={<p>Remove Access</p>}>
                    <Button
                      size="small"
                      icon="delete"
                      style={{ bottom: 2 }}
                      type="danger"
                      ghost
                      onClick={() => showConfirmDeleteAccess(item)}
                    />
                  </Popover>
                </Row>
              </List.Item>
            )}
          />
        )}
      </Item>
    </div>
  );
};
export default AppCard;
