import React from "react";
import NavBar from "../UI/NavBar/NavBar";
import SideMenu from "../UI/SideMenu/SideMenu";
import classes from "./ProfileLayout.module.css";

export default ({ children, pendingApps, apps, clients }) => (
  <div className={classes.Container}>
    <NavBar pendingApps={pendingApps} apps={apps} clients={clients} />
    <div className={classes.SideMenu}>
      <SideMenu />
    </div>
    <div className={classes.Content}>{children}</div>
  </div>
);
