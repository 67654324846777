import React, { useState } from "react";
import { Row, Button, Popconfirm, notification } from "antd";
import EditContract from "../EditContractModal/EditContractModal";
import { deleteContract } from "../../../../services/localizationService";
import PreviewButton from "../../../UI/PreviewButton/PreviewButton";
import classes from "./ContractActions.module.less";
export default ({ item, data, setData }) => {
  let [editModal, setEditModal] = useState(false);
  let [deleting, setDeleting] = useState(false);
  const confirmDelete = async () => {
    setDeleting(true);
    let { data: response } = await deleteContract(item.PK_Addendum);
    setDeleting(false);
    if (response && response.message === "success") {
      let newData = data.filter((e) => e.PK_Addendum !== item.PK_Addendum);
      setData(newData);
      notification.info({ message: "Contract deleted successfully." });
    }
  };

  return (
    <Row type="flex" justify="space-between">
      <Popconfirm
        disabled={deleting}
        onConfirm={() => confirmDelete()}
        title="Are you sure you want to delete this contract?"
      >
        <Button
          disabled={deleting}
          shape="circle"
          type="danger"
          ghost
          icon="delete"
        />
      </Popconfirm>
      <Popconfirm
        disabled={deleting}
        onConfirm={() => setEditModal(true)}
        title="Are you sure you want to edit this contract?"
      >
        <Button
          disabled={deleting}
          shape="circle"
          type="primary"
          ghost
          icon="edit"
        />
      </Popconfirm>
      {item.file_url ? (
        <div className={classes.previewButton}>
          <PreviewButton fileName={item.file_url} />
        </div>
      ) : null}
      {item.file_url ? (
        <a
          rel="noopener noreferrer"
          href={`https://appinchina.space/download/${item.file_url}`}
          target="_blank"
          className={classes.downloadButton}
        >
          <Button disabled={deleting} shape="circle" icon="cloud-download" />
        </a>
      ) : null}
      <EditContract
        update={setData}
        addendums={data}
        visible={editModal}
        contract={item}
        close={() => setEditModal(false)}
      />
    </Row>
  );
};
