import axios from "../axios";

export const getAsyncNotifications = async ({
  page = 1,
  results = 10,
  sort = "desc",
  field = "createdAt",
  completion = "INCOMPLETE",
  category = null,
  start = null,
  end = null,
  all,
}) => {
  let response = null;
  try {
    response = await axios.get(
      `/async-notifications?page=${page}&sort=${sort}&field=${field}&results=${results}${
        all !== "" ? `&${all}` : ""
      }${completion ? `&completion=${completion}` : ""}${
        category ? `&category=${category}` : ""
      }${start && end ? `&start=${start}&end=${end}` : ""}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
export const getTotalNotifications = async () => {
  let response = null;
  try {
    response = await axios.get("/notifications");
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
