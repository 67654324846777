import React, { useState, Fragment, useEffect } from "react";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import {
  Button,
  Select,
  notification,
  Spin,
  Tooltip,
  Modal,
  Row,
  Input,
} from "antd";
import Axios from "axios";
import CustomAxios from "../../../axios";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import classes from "./Editor.module.less";
import AttachmentModal from "../NewAttachmentModal/NewAttachmentModal";
import File from "../../UI/File/File";
const { Option } = Select;
const confirm = Modal.confirm;
const getLatest = (versions) => {
  return versions.length > 0 ? versions[versions.length - 1].content : null;
};
const getLatestVersionId = (versions) => {
  return versions.length > 0 ? versions[versions.length - 1].id : null;
};
export default ({
  versions,
  addNewVersion,
  loading,
  updateVersions,
  categories,
  docId,
  updateCategories,
  actualCategory,
  actualTitle,
  updateActualTitle,
}) => {
  const [contentState, setContentState] = useState(null);
  const [version, setVersion] = useState(null);
  const [editable, setEditable] = useState(true);
  const [load, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [category, setCategory] = useState(null);
  const [title, setTitle] = useState(null);
  const [operationId, setOperationId] = useState(null);

  useEffect(() => {
    if (versions.length > 0) {
      let actualId = getLatestVersionId(versions);
      setVersion(actualId);
      let item = versions.filter((e) => e.id === actualId);
      setOperationId(item[0].FK_Operation);
      setAttachments(item[0].operation_file_attachments);
      setCategory(actualCategory);
      setTitle(actualTitle);
      setContentState(
        EditorState.createWithContent(convertFromRaw(getLatest(versions)))
      );
    } else {
      addNewVersion(setVersion);
      //setContentState(null);
    }
  }, [versions]);
  const saveDoc = async () => {
    try {
      setLoading(true);
      const { data } = await CustomAxios.put("/update-version", {
        content: JSON.stringify(convertToRaw(contentState.getCurrentContent())),
        id: version,
      });
      if (!data.error) {
        setLoading(false);
        setEditable(true);
        notification.success({
          placement: "bottomLeft",
          message: "Information Saved.",
        });
      }
    } catch (error) {}
  };

  const updateTitle = async () => {
    try {
      setLoading(true);
      const { data } = await CustomAxios.put("/update-title", {
        operationId,
        title,
      });
      if (!data.error) {
        setLoading(false);
        setEditable(true);
        notification.success({
          placement: "bottomLeft",
          message: "Information Saved.",
        });
        await updateActualTitle();
      }
    } catch (error) {}
  };

  const saveAllInformation = () => {
    updateTitle();
    saveDoc();
  };

  const deleteDoc = async () => {
    try {
      setLoading(true);
      const { data } = await CustomAxios.delete(`/delete-version/${docId}`);
      if (!data.error) {
        updateVersions();
        setLoading(false);
        notification.success({
          placement: "bottomLeft",
          message: "Information Saved.",
        });
      }
    } catch (error) {}
  };

  const cancelEdit = () => {
    const cancel = versions.find(({ id }) => id === version);
    setContentState(
      EditorState.createWithContent(convertFromRaw(cancel.content))
    );
    setTitle(actualTitle);
    setEditable(true);
    notification.success({
      placement: "bottomLeft",
      message: "Discarded changes.",
    });
  };
  const showConfirmDeleteVersion = () => {
    confirm({
      title: "Are you sure you want to delete this article?",
      onOk() {
        deleteDoc();
      },
      onCancel() {},
    });
  };
  const selectVersion = (versionId) => {
    const algo = versions.find(({ id }) => id == versionId);
    setVersion(versionId);
    setAttachments(algo.operation_file_attachments);
    setContentState(
      EditorState.createWithContent(convertFromRaw(algo.content))
    );
  };

  const updateCategory = async (val) => {
    try {
      const { data } = await CustomAxios.put("/update-category", {
        docId,
        category: val,
      });
      if (data.message == "success") {
        notification.success({
          placement: "bottomRight",
          message: "Category updated.",
        });
        setCategory(val);
        await updateCategories();
      }
    } catch (error) {
      notification.error({
        placement: "bottomRight",
        message: "An error has ocurred",
      });
    }
  };
  const confirmUpdateCategory = (val) => {
    confirm({
      title: "Are you sure you want to update Category?",
      onOk() {
        updateCategory(val);
      },
      onCancel() {},
    });
  };
  const addVersion = () => {
    addNewVersion(setVersion);
    notification.success({
      placement: "bottomLeft",
      message: "New Version Created",
    });
  };

  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const timeStamp = new Date().getTime();
      const formData = new FormData();
      formData.set("uploads[]", file);
      formData.set("name", file.name);
      formData.set("timestamp", timeStamp);
      Axios.post("https://appinchina.space/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(({ data }) => {
          if (data == "success") {
            const result = `https://appinchina.space/download/${timeStamp}/${file.name}`;
            resolve({ data: { link: result } });
          }
        })
        .catch((error) => reject(error));
    });
  };

  const previewButtons = () => (
    <Row className={classes.actions} type="flex" justify="end">
      <Button icon="edit" onClick={() => setEditable(!editable)} />
      <Button
        icon="paper-clip"
        onClick={() => {
          setModal(true);
        }}
      />
      <Tooltip placement="topLeft" title="Delete this article">
        <Button
          icon="delete"
          type="danger"
          onClick={() => showConfirmDeleteVersion()}
        />
      </Tooltip>
      <AttachmentModal
        operationId={version}
        visible={modal}
        close={() => setModal(false)}
        actualData={attachments}
        updateData={setAttachments}
      />
    </Row>
  );

  const editButtons = () => (
    <Row className={classes.actions} type="flex" justify="end">
      <Input
        style={{ width: 350, margin: 10, marginTop: 0 }}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      {categories ? (
        <Select
          value={category}
          onChange={(val) => confirmUpdateCategory(val)}
          style={{ width: 250, marginBottom: 10 }}
        >
          {categories.map((it) => (
            <Option value={it.id}>{it.category}</Option>
          ))}
        </Select>
      ) : null}
      <Button icon="close" ghost type="danger" onClick={cancelEdit}>
        Cancel
      </Button>
      <Button type="primary" ghost icon="save" onClick={saveAllInformation}>
        Save
      </Button>
    </Row>
  );

  return (
    <div className={classes.container}>
      {contentState ? (
        <Fragment>
          {!editable ? editButtons() : previewButtons()}
          {loading || load ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                justifyItems: "center",
              }}
            >
              <Spin />
            </div>
          ) : (
            <div>
              <Editor
                blockRendererFn={myBlockRenderer}
                toolbarHidden={editable}
                readOnly={editable}
                editorState={contentState}
                onEditorStateChange={(contentState) =>
                  setContentState(contentState)
                }
                toolbar={{
                  image: {
                    previewImage: true,
                    uploadCallback: uploadImageCallBack,
                  },
                }}
              />
              <div className={classes.attachments}>
                {attachments
                  ? attachments.map((it) => (
                      <div key={it.id}>
                        <File
                          validation={{}}
                          value={it.file_url}
                          title={it.file_name}
                          disabled
                        />
                      </div>
                    ))
                  : []}
              </div>
            </div>
          )}
        </Fragment>
      ) : (
        <div className={classes.intro}>
          <Button icon="plus" onClick={addVersion}>
            Add new version
          </Button>
        </div>
      )}
    </div>
  );
};

function myBlockRenderer(contentBlock) {
  const type = contentBlock.getType();

  // Convert image type to mediaComponent
  if (type === "atomic") {
    return {
      component: MediaComponent,
      editable: false,
      props: {
        foo: "bar",
      },
    };
  }
}

class MediaComponent extends React.Component {
  render() {
    const { block, contentState } = this.props;
    const { foo } = this.props.blockProps;
    const data = contentState.getEntity(block.getEntityAt(0)).getData();

    const emptyHtml = " ";
    return (
      <div>
        {emptyHtml}
        <img
          src={data.src}
          alt={data.alt || ""}
          style={{ height: data.height || "auto", width: data.width || "auto" }}
        />
      </div>
    );
  }
}
