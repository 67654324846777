import React, { useState, useEffect } from "react";
import { Steps, Spin, notification, Button, Result } from "antd";
import { sendCustomEmail } from "../../services/customEmailService";
import Editor from "./Editor/Editor";
import classes from "./Mailing.module.less";
import SelectUSers from "./SelectUsers";
import { asyncForEach } from "../../functions";

const { Step } = Steps;

export default ({ apps, clients }) => {
  const [clientsApps, setClientsApps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [emailData, setEmailData] = useState({});

  useEffect(() => {
    let data = clients.map(({ email, apps }) => ({ email: email, apps }));
    setClientsApps(data);
  }, []);
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const sendEmail = async (values) => {
    setLoading(true);
    let attachments = [];
    if (values.attachments && values.attachments.fileList) {
      await asyncForEach(
        values.attachments.fileList,
        async ({ originFileObj, type, name }) => {
          try {
            let archivo64 = await toBase64(originFileObj);
            attachments = [
              ...attachments,
              {
                disposition: "attachment",
                filename: name,
                type,
                content: archivo64.split("base64,")[1],
              },
            ];
          } catch (error) {}
        }
      );
    }
    setEmailData({
      ...emailData,
      ...values,
    });
    let { data } = await sendCustomEmail({
      ...values,
      ...emailData,
      attachments,
    });
    if (data && data.message === "success") {
      setLoading(false);
      setCurrentStep(2);
      notification.success({
        message: "Email Sent Succesfully. ",
        placement: "bottomRight",
      });
    } else {
      setLoading(false);
      notification.error({
        message: "An error has ocurred.",
        placement: "bottomRight",
      });
    }
  };

  const nextStep = (values) => {
    setCurrentStep(1);
    setEmailData({
      ...emailData,
      emails: values.emails,
      apps: values.emailApps,
    });
  };

  const steps = [
    {
      title: "Select Users",
    },
    {
      title: "Write Content",
    },
    {
      title: "Email Sent",
    },
  ];

  const setAnotherEmail = () => {
    setCurrentStep(0);
    setEmailData({});
  };

  return (
    <div className={classes.container}>
      <Steps size="small" current={currentStep}>
        {steps.map(({ title }) => (
          <Step key={title} title={title} />
        ))}
      </Steps>
      {currentStep === 0 ? (
        <SelectUSers
          initialValues={emailData}
          clientsApps={clientsApps}
          apps={apps}
          nextStep={nextStep}
        />
      ) : null}
      {currentStep === 1 ? (
        loading ? (
          <Spin
            tip="Sending..."
            style={{
              display: "flex",
              height: "500px",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        ) : (
          <Editor
            initialValues={emailData}
            sendEmail={sendEmail}
            loading={loading}
            previous={() => setCurrentStep(0)}
          />
        )
      ) : null}
      {currentStep === 2 ? (
        <div className={classes.result}>
          <Result
            status="success"
            title="Your email has been successfully sent."
            extra={[
              <Button style={{ marginTop: 20 }} onClick={setAnotherEmail}>
                Send Another Email
              </Button>,
            ]}
          />
        </div>
      ) : null}
    </div>
  );
};
