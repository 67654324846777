import SemverCompare from "semver-compare";
import moment from "moment";
const functions = {
  checkValidity: (value, rules) => {
    let isValid = true;
    if (!rules) {
      return true;
    }
    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    if (rules.fileRequired) {
      isValid = value && isValid;
    }
    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }
    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }
    if (rules.isEmail) {
      const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      isValid = pattern.test(value) && isValid;
    }
    if (rules.isDate) {
      const pattern = /^\d{2}[\//-]\d{2}[\//-]\d{4}$/;
      isValid = pattern.test(value) && isValid;
    }
    if (rules.isNumeric) {
      const pattern = /^\d*\.?\d*$/;
      isValid = pattern.test(value) && isValid;
    }
    if (rules.matches) {
      isValid = isValid && value === rules.matches;
    }
    return isValid;
  },
  treatAsUTC: (date) => {
    var result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
  },
  daysBetween: (startDate, endDate) => {
    var millisecondsPerDay = 24 * 60 * 60 * 1000;
    return (
      (functions.treatAsUTC(endDate) - functions.treatAsUTC(startDate)) /
      millisecondsPerDay
    );
  },
  styleAsNumber: (str) => {
    return str !== undefined && str !== null
      ? (str + "").replace(/\b(\d+)((\.\d+)*)\b/g, (a, b, c) => {
          return (
            (b.charAt(0) > 0 && !(c || ".").lastIndexOf(".")
              ? b.replace(/(\d)(?=(\d{3})+$)/g, "$1,")
              : b) + c
          );
        })
      : "";
  },
  styleAsTime: (time) => {
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  },
  dateFormat: (date) => {
    const fullDate = new Date(date ? date : new Date().toISOString());
    const format =
      fullDate.getFullYear() +
      "-" +
      (+fullDate.getMonth() + 1) +
      "-" +
      fullDate.getDate();
    return format;
  },
  formatMoney: (n, c, d, t) => {
    var c = isNaN((c = Math.abs(c))) ? 2 : c,
      d = d == undefined ? "." : d,
      t = t == undefined ? "," : t,
      s = n < 0 ? "-" : "",
      i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
      j = (j = i.length) > 3 ? j % 3 : 0;
    return (
      s +
      (j ? i.substr(0, j) + t : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
      (c
        ? d +
          Math.abs(n - i)
            .toFixed(c)
            .slice(2)
        : "")
    );
  },
  splitAndCapitalize: (string, toLower = true) => {
    const lower = toLower ? string.toLowerCase() : string;
    return lower
      ? lower
          .split("_")
          .reduce((arr, s) => {
            arr.push(s.charAt(0).toUpperCase() + s.slice(1));
            return arr;
          }, [])
          .join(" ")
      : "";
  },
  makeDateReadable: (date) => {
    let d = new Date(Date.parse(date, "YYY-MM-DD")).toDateString();
    return d.substr(d.indexOf(" ")).trim();
  },
  createIframe: (src) => {
    var i = document.createElement("iframe");
    i.style.display = "none";
    i.onload = () => {
      i.parentNode.removeChild(i);
    };
    i.src = src;
    document.body.appendChild(i);
  },
  toArray: (obj) => {
    return Object.keys(obj).map(function(key) {
      return obj[key];
    });
  },
  setStateFromDraft: (data, component) => {
    component.setState({ currentVersion: data });
  },
  parseAsText: (text) => {
    if (text === true) return "Yes";
    else if (text === false) return "No";
    else if (text === undefined || text === null) return "N/A";
    return text;
  },
  getDomainFromUrl: (url) => {
    if (url) {
      var a = document.createElement("a");
      a.href = url;
      return a.hostname === window.location.hostname
        ? url.split("/").shift()
        : a.hostname;
    }
    return "";
  },
};

export default functions;

export const formatTransactions = (item) => {
  const [string] = functions.formatMoney(item).split(".");
  return string;
};

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const compareVersions = (versionA, versionB) => {
  try {
    const [versionANormalized, versionBNormalized] = normalizeVersions(
      `${versionA}`,
      `${versionB}`
    );
    let result = SemverCompare(versionANormalized, versionBNormalized);
    console.log("comparing", versionB, versionA, result);
    return result;
  } catch (error) {
    return false;
  }
};

const normalizeVersions = (newVersion, lastVersion) => {
  try {
    const newVersionLength = newVersion.split(".").length || 1;
    const lastVersionLength = lastVersion.split(".").length || 1;
    const zerosToAdd = Math.abs(newVersionLength - lastVersionLength);
    const zerosFill = Array(zerosToAdd)
      .fill(".0")
      .join("");
    const versionsNormalized =
      newVersionLength > lastVersionLength
        ? [newVersion, `${lastVersion}${zerosFill}`]
        : [`${newVersion}${zerosFill}`, lastVersion];

    return versionsNormalized;
  } catch (error) {
    console.log(error);
  }
};

export const getDefaultDate = () => {
  const monthAgo = moment().subtract(30, "days");
  const currentDate = moment();
  return [monthAgo, currentDate];
};
export const getLastMonthDates = () => {
  const end = moment()
    .subtract(1, "months")
    .endOf("month")
  const start = moment()
    .subtract(1, "months")
    .startOf("month")
  return [start, end];
};

export const dragArray = (arr, idToSearch, pos, prevPos) => {
  try {
    let copyData = [...arr];
    let result = [];
    if (pos === 0) {
      let all = copyData.filter((e) => e.id !== idToSearch);
      let temp = copyData[prevPos];
      console.log(all, temp);
      result = [temp, ...all];
    } else if (pos + 1 === copyData.length) {
      let all = copyData.filter((e) => e.id !== idToSearch);
      let temp = copyData[prevPos];
      result = [...all, temp];
    } else {
      let temp = copyData[prevPos];
      let all = copyData.filter((e) => e.id !== idToSearch);
      let arr1 = all.slice(0, pos);
      let arr2 = all.slice(pos, copyData.length - 1);
      result = [...arr1, temp, ...arr2];
    }
    return result;
  } catch (error) {
    return arr;
  }
};
export const chineseText = (value) => {
  let chinese = false;
  const pattern = /^([\u4E00-\uFA29]+[0-9a-zA-Z- ]*)*$/;
  chinese = pattern.test(value);
  return chinese;
};
export const isANumber = (value) => {
  const pattern = /^\d*\.?\d*$/;
  return pattern.test(value);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
export const sortByField = (arr, field) => {
  return arr.sort((a, b) => {
    if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
    if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
    return 0;
  });
};
export const downloadCSV = (data, filename) => {
  console.log("downloading csv, data:", data);
  let csvContent =
    "data:text/csv;charset=utf-8," + data.map((e) => e.join(",")).join("\n");
  let encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};
