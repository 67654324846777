import React, { useState } from "react";
import { Form, Row, Col, Divider, Modal, Input } from "antd";
import LineCard from "../../../UI/LineCard/LineCard";
import MultiApk from "./MultiApk/MultiApk";
import File from "../../../UI/ClientsFile/File";
import Badge from "../../../UI/BadgeNew/BadgeNew";
import classes from "./UploadApk.module.less";
const AppInfoParser = require("app-info-parser");
const { Item } = Form;

export default ({
  getFieldDecorator,
  disabled = false,
  data = {},
  submitFileHandler,
  compareImages,
  setApkInformation,
  deleteFile,
  modified,
}) => {
  const [appIcon, setAppIcon] = useState(null);
  const [apkInfo, setApkInfo] = useState({});

  const isHigherCodeVersion = (version) => {
    if (data.version_code) {
      const lastVersion = data.version_code;
      const newVersion = version;
      if (parseInt(newVersion) > parseInt(lastVersion)) {
        Modal.error({
          title: `Version code submitted ${newVersion} is lower than the current version live ${lastVersion} Please fix issue and resubmit.`,
          centered: true,
        });
      }
    }
  };

  const parseApk = (fieldToUpdate, event) => {
    if (event.target.files.length > 0) {
      const [apk] = event.target.files;
      const parser = new AppInfoParser(apk);
      parser.parse().then(async (pkgInfo) => {
        if (pkgInfo) {
          isHigherCodeVersion(pkgInfo.versionCode);
          let appIcon = pkgInfo.icon ? pkgInfo.icon : null;
          if (appIcon && data) {
            await compareImages(appIcon, data.icon);
          }
          setAppIcon(appIcon);
          const formUpdate = {
            version: pkgInfo.versionName,
            version_code: pkgInfo.versionCode,
            package_name: pkgInfo.package,
            apk_icon: appIcon,
          };
          setApkInformation({
            ...data,
            ...formUpdate,
          });
          setApkInfo(formUpdate);
          submitFileHandler(fieldToUpdate);
        }
      });
    }
  };

  return (
    <div className={classes.container}>
      {data.multi_apks.length > 0 ? (
        <MultiApk data={data} disabled={true} />
      ) : (
        <LineCard style={{ marginTop: 15 }} shadow>
          <Row gutter={16}>
            <Col span={12}>
              <Item label={"Package Name:"}>
                <Badge enabled={modified.includes("package_name")}>
                  {getFieldDecorator(`package_name`, {
                    initialValue: data.package_name,
                    rules: [{ required: true, message: "Required" }],
                  })(<Input disabled={disabled} />)}
                </Badge>
              </Item>
              <Item>
                <Badge enabled={modified.includes("apk")}>
                  {getFieldDecorator("apk", {
                    initialValue: data.apk,
                    rules: [
                      {
                        required: true,
                        message: "Required",
                      },
                    ],
                  })(
                    <File
                      customIcon={appIcon}
                      name="apk"
                      type="File"
                      title="App APK *"
                      submitFile={parseApk}
                      disabled={disabled}
                      onDelete={() => deleteFile("apk")}
                      validation={{
                        fileRequired: true,
                      }}
                      format={["apk"]}
                    />
                  )}
                </Badge>
              </Item>
            </Col>
            <Col span={12}>
              <Item label={"Version Number:"}>
                <Badge enabled={modified.includes("version")}>
                  {getFieldDecorator(`version`, {
                    initialValue: data.version,
                    rules: [{ required: true, message: "Required" }],
                  })(<Input disabled={disabled} />)}
                </Badge>
              </Item>
              <Item label={"Version Code:"}>
                <Badge enabled={modified.includes("version_code")}>
                  {getFieldDecorator(`version_code`, {
                    initialValue: data.version_code,
                    rules: [{ required: true, message: "Required" }],
                  })(<Input disabled={disabled} />)}
                </Badge>
              </Item>
            </Col>
          </Row>
        </LineCard>
      )}
    </div>
  );
};
