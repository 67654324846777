import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Row, Input } from "antd";
import File from "../../UI/File/File";
import { newCompany } from "../../../services/companiesService";
export default Form.create()(({ form, data, updateData, ...props }) => {
  let [saving, setSaving] = useState(false);
  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (!err) {
        makeRequest(values);
      }
    });
  };
  const makeRequest = async (values) => {
    setSaving(true);
    let { data: response } = await newCompany(values);
    setSaving(false);
    if (response && response.result) {
      updateData([
        ...data,
        { ...response.result, id: response.result.PK_Company },
      ]);
    }
    form.resetFields();
    props.close();
  };

  useEffect(() => {}, [form]);

  const { getFieldDecorator } = form;
  return (
    <div>
      <Modal
        title="New Company"
        visible={props.visible}
        footer={null}
        destroyOnClose={true}
        onCancel={props.close}
        confirmLoading={saving}
      >
        <Form hideRequiredMark={true}>
          <Form.Item label="Logo">
            {getFieldDecorator(`logo`, {})(
              <File
                title="Logo *"
                onDelete={() => {
                  form.setFieldsValue({ logo: null });
                  form.setFieldsValue({ logo: null });
                }}
                submitFile={(file) => {
                  form.setFieldsValue({ logo: file.value });
                }}
              />
            )}
          </Form.Item>
          <Form.Item label="Company Name">
            {getFieldDecorator(`name`, {
              rules: [{ required: true, message: "Required" }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Location">
            {getFieldDecorator(`location`, {
              rules: [{ required: true, message: "Required" }],
            })(<Input />)}
          </Form.Item>
          <Row type="flex" justify="center" align="middle">
            <Button
              loading={saving}
              type="primary"
              onClick={() => handleSubmit()}
              style={{ marginTop: 10 }}
            >
              Save Company
            </Button>
          </Row>
        </Form>
      </Modal>
    </div>
  );
});
