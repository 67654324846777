import React, { Component } from "react";
import { Tabs } from "antd";
import Kefus from "../Tickets/Kefus/Kefus";
import WechatAcc from "../Tickets/WechatAccount/WechatAccount";
import classes from "./TicketsHome.module.less"
const TabPane = Tabs.TabPane;

class TicketsHome extends Component {
  state = {
    activeTab: "kefus"
  };

  callback = key => {
    this.props.history.push(`/tickets/${key}`);
  };

  componentWillMount() {
    let section = this.props.match.params.section
      ? this.props.match.params.section
      : "kefus";
    this.setState({
      activeTab: section
    });
  }

  render() {
    return (
      <div className={classes.container}>
        <Tabs defaultActiveKey={this.state.activeTab} onChange={this.callback}>
          <TabPane tab="Customer Support Users" key="kefus">
            <Kefus {...this.props} />
          </TabPane>
          <TabPane tab="Wechat Accounts" key="wechat-account">
            <WechatAcc {...this.props} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default TicketsHome;
