import React, { useState, useEffect } from "react";
import classes from "./WechatAccount.module.less";
import { Menu, Icon, Button, notification, Form, Spin, Modal } from "antd";
import WechatAccountDetails from "./WechatAccountDetails/WechatAccountDetails";
import NewWechatAccount from "./NewWechatAccount/NewWechatAccount";
import {
  getWechatAccounts,
  updateWechatAccount
} from "../../../services/TicketsServices";
import history from "../../../history";

const WechatAccount = ({ form, ...props }) => {
  const { confirm } = Modal;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [actualAccount, setActualAccount] = useState({});
  const [id, setId] = useState(props.match.params.id);
  const showConfirm = () => {
    confirm({
      title: "Are you sure you want to save this information?.",
      onOk() {
        saveGlobal();
      },
      onCancel() {}
    });
  };
  const saveGlobal = async () => {
    setLoadingSave(true);
    let { data } = await updateWechatAccount(actualAccount);
    setLoadingSave(false);
    if (data && data.message === "success") {
      notification.info({
        message: "Information saved.",
        placement: "bottomLeft",
        duration: 2
      });
    } else {
      notification.warning({
        message: "Error while connecting to the server",
        placement: "bottomLeft",
        duration: 2
      });
    }
  };

  const getData = async () => {
    setLoading(true);
    let { data } = await getWechatAccounts();
    setLoading(false);
    if (data && data.accounts) {
      setData(data.accounts);
    } else {
      notification.warning({
        message: "Error while connecting to the server",
        placement: "bottomLeft",
        duration: 2
      });
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return loading ? (
    <Spin
      tip="Loading..."
      style={{
        display: "flex",
        height: "calc(100vh - 120px)",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    />
  ) : (
    <div className={classes.container}>
      <div>
        <Menu
          style={{ width: 256, height: "100%", textAlign: "left" }}
          selectedKeys={[id || ""]}
        >
          {data.map(it => (
            <Menu.Item
              key={it.id}
              onClick={() => {
                setId(it.id);
                history.push(`/tickets/wechat-account/${it.id}`);
              }}
            >
              <Icon type="wechat" />
              {it.name}
            </Menu.Item>
          ))}
        </Menu>
      </div>
      <div className={classes.details}>
        <div className={classes.buttons}>
          <Button
            htmlType="submit"
            style={{ marginRight: 10 }}
            type="primary"
            icon="save"
            disabled={!id}
            loading={loadingSave}
            onClick={() => showConfirm()}
          >
            Save
          </Button>
          <NewWechatAccount
            redirect={({ id, name }) => {
              setData([
                ...data,
                {
                  id,
                  name
                }
              ]);
              setId(id);
              history.push(`/tickets/wechat-account/${id}`);
            }}
            {...props}
          />
        </div>
        {id ? (
          <WechatAccountDetails
            {...props}
            idAccount={props.match.params.id}
            disabled={loadingSave}
            updateAccount={e => setActualAccount(e)}
          />
        ) : (
          <div className={classes.text}>
            {" "}
            Select an account to see more information.
          </div>
        )}
      </div>
    </div>
  );
};

export default Form.create()(WechatAccount);
