import axios from "../axios";

export async function getAllCompanies(page) {
  let response = null;
  try {
    response = await axios.get(`/companies?page=${page}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function getCompanyById(id) {
  let response = null;
  try {
    response = await axios.get(`/companies/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function connectAppToCompany(values) {
  let response = null;
  try {
    response = await axios.put(`/companies`, { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function newCompany(values) {
  let response = null;
  try {
    response = await axios.post(`/companies`, { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function deleteAppFromCompany(values) {
  let response = null;
  try {
    response = await axios.delete(`/companies`, {
      data: { ...values },
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function updateCompany(values) {
  let response = null;
  try {
    response = await axios.put(`/company`, { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
