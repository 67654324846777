import React, { useEffect, useState } from "react";
import classes from "./WechatAccountDetails.module.less";
import Input from "../../../UI/Input/Input";
import { Form, Spin, Row, Col, Typography } from "antd";
import IssueTypes from "../IssueTypes/IssueTypes";
import { getWechatAccountById } from "../../../../services/TicketsServices";
import AsyncSearchApp from "../../../UI/AsyncSearch/AsyncSearch";

const { Paragraph } = Typography;
const WechatAccountDetails = ({
  form,
  idAccount,
  handleSubmit,
  updateAccount,
  disabled,
  ...props
}) => {
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState({});
  const getData = async () => {
    setLoading(true);
    let { data } = await getWechatAccountById(idAccount);
    if (data && data.account) {
      let apps = data.account.apps.map((app) => ({
        key: app.id,
        label: app.name,
      }));
      setAccount({ ...data.account, apps: apps });
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [idAccount]);
  useEffect(() => {
    updateAccount(account);
  }, [account]);
  return loading ? (
    <Spin
      tip="Loading..."
      style={{
        display: "flex",
        width: "100%",
        height: "calc(100vh - 120px)",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  ) : (
    <div className={classes.container}>
      <div>
        <Form hideRequiredMark={true} layout="vertical" onSubmit={handleSubmit}>
          <Form.Item label="Name">
            {getFieldDecorator("name", {
              initialValue: account.name,
              onChange: (e) => setAccount({ ...account, name: e.target.value }),
              rules: [
                {
                  required: true,
                  message: "Please input name.",
                },
              ],
            })(<Input disabled={disabled} />)}
          </Form.Item>
          <Form.Item label="ClientId">
            {getFieldDecorator("client_id", {
              initialValue: account.client_id,
              onChange: (e) => {
                setAccount({
                  ...account,
                  client_id: e.target.value,
                });
              },
              rules: [
                {
                  required: true,
                  message: "Please input Client Id.",
                },
              ],
            })(<Input disabled={disabled} />)}
          </Form.Item>
          <Form.Item label="Wechat EndPoint">
            <Row type="flex" justify="space-around" align="middle">
              <Col span={23}>
                <Input
                  disabled
                  value={`${process.env.REACT_APP_TICKETS_URL}/api/wechat/${account.client_id}`}
                />
              </Col>
              <Col span={1}>
                <Paragraph
                  copyable={{
                    text: `${process.env.REACT_APP_TICKETS_URL}/api/wechat/${account.client_id}`,
                  }}
                />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label="Client Secret">
            {getFieldDecorator("client_secret", {
              initialValue: account.client_secret,
              onChange: (e) =>
                setAccount({ ...account, client_secret: e.target.value }),
              rules: [
                {
                  required: true,
                  message: "Please input Client Secret.",
                },
              ],
            })(<Input disabled={disabled} />)}
          </Form.Item>
          <Form.Item label="Apps">
            {getFieldDecorator("apps", {
              initialValue: account.apps,
              onChange: (e) => setAccount({ ...account, apps: e }),
            })(
              <AsyncSearchApp
                target="apps"
                multiple={true}
                placeholder="Select an app..."
                change={(value) => form.setFieldsValue({ apps: value })}
                labelInValue={true}
              />
            )}
          </Form.Item>
          <Form.Item label="Invalid Message Reply">
            {getFieldDecorator("invalid_message_reply", {
              initialValue: account.invalid_message_reply,
              onChange: (e) =>
                setAccount({
                  ...account,
                  invalid_message_reply: e.target.value,
                }),
            })(<Input type="textarea" disabled={disabled} />)}
          </Form.Item>
          <Form.Item label="Kefu Available Automated Reply">
            {getFieldDecorator("kefus_available", {
              initialValue: account.kefus_available,
              onChange: (e) =>
                setAccount({ ...account, kefus_available: e.target.value }),
            })(<Input type="textarea" disabled={disabled} />)}
          </Form.Item>
          <Form.Item label="Kefu Unavailable Automated Reply">
            {getFieldDecorator("kefus_unavailable", {
              initialValue: account.kefus_unavailable,
              onChange: (e) =>
                setAccount({ ...account, kefus_unavailable: e.target.value }),
            })(<Input type="textarea" disabled={disabled} />)}
          </Form.Item>
          <Form.Item label="Ticket Closed Automated Reply">
            {getFieldDecorator("ticket_closed", {
              initialValue: account.ticket_closed,
              onChange: (e) =>
                setAccount({ ...account, ticket_closed: e.target.value }),
            })(<Input type="textarea" disabled={disabled} />)}
          </Form.Item>
          {account.apps && account.apps.length > 0 ? (
            <Form.Item label="App Selected Automated Reply">
              {getFieldDecorator("app_selected_reply", {
                initialValue: account.app_selected_reply,
                onChange: (e) =>
                  setAccount({
                    ...account,
                    app_selected_reply: e.target.value,
                  }),
              })(<Input type="textarea" disabled={disabled} />)}
            </Form.Item>
          ) : null}
        </Form>
      </div>
      <div className={classes.title}>
        <b>Issue Types</b>
      </div>
      <div className={classes.issueTypes}>
        <IssueTypes
          updateAccount={(e) => setAccount({ ...account, issues: e })}
          issues={account.issues}
          idAccount={account.id}
          {...props}
        />
      </div>
    </div>
  );
};

export default Form.create()(WechatAccountDetails);
