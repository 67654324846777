import React, { useEffect, useState } from "react";
import PieChart from "./PieChart/PieChart";
import { Card, Row } from "antd";
import functions, { formatTransactions } from "../../../functions";
import { getDataSinceBegining } from "../../../services/aicPay";
export default ({ month, year }) => {
  const [data, setData] = useState({
    pieChart: [
      { source: "Alipay", total: 0 },
      { source: "WeChat", total: 0 },
    ],
    balance_from_begining: 0,
    transactions_from_begining: 0,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [month, year]);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (month && year) {
        const data = await getDataSinceBegining({ month, year });
        if (data) {
          const { pieChart, adminDashbordFromBegining } = data;
          setData({
            pieChart,
            ...adminDashbordFromBegining,
          });
        } else {
          setData({
            pieChart: [
              { source: "WeChat", total: 0 },
              { source: "Alipay", total: 0 },
            ],
            balance_from_begining: 0,
            transactions_from_begining: 0,
          });
        }
      }
      setLoading(false);
    } catch (error) {}
  };

  return (
    <Card title="AIC Pay (Since Beginning of Time)" loading={loading}>
      {!loading ? (
        <>
          <table>
            <tbody>
              <tr>
                <td>Transactions:</td>
                <td>
                  <span>
                    {formatTransactions(data.transactions_from_begining)}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Total since:</td>
                <td>
                  <span>
                    ¥{functions.formatMoney(data.balance_from_begining / 100)}
                  </span>
                </td>
              </tr>
              <tr>
                <td />
              </tr>
            </tbody>
          </table>
          <Row type="flex" justify="center">
            <PieChart data={data.pieChart} />
          </Row>
        </>
      ) : null}
    </Card>
  );
};
