import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Button,
  Row,
  Input,
  Select,
  Divider,
  Icon,
  Upload,
  Alert,
} from "antd";
import {
  newAppDocument,
  getDocsCategories,
} from "../../../services/appDistributionService";
import LoadingSpin from "../../UI/LoadingSpin/LoadingSpin";
import Axios from "axios";
const { Option } = Select;
export default Form.create({ name: "newHistory" })(
  ({ form, appId, updateData, actualData, ...props }) => {
    const [saving, setSaving] = useState(false);
    const [addItem, setAddItem] = useState(false);
    const [label, setLabel] = useState(null);
    const [options, setOptions] = useState([
      {
        value: "COPYRIGHT",
        label: "Copyright",
      },
      { value: "AUTHORIZATION", label: "Authorization" },
    ]);
    const [newCategory, setNewCategory] = useState("");
    const [loading, setLoading] = useState(false);
    const [uploadingImg, setUploadingImg] = useState(false);
    useEffect(() => {
      getCategories();
    }, []);
    const handleSubmit = () => {
      form.validateFields((err, values) => {
        if (!err) {
          let app_documents_attachments = values.app_documents_attachments.fileList.map(
            ({ name, size, response }) => ({
              file_name: name,
              file_size: size,
              file_url: response,
            })
          );
          let data = { ...values, app_documents_attachments };
          makeRequest(data);
        }
      });
    };
    const makeRequest = async (values) => {
      setSaving(true);
      let { data } = await newAppDocument({
        ...values,
        label,
        FK_App: appId,
      });
      setSaving(false);
      if (data && data.result) {
        const documents = actualData[values.type]
          ? { [values.type]: [...actualData[values.type], data.result] }
          : { [values.type]: [data.result] };
        updateData({
          ...actualData,
          ...documents,
        });
      }
      props.close();
      form.resetFields();
    };
    const uploadImage = async ({ file, onSuccess, onProgress, onError }) => {
      const timeStamp = new Date().getTime();
      const formData = new FormData();
      const result = `${timeStamp}/${file.name}`;
      formData.set("uploads[]", file);
      formData.set("name", file.name);
      formData.set("timestamp", timeStamp);
      setUploadingImg(true);
      let { data } = await Axios.post(
        "https://appinchina.space/upload",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onProgress({
              percent: percentCompleted,
            });
          },
        }
      );
      setUploadingImg(false);
      if (data) {
        onSuccess(result);
      } else {
        onError("error");
      }
    };
    const uploadProps = {
      listType: "picture",
    };

    const getCategories = async () => {
      try {
        setLoading(true);
        const { data } = await getDocsCategories();
        if (data.categories) {
          setOptions([...options, ...data.categories]);
        }
        setLoading(false);
      } catch (error) {}
    };

    let categoryModal = (
      <div>
        <Modal
          title="New Category"
          visible={addItem}
          footer={null}
          onCancel={() => {
            setAddItem(false);
          }}
        >
          <Input
            style={{ width: "100%" }}
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
          />
          <Row type="flex" justify="center" style={{ marginTop: 10 }}>
            <Button
              type="primary"
              onClick={() => {
                setOptions([
                  ...options,
                  { value: newCategory, label: newCategory },
                ]);
                form.setFieldsValue({ type: newCategory, label: newCategory });
                setAddItem(false);
                setNewCategory("");
              }}
            >
              Save category
            </Button>
          </Row>
        </Modal>
      </div>
    );
    const { getFieldDecorator } = form;
    return loading ? (
      <LoadingSpin />
    ) : (
      <div>
        <Modal
          title="New Document"
          visible={props.visible}
          footer={null}
          onCancel={props.close}
          destroyOnClose={true}
          confirmLoading={saving}
        >
          <Form hideRequiredMark={true}>
            <Form.Item label="Type">
              {getFieldDecorator(`type`, {
                rules: [{ required: true, message: "Required" }],
                onChange: (_, as) => setLabel(as.props.label),
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select type"
                  disabled={saving}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{ padding: "4px 8px", cursor: "pointer" }}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => {
                          setAddItem(true);
                        }}
                      >
                        <Icon type="plus" /> Other category
                      </div>
                    </div>
                  )}
                >
                  {options.map(({ value, label }) => (
                    <Option value={value} key={value} label={label}>
                      {label}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            {form.getFieldValue("type") !== undefined ? (
              form.getFieldValue("type") === "COPYRIGHT" ? (
                <Alert
                  showIcon
                  type="info"
                  message=" This document will only be viewable on the admin dashboard."
                />
              ) : (
                <Alert
                  showIcon
                  type="warning"
                  message="This document will be viewable for the clients."
                />
              )
            ) : null}
            <Form.Item label="Internal Notes">
              {getFieldDecorator(`internal_notes`)(
                <Input.TextArea disabled={saving} rows={4} />
              )}
            </Form.Item>
            <Form.Item label="Description">
              {getFieldDecorator(`description`, {
                rules: [{ required: true, message: "Required" }],
              })(<Input.TextArea disabled={saving} rows={4} />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(`app_documents_attachments`, {
                rules: [{ required: true, message: "Required" }],
              })(
                <Upload
                  onRemove={(file) => setUploadingImg(false)}
                  disabled={saving}
                  {...uploadProps}
                  customRequest={uploadImage}
                >
                  <Button loading={uploadingImg}>
                    <Icon type={uploadingImg ? "loading" : "upload"} />
                    {uploadingImg
                      ? "Uploading File"
                      : " Click to Upload New File(s)"}
                  </Button>
                </Upload>
              )}
            </Form.Item>
            {categoryModal}
            <Row type="flex" justify="center" align="middle">
              <Button
                loading={saving}
                disabled={uploadingImg}
                type="primary"
                onClick={() => handleSubmit()}
                style={{ marginTop: 10 }}
              >
                Save Document
              </Button>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
);
