import React, { useState, useEffect } from "react";
import classes from "./ApkReclaim.module.less";
import { Table, Input, Button, Radio, Row, notification } from "antd";
import {
  getApkReclaiming,
  updateApkReclaiming,
  uploadReclaimingAttachment,
} from "../../services/apkReclaimService";
import LoadingSpin from "../UI/LoadingSpin/LoadingSpin";
import AttachmentCard from "./AttachmentCard/AttachmentCard";
const { TextArea } = Input;

export default ({ appId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  let [loadingSaving, setLoadingSaving] = useState(false);

  useEffect(() => {
    fetchData();
  }, [appId]);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (appId) {
        const { data } = await getApkReclaiming(appId);
        if (data) {
          setData(data.result);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const updateRow = ({ field, value, id }) => {
    const dataCopy = data;
    const index = dataCopy.findIndex((item) => item.id === id);
    dataCopy[index] = {
      ...dataCopy[index],
      [field]: value,
      viewed: false,
    };
    setData(dataCopy);
  };

  let columns = [
    { key: "name", dataIndex: "name", title: "Store Name" },
    {
      key: "current_state",
      dataIndex: "current_state",
      title: "Current State",
      render: (current_state, item) => (
        <Radio.Group
          name="current_state"
          defaultValue={!!current_state}
          onChange={(event) =>
            updateRow({
              field: "current_state",
              value: event.target.value,
              id: item.id,
            })
          }
        >
          <Radio value={true}>Shown</Radio>
          <Radio value={false}>Hidden</Radio>
        </Radio.Group>
      ),
    },

    {
      key: "note",
      dataIndex: "note",
      title: "AppInChina Note",
      render: (note, item) => (
        <TextArea
          rows={4}
          defaultValue={note}
          onChange={(event) =>
            updateRow({
              field: "note",
              value: event.target.value,
              id: item.id,
            })
          }
        />
      ),
    },
    {
      key: "apk_client_attachments",
      dataIndex: "apk_client_attachments",
      title: "Clients Files",
      render: (client_file) =>
        client_file && client_file.length ? (
          <b>{client_file.length}</b>
        ) : (
          <b>0</b>
        ),
    },
  ];

  const saveInformation = async () => {
    try {
      setLoadingSaving(true);
      const { data: response } = await updateApkReclaiming(data);
      if (response.message !== "success") {
        notification.error({
          message: "An error has ocurred",
          placement: "bottomRight",
        });
      } else {
        notification.info({
          message: "Information saved.",
          placement: "bottomRight",
        });
      }
      setLoadingSaving(false);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadReclaimingFile = async ({ id, id_reclaim, files }) => {
    try {
      const { data: response } = await uploadReclaimingAttachment({
        id,
        id_reclaim,
        files,
      });
      if (response && response.attachments) {
        const dataCopy = data;
        const index = dataCopy.findIndex((item) => item.id === id);
        dataCopy[index] = {
          ...dataCopy[index],
          apk_reclaim_attachments: [
            ...dataCopy[index].apk_reclaim_attachments,
            ...response.attachments,
          ],
        };
        setData([...dataCopy]);
        notification.info({ message: "Attachment Saved." });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removeAttachmentFromState = (id_store, id_remove) => {
    const dataCopy = data;
    const index = dataCopy.findIndex((item) => item.id === id_store);
    const newAttachments = dataCopy[index].apk_reclaim_attachments.filter(
      (item) => item.id != id_remove
    );
    dataCopy[index] = {
      ...dataCopy[index],
      apk_reclaim_attachments: newAttachments,
    };
    notification.info({
      message: "Attachment removed.",
    });
    setData([...dataCopy]);
  };

  return (
    <div className={classes.container}>
      {loading ? (
        <LoadingSpin />
      ) : (
        <>
          <Row className={classes.globalSave} type="flex" justify="end">
            <Button
              loading={loadingSaving}
              width="200px"
              icon="save"
              type="primary"
              htmlType="submit"
              className={["login-form-button"].join(" ")}
              onClick={saveInformation}
            >
              Save
            </Button>
          </Row>
          <Table
            size="small"
            expandedRowRender={(record) => (
              <div style={{ margin: 0 }}>
                <AttachmentCard
                  update={({ files }) =>
                    uploadReclaimingFile({
                      id_reclaim: record.id_reclaim,
                      id: record.id,
                      files,
                    })
                  }
                  deleteHandler={(id_remove) =>
                    removeAttachmentFromState(record.id, id_remove)
                  }
                  adminData={record.apk_reclaim_attachments}
                  clientData={record.apk_client_attachments}
                />
              </div>
            )}
            dataSource={data}
            columns={columns}
          />
        </>
      )}
    </div>
  );
};
