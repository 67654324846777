import React, { Component } from "react";
import classes from "./ApkDetails.module.css";
import ReactFileDrop from "react-file-drop";
import EventBus from "eventbusjs";
const AppInfoParser = require("app-info-parser");

class ApkDetails extends Component {
  state = {
    label: "Drop an APK here!",
    file: null,
    details: {},
  };

  handleDrop = (files, event) => {
    if (
      files.length &&
      files[0].name &&
      files[0].name.split(".").pop() === "apk"
    ) {
      this.setState({
        label: files.length ? files[0].name : "Drop an APK here!",
        file: files[0],
      });
      this.parseApk(files[0]);
    } else {
      EventBus.dispatch("ALERT", this, "Only APK files are allowed");
    }
  };

  parseApk = (file) => {
    try {
      const parser = new AppInfoParser(file);
      parser
        .parse()
        .then((pkgInfo) => {
          console.log("app info ----> ", pkgInfo);
          if (pkgInfo) {
            this.setState({ pkgInfo });
          }
        })
        .catch((err) => {
          console.log("err ----> ", err);
        });
    } catch (e) {
      console.log("catched error", e);
    }
  };

  render() {
    let details = this.state.pkgInfo;
    console.log("details", details);
    let names = !!details && details.application && details.application.label;
    return (
      <div className={classes.Container}>
        <div className={classes.Content}>
          <ReactFileDrop
            onDrop={this.handleDrop}
            className={classes.file}
            draggingOverFrameClassName={classes.dragging}
          >
            {this.state.label}
          </ReactFileDrop>
          {details ? (
            <div className={classes.details}>
              <img src={details.icon} alt="" />
              <table>
                <tbody>
                  <tr>
                    <td>Name:</td>
                    <td>
                      {names && names.length
                        ? typeof names === "object"
                          ? names.pop()
                          : names
                        : "No name found inside the APK"}
                    </td>
                  </tr>
                  <tr>
                    <td>Package Name:</td>
                    <td>{details.package}</td>
                  </tr>
                  <tr>
                    <td>Version Code:</td>
                    <td>{details.versionCode}</td>
                  </tr>
                  <tr>
                    <td>Version Name:</td>
                    <td>{details.versionName}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default ApkDetails;
