import React from "react";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";
import functions from "../../../functions";
export default ({ data = [] }) => {
  const cols = {
    date: {
      range: [0, 1],
    },
  };
  return (
    <div>
      <Chart padding={60} height={400} data={data} scale={cols} forceFit>
        <Legend />
        <Axis name="date" />
        <Axis
          name="count"
          label={{
            formatter: (val) => `${functions.styleAsNumber(val)}`,
          }}
        />
        <Tooltip
          useHtml
          g2-tooltip={{
            boxShadow: "none",
            color: "#fff",
            backgroundColor: "#222",
          }}
          crosshairs={{
            type: "y",
          }}
          style={{
            color: "red",
          }}
        />
        <Geom
          type="line"
          position="date*count"
          size={2}
          color={"manager"}
          shape="square"
        />
        <Geom
          type="point"
          position="date*count"
          size={4}
          shape={"circle"}
          color={"manager"}
          style={{
            stroke: "#fff",
            lineWidth: 1,
          }}
        />
      </Chart>
    </div>
  );
};
