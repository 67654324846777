import React, { useState, Fragment, useEffect } from "react";
import classes from "./AddWechatAccountModal.module.less";
import { Modal, Button, Select, Form, notification } from "antd";
import { getWechatAccounts } from "../../../services/TicketsServices";
const AddWechatAccountModal = ({ form, onAdd, accounts }) => {
  const { getFieldDecorator } = form;
  const { Option } = Select;
  let [modalOpen, setModalOpen] = useState(false);
  let [loading, setLoading] = useState(false);
  let [data, setData] = useState([]);
  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        validateSubmit(values);
      }
    });
  };
  const validateSubmit = ({ wechat_accounts }) => {
    let data = { account_id: wechat_accounts.key, name: wechat_accounts.label };
    const result = accounts.find(
      ({ account_id }) => account_id === data.account_id
    );

    if (result && result.account_id) {
      notification.warning({
        message: "Wechat Account already exists",
        placement: "bottomLeft",
        duration: 2
      });
    } else {
      notification.info({
        message: "New Wechat Account added",
        placement: "bottomLeft",
        duration: 2
      });
      onAdd(data);
    }
    setModalOpen(false);
    form.resetFields();
  };
  const getAccounts = async () => {
    setLoading(true);
    let { data } = await getWechatAccounts();
    setLoading(false);
    if (data && data.accounts) {
      setData(data.accounts);
    } else {
      notification.warning({
        message: "Error while connecting to the server",
        placement: "bottomLeft",
        duration: 2
      });
    }
  };
  useEffect(() => {
    getAccounts();
  }, []);
  return (
    <Fragment>
      <div className={classes.button}>
        <Button
          disabled={loading}
          onClick={() => setModalOpen(true)}
          type="primary"
          icon="plus"
        >
          Allow Access to Wechat Account
        </Button>
      </div>
      <Modal
        title="Allow Access to Wechat Account"
        visible={modalOpen}
        onOk={handleSubmit}
        onCancel={() => setModalOpen(false)}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <Form layout="vertical" onSubmit={handleSubmit}>
          <Form.Item label="Wechat Account">
            {getFieldDecorator("wechat_accounts", {
              rules: [
                {
                  required: true,
                  message: "Please select an account."
                }
              ]
            })(
              <Select labelInValue placeholder="Select...">
                {data.map(it => (
                  <Option key={it.id} value={it.id} label={it.name}>
                    {it.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default Form.create()(AddWechatAccountModal);
