import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Row, Input } from "antd";
import { newProject } from "../../../services/projectsService";
export default Form.create()(({ form, data, updateData, ...props }) => {
  let [saving, setSaving] = useState(false);
  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (!err) {
        makeRequest(values);
      }
    });
  };
  const makeRequest = async (values) => {
    setSaving(true);
    let { data: response } = await newProject(values);
    setSaving(false);
    if (response && response.result) {
      updateData([
        ...data,
        {
          ...response.result,
          date: response.result.createdAt,
          id: response.result.PK_Project,
        },
      ]);
    }
    form.resetFields();
    props.close();
  };

  useEffect(() => {}, [form]);

  const { getFieldDecorator } = form;
  return (
    <div>
      <Modal
        title="New Project"
        visible={props.visible}
        footer={null}
        destroyOnClose={true}
        onCancel={props.close}
        confirmLoading={saving}
      >
        <Form hideRequiredMark={true}>
          <Form.Item label="Title">
            {getFieldDecorator(`title`, {
              rules: [{ required: true, message: "Required" }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator(`description`, {
              rules: [{ required: true, message: "Required" }],
            })(<Input.TextArea rows={4} />)}
          </Form.Item>
          <Row type="flex" justify="center" align="middle">
            <Button
              loading={saving}
              type="primary"
              onClick={() => handleSubmit()}
              style={{ marginTop: 10 }}
            >
              Save Project
            </Button>
          </Row>
        </Form>
      </Modal>
    </div>
  );
});
