import axios from "../axios";

export async function getApkReclaiming(app_id) {
  let response = null;
  try {
    response = await axios.get(`/distribution-reclaiming/${app_id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function updateApkReclaiming(data) {
  let response = null;
  try {
    response = await axios.put(`/distribution-reclaiming`, {
      data,
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function uploadReclaimingAttachment(data) {
  let response = null;
  try {
    response = await axios.post(`/distribution-reclaiming`, {
      ...data,
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function deleteReclaimingAttachment(id) {
  let response = null;
  try {
    response = await axios.delete(`/distribution-reclaiming`, {
      data: {
        id,
      },
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
