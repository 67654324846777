import React, { useState } from "react";
import classes from "../AppLocalization/AppLocalization.module.less";

import PaymentsAccounts from "./Payments";
import AnalyticsAccounts from "./Analytics";
import ServerSelect from "./ServerSelect";
export default (props) => {
  let [server, setServer] = useState(null);
  return (
    <div className={classes.container}>
      <ServerSelect {...props} onChange={setServer} />
      <AnalyticsAccounts {...props} server={server} />
      <PaymentsAccounts {...props} server={server} />
    </div>
  );
};
