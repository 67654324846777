import React, { useState, Fragment, useEffect } from "react";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { Button, notification, Spin, Tooltip, Modal, DatePicker } from "antd";
import Axios from "axios";
import { updateBulletin } from "../../../services/bulletinService";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import classes from "./Editor.module.less";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import moment from "moment";
const confirm = Modal.confirm;

export default ({
  content,
  updateInfo,
  docId,
  loading,
  selected,
  bulletin,
  bulletins,
  orderBulletins,
}) => {
  const [contentState, setContentState] = useState(null);
  const [editable, setEditable] = useState(true);
  const [load, setLoading] = useState(false);
  const [date, setDate] = useState(null);
  useEffect(() => {
    const html = content ? content : "";
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setContentState(editorState);
    }
  }, [content]);

  useEffect(() => {
    if (bulletin.date) {
      setDate(bulletin.date);
    }
  }, [bulletin]);

  const saveDoc = async () => {
    try {
      // console.log(bulletin.date, date, new Date(date).toISOString());
      setLoading(true);
      let content = draftToHtml(convertToRaw(contentState.getCurrentContent()));
      const { data } = await updateBulletin({
        content,
        id: docId,
        date: new Date(date),
      });
      setLoading(false);
      if (data && data.message === "success") {
        setEditable(true);
        notification.success({
          placement: "bottomLeft",
          message: "Information Saved.",
        });
        if (moment(bulletin.date).format("YYYY-MM-DD") !== date) {
          let copy = [...bulletins];
          const index = copy.map((item) => item.id).indexOf(docId);
          copy[index] = {
            ...copy[index],
            date: new Date(date).toISOString(),
          };
          orderBulletins(copy);
        }
      }
    } catch (error) {}
  };
  const deleteDoc = async () => {
    try {
      setLoading(true);
      const { data } = await updateBulletin({ status: 0, id: docId });
      setLoading(false);
      if (data && data.message === "success") {
        selected(null);
        updateInfo();

        notification.success({
          placement: "bottomLeft",
          message: "Information Saved.",
        });
      }
    } catch (error) {}
  };

  const cancelEdit = () => {
    const html = content ? content : "";
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setContentState(editorState);
    }

    setEditable(true);
    notification.success({
      placement: "bottomLeft",
      message: "Discarded changes.",
    });
  };
  const showConfirmDeleteVersion = () => {
    confirm({
      title: "Are you sure you want to delete this bulletin?",
      onOk() {
        deleteDoc();
      },
      onCancel() {},
    });
  };

  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const timeStamp = new Date().getTime();
      const formData = new FormData();
      formData.set("uploads[]", file);
      formData.set("name", file.name);
      formData.set("timestamp", timeStamp);
      Axios.post("https://appinchina.space/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(({ data }) => {
          if (data == "success") {
            const result = `https://appinchina.space/download/${timeStamp}/${file.name}`;
            resolve({ data: { link: result } });
          }
        })
        .catch((error) => reject(error));
    });
  };

  const previewButtons = () => (
    <div className={classes.actions}>
      <Button icon="edit" onClick={() => setEditable(!editable)}>
        Edit Bulletin
      </Button>
      <Tooltip placement="topLeft" title="Delete this bulletin">
        <Button
          icon="delete"
          type="danger"
          onClick={() => showConfirmDeleteVersion()}
        />
      </Tooltip>
    </div>
  );

  const editButtons = () => (
    <div className={classes.actions}>
      <DatePicker
        format="YYYY-MM-DD"
        onChange={(_, string) => {
          setDate(string);
        }}
        value={date ? moment(date) : null}
      />
      <Button icon="close" onClick={cancelEdit}>
        Cancel
      </Button>
      <Button icon="save" onClick={saveDoc}>
        Save
      </Button>
    </div>
  );

  return (
    <div className={classes.container}>
      {contentState ? (
        <Fragment>
          {!editable ? editButtons() : previewButtons()}
          {loading || load ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                justifyItems: "center",
              }}
            >
              <Spin />
            </div>
          ) : (
            <Editor
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              toolbarHidden={editable}
              readOnly={editable}
              editorState={contentState}
              onEditorStateChange={(contentState) =>
                setContentState(contentState)
              }
              toolbar={{
                image: {
                  previewImage: true,
                  uploadCallback: uploadImageCallBack,
                },
              }}
            />
          )}
        </Fragment>
      ) : null}
    </div>
  );
};
