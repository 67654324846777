import axios from "../axios";

export const globalSeach = async (text) => {
  try {
    const { data } = await axios(`/search?text=${text}`);
    return data;
  } catch (error) {
    return [];
  }
};

export const multiSelectSearch = async ({ text, target }) => {
  try {
    const { data } = await axios(`/async-search?text=${text}&target=${target}`);
    return data;
  } catch (error) {
    return [];
  }
};

export const mailingSearch = async ({ apps }) => {
  try {
    const { data } = await axios(`/async-mailing?apps=${apps}`);
    return data;
  } catch (error) {
    return [];
  }
};
