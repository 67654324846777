import React, { Component, Fragment } from 'react';
import classes from './FilePreview.module.css';
import ToolTip from '../../UI/Tooltip/Tooltip'
import { MdFileDownload, MdVisibility } from 'react-icons/md'
import Dialog from '../Dialog/Dialog'

class FilePreview extends Component {

    state = {
        dialogPreview: false
    }

    dialogHandler = () => {
        this.setState((prevState) => ({
            dialogPreview: !prevState.dialogPreview
        }))
    }

    render() {
        let fileExtensions = (/(\.pdf)$/i)
        let imageExtensions = (/(\.jpg|\.png|\.jpeg|\.svg\.jpg)$/i)
        let previewAbleContent = null
        let modifiedClass = this.props.modified ? classes.modified : ""

        if (fileExtensions.exec(this.props.fileName)) {
            previewAbleContent = (

                <Fragment>
                    <MdVisibility style={{ margin: "0 5px 5px 0" }} onClick={this.dialogHandler} size="1.4em" />
                    <Dialog display={this.state.dialogPreview} close={this.dialogHandler}>
                        <object data={`https://appinchina.space/uploads/${this.props.fileName}`} type="application/pdf" width="100%" height="650px">
                            <p><b>Example fallback content</b>: This browser does not support PDFs. Please download the PDF to view it: <a href={`https://appinchina.space/download/${this.props.fileName}`} download>Download PDF</a>.</p>
                        </object>

                    </Dialog>
                </Fragment>
            )
        }
        else if (imageExtensions.exec(this.props.fileName)) {
            previewAbleContent = this.props.fileName ? (
                <ToolTip content={<img src={`https://appinchina.space/uploads/${this.props.fileName}`} />}>
                    <MdVisibility size="1.4em" />
                </ToolTip>
            ) : null
        }

        let downloadButton = this.props.fileName ? (
            <a style={{ margin: "0 5px " }} href={`https://appinchina.space/download/${this.props.fileName}`} target="_blank" download>
                <MdFileDownload size="1.4em" />
            </a>
        ) : <span>(no file)</span>

        return (
            <div>
                {
                    <div className={[classes.Container, modifiedClass].join(" ")}>
                        <p className={classes.label}>{this.props.placeholder}</p>
                        <div>
                            {previewAbleContent}
                            {downloadButton}
                        </div>
                    </div>
                }
            </div>
        )
    }
}
export default FilePreview