import axios from "../axios";

export async function getFinanceSummary(month, year) {
  let response = null;
  try {
    response = await axios.get("/home/finance-summary", {
      params: {
        month,
        year,
      },
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}

export async function getDistributionPerformance(month, year) {
  let response = null;
  try {
    response = await axios.get("/home/distribution-performance", {
      params: {
        month,
        year,
      },
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}

export async function getWebsiteImpact(month, year) {
  let response = null;
  try {
    response = await axios.get("/home/website-impact", {
      params: {
        month,
        year,
      },
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}

export async function getLocationStatistics(month, year) {
  let response = null;
  try {
    response = await axios.get("/home/location-statistics", {
      params: {
        month,
        year,
      },
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}

export async function getCategoryStatistics(month, year) {
  let response = null;
  try {
    response = await axios.get("/home/category-statistics", {
      params: {
        month,
        year,
      },
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}

export async function getAvgUsage() {
  let response = null;
  try {
    response = await axios.get("/dashboard-tracking");
  } catch (error) {
    console.log("error", error);
  }
  return response;
}

export async function getMarketingPerformance(month, year) {
  let response = null;
  try {
    response = await axios.get("/home/marketing-performance", {
      params: {
        month,
        year,
      },
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}

export async function getNewsletter() {
  let response = null;
  try {
    response = await axios.get("/home/newsletter-signup");
  } catch (error) {
    console.log("error", error);
  }
  return response;
}

export async function deleteNewsletter(id) {
  let response = null;
  try {
    response = await axios.delete(`home/newsletter-signup-delete/${id}`);
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
