import React, { Component } from "react";
import { Skeleton, Table, Button, Modal, notification } from "antd";
import {
  getTranslationRequests,
  updateTranslationReport
} from "../../../services/localizationService";
import moment from "moment";
import File from "../../UI/File/File";
export default class Translation extends Component {
  state = {
    loadingTranslation: true,
    currentTranslation: null,
    translationRequests: null,
    reportModalVisible: false,
    currentReportEdited: null,
    currentReportFile: null,
    savingReport: false
  };

  translationModal = () => (
    <Modal
      title="Translation Text Submitted"
      visible={this.state.translationModalVisible}
      onOk={this.closeModal}
      onCancel={this.closeModal}
    >
      {
        <p>
          {!!this.state.currentTranslation &&
            this.state.currentTranslation.text}
        </p>
      }
    </Modal>
  );
  closeModalReport = () => {
    this.setState({
      reportModalVisible: false,
      sdkModalVisible: false
    });
  };
  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: "bottomLeft"
    });
  };
  saveReportFile = async () => {
    this.setState({
      savingReport: true
    });
    let response = await updateTranslationReport(
      this.state.currentReportEdited.PK_Translation,
      this.state.currentReportFile
    );
    if (response.data === "success") {
      this.setState({
        savingReport: false,
        reportModalVisible: false
      });
      this.getData();
      this.openNotificationWithIcon("info", "Information saved.");
    }
  };

  reportModal = () => (
    <Modal
      title="Translation Report"
      visible={this.state.reportModalVisible}
      onOk={this.saveReportFile}
      onCancel={this.closeModalReport}
      confirmLoading={this.state.savingReport}
    >
      <File
        type="File"
        validation={{ fileRequired: false }}
        title="Report File *"
        onDelete={() => this.deleteFile("currentReportFile")}
        submitFile={file => this.handleFileChange(file, "currentReportFile")}
        value={this.state.currentReportFile}
      />
    </Modal>
  );
  showTranslationContent = element => {
    this.setState({
      currentTranslation: element,
      translationModalVisible: true
    });
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    let response = await getTranslationRequests(this.props.appId);
    this.setState({
      translationRequests: response.data,
      loadingTranslation: false
    });
  };

  closeModal = () => {
    this.setState({
      translationModalVisible: false
    });
  };

  deleteFile = field => {
    this.setState({
      [field]: null
    });
  };

  handleFileChange = ({ name, value }, field) => {
    this.setState({
      [field]: value
    });
  };
  

  translationColumns = () => [
    {
      title: "Date Submitted",
      dataIndex: "date_added",
      defaultSortOrder: "ascend",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => {
        if (a.date_added > b.date_added) return -1;
        else if (a.date_added < b.date_added) return 1;
        return 0;
      },
      render: date_added => moment(date_added).format("YYYY-MM-DD")
    },
    {
      title: "Original Language",
      dataIndex: "original_language"
    },
    {
      title: "Text to Translate",
      dataIndex: "file_url",
      render: (translation_file, element) =>
        translation_file ? (
          <a
            rel="noopener noreferrer"
            href={`https://appinchina.space/download/${translation_file}`}
            target="_blank"
          >
            <Button icon="cloud-download">Download File</Button>
          </a>
        ) : (
          <Button
            icon="eye"
            onClick={() => this.showTranslationContent(element)}
          >
            View Text
          </Button>
        )
    },
    {
      title: "Report",
      dataIndex: "report",
      render: (report, element) => (
        <Button
          onClick={() => this.showReportModal(element)}
          icon="cloud-upload"
        >
          {report ? "View Report" : "Add Report"}
        </Button>
      )
    }
  ];

  showReportModal = element => {
    this.setState({
      reportModalVisible: true,
      currentReportEdited: element,
      currentReportFile: element.report
    });
  };

  render() {
    return (
      <Skeleton
        loading={this.state.loadingTranslation}
        paragraph={{
          width: "100%",
          rows: 3
        }}
      >
        {" "}
        {this.reportModal()}
        {this.translationModal()}
        <Table
          dataSource={this.state.translationRequests}
          columns={this.translationColumns()}
          size="small"
          pagination={{ pageSize: 5 }}
        />
      </Skeleton>
    );
  }
}
