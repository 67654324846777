import React from "react";
import { Table } from "antd";
import functions from "../../../functions";

let columns = [
  {
    title: "ID",
    key: "id",
    dataIndex: "id",
  },
  {
    title: "Date",
    key: "date",
    dataIndex: "date",
    render: (date) => functions.dateFormat(date),
    align: "center",
  },
  {
    title: "Amount",
    key: "amount",
    dataIndex: "amount",
    render: (amount) => "$" + functions.formatMoney(amount),
    align: "right",
  },
];

export default ({ payments, loading }) => (
  <Table
    columns={columns}
    loading={!payments || loading}
    title={() => "Payments Received"}
    dataSource={payments}
    pagination={false}
    scroll={{ y: 260 }}
  />
);
