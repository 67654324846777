import React from 'react'
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";
import DataSet from "@antv/data-set";

export default class Series extends React.Component {
    render() {
        const { data } = this.props
        const ds = new DataSet();
        const dv = ds.createView().source(data);
        const cols = {
            month: {
                range: [0, 200]
            }
        };
        return (
            <div>
                <Chart 
                    height={500} 
                    data={dv} 
                    scale={cols} 
                    forceFit
                    padding={[20, 'auto', 100, 'auto']}
                >
                    <Legend />
                    <Axis name="date" />
                    <Axis
                        name="numberOfLeads"                       
                    />
                    <Tooltip
                        crosshairs={{
                            type: "y"
                        }}
                    />
                    <Geom
                        type="line"
                        position="date*numberOfLeads"
                        size={2}
                        //color={"leads"}
                    />
                    <Geom
                        type="point"
                        position="date*numberOfLeads"
                        size={4}
                        shape={"circle"}
                        //color={"leads"}
                        style={{
                            stroke: "#fff",
                            lineWidth: 1
                        }}
                    />
                </Chart>
            </div>
        );
    }
}
