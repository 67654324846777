import React from "react";
import { Modal, Form, Input, Select } from "antd";
const { Option } = Select;
export default ({
  addDocModal,
  handleSubmitDocument,
  setAddDocModal,
  loadingUpload,
  getFieldDecorator,
  categories,
}) => {
  return (
    <Modal
      title="Add New Document"
      visible={addDocModal}
      onOk={handleSubmitDocument}
      onCancel={() => setAddDocModal(false)}
      okButtonProps={{
        disabled: loadingUpload,
        loading: loadingUpload,
      }}
      cancelButtonProps={{
        disabled: loadingUpload,
        loading: loadingUpload,
      }}
    >
      <Form>
        <Form.Item label="Title">
          {getFieldDecorator("title", {
            rules: [
              {
                required: true,
                message: "Required!",
              },
            ],
          })(<Input />)}
        </Form.Item>
        {categories ? (
          <Form.Item label="Category">
            {getFieldDecorator("doc_category", {
              rules: [{ required: true, message: "Required!" }],
            })(
              <Select>
                {categories.map(({ category, id }) => (
                  <Option key={id} value={id}>
                    {category}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        ) : null}
      </Form>
    </Modal>
  );
};
