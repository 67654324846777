import React, { useState } from "react";
import classes from "./SearchBar.module.css";
import { Input, Icon } from "antd";
import { Link } from "react-router-dom";
import { globalSeach } from "../../services/globalSearch";
import UserImage from "../../assets/defaultUser.png";
import AppImage from "../UI/AppImage/AppImage";

const SearchBar = () => {
  const [usersResults, setUserResults] = useState([]);
  const [appsResults, setAppsResults] = useState([]);
  const [companyResults, setCompanyResults] = useState([]);
  const [focused, setFocus] = useState(false);
  const [searching, setSearching] = useState(false);
  const handleSearch = async (search) => {
    try {
      const isNotBlank = search.trim() !== "";
      if (isNotBlank) {
        setSearching(true);
        const { results } = await globalSeach(search);
        setUserResults(results.users);
        setAppsResults(results.apps);
        setCompanyResults(results.companies);
      } else {
        setUserResults([]);
        setAppsResults([]);
        setCompanyResults([]);
      }
      setSearching(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFocus = () => {
    setFocus(true);
  };

  const getResults = () => {
    let UR = usersResults,
      AR = appsResults,
      CR = companyResults;
    if (focused) {
      return (
        <div className={classes.Results}>
          {AR.length ? (
            <>
              <b className={classes.Title}>Apps</b>
              {AR.map((it, index) => (
                <Link
                  className={classes.Result}
                  key={index}
                  to={"/apps/" + it.id}
                >
                  <AppImage id={it.id} size="small" />
                  <span>
                    <span>{it.name}</span>
                    <span className={classes.Details}>{it.company_name}</span>
                  </span>
                </Link>
              ))}
            </>
          ) : null}
          {CR.length ? (
            <>
              <b className={classes.Title}>Companies</b>
              {CR.map((it, index) => (
                <Link
                  className={classes.Result}
                  key={index}
                  to={"/companies/" + it.id}
                >
                  <img
                    src={"https://appinchina.space/uploads/" + it.logo}
                    alt="Company"
                    title="Company"
                  />
                  <span>
                    <span>{it.name}</span>
                  </span>
                </Link>
              ))}
            </>
          ) : null}
          {UR.length ? (
            <>
              <b className={classes.Title}>Users</b>
              {UR.map((it, index) => (
                <Link
                  className={classes.Result}
                  key={index}
                  to={"/clients/" + it.id}
                >
                  <img src={UserImage} alt="User" title="User" />
                  <span>
                    <span>
                      {it.email.length < 32
                        ? it.email
                        : it.email.substr(0, 32) + "..."}
                    </span>
                    <span className={classes.Details}>{it.name}</span>
                  </span>
                </Link>
              ))}
            </>
          ) : null}
        </div>
      );
    }
  };

  let isFocusedClass = focused ? classes.Focused : "";
  return (
    <div className={[classes.Container, isFocusedClass].join(" ")}>
      <Input
        placeholder="Search..."
        prefix={<Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />}
        onChange={(event) => handleSearch(event.target.value.toLowerCase())}
        onFocus={handleFocus}
        onBlur={() => setTimeout(() => setFocus(false), 200)}
      />
      {getResults()}
      {searching ? <div className={classes.NoResults}>No results.</div> : null}
    </div>
  );
};

export default SearchBar;
