import React, { Component } from "react";
import Axios from "../../axios";
import LineCard from "../UI/LineCard/LineCard";
import Input from "../UI/Input/Input";
import classes from "./SecurityForm.module.css";
import Preloader from "../UI/Preloader/Preloader";
import FilePreview from "../UI/FilePreview/FilePreview";
import { Button, Icon } from "antd";
import DownloadAsZip from "../DownloadZip/downloadZip";

class SecurityForm extends Component {
  state = {
    clientForms: {},
    loading: true
  };

  componentDidMount() {
    Axios.get(
      `/security-assessment-information/${this.props.match.params.id}`
    ).then(result => {
      this.setState({
        clientForms: result.data.security_form,
        loading: false
      });
    });
  }

  render() {
    const { loading, clientForms } = this.state;
    let data =
      !loading && clientForms
        ? {
            id: clientForms.id,
            safety_management: clientForms.safety_management,
            user_identity_verification: clientForms.user_identity_verification,
            users_record_retention: clientForms.users_record_retention,
            users_retention_prevention: clientForms.users_retention_prevention,
            personal_information_protection:
              clientForms.personal_information_protection,
            complaint_report_system: clientForms.complaint_report_system,
            mechanism_legal_supervision: clientForms.mechanism_legal_supervision
          }
        : {};
    return !loading ? (
      data.id ? (
        <div className={classes.linecard}>
          <div style={{ margin: "10px 0px ", display: "flex" }}>
            <Button
              style={{ margin: "0px 10px 0 0" }}
              type="ghost"
              onClick={() => window.history.back()}
            >
              <Icon type="left" />
              Go back
            </Button>
          </div>
          {
            <div>
              <LineCard title="Security Form Information" margin="20px">
                <div className={classes.container}>
                  <Input
                    name="safety_management"
                    placeholder="Question 1"
                    value={data.safety_management}
                    disabled={true}
                    type="textarea"
                  />
                  <Input
                    name="user_identity_verification"
                    placeholder="Question 2"
                    value={data.user_identity_verification}
                    disabled={true}
                    type="textarea"
                  />
                  <Input
                    name="users_record_retention"
                    placeholder="Question 3"
                    value={data.users_record_retention}
                    disabled={true}
                    type="textarea"
                  />
                  <Input
                    name="users_retention_prevention"
                    placeholder="Question 4"
                    value={data.users_retention_prevention}
                    disabled={true}
                    type="textarea"
                  />
                  <Input
                    name="personal_information_protection"
                    placeholder="Question 5"
                    value={data.personal_information_protection}
                    disabled={true}
                    type="textarea"
                  />
                  <Input
                    name="complaint_report_system"
                    placeholder="Question 6"
                    value={data.complaint_report_system}
                    disabled={true}
                    type="textarea"
                  />
                  <Input
                    name="mechanism_legal_supervision"
                    placeholder="Question 7"
                    value={data.mechanism_legal_supervision}
                    disabled={true}
                    type="textarea"
                  />
                </div>
              </LineCard>
            </div>
          }{" "}
        </div>
      ) : (
        <div className={classes.NoResults}>
          <span>
            <b>Information not found</b>
            <Button
              onClick={() => window.history.back()}
              type="Ghost"
              width="100px"
              margin="auto"
            >
              Go back
            </Button>
          </span>
        </div>
      )
    ) : (
      <div className={classes.preloadercontainer}>
        <Preloader size={3} />
      </div>
    );
  }
}

export default SecurityForm;
