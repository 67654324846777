import React from "react";
import { Table, Avatar, Row, Col, Tag, Popover, Button, Modal, message } from "antd";
import classes from "./UserTable.module.less";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
const { confirm } = Modal;
const UserTable = ({ data, app, setApp }) => {
    const ROL = {
        OWNER: <Tag color="blue">Owner</Tag>,
        ADMIN: <Tag color="cyan">Admin</Tag>,
        VIEWER: <Tag color="green">Viewer</Tag>,
        PAYMENTS: <Tag color="purple">Payments Manager</Tag>,
    };
    const deleteAccess = async (val) => {
        let clientsLinked = app.users;
        let filter = clientsLinked.filter((e) => e.id !== val);
        setApp({ ...app, users: [...filter] })
    };
    const viewAsClient = (user) => {
        window.open(
            `${process.env.REACT_APP_CLIENTS_URL}/?jwt=${window.localStorage.id_token
            }&email_to_impersonate=${encodeURIComponent(user.email)}`,
            "_blank"
        );
    };
    const showConfirmDeleteAccess = (user) => {
        confirm({
            title: `Are you sure you want to remove access to ${user.email} ?`,
            onOk() {
                deleteAccess(user.id);
            },
            onCancel() { },
        });
    };
    const columns = [
        {
            title: "Email",
            key: "email",
            dataIndex: "email",
            width: 300,
            render: (_, item) => <Row>
                <Avatar icon="user" /> <Link to={`/clients/${item.id}`}> {item.email}</Link>
            </Row>
        },
        {
            title: "First Name",
            key: "first_name",
            dataIndex: "first_name",
            align: "left",
            width: 150,
        },
        {
            title: "Last Name",
            key: "last_name",
            dataIndex: "last_name",
            align: "left",
            width: 150
        },
        {
            title: "Position",
            key: "position",
            dataIndex: "position",
            align: "left",
            width: 150,
        },
        {
            title: "Role",
            align: "center",
            key: "clients_apps",
            dataIndex: "clients_apps",
            width: 100,
            render: (clients_apps) => ROL[clients_apps.role]
        },
        {
            title: "Actions",
            align: "center",
            key: "id",
            width: 150,
            dataIndex: "id",
            render: (_, item) => {
                return (
                    <Row justify="center" type="flex" gutter={8}>
                        <Col>
                            <Popover content="Remove Access">
                                <Button
                                    size="small"
                                    icon="delete" type="danger"
                                    ghost
                                    onClick={() => showConfirmDeleteAccess(item)}
                                />
                            </Popover>
                        </Col>
                        <Col>
                            <Popover content="View As Client">
                                <Button
                                    size="small"
                                    icon="eye"
                                    type="primary"
                                    ghost
                                    onClick={() => viewAsClient(item)}
                                />
                            </Popover>
                        </Col>
                        <Col>
                            <Popover content="Copy Email">
                                <CopyToClipboard
                                    text={item.email}
                                    onCopy={() => message.success("Copied!")}
                                >
                                    <Button
                                        size="small"
                                        icon="copy" />
                                </CopyToClipboard>
                            </Popover>
                        </Col>
                    </Row>
                )
            }

        }
    ]
    return <div className={classes.container}>
        <Table size="small" scroll={{ x: 900 }} dataSource={data} columns={columns} pagination={{ pageSize: 5 }} />
    </div>

}

export default UserTable