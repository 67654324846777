import React, { useState } from "react";
import { Modal, Form, Button, Row, Input } from "antd";
import File from "../../../UI/File/File";
import { newAddendumFile } from "../../../../services/localizationService";
export default Form.create({ name: "newAddendum" })(
  ({ form, update, addendums, app_id, ...props }) => {
    let [savingAddendum, setSaving] = useState(false);
    const handleSubmit = () => {
      form.validateFields((err, values) => {
        if (!err) {
          makeRequest(values);
        }
      });
    };
    const makeRequest = async (values) => {
      setSaving(true);
      let { data } = await newAddendumFile({
        values,
        app_id,
      });
      setSaving(false);
      if (data && data.PK_Addendum) {
        update([...addendums, data]);
      }
      props.close();
      form.resetFields();
    };
    const { getFieldDecorator } = form;
    return (
      <div>
        <Modal
          title="New Contract"
          visible={props.visible}
          footer={null}
          onCancel={props.close}
          confirmLoading={savingAddendum}
        >
          <Form hideRequiredMark={true}>
            <Form.Item label="Contract File">
              {getFieldDecorator(`file_url`, {
                rules: [{ required: true, message: "Required" }],
              })(
                <File
                  type="File"
                  validation={{ fileRequired: true }}
                  title="Addendum File *"
                  onDelete={() => {
                    form.setFieldsValue({ file_url: null });
                    form.setFieldsValue({ file_name: null });
                  }}
                  submitFile={(file) => {
                    form.setFieldsValue({ file_url: file.value });
                    form.setFieldsValue({ file_name: file.name });
                  }}
                />
              )}
            </Form.Item>
            <Form.Item label="File Name">
              {getFieldDecorator(`file_name`, {
                rules: [{ required: true, message: "Required" }],
              })(<Input disabled={true} />)}
            </Form.Item>
            <Form.Item label="Comments">
              {getFieldDecorator(`comments`, {
                rules: [{ required: true, message: "Required" }],
              })(<Input.TextArea />)}
            </Form.Item>
            <Row type="flex" justify="center" align="middle">
              <Button
                loading={savingAddendum}
                disable={savingAddendum}
                type="primary"
                onClick={() => handleSubmit()}
                style={{ marginTop: 10 }}
              >
                Save Contract
              </Button>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
);
