import React from "react";
import { Form, Row, Col, Input } from "antd";
import LineCard from "../../../UI/LineCard/LineCard";
import File from "../../../UI/ClientsFile/File";
import classes from "./PrivacyPolicy.module.less";
import Badge from "../../../UI/BadgeNew/BadgeNew";

const { Item } = Form;

export default ({
  getFieldDecorator,
  isRequired,
  disabled = false,
  data = {},
  submitFileHandler,
  deleteFile,
  modified,
}) => {
  return (
    <>
      <LineCard
        className={classes.container}
        title="Privacy Policy"
        style={{ marginTop: 15 }}
        shadow
      >
        <Row>
          <Col span={24}>
            <Item label="Please provide a link to your privacy policy, in Chinese if available.">
              <Badge enabled={modified.includes("privacy_policy")}>
                {getFieldDecorator(`privacy_policy`, {
                  initialValue: data.privacy_policy,
                  rules: [{ required: true, message: "Required" }],
                })(<Input disabled={disabled} />)}
              </Badge>
            </Item>
          </Col>
          <p>
            Tencent app store requires proof that your app complies with data
            privacy laws. Please submit two screenshots for confirmation,
            following these examples:
          </p>
          <Row gutter={16} type="flex" justify="space-around">
            <Col sm={24} xl={12}>
              <p>
                1. This screenshot must show the pop-up message for user
                confirmation of the privacy policy. In JPG or PNG format.
              </p>
              <Item>
                <Badge enabled={modified.includes("privacy_screenshot_1")}>
                  {getFieldDecorator(`privacy_screenshot_1`, {
                    initialValue: data.privacy_screenshot_1,
                    rules: [{ required: isRequired, message: "Required" }],
                  })(
                    <File
                      name="privacy_screenshot_1"
                      type="Image"
                      title="Screenshot 1 *"
                      validation={{
                        fileRequired: isRequired,
                        size: 1000000,
                      }}
                      disabled={disabled}
                      onDelete={() => deleteFile("privacy_screenshot_1")}
                      format={["png", "jpg", "jpeg"]}
                      submitFile={submitFileHandler}
                    />
                  )}
                </Badge>
              </Item>
            </Col>
            <Col sm={24} xl={12}>
              <p>
                2. This screenshot must show the permanent links to your privacy
                policy and terms of service within the app itself.
              </p>
              <Item>
                <Badge enabled={modified.includes("privacy_screenshot_2")}>
                  {getFieldDecorator(`privacy_screenshot_2`, {
                    initialValue: data.privacy_screenshot_2,
                    rules: [{ required: isRequired, message: "Required" }],
                  })(
                    <File
                      name="privacy_screenshot_2"
                      type="Image"
                      title="Screenshot 2 *"
                      validation={{
                        fileRequired: isRequired,
                        size: 1000000,
                      }}
                      disabled={disabled}
                      onDelete={() => deleteFile("privacy_screenshot_2")}
                      format={["png", "jpg", "jpeg"]}
                      submitFile={submitFileHandler}
                    />
                  )}
                </Badge>
              </Item>
            </Col>
          </Row>
        </Row>
      </LineCard>
      <br />
      <LineCard
        title="Privacy Policy (Detailed)"
        className={classes.container}
        shadow
      >
        <p>
          Tencent requires a file with the full text of the privacy policy which
          should cover the entire privacy policy of your app. If this file is
          not provided the app won't be accepted by the store.
        </p>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Item>
              <Badge enabled={modified.includes("long_privacy_screenshot")}>
                {getFieldDecorator(`long_privacy_screenshot`, {
                  rules: [
                    {
                      required: true,
                      message: "Required",
                    },
                  ],
                  initialValue: data.long_privacy_screenshot,
                })(
                  <File
                    type="File"
                    name="long_privacy_screenshot"
                    validation={{
                      fileRequired: true,
                      size: 20971520,
                    }}
                    title="File *"
                    disabled={disabled}
                    onDelete={() => deleteFile("long_privacy_screenshot")}
                    submitFile={submitFileHandler}
                  />
                )}
              </Badge>
            </Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className={classes.requirements}>
              <b>Requirements:</b>
              <ol>
                <li>
                  The content of the privacy policy must be consistent with the
                  content of the privacy policy agreement in the APP.
                </li>
                <li>
                  If the privacy policy agreement contains a link, please make
                  sure you provide it to us.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
      </LineCard>{" "}
      <br />
      <LineCard
        title="Business License Requirements"
        className={classes.container}
        shadow
      >
        <p>
          Tencent now requires the photocopy of your business license to have
          the Chinese sentence "用于腾讯移动开放平台开发者授权资质使用+Date"
          handwritten on it, this requirement applies to all clients who wish to
          publish their app on Tencent.
        </p>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Item>
              {getFieldDecorator(`business_license_chinese`, {
                rules: [
                  {
                    required: false,
                    message: "Required",
                  },
                ],
                initialValue: data.business_license_chinese,
              })(
                <File
                  type="File"
                  name="business_license_chinese"
                  validation={{
                    fileRequired: false,
                    size: 20971520,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  title="File *"
                  disabled={disabled}
                  onDelete={() => deleteFile("business_license_chinese")}
                  submitFile={submitFileHandler}
                />
              )}
            </Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className={classes.requirements}>
              <b>Notes:</b>
              <ol>
                <li>
                  Please HANDWRITE the Chinese sentence{" "}
                  <b>"用于腾讯移动开放平台开发者授权资质使用+Date"</b> on a copy
                  of business license, stamp, scan, then upload. The Business
                  stamp must cover part of the handwritten sentence
                </li>
                <li>
                  The Chinese sentence means{" "}
                  <b>
                    "It is used for Tencent mobile open platform developer's
                    authorization review purpose"
                  </b>
                </li>
                <li>
                  If you have difficult to write the Chinese sentence, please
                  contact your account manager for assistant
                </li>
              </ol>
            </div>
          </Col>
        </Row>
      </LineCard>
    </>
  );
};
