import React from "react";
import {
  Form,
  Input,
  Radio,
  Switch,
  Select,
  DatePicker,
  InputNumber,
} from "antd";
import classes from "./SalesSection.module.less";
import { APP_DETAILS_OPTIONS } from "../../../constants";
import moment from "moment";
import File from "../../UI/File/File";
import ServiceTags from "./ServiceTags/ServiceTags";
const { Item } = Form;
const { Option } = Select;
const SalesSection = ({
  app,
  loading,
  updateApp,
  getFieldDecorator,
  onDelete,
  handleAppStatus,
  handleFileChange,
}) => {
  const switch_label =
    app.main_information.account_status === "1" ||
    app.main_information.account_status === true
      ? "App Active"
      : "App Inactive";

  const addAgreementFile = () => {
    const stateCopy = { ...app };
    const length = stateCopy.main_information.agreement_file.length;
    if (length === 0) {
      stateCopy.main_information.agreement_file = [""];
      updateApp(stateCopy);
    } else if (
      length > 0 &&
      stateCopy.main_information.agreement_file[length - 1] !== ""
    ) {
      stateCopy.main_information.agreement_file = [
        ...stateCopy.main_information.agreement_file,
        "",
      ];

      //  console.log("stateCopy", stateCopy);
      updateApp(stateCopy);
    }
  };
  const addServiceTag = (values) => {
    const stateCopy = { ...app };
    let newTags = values.join(",");
    stateCopy.main_information.service_tags = newTags;
    updateApp(stateCopy);
  };
  return (
    <div className={classes.container}>
      <Item label={`${switch_label}`} style={{ margin: 0, textAlign: "left" }}>
        {getFieldDecorator(`app.main_information.account_status`, {
          valuePropName: "checked",
          initialValue:
            app.main_information.account_status === "1" ||
            app.main_information.account_status === true
              ? true
              : false,
        })(<Switch />)}
      </Item>
      <Item label="App Status" style={{ margin: 0, width: "100%" }}>
        {getFieldDecorator(`app.main_information.completion`, {
          initialValue: app.main_information.completion,
          onChange: (value) => {
            handleAppStatus(value, "completion");
          },
        })(
          <Select style={{ width: "100%" }}>
            {APP_DETAILS_OPTIONS.completion.map((it) => (
              <Option key={it.value} value={it.value} label={it.label}>
                {it.label}
              </Option>
            ))}
          </Select>
        )}
      </Item>
      <Item label="App Type" style={{ margin: 0, width: "100%" }}>
        {getFieldDecorator(`app.main_information.app_type`, {
          initialValue: app.main_information.app_type,
        })(
          <Select style={{ width: "100%" }}>
            {APP_DETAILS_OPTIONS.app_type.map((it) => (
              <Option key={it.value} value={it.value} label={it.label}>
                {it.label}
              </Option>
            ))}
          </Select>
        )}
      </Item>
      <Item label="Next Payment Date" style={{ margin: 0, width: "100%" }}>
        {getFieldDecorator(`app.main_information.next_payment_date`, {
          initialValue: app.main_information.next_payment_date
            ? moment(app.main_information.next_payment_date)
            : null,
        })(<DatePicker format={"DD.MM.YYYY"} style={{ width: "100%" }} />)}
      </Item>
      <Item label="Renewal Date" style={{ margin: 0, width: "100%" }}>
        {getFieldDecorator(`app.main_information.renewal_date`, {
          initialValue: app.main_information.renewal_date
            ? moment(app.main_information.renewal_date)
            : null,
        })(<DatePicker format={"DD.MM.YYYY"} style={{ width: "100%" }} />)}
      </Item>
      <Item label="NDA" style={{ margin: 0, textAlign: "left" }}>
        {getFieldDecorator(`app.main_information.confidential`, {
          initialValue: app.main_information.confidential,
        })(
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        )}
      </Item>
      <Item label="Company Name" style={{ margin: 0, width: "100%" }}>
        {getFieldDecorator(`app.main_information.company_name`, {
          initialValue: app.main_information.company_name,
        })(<Input />)}
      </Item>
      <Item label="Revenue Model" style={{ margin: 0 }}>
        {getFieldDecorator(`app.main_information.revenue_model`, {
          initialValue: app.main_information.revenue_model,
        })(
          <Select mode="multiple">
            {APP_DETAILS_OPTIONS.REVENUE_MODEL.map((it) => (
              <Option key={it.value} value={it.value} label={it.label}>
                {it.label}
              </Option>
            ))}
          </Select>
        )}
      </Item>
      <Item label="Contract Value (USD)" style={{ margin: 0, width: "100%" }}>
        {getFieldDecorator(`app.main_information.contract_value`, {
          initialValue: app.main_information.contract_value,
        })(<Input />)}
      </Item>
      <Item
        label="Contract Anniversary (mm-dd)"
        style={{ margin: 0, width: "100%" }}
      >
        {getFieldDecorator(`app.main_information.contract_aniversary`, {
          rules: [
            {
              pattern: /^\d{2}[\//-]\d{2}$/,
              message: "Invalid Format",
            },
          ],
          initialValue: app.main_information.contract_aniversary,
        })(<Input />)}
      </Item>
      <Item label="Contract Start Date" style={{ margin: 0, width: "100%" }}>
        {getFieldDecorator(`app.main_information.contract_signed_date`, {
          initialValue: app.main_information.contract_signed_date
            ? moment(app.main_information.contract_signed_date)
            : null,
        })(<DatePicker format={"DD.MM.YYYY"} style={{ width: "100%" }} />)}
      </Item>
      <Item
        label="Contract Termination Date"
        style={{ margin: 0, width: "100%" }}
      >
        {getFieldDecorator(`app.main_information.contract_termination_date`, {
          initialValue: app.main_information.contract_termination_date
            ? moment(app.main_information.contract_termination_date)
            : null,
        })(<DatePicker format={"DD.MM.YYYY"} style={{ width: "100%" }} />)}
      </Item>
      <Item label="Invoice Link" style={{ margin: 0, width: "100%" }}>
        {getFieldDecorator(`app.main_information.invoice_link`, {
          initialValue: app.main_information.invoice_link,
        })(<Input />)}
      </Item>
      <Item
        label="Translation Words Included"
        style={{ margin: 0, width: "100%" }}
      >
        {getFieldDecorator(`app.main_information.translation_words_included`, {
          initialValue: app.main_information.translation_words_included,
        })(<InputNumber />)}
      </Item>
      <Item label="Sales Note" style={{ margin: 0, width: "100%" }}>
        {getFieldDecorator(`app.main_information.sales_note`, {
          initialValue: app.main_information.sales_note,
        })(<Input.TextArea rows={2} />)}
      </Item>
      <Item label="SCC Owner" style={{ margin: 0, width: "100%" }}>
        {getFieldDecorator(`app.main_information.scc_owner`, {
          initialValue: app.main_information.scc_owner,
        })(
          <Select style={{ width: "100%" }}>
            {APP_DETAILS_OPTIONS.SCC_owner.map((it) => (
              <Option key={it.value} value={it.value} label={it.label}>
                {it.label}
              </Option>
            ))}
          </Select>
        )}
      </Item>
      <Item label="App Store Accounts" style={{ margin: 0, width: "100%" }}>
        {getFieldDecorator(`app.main_information.app_store_accounts`, {
          initialValue: app.main_information.app_store_accounts,
        })(
          <Select style={{ width: "100%" }}>
            {APP_DETAILS_OPTIONS.app_store_accounts.map((it) => (
              <Option key={it.value} value={it.value} label={it.label}>
                {it.label}
              </Option>
            ))}
          </Select>
        )}
      </Item>
      <Item label="Service Tags" style={{ margin: 0, width: "100%" }}>
        {getFieldDecorator(`app.main_information.service_tags`, {
          initialValue: app.main_information.service_tags,
        })(
          <ServiceTags
            initialValue={app.main_information.service_tags}
            addServiceTag={addServiceTag}
          />
        )}
      </Item>
    </div>
  );
};

export default SalesSection;
