import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classes from "./OperationsKPI.module.less";
import { DatePicker, Row, Button, Table, Card, Tooltip, Icon } from "antd";
import ActionsPerDay from "./ActionsPerDay/ActionsPerDay";
import { getLastMonthDates } from "../../functions";
import { getKPI, getKPIChart } from "../../services/kpiService";
import UpdatesCompleted from "./UpdatesCompletedByMonth/UpdatesCompleted";
import queryString from "query-string";
import moment from "moment";
const { RangePicker } = DatePicker;
export default ({ ...props }) => {
  let [loading, setLoading] = useState(false);
  let [loadingChart, setLoadingChart] = useState(false);
  let [table, setTable] = useState([]);
  let [chart, setChart] = useState([]);
  let [dateRange, setDateRange] = useState(null);
  let [updatesPerMonth, setUpdatesPerMonth] = useState([]);

  const columns = [
    {
      key: "manager",
      dataIndex: "manager",
      title: "Member",
      fixed: "left",
      width: "100px",
    },
    {
      key: "tasksCompleted",
      dataIndex: "tasksCompleted",
      title: (
        <div className={classes.headers}>
          <Tooltip
            placement="rightTop"
            title="Defined as when all stores for a version have a ‘Published’ status,
            or if the version does not have published, but there’s a higher
            version, then the date of completion for that version is the date of
            the new version submission."
          >
            <p>
              Number of Tasks Completed <Icon type="info-circle" />
            </p>
          </Tooltip>
        </div>
      ),
      align: "right",
    },
    {
      key: "tasksSubmitted",
      dataIndex: "tasksSubmitted",
      title: "Tasks Submitted by Clients",
      align: "right",
    },
    {
      key: "updatesSubmitted",
      dataIndex: "updatesSubmitted",
      title: "Number of Updates Submitted by Clients",
      align: "right",
    },
    {
      key: "updatesCompleted",
      dataIndex: "updatesCompleted",
      title: "Number of Updates Completed",
      align: "right",
      render: (updatesCompleted, item) =>
        item.manager === "TEAM" ? updatesCompleted / 4 : updatesCompleted,
    },
    {
      key: "totalRate",
      dataIndex: "totalRate",
      title: (
        <div className={classes.headers}>
          <Tooltip
            placement="rightTop"
            title="At the time of completion, record the total store success rate. This
            is only calculating for updates that were completed in that period."
          >
            <p>
              Total Stores Success Rate <Icon type="info-circle" />
            </p>
          </Tooltip>
        </div>
      ),
      align: "right",
    },
    {
      key: "ratio",
      dataIndex: "ratio",
      title: (
        <div className={classes.headers}>
          <Tooltip
            placement="rightTop"
            title="For a completed version, take a proportion of  the total number of rejected actions / by published actions."
          >
            <p>
              Rejection to Published Ratio (%) <Icon type="info-circle" />
            </p>
          </Tooltip>
        </div>
      ),
      align: "right",
    },
    {
      key: "totalTime",
      dataIndex: "totalTime",
      title: (
        <div className={classes.headers}>
          <Tooltip
            placement="rightTop"
            title="Time from when client submits the app to when status becomes published (the latest date), or if the update was not complete, it uses the newest version of submission date as the end date for the previous version."
          >
            <p>
              Average time to complete an update (Days){" "}
              <Icon type="info-circle" />
            </p>
          </Tooltip>
        </div>
      ),
      align: "right",
    },
    {
      key: "manager",
      dataIndex: "manager",
      title: "",
      render: (manager) =>
        manager !== "TEAM" ? (
          <Link
            to={{
              pathname: "/kpi/manager/" + manager,
              search: `?start=${dateRange[0].format(
                "YYYY-MM-DD"
              )}&end=${dateRange[1].format("YYYY-MM-DD")}`,
            }}
            className={classes.link}
          >
            Details
          </Link>
        ) : (
          ""
        ),
    },
  ];
  const getData = async (date) => {
    setLoading(true);
    let { data } = await getKPI({
      start: date[0].format("YYYY-MM-DD"),
      end: date[1].format("YYYY-MM-DD"),
    });
    setLoading(false);
    if (data && data.table) {
      setTable(data.table);
      setUpdatesPerMonth(data.updatesPerMonth);
    }
  };

  const getDataChart = async (date) => {
    setLoadingChart(true);
    let { data } = await getKPIChart({
      start: date[0].format("YYYY-MM-DD"),
      end: date[1].format("YYYY-MM-DD"),
    });
    setLoadingChart(false);
    if (data && data.chart) {
      setChart(data.chart);
    }
  };
  const queryDate = () => {
    let url = props.location.search;
    let params = queryString.parse(url);
    let date =
      params && params.start && params.end
        ? [moment(params.start, "YYYY-MM-DD"), moment(params.end, "YYYY-MM-DD")]
        : getLastMonthDates();
    setDateRange(date);
    getData(date);
    getDataChart(date);
  };

  useEffect(() => {
    queryDate();
  }, [props.location]);

  let assignDateToURL = (value) => {
    props.history.replace({
      pathname: `/kpi/overall/`,
      search: `?start=${value[0].format("YYYY-MM-DD")}&end=${value[1].format(
        "YYYY-MM-DD"
      )}`,
    });
  };

  return (
    <div className={classes.container}>
      <h3>Operations KPI</h3>
      <Row type="flex" justify="start">
        <div className={classes.datePicker}>
          {dateRange ? (
            <RangePicker
              value={[dateRange[0], dateRange[1]]}
              onChange={(value) => assignDateToURL(value)}
              disabled={loading}
              loading={loading}
              format="YYYY-MM-DD"
            />
          ) : null}
        </div>
        <Button
          loading={loading}
          onClick={() => setDateRange(getLastMonthDates())}
          type="primary"
        >
          Reset
        </Button>
      </Row>
      <Table
        dataSource={table}
        loading={loading}
        size="small"
        pagination={false}
        columns={columns}
        scroll={{ x: 1000 }}
        rowKey={(record) => record.manager}
      />
      <Card loading={loadingChart} size="small" title="Actions Per Day">
        <ActionsPerDay data={chart} />
      </Card>
      <UpdatesCompleted updatesPerMonth={updatesPerMonth} loading={loading} />
      {/*<Card loading={loading} size="small" title="Total Actions">
        <TotalTasks data={table} />
          </Card>*/}
    </div>
  );
};
