import React, { useState, useEffect } from "react";
import { Table, Button, Row } from "antd";
import moment from "moment";
import AddNewModal from "./AddNewModal/AddNewModal";
import ContractActions from "./ContractActions/ContractActions";

export default ({ app }) => {
  let [data, setData] = useState([]);
  let [newModal, setNewModal] = useState(false);
  let colums = [
    {
      key: "createdAt",
      dataIndex: "createdAt",
      title: "Upload Date",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      key: "file_name",
      dataIndex: "file_name",
      title: "File Name",
      align: "left",
    },
    {
      key: "comments",
      dataIndex: "comments",
      title: "Comments",
      align: "left",
    },
    {
      key: "file_url",
      dataIndex: "file_url",
      title: "",
      width: "200px",
      render: (_, item) => (
        <ContractActions item={{ ...item }} data={data} setData={setData} />
      ),
    },
  ];
  useEffect(() => {
    setData(app.addendum_files);
  }, []);

  return (
    <div>
      <Table
        pagination={{ pageSize: 10 }}
        size="small"
        columns={colums}
        dataSource={data}
        scroll={{ x: "700px" }}
      />
      <Row type="flex" justify="end" style={{ marginTop: 10 }}>
        <Button onClick={() => setNewModal(true)} type="primary" icon="plus">
          Add New
        </Button>
        <AddNewModal
          update={setData}
          app_id={app.id}
          addendums={data}
          visible={newModal}
          close={() => setNewModal(false)}
        />
      </Row>
    </div>
  );
};
