import React, { useState, useEffect } from "react";
import { Card } from "antd";
import { getAvgUsage } from "../../../services/welcomePageService";
import MultilineChart from "./MultiLineChart";

export default () => {
  let [loading, setLoading] = useState(true);
  let [chart, setChart] = useState([]);
  const getData = async () => {
    let { data } = await getAvgUsage();
    setLoading(false);
    if (data) {
      let format = [];
      Object.entries(data).map((item) => {
        Object.entries(item[1]).map(([key, value]) => {
          format.push({
            user: item[0],
            date: key,
            duration: +value.toFixed(2),
          });
        });
      });
      setChart(format);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Card title="Dashboard Usage" loading={loading}>
      <MultilineChart chart={chart} />
    </Card>
  );
};
