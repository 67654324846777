import Axios from "../axios";

export async function getAllFeedbacks(){
    let response = null;
  try {
    response = await Axios.get("/feedbacks");
  } catch (error) {
    console.log("error", error);
  }
  return response;
}