import React, { useState } from "react";
import { Modal, Button, Select, Form, Row, Tag } from "antd";
import { ROLES } from "../../../../constants";
import AsyncSearchUser from "../../../UI/AsyncSearch/AsyncSearch";
const { Option } = Select;
export default Form.create({ name: "newClientApp" })(
  ({ form, appForm, app, setApp, ...props }) => {
    const [loading, setLoading] = useState(false);
    const { getFieldDecorator } = form;
    const makeRequest = ({ user, role }) => {
      setLoading(true);
      setApp({
        ...app, users: [...app.users, {
          id: user.key,
          email: user.label,
          clients_apps: { role },
        }]
      })
      form.resetFields();
      props.close();
      setLoading(false);
    };
    const handleSubmitUser = () => {
      form.validateFields((err, values) => {
        if (!err) {
          console.log("no error", values)
          makeRequest(values);
        }
        else {
          console.log("error :c")
        }
      });
    };

    return (
      <Modal
        width={500}
        title="New Client Linked"
        visible={props.display}
        onCancel={props.close}
        footer={null}
      >
        <div>
          <h2>ROLES:</h2>
          <p>
            <Tag color="blue">Owner</Tag> Provides full access to the account.
            Owners can invite new users, edit information and submit updates.
          </p>
          <p>
            <Tag color="cyan">Admin</Tag> Provides full access to the account.
            Admins can edit information and submit updates but they can not
            invite new users.
          </p>
          <p>
            <Tag color="green">Viewer</Tag> The user can view all data in the
            account but can not make any changes.
          </p>
          <p>
            <Tag color="purple">Payments Manager</Tag> The user can interact
            exclusively with the AppInChina Pay data.
          </p>
        </div>
        <Form hideRequiredMark={true}>
          <Form.Item label="User">
            {getFieldDecorator(`user`, {
              rules: [{ required: props.display, message: "Required" }],
            })(
              <AsyncSearchUser
                target="users"
                placeholder="Select an user..."
                change={(value) => form.setFieldsValue({ user: value })}
                labelInValue={true}
              />
            )}
          </Form.Item>
          <Form.Item label="Role">
            {getFieldDecorator(`role`, {
              rules: [{ required: props.display, message: "Required" }],
              initialValue: "OWNER",
            })(
              <Select style={{ width: "100%" }}>
                {ROLES.map(({ value, label }) => (
                  <Option key={value} value={value} label={label}>
                    {label}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Row type="flex" justify="center" align="middle">
            <Button
              loading={loading}
              disable={loading}
              type="primary"
              onClick={() => handleSubmitUser()}
              style={{ marginTop: 10 }}
            >
              Add
            </Button>
          </Row>
        </Form>
      </Modal>
    );
  }
);
