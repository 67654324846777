import tencent from "./assets/stores/tencent.png";
import _360 from "./assets/stores/360.png";
import _2345 from "./assets/stores/2345.png";
import anzhi from "./assets/stores/anzhi.png";
import appchina from "./assets/stores/appchina.png";
import baidu from "./assets/stores/baidu.png";
import lenovo from "./assets/stores/lenovo.png";
import meizu from "./assets/stores/meizu.jpeg";
import miui from "./assets/stores/miui.png";
import mm from "./assets/stores/mm.png";
import pp from "./assets/stores/pp.png";
import sogou from "./assets/stores/sogou.png";
import wandoujia from "./assets/stores/wandoujia.png";
import huawei from "./assets/stores/huawei.png";
const AICON_STORES = [
  {
    store: "tencent",
    name: "Tencent MyApp",
    url: "http://android.myapp.com/",
    scrapeable: true,
    results: {},
    loading: true,
    market_coverage: 26.03,
    icon: tencent,
  },
  {
    store: "huawei",
    name: "Huawei App Market",
    url: "http://appstore.huawei.com/",
    scrapeable: true,
    market_coverage: 12.76,
    icon: huawei,
  },
  {
    store: "oppo",
    name: "Oppo Software Store",
    url: "https://store.oppomobile.com/",
    scrapeable: false,
    market_coverage: 12.35,
  },
  {
    store: "360",
    name: "360 Mobile Assistant",
    url: "http://zhushou.360.cn/",
    scrapeable: true,
    results: {},
    loading: true,
    market_coverage: 9.71,
    icon: _360,
  },
  {
    store: "baidu",
    name: "Baidu Mobile Assistant",
    url: "http://as.baidu.com",
    scrapeable: true,
    results: {},
    loading: true,
    market_coverage: 9.15,
    icon: baidu,
  },
  {
    store: "xiaomi",
    name: "MIUI App Store",
    url: "http://app.xiaomi.com",
    scrapeable: true,
    results: {},
    loading: true,
    market_coverage: 8.71,
    icon: miui,
  },
  {
    store: "vivo",
    name: "Vivo App Store",
    url: "https://dev.vivo.com.cn/doc/products/pc/index.html",
    scrapeable: false,
    market_coverage: 7.34,
  },
  {
    store: "pp",
    name: "PP Assistant",
    url: "https://www.25pp.com/android/",
    scrapeable: true,
    results: {},
    loading: true,
    market_coverage: 2.57,
    icon: pp,
  },
  {
    store: "mm",
    name: "China Mobile MM Store",
    url: "http://mm.10086.cn/",
    scrapeable: true,
    results: {},
    loading: true,
    market_coverage: 2.02,
    icon: mm,
  },
  {
    store: "anzhi",
    name: "Anzhi Market",
    url: "http://www.anzhi.com/applist.html",
    scrapeable: true,
    results: {},
    loading: true,
    market_coverage: 1.82,
    icon: anzhi,
  },
  {
    store: "sogou",
    name: "Sogou Mobile Assistant",
    url: "http://zhushou.sogou.com/",
    scrapeable: true,
    results: {},
    loading: true,
    market_coverage: 1.5,
    icon: sogou,
  },
  {
    store: "wandoujia",
    name: "Wandoujia",
    url: "http://www.wandoujia.com/apps",
    scrapeable: true,
    results: {},
    loading: true,
    market_coverage: 1.24,
    icon: wandoujia,
  },
  {
    store: "himarket",
    name: "HiMarket",
    url: "http://apk.hiapk.com/",
    scrapeable: false,
    market_coverage: 1.22,
  },
  {
    store: "meizu",
    name: "Meizu Flyme",
    url: "http://app.meizu.com/",
    scrapeable: true,
    results: {},
    loading: true,
    market_coverage: 0.7,
    icon: meizu,
  },
  {
    store: "coolpad",
    name: "Coolpad",
    url: "http://www.coolmart.net.cn/#app",
    scrapeable: false,
    market_coverage: 0.66,
  },
  {
    store: "lenovo",
    name: "Lenovo Le Store",
    url: "http://www.lenovomm.com/index_app.html",
    scrapeable: true,
    results: {},
    loading: true,
    market_coverage: 0.58,
    icon: lenovo,
  },
  {
    store: "samsung",
    name: "Samsung App Store",
    url: "https://www.samsung.com/my/apps/galaxy-apps/",
    scrapeable: false,
    market_coverage: 0.45,
  },
  {
    store: "zte",
    name: "ZTE App Store",
    url: "http://apps.ztems.com",
    scrapeable: false,
    market_coverage: 0.37,
  },
  {
    store: "chinaunicom",
    name: "China Unicom Wo Store",
    url: "http://store.wo.com.cn",
    scrapeable: false,
    market_coverage: 0.37,
  },
  {
    store: "ct",
    name: "China Telecom 189 Store",
    url: "https://www.189store.com/index/toMkt?type=1",
    scrapeable: false,
    market_coverage: 0.16,
  },
  {
    store: "appchina",
    name: "AppChina",
    url: "http://www.appchina.com/soft",
    scrapeable: true,
    results: {},
    loading: true,
    market_coverage: 0.12,
    icon: appchina,
  },
  {
    store: "91",
    name: "91 Assistant",
    url: "http://www.91.com/",
    scrapeable: false,
    market_coverage: 0.06,
  },
  {
    store: "google",
    name: "Google Play",
    url: "http://play.google.com/store",
    scrapeable: false,
    market_coverage: 0.04,
  },
  {
    store: "2345",
    name: "2345",
    url: "http://zhushou.2345.com",
    scrapeable: true,
    results: {},
    loading: true,
    market_coverage: 0.0,
    icon: _2345,
  },
];

const APP_DETAILS_OPTIONS = {
  business_form_status: [
    { value: "NOT_RECEIVED", label: "Not Received" },
    { value: "RECEIVED", label: "Received" },
    { value: "APPROVED", label: "Approved" },
    { value: "ISSUE", label: "Issue" },
  ],
  app_type: [
    { value: "APP", label: "App" },
    { value: "GAME_FREE", label: "Game (Free)" },
    { value: "GAME_IAP", label: "Game (IAP)" },
  ],
  app_form_status: [
    { value: "NOT_RECEIVED", label: "Not Received" },
    { value: "RECEIVED", label: "Received" },
    { value: "APPROVED", label: "Approved" },
    { value: "ISSUE", label: "Issue" },
  ],
  security_form_status: [
    { value: "NOT_RECEIVED", label: "Not Received" },
    { value: "RECEIVED", label: "Received" },
    { value: "APPROVED", label: "Approved" },
    { value: "ISSUE", label: "Issue" },
  ],
  copyright_form_status: [
    { value: "WAITING", label: "Waiting for SCC information" },
    { value: "SUBMITTED", label: "Online application submitted" },
    {
      value: "PREPARING_DOCUMENTS",
      label: "Preparing for stamped documents by clients",
    },
    { value: "DOCUMENTS_SENT", label: "Documents sent to agent" },
    { value: "APPROVED", label: "SCC approved" },
    { value: "DEREGISTERED", label: "SCC deregistered" },
  ],
  SCC_status: [
    { value: "NA", label: "N/A" },
    { value: "Aplied", label: "Applied" },
    { value: "NOT_APPLIED", label: "Not  Applied" },
    { value: "DONE", label: "Done" },
  ],
  SCC_owner: [
    { value: "NA", label: "N/A" },
    { value: "AIC", label: "Jiayou" },
    { value: "ZHENCHUANG", label: "Zhenchuang" },
    { value: "SINNOGROW", label: "Sinnogrow" },
    { value: "CLIENT_CC", label: "Client (Chinese Company)" },
    { value: "CLIENT_OC", label: "Client (Overseas Company)" },
  ],
  app_store_accounts: [
    { value: "AIC", label: "Jiayou" },
    { value: "ZHENCHUANG", label: "Zhenchuang" },
    { value: "SINNOGROW", label: "Sinnogrow" },
    { value: "CLIENT_CC", label: "Client (Chinese Company)" },
    { value: "CLIENT_OC", label: "Client (Overseas Company)" },
  ],
  wechat_integration: [
    { value: "NA", label: "N/A" },
    { value: "NEEDED", label: "Needed" },
    { value: "IN_PROGRESS", label: "In Progress" },
    { value: "ISSUE", label: "Issue" },
    { value: "DONE", label: "Done" },
  ],
  alipay_integration: [
    { value: "NA", label: "N/A" },
    { value: "NEEDED", label: "Needed" },
    { value: "IN_PROGRESS", label: "In Progress" },
    { value: "DONE", label: "Done" },
  ],
  testing: [
    { value: "NA", label: "N/A" },
    { value: "NOT STARTED", label: "Not Started" },
    { value: "ISSUE", label: "Issue" },
    { value: "DONE", label: "Done" },
  ],
  wechat_owner: [
    { value: "NA", label: "N/A" },
    { value: "AIC", label: "AIC" },
    { value: "Client", label: "Client" },
  ],
  alipay_owner: [
    { value: "NA", label: "N/A" },
    { value: "AIC", label: "AIC" },
    { value: "Client", label: "Client" },
  ],
  targeted_store: [
    { value: "NA", label: "N/A" },
    { value: "AIC", label: "AIC" },
    { value: "CLIENT_M", label: "Client (Mainland)" },
    { value: "CLIENT_HK", label: "Client (HK COmpany)" },
  ],
  stores_to_upload: [
    { value: 0, label: "Not set" },
    { value: 1, label: "1 store" },
    { value: 2, label: "2 stores" },
    { value: 3, label: "3 stores" },
    { value: 4, label: "4 stores" },
    { value: 5, label: "5 stores" },
    { value: 6, label: "6 stores" },
    { value: 7, label: "7 stores" },
    { value: 8, label: "8 stores" },
    { value: 9, label: "9 stores" },
    { value: 10, label: "10 stores" },
    { value: 15, label: "15 stores" },
    { value: 20, label: "20 stores" },
  ],
  completion: [
    { value: "NEW_APP", label: "New App" },
    { value: "ONGOING", label: "Ongoing" },
    { value: "COMPLETED", label: "Completed" },
    { value: "DELAYED", label: "Delayed" },
    { value: "SUSPENDED", label: "Suspended" },
    { value: "REMOVED", label: "Removed" },
  ],
  ACCOUNT_STATUS: [
    { value: "POTENTIAL", label: "Potential" },
    { value: "ACTIVE", label: "Active" },
    { value: "SUSPENDED", label: "Suspended" },
    { value: "CANCELED", label: "Canceled" },
  ],
  REVENUE_MODEL: [
    { value: "ADS", label: "Ads" },
    { value: "IAP", label: "IAP" },
    { value: "IOT", label: "IoT / Companion" },
    { value: "B2B", label: "B2B" },
    { value: "BA", label: "Brand Awareness" },
  ],
  CONFIDENTIAL: [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ],
  icp_status: [
    { value: "NOT_STARTED", label: "Not Started" },
    { value: "ONGOING", label: "Ongoing" },
    { value: "COMPLETED", label: "Completed" },
  ],
};

const NOTIFICATION_OPTIONS = {
  completion: [
    { value: "NOT_STARTED", label: "Not Started" },
    { value: "ONGOING", label: "Ongoing" },
    { value: "REJECTED", label: "Rejected" },
    { value: "COMPLETED", label: "Completed" },
  ],
};
const REPLIES_OPTIONS = {
  completion: [
    { value: "NOT_STARTED", label: "Not Started" },
    { value: "COMPLETED", label: "Completed" },
  ],
};

const LIST_INDUSTRIES = [
  { name: "Action", code: "Action" },
  { name: "Adventure", code: "Adventure" },
  { name: "Arcade", code: "Arcade" },
  { name: "Art & Design", code: "Art & Design" },
  { name: "Auto & Vehicles", code: "Auto & Vehicles" },
  { name: "Beauty", code: "Beauty" },
  { name: "Board", code: "Board" },
  { name: "Books & Reference", code: "Books & Reference" },
  { name: "Business", code: "Business" },
  { name: "Card", code: "Card" },
  { name: "Casino", code: "Casino" },
  { name: "Casual", code: "Casual" },
  { name: "Comics", code: "Comics" },
  { name: "Communication", code: "Communication" },
  { name: "Dating", code: "Dating" },
  { name: "Education", code: "Education" },
  { name: "Educational", code: "Educational" },
  { name: "Entertainment", code: "Entertainment" },
  { name: "Events", code: "Events" },
  { name: "Finance", code: "Finance" },
  { name: "Food & Drink", code: "Food & Drink" },
  { name: "Health & Fitness", code: "Health & Fitness" },
  { name: "House & Home", code: "House & Home" },
  { name: "Libraries & Demo", code: "Libraries & Demo" },
  { name: "Lifestyle", code: "Lifestyle" },
  { name: "Maps & Navigation", code: "Maps & Navigation" },
  { name: "Medical", code: "Medical" },
  { name: "Music", code: "Music" },
  { name: "Music & Audio", code: "Music & Audio" },
  { name: "News & Magazines", code: "News & Magazines" },
  { name: "Parenting", code: "Parenting" },
  { name: "Personalization", code: "Personalization" },
  { name: "Photography", code: "Photography" },
  { name: "Productivity", code: "Productivity" },
  { name: "Puzzle", code: "Puzzle" },
  { name: "Racing", code: "Racing" },
  { name: "Role Playing", code: "Role Playing" },
  { name: "Shopping", code: "Shopping" },
  { name: "Simulation", code: "Simulation" },
  { name: "Social", code: "Social" },
  { name: "Sports", code: "Sports" },
  { name: "Strategy", code: "Strategy" },
  { name: "Tools", code: "Tools" },
  { name: "Travel & Local", code: "Travel & Local" },
  { name: "Trivia", code: "Trivia" },
  { name: "Video Players & Editors", code: "Video Players & Editors" },
  { name: "Weather", code: "Weather" },
  { name: "Word", code: "Word" },
  { name: "Other", code: "Other" },
];
const LIST_COUNTRIES = [
  { name: "Afghanistan", code: "AF" },
  { name: "\xC5land Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "Andorra", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "Rwanda", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Vietnam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

const STORES = [
  {
    short_name: "tencent",
    name: "Tencent",
  },
  {
    short_name: "huawei",
    name: "Huawei",
  },
  {
    short_name: "oppo",
    name: "Oppo",
  },
  {
    short_name: "360",
    name: "360",
  },
  {
    short_name: "baidu",
    name: "Baidu",
  },
  {
    short_name: "xiaomi",
    name: "Xiaomi",
  },
  {
    short_name: "vivo",
    name: "Vivo",
  },
  {
    short_name: "pp",
    name: "PP Assistant",
  },
  {
    short_name: "mm",
    name: "China Mobile",
  },
  {
    short_name: "anzhi",
    name: "Anzhi",
  },
  {
    short_name: "sogou",
    name: "Sogou",
  },
  {
    short_name: "wandoujia",
    name: "Wandoujia",
  },
  {
    short_name: "himarket",
    name: "Himarket",
  },
  {
    short_name: "meizu",
    name: "Meizu",
  },
  {
    short_name: "coolpad",
    name: "Coolpad",
  },
  {
    short_name: "lenovo",
    name: "Lenovo",
  },
  {
    short_name: "samsung",
    name: "Samsung",
  },
  {
    short_name: "zte",
    name: "ZTE",
  },
  {
    short_name: "um",
    name: "China Unicom",
  },
  {
    short_name: "tm",
    name: "China Telecom",
  },
  {
    short_name: "appchina",
    name: "Appchina",
  },
  {
    short_name: "91",
    name: "91",
  },
  {
    short_name: "google",
    name: "Google Play",
  },
  {
    short_name: "coolmarket",
    name: "Cool APK",
  },
  {
    short_name: "shouji",
    name: "Shouji",
  },
  {
    short_name: "dian",
    name: "Dian Ming",
  },
  {
    short_name: "liqu",
    name: "Liqu",
  },
  {
    short_name: "daily",
    name: "Daily App",
  },
  {
    short_name: "snail",
    name: "Snail Games",
  },
  {
    short_name: "jinli",
    name: "Gionee",
  },
  {
    short_name: "zhuoyi",
    name: "Zhuoyi",
  },
  {
    short_name: "7to",
    name: "7to",
  },
  {
    short_name: "mumayi",
    name: "MuMaYi",
  },
  {
    short_name: "amazon",
    name: "Amazon",
  },
  {
    short_name: "jifeng",
    name: "GFan",
  },
  {
    short_name: "2345",
    name: "2345",
  },
  {
    short_name: "taobao",
    name: "Taobao",
  },
  {
    short_name: "mapaotang",
    name: "Mobi Market",
  },
  {
    short_name: "anfen",
    name: "AppFun",
  },
  {
    short_name: "Anzhuo",
    name: "AnzhuoYuan",
  },
  {
    short_name: "kingsoft",
    name: "Kingsoft",
  },
  {
    short_name: "kuchuan",
    name: "Kuchuan",
  },
  {
    short_name: "cheering",
    name: "Cheering",
  },
  {
    short_name: "feifan",
    name: "CR Sky",
  },
  {
    short_name: "eoe",
    name: "EOE",
  },
  {
    short_name: "uc",
    name: "UC",
  },
  {
    short_name: "163",
    name: "163",
  },
  {
    short_name: "niucoo",
    name: "Niucoo",
  },
  {
    short_name: "nduo",
    name: "N Duo",
  },
];
const APP_TESTING_CATEGORIES = [
  { value: "ANALYTICS", label: "Analytics" },
  { value: "MAPS", label: "Maps" },
  { value: "MONETIZATION", label: "Monetization" },
  { value: "PAYMENTS", label: "Payments" },
  { value: "PUSH_NOTIFICATIONS", label: "Push Notifications" },
  { value: "OTHER", label: "Other" },
];
const WORKS_IN_CHINA = [
  { value: true, label: "Accessible" },
  { value: false, label: "Blocked" },
  { value: null, label: "No Data" },
];
const DISTRIBUTION_STATUS = [
  { value: "NOT_SUBMITTED", label: "Not Submitted" },
  { value: "SUBMITTED_TO_STORE", label: "Submitted to Store" },
  { value: "PUBLISHED", label: "Published" },
  { value: "REJECTED", label: "Rejected" },
  { value: "RECLAIM", label: "Reclaim" },
  { value: "PAUSED", label: "Paused" },
  { value: "SUSPENDED", label: "Suspended" },
  { value: "REMOVED", label: "Removed" },
];
const PROBLEM_STATUS = [
  { value: "NONE", label: "None" },
  {
    value: "COPYRIGHT_REQUIRED",
    label: "Software Copyright Certificate required",
  },
  { value: "NAME_NOT_MATCH", label: "The app name does not match APK" },
  { value: "ICON_NOT_MATCH", label: "The icon does not match APK" },
  { value: "WRONG_CREDENTIALS", label: "Wrong test credentials" },
  { value: "INSUFFICIENT_DESCRIPTION", label: "Insufficient app description" },
  { value: "APP_TOO_SIMPLE", label: "The app is too simple" },
  { value: "TOO_MANY_ADS", label: "The app has too many ads" },
  { value: "MALICIOUS_ADS", label: "Contains malicious ads" },
  { value: "UNALLOWED_LINKS", label: "Contains unallowed links in China" },
  { value: "OTHER", label: "Other (Add Details)" },
];
const STORES_NAMES = STORES.map((store) => store.short_name);
const ROLES = [
  { value: "OWNER", label: "Owner" },
  { value: "PAYMENTS", label: "Payments Manager" },
  { value: "ADMIN", label: "Admin" },
  { value: "VIEWER", label: "Viewer" },
];
const FORM_OPTIONS = {
  published: [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ],
  tablet_devices: [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ],
  app_cost: [
    { value: "FREE", label: "Free" },
    { value: "FREEMIUM", label: "Freemium" },
    { value: "PAID", label: "Paid" },
  ],
  advertising: [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ],
  internet: [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ],
  revenue_model: [
    { value: "ADS", label: "Ads" },
    { value: "IAP", label: "IAP" },
    { value: "IOT", label: "IoT / Companion" },
    { value: "B2B", label: "B2B" },
  ],
};

let NOTIFICATION_CATEGORIES = [
  {
    text: "New APK",
    value: "app",
  },
  {
    text: "App Info Update",
    value: "app_info",
  },
  {
    text: "Copyright",
    value: "copyright",
  },
  {
    text: "Company",
    value: "company",
  },
  {
    text: "User Acquisition",
    value: "acquisition",
  },
  {
    text: "Security Assessment",
    value: "security",
  },
  {
    text: "Localization",
    value: "localization",
  },
  {
    text: "Reviews",
    value: "reviews",
  },
  {
    text: "Custom",
    value: "custom",
  },
  {
    text: "Analytics",
    value: "analytics",
  },
  {
    text: "Monetization",
    value: "monetization",
  },
  {
    text: "SDK Credentials",
    value: "sdk_credentials",
  },
  {
    text: "Authorization Form",
    value: "authorization_form",
  },
  {
    text: "App Deactivated",
    value: "app_deactivation",
  },
];
let ICP_STATUS = [
  { value: "NOT_STARTED", label: "Not Started" },
  { value: "ONGOING", label: "Ongoing" },
  { value: "COMPLETED", label: "Completed" },
];
export {
  APP_DETAILS_OPTIONS,
  AICON_STORES,
  LIST_INDUSTRIES,
  LIST_COUNTRIES,
  NOTIFICATION_OPTIONS,
  STORES,
  STORES_NAMES,
  REPLIES_OPTIONS,
  APP_TESTING_CATEGORIES,
  WORKS_IN_CHINA,
  DISTRIBUTION_STATUS,
  PROBLEM_STATUS,
  ROLES,
  FORM_OPTIONS,
  NOTIFICATION_CATEGORIES,
  ICP_STATUS,
};
