import React, { useState, useEffect } from "react";
import queryString from "query-string";
import classes from "./AppDistributionTime.module.less";
import { Button, Table, Tag, Row, DatePicker, Icon, Tooltip } from "antd";
import { getLastMonthDates } from "../../../functions";
import { getKPIPerManager } from "../../../services/kpiService";
import { Link } from "react-router-dom";
import moment from "moment";
const { RangePicker } = DatePicker;

export default ({ ...props }) => {
  let [data, setData] = useState([]);
  let [loading, setLoading] = useState(false);
  let [dateRange, setDateRange] = useState(getLastMonthDates());
  let { manager } = props.match.params;

  let filterVersionsByDate = (item) => {
    return moment(item.completion_date).isBetween(dateRange[0], dateRange[1]);
  };

  let columns = [
    {
      key: "name",
      dataIndex: "name",
      title: "App",
      defaultSortOrder: "ascend",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => {
        if (a.name > b.name) return -1;
        else if (a.name < b.name) return 1;
        return 0;
      },
    },
    {
      key: "versions",
      dataIndex: "versions",
      title: "Versions Completed",
      render: (versions) => (
        <p>{versions.filter(filterVersionsByDate).length}</p>
      ),
    },
    {
      key: "versions",
      dataIndex: "versions",
      align: "right",
      render: (versions) => {
        let filteredVersions = versions.filter(filterVersionsByDate);
        return (
          <div>
            <p>
              {filteredVersions.length > 0
                ? (
                    filteredVersions.reduce(
                      (acc, curr) => acc + curr.completion_time,
                      0
                    ) / filteredVersions.length
                  ).toFixed(2)
                : "N/A"}
            </p>
          </div>
        );
      },
      title: (
        <div className={classes.headers}>
          <Tooltip
            placement="rightTop"
            title="Time from when client submits the app to when status becomes published (the latest date), or if the update was not complete, it uses the newest version of submission date as the end date for the previous version."
          >
            <p>
              Average Distribution Time (Days) <Icon type="info-circle" />
            </p>
          </Tooltip>
        </div>
      ),
    },
    {
      key: "currentVersion",
      dataIndex: "versions",
      align: "right",
      render: (versions) => {
        let filteredVersions = versions.filter(filterVersionsByDate);
        return (
          <div>
            <p>
              {filteredVersions.length > 0
                ? (
                    filteredVersions.reduce(
                      (acc, curr) => acc + curr.success_rate,
                      0
                    ) / filteredVersions.length
                  ).toFixed(2)
                : "N/A"}
            </p>
          </div>
        );
      },
      title: (
        <div className={classes.headers}>
          <Tooltip
            placement="rightTop"
            title="At the time of completion, record the total store success rate. This
            is only calculating for updates that were completed in that period."
          >
            <p>
              Total Stores Success Rate <Icon type="info-circle" />
            </p>
          </Tooltip>
        </div>
      ),
    },

    {
      key: "versions",
      dataIndex: "versions",
      align: "right",
      render: (versions) => {
        let latestVersion = versions && versions.shift();
        return latestVersion && latestVersion.success_rate
          ? latestVersion.success_rate.toFixed(2)
          : "0.00";
      },
      title: (
        <div className={classes.headers}>
          <Tooltip
            placement="rightTop"
            title="This will calculate the success rate of the latest version with distribution data."
          >
            <p>
              Current Version Success Rate <Icon type="info-circle" />
            </p>
          </Tooltip>
        </div>
      ),
    },
    {
      key: "id",
      dataIndex: "id",
      title: "",
      render: (id, item) => {
        return item.manager !== "TEAM" ? (
          <Link
            to={{
              pathname: `/kpi/app/${manager}/${encodeURIComponent(
                item.name
              )}/${id}`,
              search: `?start=${dateRange[0].format(
                "YYYY-MM-DD"
              )}&end=${dateRange[1].format("YYYY-MM-DD")}`,
            }}
            className={classes.link}
          >
            Details
          </Link>
        ) : (
          ""
        );
      },
    },
  ];
  const getData = async () => {
    setLoading(true);
    let response = await getKPIPerManager(manager);
    setLoading(false);
    if (response && response.data && response.data.apps) {
      setData(response.data.apps);
    }
  };
  const queryDate = () => {
    let url = props.location.search;
    let params = queryString.parse(url);
    let date =
      params && params.start && params.end
        ? [moment(params.start, "YYYY-MM-DD"), moment(params.end, "YYYY-MM-DD")]
        : getLastMonthDates();
    setDateRange(date);
    getData();
  };

  let assignDateToURL = (value) => {
    props.history.replace({
      pathname: `/kpi/manager/${manager}`,
      search: `?start=${value[0].format("YYYY-MM-DD")}&end=${value[1].format(
        "YYYY-MM-DD"
      )}`,
    });
  };

  useEffect(() => {
    queryDate();
  }, [props.location]);

  return (
    <div className={classes.container}>
      <Row type="flex" justify="start">
        <Link
          to={{
            pathname: "/kpi/overall",
            search: `?start=${dateRange[0].format(
              "YYYY-MM-DD"
            )}&end=${dateRange[1].format("YYYY-MM-DD")}`,
          }}
        >
          <Button disabled={loading} loading={loading} icon="left">
            Go Back
          </Button>
        </Link>
      </Row>
      <Row className={classes.title} type="flex" justify="start">
        <h3>
          Distribution Times of <b>{manager}</b>
        </h3>
      </Row>
      <Row className={classes.datePickerContainer} type="flex" justify="start">
        <div className={classes.datePicker}>
          <RangePicker
            disabled={loading}
            loading={loading}
            value={[dateRange[0], dateRange[1]]}
            onChange={(value) => assignDateToURL(value)}
            format="YYYY-MM-DD"
          />
        </div>
        <Button
          disabled={loading}
          loading={loading}
          onClick={() => assignDateToURL(getLastMonthDates())}
          type="primary"
        >
          Reset
        </Button>
      </Row>
      <Table
        loading={loading}
        size="small"
        dataSource={data}
        columns={columns}
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
};
