import React, { useEffect, useState } from "react";
import classes from "./VersionsDistributionTime.module.less";
import { Table, Row, Button, DatePicker, List, Icon, Tag, Tooltip } from "antd";
import AppImage from "../../UI/AppImage/AppImage";
import { getLastMonthDates } from "../../../functions";
import { getKPIPerVersion } from "../../../services/kpiService";
import history from "../../../history";
import { Link } from "react-router-dom";
import moment from "moment";
import queryString from "query-string";
const { RangePicker } = DatePicker;
export default ({ ...props }) => {
  let [dateRange, setDateRange] = useState(getLastMonthDates());
  let [loading, setLoading] = useState(false);
  let [data, setData] = useState([]);
  let { appName, appId, manager } = props.match.params;
  let columns = [
    { key: "version", dataIndex: "version", title: "Version" },
    {
      key: "start_date",
      dataIndex: "start_date",
      title: "Start Date",
      align: "center",
      render: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
    },
    {
      key: "completion_date",
      dataIndex: "completion_date",
      title: "Completion Date",
      align: "center",
      render: (date) => (date ? moment(date).format("YYYY-MM-DD") : "-"),
    },
    {
      key: "completion_time",
      dataIndex: "completion_time",
      align: "center",
      title: (
        <div className={classes.headers}>
          <Tooltip
            placement="rightTop"
            title="Time from when client submits the app to when status becomes published (the latest date), or if the update was not complete, it uses the newest version of submission date as the end date for the previous version."
          >
            <p>
              Completion Time (Days) <Icon type="info-circle" />
            </p>
          </Tooltip>
        </div>
      ),
      render: (time) => (time === null ? "-" : time),
    },

    {
      key: "slowest_store",
      dataIndex: "slowest_store",
      title: "Slowest Store",
      render: (slowest_store) => !!slowest_store && slowest_store.store_name,
    },
    {
      key: "success_rate",
      dataIndex: "success_rate",
      align: "right",
      title: (
        <div className={classes.headers}>
          <Tooltip
            placement="rightTop"
            title="At the time of completion, record the total store success rate. This
            is only calculating for updates that were completed in that period."
          >
            <p>
              Success Rate <Icon type="info-circle" />
            </p>
          </Tooltip>
        </div>
      ),
      render: (success_rate, item) => {
        return (
          <p>
            {success_rate ? success_rate.toFixed(2) : "0.00"}% (
            {item.stores_completed}/{item.stores_paid})
          </p>
        );
      },
    },
  ];
  let columnsVersions = [
    {
      key: "store_name",
      dataIndex: "store_name",
      title: "Store",
    },
    {
      key: "start_date",
      dataIndex: "start_date",
      title: "Start Date",
      render: (date) => (date ? moment(date).format("YYYY-MM-DD") : "-"),
    },
    {
      key: "completion_date",
      dataIndex: "completion_date",
      title: "Completion Date",
      render: (date) => (date ? moment(date).format("YYYY-MM-DD") : "-"),
    },
    {
      key: "last_status",
      dataIndex: "last_status",
      title: "Last Status",
      render: (status) => (status ? STATUS[status] : ""),
    },
    {
      key: "completion_time",
      dataIndex: "completion_time",
      title: "Completion Time",
      render: (completion_time, { completion_date }) =>
        completion_date ? completion_time : "-",
    },
  ];
  const STATUS = {
    NOT_SUBMITTED: <Tag color="gold">Not Submitted</Tag>,
    SUBMITTED_TO_STORE: <Tag color="cyan">Submitted to Store</Tag>,
    PUBLISHED: <Tag color="green">Published</Tag>,
    REJECTED: <Tag color="blue">Rejected</Tag>,
    RECLAIM: <Tag color="magenta">Reclaim</Tag>,
    PAUSED: <Tag color="purple">Paused</Tag>,
    SUSPENDED: <Tag color="volcano">Suspended</Tag>,
    REMOVED: <Tag color="red">Removed</Tag>,
  };
  const getData = async () => {
    setLoading(true);
    let response = await getKPIPerVersion({
      appId,
    });
    setLoading(false);
    if (response && response.data.versions) {
      setData(response.data.versions);
    }
  };
  const queryDate = () => {
    let url = props.location.search;
    let params = queryString.parse(url);
    let date =
      params && params.start && params.end
        ? [moment(params.start, "YYYY-MM-DD"), moment(params.end, "YYYY-MM-DD")]
        : getLastMonthDates();
    setDateRange(date);
    getData();
  };

  useEffect(() => {
    queryDate();
  }, [props.location]);

  let filterByRange = (item) => {
    /*return item.completion_date
      ? moment(item.completion_date).isBetween(dateRange[0], dateRange[1])
      : moment(item.start_date).isBetween(dateRange[0], dateRange[1]);*/
    return (
      moment(item.completion_date).isBetween(dateRange[0], dateRange[1]) ||
      moment(item.start_date).isBetween(dateRange[0], dateRange[1])
    );
  };

  let assignDateToURL = (value) => {
    props.history.replace({
      pathname: `/kpi/app/${manager}/${appName}/${appId}`,
      search: `?start=${value[0].format("YYYY-MM-DD")}&end=${value[1].format(
        "YYYY-MM-DD"
      )}`,
    });
  };

  return (
    <div className={classes.container}>
      <Row type="flex" justify="start">
        <Link
          to={{
            pathname: `/kpi/manager/${manager}`,
            search: `?start=${dateRange[0].format(
              "YYYY-MM-DD"
            )}&end=${dateRange[1].format("YYYY-MM-DD")}`,
          }}
        >
          <Button disabled={loading} loading={loading} icon="left">
            Go Back
          </Button>
        </Link>
      </Row>
      <p>
        <br />
      </p>

      <Row className={classes.title} type="flex">
        <AppImage id={appId} />

        <Link to={`/apps/${appId}`} target="_blank">
          <p style={{ color: "#2f2f2f", fontWeight: "bold" }}>
            {decodeURIComponent(appName)} <Icon type="link" />
          </p>
        </Link>
      </Row>
      <p>
        <br />
      </p>
      <Row className={classes.datePickerContainer} type="flex" justify="start">
        <div className={classes.datePicker}>
          <RangePicker
            value={[dateRange[0], dateRange[1]]}
            onChange={(value) => assignDateToURL(value)}
            disabled={loading}
            loading={loading}
            format="YYYY-MM-DD"
          />
        </div>
        <div className={classes.datePicker}>
          <Button onClick={() => assignDateToURL(getLastMonthDates())}>
            Reset Filter
          </Button>
        </div>
        <Link to={`/apps/${appId}/history`} target="_blank">
          <Button icon="link">View Submissions History </Button>
        </Link>
      </Row>
      <Table
        size="small"
        dataSource={data.filter(filterByRange)}
        columns={columns}
        pagination={false}
        loading={loading}
      />
      <List
        size="small"
        dataSource={data.filter(filterByRange)}
        loading={loading}
        renderItem={(item) => {
          console.log("item", item);
          return (
            <List.Item>
              <div style={{ width: "100%", paddingTop: "20px" }}>
                <Table
                  columns={columnsVersions}
                  dataSource={item.stores_details}
                  title={() => item.version}
                  size="small"
                  pagination={false}
                />
              </div>
            </List.Item>
          );
        }}
      />
    </div>
  );
};
