import React from "react";
import classes from "./GeneralRemarks.module.less";
import { Form, Input } from "antd";
const { Item } = Form;
const GeneralRemarks = ({ getFieldDecorator, app, loading }) => {
  return (
    <div className={classes.container}>
      <Item style={{ margin: 0 }}>
        {getFieldDecorator(`app.main_information.general_remarks`, {
          initialValue: app.id ? app.main_information.general_remarks : "",
        })(<Input.TextArea rows={3} disabled={loading} />)}
      </Item>
    </div>
  );
};
export default GeneralRemarks;
