import React, { useState, useEffect } from "react";
import classes from "./ProjectDetails.module.less";
import { Link } from "react-router-dom";
import { Card, Row, Button, Icon, Table, notification, Popconfirm } from "antd";
import {
  getProjectById,
  deleteAppFromProject,
} from "../../services/projectsService";
import AddAppModal from "./AddAppModal/AddAppModal";
import AppImage from "../UI/AppImage/AppImage";
import CardDetailsForm from "./CardDetailsForm/CardDetailsForm";
export default ({ apps, ...props }) => {
  let [loading, setLoading] = useState(false);
  let [saving, setSaving] = useState(false);
  let [data, setData] = useState({});
  let [addApp, setAddApp] = useState(false);
  let [appsConnected, setAppsConnected] = useState([]);
  let columns = [
    {
      key: "name",
      dataIndex: "name",
      title: "Name",
      render: (name, item) => {
        return (
          <Link to={`/apps/${item.id}/details`}>
            <div className={classes.logo}>
              <AppImage id={item.id} /> {name}
            </div>
          </Link>
        );
      },
    },
    {
      key: "id",
      dataIndex: "id",
      title: "",
      align: "left",
      render: (id) => {
        return (
          <Popconfirm
            onConfirm={() =>
              removeAppFromProject({ app: id, project: data.id })
            }
            title="Are you sure you want to delete this app from project?"
          >
            <Button
              size="small"
              ghost
              shape="circle"
              type="danger"
              icon="delete"
            />
          </Popconfirm>
        );
      },
    },
  ];
  const removeAppFromProject = async (values) => {
    setSaving(true);
    let { data: response } = await deleteAppFromProject({ ...values });
    setSaving(false);
    makeRequest();
    if (response && response.message === "success") {
      notification.success({
        message: "Information saved.",
      });
    }
  };

  const makeRequest = async () => {
    setLoading(true);
    let { data: response } = await getProjectById(props.match.params.id);
    if (response && response.details) {
      setData({ ...response.details });
      setAppsConnected(response.details.apps);
    }
    setLoading(false);
  };

  useEffect(() => {
    makeRequest();
  }, []);

  return (
    <div className={classes.container}>
      <Row type="flex" justify="start">
        <Button
          disabled={loading}
          style={{ margin: "0px 10px 0 0" }}
          onClick={() => window.history.back()}
        >
          <Icon type="left" />
          Go back
        </Button>
      </Row>
      <CardDetailsForm data={data} loading={loading} />
      <Card
        loading={loading}
        title="Apps"
        size="small"
        extra={
          <Button
            disabled={loading || saving}
            onClick={() => setAddApp(true)}
            icon="plus"
            type="primary"
          >
            Add App
          </Button>
        }
      >
        <Table
          size="small"
          columns={columns}
          loading={loading || saving}
          dataSource={appsConnected}
        />
      </Card>
      <AddAppModal
        appsConnected={appsConnected}
        id={data.id}
        updateData={makeRequest}
        visible={addApp}
        close={() => setAddApp(false)}
        apps={apps}
      />
    </div>
  );
};
