import React, { useState, useEffect } from "react";
import { getSalesTags } from "../../../../services/appDistributionService";
import { Select } from "antd";
const { Option } = Select;
export default ({ initialValue, addServiceTag }) => {
  let [tags, setTags] = useState([]);
  let [loading, setLoading] = useState(false)
  const fetchTags = async () => {
    try {
      setLoading(true);
      const { data } = await getSalesTags();
      setTags(data.tags);
      setLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    fetchTags();
  }, []);
  
  return (
    <Select
      defaultValue={initialValue}
      onChange={(values) => addServiceTag(values)}
      mode="multiple"
      style={{ width: "100%" }}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={loading}
      loading={loading}
    >
      {tags.map((it) => (
        <Option key={it.value} value={it.value} label={it.label}>
          {it.label}
        </Option>
      ))}
    </Select>
  );
};
