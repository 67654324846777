import React, { useState } from "react";
import { Card, Tabs } from "antd";
import Leads from "./Leads/Leads";
import Newsletter from "./Newsletter/Newsletter";
const { TabPane } = Tabs;
export default ({ month, year }) => {
  let [key, setKey] = useState("leads");
  return (
    <Card style={{ marginTop: "20px" }} title="Marketing Performance">
      <Tabs activeKey={key} onChange={(val) => setKey(val)}>
        <TabPane tab="Leads" key="leads">
          <Leads month={month} year={year} />
        </TabPane>
        <TabPane tab="Newsletter" key="newsletter">
          <Newsletter />
        </TabPane>
      </Tabs>
    </Card>
  );
};
