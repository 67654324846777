import React, { useState, useEffect } from "react";
import classes from "./CloudStore.module.less";
import {
  Table,
  Modal,
  Button,
  Row,
  Col,
  Tag,
  DatePicker,
  Input,
  Form,
  Select,
  Skeleton,
  InputNumber,
  notification,
  Alert,
  Checkbox
} from "antd";
import functions from "../../functions";
import moment from "moment";
import {
  getCloudByApp,
  newAccountBalance,
  verifyAccountBalance,
  updateOrder
} from "../../services/cloudStoreService";
import OrderDetailsModal from "./OrderDetailsModal/OrderDetailsModal";
import PayPalDetails from "./PayPalDetailsModal/PayPalDetailsModal";
import CloudBilling from "./CloudBilling/CloudBilling";
const { Item } = Form;
const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;
const PAYMENT_STATUS = {
  PAYMENT_PENDING: <Tag color="gold">Payment Pending</Tag>,
  PAYMENT_SUBMITTED: <Tag color="purple">Payment Submitted</Tag>,
  PAYMENT_MISMATCH: <Tag color="cyan">Payment Mismatch</Tag>,
  RENEW: <Tag color="blue">Renewal Payment</Tag>,
  PAID: <Tag color="green">Paid</Tag>,
  ERROR: <Tag color="red">Error</Tag>,
  CANCELLED: <Tag color="magenta">Cancelled</Tag>,
  CANCELATION_SUBMITTED: <Tag color="volcano">Cancelation Submitted</Tag>
};

const AddBalanceForm = Form.create()(({ form, addBalance, appId, loading }) => {
  const [visible, setVisible] = useState(false);
  const [saving, setSaving] = useState(false);
  const { getFieldDecorator } = form;

  const submitHandler = event => {
    event.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        confirm({
          title: "Are you sure you want to register this account balance?",
          async onOk() {
            try {
              setSaving(true);
              await addBalance({
                ...values,
                date: values.date.format("YYYY-MM-DD"),
                status: "VERIFIED",
                FK_App: appId
              });
              setVisible(false);
              setSaving(false);
              form.resetFields();
            } catch (error) {}
          },
          onCancel() {}
        });
      }
    });
  };

  return (
    <>
      <Button
        style={{ width: 150 }}
        type="primary"
        icon="plus"
        onClick={() => setVisible(true)}
      >
        Add New
      </Button>
      <Modal
        visible={visible}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        footer={null}
      >
        <Form onSubmit={submitHandler}>
          <Item label="Amount">
            {getFieldDecorator("amount", {
              rules: [{ required: true, message: "Required Field" }]
            })(<InputNumber style={{ width: "100%" }} />)}
          </Item>
          <Item label="Date">
            {getFieldDecorator("date", {
              rules: [{ required: true, message: "Required Field" }]
            })(<DatePicker style={{ width: "100%" }} />)}
          </Item>
          <Item label="Notes">
            {getFieldDecorator("notes", {
              rules: [{ required: true, message: "Required Field" }]
            })(<TextArea />)}
          </Item>
          <Row type="flex" justify="center">
            <Button
              type="primary"
              icon="save"
              htmlType="submit"
              disabled={saving}
              loading={saving}
            >
              Save
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
});

const EditOrderForm = Form.create()(
  ({ form, visible, setVisible, orderToEdit, editOrderHandler }) => {
    const [saving, setSaving] = useState(false);
    const { getFieldDecorator } = form;
    const submitHandler = event => {
      event.preventDefault();
      form.validateFields(async (err, values) => {
        if (!err) {
          try {
            setSaving(true);
            await editOrderHandler({
              ...orderToEdit,
              ...values,
              last_payment_date: values.last_payment_date.format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              next_payment_date: values.next_payment_date.format(
                "YYYY-MM-DD HH:mm:ss"
              )
            });
            notification.info({
              message: "Information saved!"
            });
            setVisible(false);
            setSaving(false);
            form.resetFields();
          } catch (error) {}
        }
      });
    };

    return (
      <Modal
        visible={visible}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        footer={null}
      >
        <Form onSubmit={submitHandler}>
          <Item label="Order Creation Date">
            {getFieldDecorator("createdAt", {
              initialValue: orderToEdit.createdAt
            })(<Input disabled />)}
          </Item>
          <Row gutter={16}>
            <Col span={12}>
              <Item label="Paid Months">
                {getFieldDecorator("paid_months", {
                  initialValue:
                    orderToEdit.cloud_payments &&
                    orderToEdit.cloud_payments[0].cloud_order_payment
                      .months_paid
                      ? orderToEdit.cloud_payments[0].cloud_order_payment
                          .months_paid
                      : null
                })(<Input disabled />)}
              </Item>
            </Col>
            <Col span={12}>
              <Item label="Renewal Months">
                {getFieldDecorator("renewal_months", {
                  rules: [{ required: true, message: "Required Field" }],
                  initialValue: orderToEdit.renewal_months
                    ? orderToEdit.renewal_months
                    : orderToEdit.cloud_payments &&
                      orderToEdit.cloud_payments[0].cloud_order_payment
                        .months_paid
                    ? orderToEdit.cloud_payments[0].cloud_order_payment
                        .months_paid
                    : null
                })(
                  <Select disabled={saving}>
                    <Option value={1}>1</Option>
                    <Option value={2}>2</Option>
                    <Option value={3}>3</Option>
                    <Option value={4}>4</Option>
                    <Option value={5}>5</Option>
                    <Option value={6}>6</Option>
                    <Option value={7}>7</Option>
                    <Option value={8}>8</Option>
                    <Option value={9}>9</Option>
                    <Option value={10}>10</Option>
                    <Option value={11}>11</Option>
                    <Option value={12}>12</Option>
                  </Select>
                )}
              </Item>
            </Col>
            <Col span={12}>
              <Item label="Last Payment Date">
                {getFieldDecorator("last_payment_date", {
                  rules: [{ required: true, message: "Required Field" }],
                  initialValue: orderToEdit.activation_date
                    ? moment(orderToEdit.activation_date)
                    : orderToEdit.cloud_payments &&
                      orderToEdit.cloud_payments[0].cloud_order_payment
                        .createdAt
                    ? moment(
                        orderToEdit.cloud_payments[0].cloud_order_payment
                          .createdAt
                      )
                    : null
                })(<DatePicker disabled={saving} />)}
              </Item>
            </Col>
            <Col span={12}>
              <Item label="Next Payment Date">
                {getFieldDecorator("next_payment_date", {
                  rules: [{ required: true, message: "Required Field" }],
                  initialValue: orderToEdit.expiration_date
                    ? moment(orderToEdit.expiration_date)
                    : orderToEdit.cloud_payments &&
                      orderToEdit.cloud_payments[0].cloud_order_payment
                        .createdAt &&
                      orderToEdit.cloud_payments[0].cloud_order_payment
                        .months_paid
                    ? moment(
                        orderToEdit.cloud_payments[0].cloud_order_payment
                          .createdAt
                      ).add(
                        orderToEdit.cloud_payments[0].cloud_order_payment
                          .months_paid,
                        "M"
                      )
                    : null
                })(<DatePicker disabled={saving} />)}
              </Item>
            </Col>
            <Col span={24}>
              <Item label="Instance">
                {getFieldDecorator("instance", {
                  rules: [{ required: true, message: "Required Field" }],
                  initialValue: orderToEdit.instance
                })(<Input disabled={saving} />)}
              </Item>
            </Col>
            <Col span={12}>
              <Item label="Location">
                {getFieldDecorator("location", {
                  rules: [{ required: true, message: "Required Field" }],
                  initialValue: orderToEdit.location
                })(<Input disabled={saving} />)}
              </Item>
            </Col>
            <Col span={12}>
              <Item label="IP Address">
                {getFieldDecorator("ip_address", {
                  rules: [{ required: true, message: "Required Field" }],
                  initialValue: orderToEdit.ip_address
                })(<Input disabled={saving} />)}
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col>
              <Item label="Credentials">
                {getFieldDecorator("credentials", {
                  rules: [{ required: true, message: "Required Field" }],
                  initialValue: orderToEdit.credentials
                })(<Input.TextArea disabled={saving} />)}
              </Item>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Button
              disabled={saving}
              loading={saving}
              type="primary"
              icon="save"
              htmlType="submit"
            >
              Save
            </Button>
          </Row>
        </Form>
      </Modal>
    );
  }
);

export default ({ appId, ...props }) => {
  const [visibleEditForm, setVisibleEditForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orderToEdit, setOrderToEdit] = useState({});
  const [accountData, setAccountData] = useState({});
  const [orderModal, setOrderModal] = useState(false);
  const [paypalModal, setPaypalModal] = useState(false);
  const [paypalDetails, setPaypalDetails] = useState("");
  const [orderId, setOrderId] = useState("");
  const [details, setDetails] = useState([]);
  const getData = async () => {
    let { data } = await getCloudByApp(appId);
    setLoading(false);
    if (data) {
      setAccountData(data);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    getData();
  }, [appId]);

  const addBalance = async values => {
    let { data } = await newAccountBalance({ ...values });
    if (data && data.message === "success") {
      notification.info({
        message: "Account Balance saved"
      });
      getData();
    }
  };

  const editOrderHandler = async values => {
    try {
      const { product_type, PK_Order, ...details } = values
      const { data } = await updateOrder({
        orderData: {
          product_type,
          orderID: PK_Order
        },
        details
      });
      if (!data.error) {
        const ordersDataCopy = [...accountData.orders];
        const index = ordersDataCopy.findIndex(({ id }) => values.id === id);
        ordersDataCopy[index] = {
          ...values
        };
        setAccountData({
          ...accountData,
          ordersData: ordersDataCopy
        });
      } 
    } catch (error) {

    }
  };
  const verifyConfirm = id => {
    confirm({
      title: "Are you sure you want to verify this register?",
      onOk() {
        verifyRequest(id);
      },
      onCancel() {}
    });
  };
  const verifyRequest = async id => {
    let { data } = await verifyAccountBalance({ id });
    if (data && data.message === "success") {
      notification.info({
        message: "Verified!"
      });
      getData();
    }
  };

  const balanceRows = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: date => moment(date).format("YYYY-MM-DD")
    },
    {
      title: "PayPal ID",
      dataIndex: "transaction_id",
      key: "transaction_id"
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: amount => `USD $${functions.formatMoney(amount)}`
    },
    {
      title: "Status",
      dataIndex: "verified",
      key: "verified",
      align: "center",
      render: status =>
        status ? (
          <Tag color="green">VERIFIED</Tag>
        ) : (
          <Tag color="volcano">NOT VERIFIED</Tag>
        )
    },
    {
      title: "Deposit method",
      dataIndex: "deposit",
      key: "deposit",
      align: "center",
      render: (_, item) =>
        item.paypal_callback_string ? (
          <Tag color="blue">PAYPAL</Tag>
        ) : (
          <Tag color="purple">BANK TRANSFER</Tag>
        )
    },
    {
      title: "Details",
      dataIndex: "notes",
      key: "notes",
      align: "center"
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      align: "right",
      render: (_, item) => {
        const isVerified = item.status === "VERIFIED" || item.verified;
        return (
          <Button
            onClick={() => verifyConfirm(item.PK_Account_Balance)}
            type="primary"
            icon="check"
            disabled={isVerified}
          >
            VERIFY
          </Button>
        );
      }
    }
  ];

  const ordersRows = [
    {
      title: "Order",
      dataIndex: "title",
      key: "title",
      width: 150,
      fixed: "left"
    },
    {
      title: "Order Details",
      dataIndex: "product_specifications",
      key: "product_specifications",
      render: (_, values) => {
        return (
          <Button
            onClick={() => {
              setOrderModal(true);
              setOrderId(values.id);
              setDetails(JSON.parse(values.product_specifications));
            }}
            type="link"
          >
            View Details
          </Button>
        );
      }
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
      key: "payment_status",
      render: (_, item) =>
        PAYMENT_STATUS[
          item.cloud_payments[0].cloud_order_payment.payment_status
        ]
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
      render: (_, item) =>
        item.cloud_payments[0].cloud_order_payment.payment_method
          ? functions.splitAndCapitalize(
              item.cloud_payments[0].cloud_order_payment.payment_method
            )
          : ""
    },
    {
      title: "",
      dataIndex: "paypal_callback_string",
      key: "paypal_callback_string",
      render: (details, item) =>
        item.payment_method && item.payment_method === "paypal" ? (
          <Button
            onClick={() => {
              setPaypalModal(true);
              setPaypalDetails(details);
            }}
            type="link"
          >
            View Paypal Details
          </Button>
        ) : null
    },
    {
      title: "Months Paid",
      dataIndex: "months_paid",
      key: "months_paid",
      align: "right",
      render: (_, item) =>
        item.cloud_payments[0].cloud_order_payment.months_paid
    },
    {
      title: "Monthly Price",
      dataIndex: "monthly_price",
      key: "monthly_price",
      align: "right",
      render: price => `USD $${functions.formatMoney(price)}`
    },
    {
      title: "One Time Fee",
      dataIndex: "onetime_fee",
      key: "onetime_fee",
      align: "right",
      render: price =>
        price ? `USD $${functions.formatMoney(price)}` : "USD $0"
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "right",
      render: (_, item) =>
        item.cloud_payments[0].cloud_order_payment.total ? (
          <strong>
            USD $
            {functions.formatMoney(
              item.cloud_payments[0].cloud_order_payment.total
            )}
          </strong>
        ) : (
          ""
        )
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      align: "right",
      render: (_, item) => (
        <>
          <Button
            type="primary"
            icon="edit"
            onClick={() => {
              setOrderToEdit(item);
              setVisibleEditForm(true);
            }}
          >
            Edit
          </Button>
        </>
      )
    }
  ];

  return (
    <div className={classes.container} style={{ padding: 15 }}>
      <Skeleton loading={loading}>
        <Alert
          message={
            <Row type="flex" justify="space-around">
              <p>Account Balance: </p>{" "}
              <b>${functions.formatMoney(accountData.total)}</b>
            </Row>
          }
        />
        <Row style={{ marginTop: 20 }} type="flex" justify="end">
          <AddBalanceForm addBalance={addBalance} appId={appId} />
        </Row>
        <h3>Account Balance History</h3>
        <Table
          style={{ marginTop: 15 }}
          size="small"
          columns={balanceRows}
          dataSource={accountData.account}
        />

        <h3>Orders History</h3>
        <Table
          style={{ marginTop: 15 }}
          size="small"
          columns={ordersRows}
          scroll={{ x: 1400 }}
          dataSource={accountData.orders}
        />

        <h3>Cloud Billing</h3>
        <CloudBilling data={accountData.billing} />
        <EditOrderForm
          orderToEdit={orderToEdit}
          visible={visibleEditForm}
          setVisible={setVisibleEditForm}
          editStatus={setAccountData}
          editOrderHandler={editOrderHandler}
        />
        <OrderDetailsModal
          details={details}
          orderId={orderId}
          close={() => setOrderModal(false)}
          display={orderModal}
        />
        <PayPalDetails
          paypalDetails={paypalDetails}
          display={paypalModal}
          close={() => setPaypalModal(false)}
        />
      </Skeleton>
    </div>
  );
};
