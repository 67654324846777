import React, { useState, Fragment } from "react";
import JSZip from "jszip";
import Axios from "axios";
import { saveAs } from "file-saver";
import { Button, Progress, Modal } from "antd";
const zip = new JSZip();

export default ({ data, form }) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [visible, setVisible] = useState(false);

  const getLinks = data => {
    return Object.values(data)
      .filter(value =>
        value
          ? /.apk|.png|.doc|.docx|.pdf|.zip|.ppt|.pptx|.flv|.mkv|.mp4|.gif|.jpg|.jpeg/.test(
              value
            )
          : false
      )
      .map(it => ({
        filePath: `https://appinchina.space/download/${encodeURIComponent(it)}`,
        name: it
      }));
  };

  const download = () => {
    let links = getLinks(data);
    let links_apks = [];
    let apks =
      data.multi_apks.map(it => links_apks.push(...getLinks(it))) || [];
    links = links.concat(links_apks);
    if (links.length > 0) {
      let count = 0;
      let totalFiles = links.length;
      let percentage = 100 / totalFiles;
      setLoading(true);
      setVisible(true);
      links.forEach(async ({ name, filePath }) => {
        try {
          const { data } = await Axios.get(filePath, {
            responseType: "blob"
          });
          if (data) {
            zip.file(name, data, {
              binary: true
            });
            ++count;
            setProgress(count * percentage);
            if (count == totalFiles) {
              zip.generateAsync({ type: "blob" }).then(content => {
                setLoading(false);
                setVisible(false);
                setProgress(0);
                saveAs(content, `${form}-${new Date().toISOString()}.zip`);
              });
            }
          }
        } catch (error) {
          totalFiles--;
          percentage = 100 / totalFiles;
        }
      });
    }
  };

  return (
    <Fragment>
      <Button
        loading={loading}
        onClick={download}
        type="primary"
        icon="download"
      >
        Download files as zip
      </Button>
      <Modal
        title={<h3 style={{ textAlign: "center" }}>{"Download Progress"}</h3>}
        visible={visible}
        footer={[]}
        centered
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Progress
            percent={progress}
            type="circle"
            format={percent => `${percent.toFixed(2)}%`}
          />
        </div>
      </Modal>
    </Fragment>
  );
};
