import axios from "../axios";

export async function getManagers() {
  let response = null;
  try {
    response = await axios.get(`/account-managers`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
