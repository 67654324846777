import React, { useState, useEffect } from "react";
import { Select, Modal, notification, Row, Skeleton, Col } from "antd";
import Axios from "../../axios";
import classes from "./Analytics.module.less";
const { Option } = Select;
const { confirm } = Modal;

export default ({ match, server }) => {
  const [analyticsAccounts, setAnalyticsAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [id, setId] = useState(null);
  const [analyticsInfo, setAnalyticsInfo] = useState({});
  useEffect(() => {
    if (server) {
      fetch();
    }
  }, [server]);

  const fetch = async () => {
    try {
      setLoading(true);
      const { data } = await Axios.get(
        `/analitycs-accounts/${match.params.id}/${server.id || ""}`
      );
      if (data.accounts) {
        const { accounts, analitycs_id } = data;
        setAnalyticsAccounts(accounts);
        setId(analitycs_id);
        console.log(analitycs_id);
        setLoading(false);
      }
    } catch (error) {
      notification.error({
        message: "An error has ocurred conecting to the server.",
        placement: "bottomLeft",
      });
    }
  };

  const selectApp = (value) => {
    confirm({
      title: "Do you want to select this account?",
      okButtonProps: {
        loading: loadingResponse,
      },
      onOk() {
        try {
          const values = analyticsAccounts.find(({ id }) => id == value);
          setLoadingResponse(true);
          Axios.post("/analitycs-account", {
            id: match.params.id,
            analytics_id: value,
            aicpay_secret: values ? values.account_secret : null,
            aicpay_appname: values ? values.company_name : null,
            max_logon_num: values ? values.max_logon_num : null,
          })
            .then((response) => {
              if (response.data.message === "success") {
                setId(value);
                setAnalyticsInfo({ ...values });
                setLoadingResponse(false);
                notification.success({
                  message: "Information Updated.",
                  placement: "bottomLeft",
                });
              }
            })
            .catch(() => {
              notification.error({
                message: "An error has ocurred.",
                placement: "bottomLeft",
              });
            });
        } catch (error) {
          notification.error({
            message: "An error has ocurred.",
            placement: "bottomLeft",
          });
        }
      },
    });
  };

  return (
    <>
      <Skeleton loading={loading}>
        <Row gutter={16}>
          <Col span={24}>
            <h2>App Account:</h2>
            <Select
              placeholder="Select a app"
              onChange={(value, item) => {
                selectApp(value);
              }}
              style={{ width: "100%" }}
              value={id}
              disabled={!server}
            >
              <Option key={null} value={null}>
                Not Set
              </Option>
              {analyticsAccounts.map(({ company_name, id }) => (
                <Option key={id} value={id}>
                  {company_name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        {analyticsInfo && analyticsInfo.account_secret ? (
          <div className={classes.info}>
            <b>App ID</b> <p>{analyticsInfo.id}</p>
            <b>App Secret</b> <p>{analyticsInfo.account_secret}</p>
          </div>
        ) : null}
      </Skeleton>
    </>
  );
};
