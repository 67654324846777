import React from "react";
import { Table, Button, Tag } from "antd";
import history from "../../../history";
import moment from "moment";
export default ({ loading, documents, appId }) => {
  let columns = [
    {
      key: "submission",
      dataIndex: "submission",
      title: "Submission",
      defaultSortOrder: "ascend",
      sorter: (a, b) => {
        if (a.submission > b.submission) return -1;
        else if (a.submission < b.submission) return 1;
        return 0;
      },
      render: (date) => (date ? moment(date).format("YYYY-MM-DD HH:mm") : ""),
    },
    {
      key: "version",
      dataIndex: "version",
      title: "Version",
      render: (version) => (version ? version : "N/A"),
    },
    {
      key: "details",
      dataIndex: "details",
      title: "Details",
      render: (_, item) => <Tag color="purple">Document</Tag>,
    },
    {
      key: "submitted_by",
      dataIndex: "submitted_by",
      title: "Submitted By",
    },
    {
      key: "options",
      dataIndex: "options",
      title: "",
      render: (_, item) => (
        <Button
          onClick={() => {
            history.push(`/apps/${appId}/documents`);
          }}
          type="link"
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <div style={{ background: "purpleu" }}>
      <Table
        loading={loading}
        dataSource={documents}
        columns={columns}
        size="small"
        scroll={{ x: 800 }}
        pagination={documents.length > 10 ? { pageSize: 10 } : false}
      />
    </div>
  );
};
