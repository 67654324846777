import React from "react";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";
import functions from "../../../functions";

export default ({ data, formatLabel }) => {
  return (
    <div>
      <Chart 
        height={400} 
        data={data} 
        padding={60} 
        forceFit         
      >
        <Legend />
        <Legend />
        <Axis           
          name="date" 
          label={{
            ...formatLabel
          }}  
        />
        <Axis
          name="event_count"
          label={{
            formatter: val => `${functions.styleAsNumber(val)}`
          }}
        />
        <Tooltip
          crosshairs={{
            type: "y"
          }}
        />
        <Geom
          type="line"
          position="date*event_count"
          size={2}
          color={"event"}
          tooltip={[
            "event*event_count*date",
            (event, event_count, date) => {
              event_count = functions.styleAsNumber(event_count);
              const title = formatLabel ? { title: `${date} hours`} : {};
              return {                
                name: event,
                value: event_count,
                ...title
              };
            }
          ]}
        />
        <Geom
          type="point"
          position="date*event_count"
          size={4}
          shape={"circle"}
          color={"event"}
          style={{
            stroke: "#fff",
            lineWidth: 1
          }}          
        />
      </Chart>
    </div>
  );
};
