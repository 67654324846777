import React, { useState } from "react";
import classes from "./AttachmentCard.module.less";
import { Table, Button, Row, Icon, Modal } from "antd";
import { deleteReclaimingAttachment } from "../../../services/apkReclaimService";
import moment from "moment";
import NewAttachmentModal from "./NewAttachmentModal/NewAttachmentModal";
const { confirm } = Modal;
export default ({ adminData, clientData, update, deleteHandler }) => {
  let [display, setDisplay] = useState(false);

  let columns = [
    {
      dataIndex: "date",
      key: "date",
      title: "Date",
      render: (date) => moment(date).format("YYYY-MM-DD HH:mm"),
    },
    { dataIndex: "user", key: "user", title: "Uploader" },
    { dataIndex: "file_name", key: "file_name", title: "File Name" },
    { dataIndex: "file_size", key: "file_size", title: "File Size (Bytes)" },
    {
      dataIndex: "file",
      key: "file",
      title: "",
      render: (file_url) =>
        file_url ? (
          <a
            rel="noopener noreferrer"
            href={`https://appinchina.space/download/${file_url}`}
            target="_blank"
          >
            <Button icon="cloud-download">Download</Button>
          </a>
        ) : (
          "No file attached"
        ),
    },
  ];

  const handleDelete = (id) => {
    confirm({
      title: "Are you sure you want to delete this attachment?",
      async onOk() {
        await deleteReclaimingAttachment(id);
        deleteHandler(id);
      },
    });
  };

  return (
    <div className={classes.container}>
      <h3>Client Attachments</h3>
      <Table
        columns={columns}
        pagination={
          clientData && clientData.length > 5 ? { pageSize: 5 } : false
        }
        size="small"
        dataSource={clientData}
      />
      <Row className={classes.Button} type="flex" justify="space-between">
        <h3>Admin Attachments</h3>
        <Button onClick={() => setDisplay(true)}>
          <Icon type="upload" /> Click to Upload
        </Button>
      </Row>
      <Table
        columns={[
          ...columns,
          {
            dataIndex: "delete",
            key: "delete",
            title: "",
            render: (_, e) => (
              <Button
                onClick={() => handleDelete(e.id)}
                icon="delete"
                shape="circle"
                type="danger"
              />
            ),
          },
        ]}
        pagination={adminData.length > 5 ? { pageSize: 5 } : false}
        size="small"
        dataSource={adminData}
      />
      <NewAttachmentModal
        updateData={update}
        visible={display}
        close={() => setDisplay(false)}
      />
    </div>
  );
};
