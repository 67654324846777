import React, { useState, Fragment } from "react";
import { Modal, DatePicker, Button, notification } from "antd";
import moment from "moment";
import { generateReport } from "../../../services/paymentsService";
const { MonthPicker } = DatePicker;
export default (props) => {
  const [actualDate, setActalDate] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState(null);
  const [displayLink, setDisplayLink] = useState(false);

  const onChangeMonthPicker = (date, dateString) => {
    const firstDay = date.startOf("month").format("YYYY-MM-DD");
    const lastDay = date.endOf("month").format("YYYY-MM-DD");
    setStart(firstDay);
    setEnd(lastDay);
    setActalDate(moment(date));
  };
  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: "bottomLeft",
    });
  };
  const generateReportRequest = async (paymentsId, appId, end, start) => {
    setLoading(true);
    if (
      paymentsId.alipay_id ||
      (paymentsId.wechat_id && paymentsId.payments_percentaje)
    ) {
      console.log(props);
      let appName =
        props.appInfo && props.appInfo.name ? props.appInfo.name : "";
      let response = await generateReport(
        paymentsId,
        appId,
        end,
        start,
        appName.name ? appName.name : ""
      );
      if (response && response.status === 200) {
        console.log(response);
        if (response.data && response.data.pdf) {
          setLink(response.data.pdf);
          setDisplayLink(true);
        }
        openNotificationWithIcon("info", "Report was generated successfully.");
      } else {
        openNotificationWithIcon("warning", "An error has ocurred.");
      }
    } else {
      openNotificationWithIcon(
        "warning",
        "Please assign AIC Percentage and at least one payment account to continue."
      );
    }
    setLoading(false);
  };
  const closeModal = () => {
    props.setShowStatementReport(false);
    setActalDate("");
    setStart("");
    setEnd("");
    setLoading(false);
    setDisplayLink(false);
    setLink(null);
  };

  return (
    <div>
      <Modal
        title="Generate Statement Report"
        visible={props.showStatementReport}
        onCancel={() => closeModal()}
        footer={null}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          {!displayLink ? (
            <Fragment>
              <MonthPicker
                value={actualDate}
                onChange={onChangeMonthPicker}
                placeholder="Select month"
                allowClear={false}
                style={{ margin: 10 }}
              />

              <Button
                disabled={actualDate !== "" ? false : true}
                loading={loading}
                type="primary"
                onClick={() =>
                  generateReportRequest(
                    props.paymentsId,
                    props.appId,
                    end,
                    start
                  )
                }
                style={{ margin: 10 }}
              >
                {loading ? "Generating report..." : "Send Information"}
              </Button>
            </Fragment>
          ) : (
            <a target="_blank" href={link}>
              View Report
            </a>
          )}
        </div>
      </Modal>
    </div>
  );
};
