import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Alert, Row, Col, Select, Button } from "antd";
import { APP_TESTING_CATEGORIES } from "../../constants";
import moment from "moment";
import compareStrings from "compare-strings";
import classes from "./SdksModal.module.less";

const { Item } = Form;
const { Option } = Select;

export default Form.create()(
  ({
    sdkList,
    form,
    visible,
    closeModal,
    isCreate,
    initialValues,
    updateSdk,
    createSdk,
    modalLoading
  }) => {
    const [suggestions, setSuggestions] = useState([]);

    const submitHandler = () => {
      form.validateFields((err, values) => {
        if (!err) {
          if (isCreate) {
            createSdk(values);
          } else {
            updateSdk({
              ...values,
              id: initialValues.id,
              date: new Date().toISOString()
            });
          }
        }
      });
    };

    const insertMatchingSdk = (sdk, similarity, oldSuggestions) => {
      let newSuggestions = [];
      for (var i = 0; i < 6; i += 1) {
        if (
          similarity < 1 &&
          sdk.test_endpoint &&
          (similarity > oldSuggestions[i].similarity ||
            !oldSuggestions[i].similarity)
        ) {
          newSuggestions.push({
            sdk,
            similarity
          });
          newSuggestions = [...newSuggestions, ...oldSuggestions.slice(i, 6)];
          break;
        } else {
          newSuggestions.push(oldSuggestions[i]);
        }
      }
      return newSuggestions;
    };

    const findSuggestions = word => {
      let updatedSuggestions = Array(6).fill({});
      sdkList.forEach(sdk => {
        let similarity = compareStrings(word, sdk.name || "");
        updatedSuggestions = insertMatchingSdk(
          sdk,
          similarity,
          updatedSuggestions
        );
      });
      return updatedSuggestions;
    };

    const setEndpoint = sdk => {
      form.setFieldsValue({
        test_endpoint: sdk.test_endpoint
      });
    };

    useEffect(() => {
      setTimeout(() => {
        let bestMatches = findSuggestions(initialValues.name);
        console.log("bestmatches", bestMatches);
        setSuggestions(bestMatches);
      }, 200);
    }, []);

    const { getFieldDecorator } = form;
    return (
      <Modal
        visible={visible}
        onCancel={closeModal}
        okText="Save and Test"
        onOk={submitHandler}
        confirmLoading={modalLoading}
      >
        <Form>
          <Row gutter={16}>
            <Col span={12}>
              <Item label="Name">
                {getFieldDecorator("name", {
                  rules: [{ required: true, message: "Required" }],
                  initialValue: initialValues.name
                })(<Input />)}
              </Item>
            </Col>
            <Col span={12}>
              <Item label="Category">
                {getFieldDecorator("category", {
                  rules: [{ required: true, message: "Required" }],
                  initialValue: initialValues.category
                })(
                  <Select>
                    {APP_TESTING_CATEGORIES.map(it => (
                      <Option key={it.value} value={it.value} label={it.label}>
                        {it.label}
                      </Option>
                    ))}
                  </Select>
                )}
              </Item>
            </Col>
          </Row>
          <Item label="Alternative">
            {getFieldDecorator("alternative", {
              initialValue: initialValues.alternative
            })(<Input.TextArea />)}
          </Item>
          <Item label="Endpoint Domain">
            {getFieldDecorator("test_endpoint", {
              initialValue: initialValues.test_endpoint
            })(<Input />)}
          </Item>
        </Form>
        <div className={classes.suggestions}>
          <p>Endpoint Suggestions:</p>
          {suggestions.map(({ sdk }) => (
            <Button onClick={() => setEndpoint(sdk)}>
              <p>{sdk.name}</p>
              <span>{sdk.test_endpoint}</span>
            </Button>
          ))}
        </div>
        {isCreate ? null : !initialValues.test_date ? (
          <Alert message="Not tested yet" type="info" showIcon />
        ) : initialValues.works_in_china ? (
          <Alert
            message={`Tested on ${moment(initialValues.test_date).format(
              "YYYY-MM-DD HH:mm"
            )} and it connected successfully.`}
            type="success"
            showIcon
          />
        ) : (
          <Alert
            message={`Tested on ${moment(initialValues.test_date).format(
              "YYYY-MM-DD HH:mm"
            )} and it did not connect.`}
            type="error"
            showIcon
          />
        )}
      </Modal>
    );
  }
);
