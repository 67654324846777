import React from "react";
import { List, Tag, Row, Button } from "antd";
import classes from "./Results.module.less";
export default ({
  data = [],
  searchValue = "",
  searchAgain,
  onSelect,
  close,
}) => {
  const getContentFormat = (string) => {
    let filterBy = string;
    const index = filterBy.toLowerCase().indexOf(searchValue.toLowerCase());
    const beforeStr = filterBy.substr(0, index);
    const afterStr = filterBy.substr(index + searchValue.length);
    return index > -1 ? (
      <span className={classes.description}>
        {beforeStr}
        <span style={{ background: "#FFFF00" }}>{searchValue}</span>
        {afterStr}
      </span>
    ) : (
      filterBy
    );
  };
  return (
    <div>
      <List
        itemLayout="horizontal"
        dataSource={data}
        pagination={{ pageSize: 5 }}
        rowKey={(record) => record.PK_Help_Category}
        renderItem={(item) => {
          let title = getContentFormat(item.title);
          let content =
            item.help_articles.length > 0 &&
            item.help_articles[0] &&
            item.help_articles[0].length > 0
              ? item.help_articles[0].map((it) => getContentFormat(it))
              : "";

          return (
            <List.Item
              onClick={() => {
                searchAgain();
                close();
                onSelect(item.id || item.PK_Help_Category);
              }}
            >
              <List.Item.Meta
                className={classes.card}
                title={
                  <Row type="flex" justify="space-between">
                    <span>{title}</span>
                    <Tag color="green">{item.category}</Tag>
                  </Row>
                }
                description={content}
              />
            </List.Item>
          );
        }}
      />
      <Row style={{ marginTop: 10 }} type="flex" justify="center">
        <Button icon="search" onClick={() => searchAgain()} type="primary">
          Continue Searching
        </Button>
      </Row>
    </div>
  );
};
