import React, { useState, Fragment } from "react";
import classes from "./NewIssueType.module.less";
import { Modal, Button, Form, notification, Input } from "antd";
const NewIssueType = ({ form, onAdd }) => {
  const { getFieldDecorator } = form;
  let [modalOpen, setModalOpen] = useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        notification.info({
          message: "New Issue Type added",
          placement: "bottomLeft",
          duration: 2
        });
        setModalOpen(false);
        onAdd(values);
        form.resetFields();
      }
    });
  };
  return (
    <Fragment>
      <div className={classes.button}>
        <Button onClick={() => setModalOpen(true)} icon="plus">
          Add New Issue Type
        </Button>
      </div>
      <Modal
        title="New Issue Type"
        visible={modalOpen}
        onOk={handleSubmit}
        onCancel={() => setModalOpen(false)}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <Form layout="vertical" onSubmit={handleSubmit}>
          <Form.Item label="Issue Types">
            {getFieldDecorator("description", {
              rules: [
                {
                  required: true,
                  message: "Please input."
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default Form.create()(NewIssueType);
