import React, { useEffect, useState, Fragment } from "react";
import {
  getAllBulletin,
  createNewBulletin,
  getBulletinById,
} from "../../services/bulletinService";
import {
  Spin,
  Menu,
  Button,
  Modal,
  Form,
  Input,
  notification,
  DatePicker,
  Icon,
  Tag,
} from "antd";
import classes from "./Bulletin.module.less";
import Editor from "./Editor/Editor";
import moment from "moment";
const { Item } = Menu;

export default Form.create()(({ form }) => {
  const [loading, setLoading] = useState(true);
  const [bulletinList, setBulletinList] = useState([]);
  const [addNewBulletin, setAddNewBulletin] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [selected, setSelected] = useState(null);
  const [content, setContent] = useState("");
  const { getFieldDecorator } = form;
  const [loadingContent, setLoadingContent] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [bulletin, setBulletin] = useState({});
  useEffect(() => {
    fetch();
  }, []);
  const orderBulletins = (list) => {
    let order = list.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
    setBulletinList(order);
  };
  const getBulletinContent = async (id) => {
    setLoadingContent(true);
    const { data } = await getBulletinById(id);
    if (data && data.bulletin) {
      setContent(data.bulletin.content);
      setBulletin(data.bulletin);
    }
    setLoadingContent(false);
  };

  const fetch = async () => {
    try {
      setLoading(true);
      const { data } = await getAllBulletin();
      if (data && data.bulletins) {
        orderBulletins(data.bulletins);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmitDocument = () => {
    form.validateFields(["title", "date"], async (err, values) => {
      if (!err) {
        setLoadingUpload(true);
        const { data } = await createNewBulletin({
          date: values.date,
          title: values.title,
        });
        setLoadingUpload(false);
        if (data && data.bulletin) {
          setBulletinList([
            ...bulletinList,
            { id: data.bulletin.PK_Bulletin, ...data.bulletin },
          ]);
          setSelected(data.bulletin.PK_Bulletin);
          setContent("");
          setLoadingUpload(false);
          setAddNewBulletin(false);
          form.resetFields();
          notification.success({
            placement: "bottomLeft",
            message: "Bulletin Created.",
          });
        }
      }
    });
  };
  const reportWindowSize = () => {
    setWidth(window.innerWidth);
  };
  window.onresize = reportWindowSize;

  return (
    <Fragment>
      {loading ? (
        <Spin
          tip="Loading content..."
          style={{
            display: "flex",
            height: "500px",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      ) : (
        <div className={classes.container}>
          <div className={classes.menu}>
            <Menu
              overflowedIndicator={<Icon type="menu-unfold" />}
              mode={width > 700 ? "inline" : "horizontal"}
              selectedKeys={selected}
            >
              {bulletinList.length > 0
                ? bulletinList.map(({ id, title, date }) => (
                    <Item
                      onClick={() => {
                        setSelected(id);
                        getBulletinContent(id);
                      }}
                      key={id}
                    >
                      <div className={classes.List}>
                        <Tag color="green">
                          {moment(date).format("YYYY-MM-DD")}
                        </Tag>
                        <p>{title}</p>
                      </div>
                    </Item>
                  ))
                : null}
            </Menu>{" "}
          </div>
          <div className={classes.buttons}>
            <Button
              onClick={() => setAddNewBulletin(true)}
              icon="plus"
              type="primary"
            >
              Add New Bulletin
            </Button>
          </div>
          <div className={classes.content}>
            {" "}
            {selected && content !== "" ? (
              content !== "" ? (
                <Editor
                  docId={selected}
                  content={content}
                  bulletins={bulletinList}
                  orderBulletins={orderBulletins}
                  loading={loadingContent}
                  updateInfo={fetch}
                  selected={setSelected}
                  bulletin={bulletin}
                />
              ) : (
                <p>Error</p>
              )
            ) : (
              <div className={classes.flex}>
                <p>Please select a Bulletin</p>
              </div>
            )}
          </div>
        </div>
      )}
      <Modal
        title="Add New Bulletin"
        visible={addNewBulletin}
        onOk={handleSubmitDocument}
        onCancel={() => setAddNewBulletin(false)}
        okButtonProps={{
          disabled: loadingUpload,
          loading: loadingUpload,
        }}
        cancelButtonProps={{
          disabled: loadingUpload,
          loading: loadingUpload,
        }}
      >
        <Form>
          <Form.Item label="Title">
            {getFieldDecorator("title", {
              rules: [
                {
                  required: true,
                  message: "Required!",
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Date">
            {getFieldDecorator("date", {
              rules: [
                {
                  required: true,
                  message: "Required!",
                },
              ],
            })(<DatePicker style={{ width: "100%" }} />)}
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
});
