import React from "react";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";
import DataSet from "@antv/data-set";

export default class Groupedcolumn extends React.Component {
  render() {
    const { data } = this.props;
    const ds = new DataSet();
    const dv = ds.createView().source(data);
    return (
      <div>
        <Chart
          height={500}
          animate
          data={dv}
          forceFit
          padding={[20, "auto", 100, "auto"]}
        >
          <Axis name="date" />
          <Axis name="numberOfLeads" />
          <Legend />
          <Tooltip
            crosshairs={{
              type: "y",
            }}
          />
          <Geom type="interval" position="date*numberOfLeads" />
        </Chart>
      </div>
    );
  }
}
