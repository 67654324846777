import React, { useState, Fragment } from "react";
import { Button, Modal, Typography, Spin, notification } from "antd";
import Axios from "../../../axios";

export default ({ hasId, appId }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState(null);

  const openModal = async () => {
    if (hasId) {
      setVisible(true);
      setLoading(true);
      try {
        const { data } = await Axios.post("/apikey", {
          appId
        });
        setApiKey(data.key);
        setLoading(false);
      } catch (error) {}
    } else {
      notification.error({
        message: "Establish at least one account.",
        placement: "bottomRight"
      });
    }
  };

  return (
    <Fragment>
      <Modal
        title="Api Key"
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              justifyItems: "center"
            }}
          >
            <Spin />
          </div>
        ) : (
          <Typography.Text copyable>{apiKey}</Typography.Text>
        )}
      </Modal>
      <Button icon="key" type="primary" onClick={openModal}>
        Generate
      </Button>
    </Fragment>
  );
};
