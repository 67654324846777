import React, { Fragment, useState, useEffect } from "react";
import classes from "./AppHeader.module.less";
import Axios from "../../../axios";
import { Button, Tooltip, notification, Modal, Spin, Tag } from "antd";
import QRDialog from "../../Dialogs/QRDialog/QRDialog";
import { undoLastAppForm } from "../../../services/appDistributionService";
import history from "../../../history";
import RejectionsReasons from "../../AppDetailsDistribution/RejectionReasons/RejectionReasons";
import AppImageEdit from "../../UI/AppImageEdit/AppImageEdit";
import AppNameEdit from "../../UI/AppNameEdit/AppNameEdit";
const ButtonGroup = Button.Group;
const confirm = Modal.confirm;

export default ({ app, getHistory, getApp, loadingApp, ...props }) => {
  let [loadingDelete, setLoadingDelete] = useState(false);
  let [email, setEmail] = useState(null);
  let [displayQRDialog, setDisplayQRDialog] = useState(false);
  let [emailModal, setEmailModal] = useState(false);
  let [clients_linked, setClientsLinked] = useState(null);

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: "bottomLeft",
    });
  };

  const undoLastUpdate = async () => {
    let { data } = await undoLastAppForm(props.match.params.id);
    if (data && data.message === "one") {
      openNotificationWithIcon(
        "warning",
        "Invalid request, App has one App Form."
      );
    } else if (data && data.message === "success") {
      await getHistory();
      openNotificationWithIcon(
        "info",
        "Last App Form was undone successfully."
      );
    } else {
      openNotificationWithIcon("warning", "An error has ocurred.");
    }
  };

  const showConfirmUndone = () => {
    confirm({
      title: "Are you sure you want to undo the last app form update?",
      onOk() {
        undoLastUpdate();
      },
      onCancel() {},
    });
  };

  const handleDeleteApp = () => {
    openNotificationWithIcon("info", "Deleting app...");
    setLoadingDelete(true);
    Axios.delete("/apps", {
      data: {
        appId: props.match.params.id,
      },
    })
      .then((response) => {
        if (response.data.message == "success") {
          props.history.push("/apps");
        }
      })
      .catch((err) => {
        openNotificationWithIcon("error", "An error has ocurred.");
      });
    setLoadingDelete(false);
  };

  const confirmDeleteApp = () => {
    confirm({
      title: "Are you sure, this will remove the app from the database?",
      onOk() {
        handleDeleteApp();
      },
      onCancel() {},
    });
  };

  const showQrCode = () => {
    let package_name = app.main_information.package_name;
    if (package_name && !package_name.includes(" ")) setDisplayQRDialog(true);
    else
      openNotificationWithIcon(
        "error",
        "Please add a valid package name and save the information."
      );
  };

  const viewAsClient = () => {
    if (email === undefined || email === null) {
      openNotificationWithIcon(
        "error",
        "Connect at least one client to this app in order to view it as a client."
      );
    } else {
      window.open(
        `${process.env.REACT_APP_CLIENTS_URL}/?jwt=${
          window.localStorage.id_token
        }&email_to_impersonate=${encodeURIComponent(email)}`,
        "_blank"
      );
    }
  };

  const handleAcceptedDistribution = () => {
    openNotificationWithIcon("info", "Updating users and passwords...");
    Axios.put("/distribute", {
      id: props.match.params.id,
    })
      .then((response) => {
        if (response.data.message === "success") {
          props.getApp();
        }
        openNotificationWithIcon(
          "success",
          "Succesfully updated credentials for this app."
        );
      })
      .catch((error) => {
        openNotificationWithIcon(
          "error",
          "There was an error while connecting to the server."
        );
      });
  };

  const confirmDistributeOurAccount = () => {
    confirm({
      title: "Distribute under our account?",
      onOk() {
        handleAcceptedDistribution();
      },
      onCancel() {},
    });
  };

  const viewLastUpdate = () => {
    const notification = app.tasks.sort((a, b) => a.index - b.index).pop();
    if (notification) {
      let link = "";
      if (notification.category === "company") {
        link = "business-information";
      } else if (notification.category === "app") {
        link = "app-information";
      } else if (notification.category === "copyright") {
        link = "copyright-information";
      } else {
        link = "app-information";
      }
      history.push({
        pathname: `/${link}/${app.id}`,
      });
    } else {
      openNotificationWithIcon(
        "error",
        "There are no client form submissions yet."
      );
    }
  };

  const getCompletionTag = (completion) => {
    switch (completion) {
      case "COMPLETED":
        return <Tag color="green">Completed</Tag>;
      case "REMOVED":
        return <Tag color="red">Removed</Tag>;
      case "SUSPENDED":
        return <Tag color="volcano">Suspended</Tag>;
      case "ONGOING":
        return <Tag color="orange">In Progress</Tag>;
      case "NEW_APP":
        return <Tag color="purple">New App</Tag>;
      default:
        return <Tag color="volcano">Suspended</Tag>;
    }
  };

  const getAppTypeTag = (app_type) => {
    switch (app_type) {
      case "GAME_IAP":
        return (
          <span style={{ paddingTop: "3px" }}>
            <Tag color="blue">Game (IAP)</Tag>
          </span>
        );
      case "GAME_FREE":
        return (
          <span style={{ paddingTop: "3px" }}>
            <Tag color="geekblue">Game (Free)</Tag>
          </span>
        );
      default:
        return (
          <span style={{ paddingTop: "3px" }}>
            <Tag color="green">Type: App</Tag>
          </span>
        );
    }
  };

  const updateApp = () => {
    if (app && app.id) {
      const [first_email] = app.users.map((client) => client.email);
      setEmail(first_email);
      setClientsLinked(app.users);
    }
  };

  useEffect(() => {
    if (app && app.id) {
      updateApp();
    }
  }, [app]);

  return (
    <div className={classes.appHeader}>
      {!loadingApp ? (
        <Fragment>
          <AppImageEdit getApp={getApp} id={app.id} />
          <AppNameEdit getApp={getApp} id={app.id} name={app.name} />
          <span className={classes.appId}>App ID: {app.index}</span>
          <ButtonGroup>
            <Tooltip placement="topLeft" title="Undo Last App Form Submission">
              <Button onClick={() => showConfirmUndone()} icon="undo" />
            </Tooltip>
            <Tooltip placement="topLeft" title="Send Rejection Reasons">
              <Button onClick={() => setEmailModal(true)} icon="mail" />
            </Tooltip>
            <Tooltip placement="topLeft" title="Show QR Code">
              <Button onClick={showQrCode} icon="android" />
            </Tooltip>
            <Tooltip placement="topLeft" title="View As Client">
              <Button onClick={viewAsClient} icon="eye" />
            </Tooltip>
            <Tooltip placement="topLeft" title="Distribute Under Our Account">
              <Button onClick={confirmDistributeOurAccount} icon="team" />
            </Tooltip>
            {app.tasks && app.tasks.length > 0 ? (
              <Tooltip placement="topLeft" title="View Last Client Form Update">
                <Button onClick={viewLastUpdate} icon="clock-circle" />
              </Tooltip>
            ) : null}
            <Tooltip placement="topLeft" title="Delete App">
              <Button
                onClick={() => confirmDeleteApp()}
                loading={loadingDelete}
                icon="delete"
              />
            </Tooltip>
          </ButtonGroup>
          <div className={classes.completion}>
            {getCompletionTag(app.main_information.completion)}
            {getAppTypeTag(app.main_information.app_type)}
          </div>
          <QRDialog
            display={displayQRDialog}
            close={() => setDisplayQRDialog(false)}
            name={app.main_information.package_name}
          />
          <RejectionsReasons
            emailModal={emailModal}
            clients_linked={clients_linked}
            account_manager={app.main_information.account_manager}
            openNotificationWithIcon={openNotificationWithIcon}
            closeModal={() => setEmailModal(false)}
          />
        </Fragment>
      ) : (
        <Spin
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      )}
    </div>
  );
};
