import React, { useState, useEffect } from "react";
import classes from "./Companies.module.less";
import { Row, Button, Table, Avatar } from "antd";
import UsersCard from "./UsersCard/UsersCard";
import GridApps from "../UI/GridApps/GridApps";
import { Link } from "react-router-dom";
import { getAllCompanies } from "../../services/companiesService";
import NewCompanyModal from "./NewCompanyModal/NewCompanyModal";
import functions from "../../functions";
export default () => {
  let [loading, setLoading] = useState(false);
  let [data, setData] = useState([]);
  let [page, setPage] = useState(1);
  let [newCompany, setNewCompany] = useState(false);
  let columns = [
    {
      key: "name",
      dataIndex: "name",
      title: "Name",
      fixed: "left",
      width: "220px",
      render: (_, item) => {
        let logo = item.logo ? (
          <Avatar
            shape="square"
            src={`https://appinchina.space/uploads/${item.logo}`}
            size={45}
          />
        ) : (
          <Avatar shape="square" size={45} icon="bank" />
        );
        return (
          <div className={classes.avatar}>
            <Link to={`/companies/${item.id}`}>
              <div className={classes.logo}>{logo}</div>
              {item.name}
            </Link>
          </div>
        );
      },
    },
    {
      key: "total",
      dataIndex: "total",
      title: "Toval value",
      render: (_, val) => {
        const total = val.apps.reduce((prev, curr) => {
          return prev + curr.main_information.contract_value;
        }, 0);
        return functions.styleAsNumber(total) || 0;
      },
    },
    {
      key: "apps",
      dataIndex: "apps",
      title: "Apps",
      width: "275px",
      render: (apps) => {
        return apps ? <GridApps data={apps} /> : null;
      },
    },
    {
      key: "users",
      dataIndex: "users",
      title: "Users",
      render: (users, { apps }) => {
        return apps && apps.length ? <UsersCard data={apps} /> : null;
      },
    },
  ];

  const makeRequest = async () => {
    setLoading(true);
    let { data: response } = await getAllCompanies(page);
    setLoading(false);
    if (response && response.companies) {
      setData(response.companies);
    }
  };
  useEffect(() => {
    makeRequest();
  }, []);
  return (
    <div className={classes.container}>
      <Row type="flex" justify="space-between">
        <h3>Companies</h3>
        <Button
          disabled={loading}
          onClick={() => setNewCompany(true)}
          icon="plus"
          type="primary"
        >
          Add New Company
        </Button>
      </Row>
      <Table
        loading={loading}
        pagination={{ pageSize: 10 }}
        columns={columns}
        dataSource={data}
        size="small"
        scroll={{ x: 800 }}
        rowKey={(record) => record.id}
      />
      <NewCompanyModal
        visible={newCompany}
        data={data}
        updateData={setData}
        close={() => setNewCompany(false)}
      />
    </div>
  );
};
