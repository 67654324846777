import React, { useState, useEffect } from "react";
import classes from "./CompanyDetails.module.less";
import { Link } from "react-router-dom";
import {
  Card,
  Row,
  Button,
  Icon,
  Table,
  Tag,
  notification,
  Popconfirm,
} from "antd";
import {
  getCompanyById,
  deleteAppFromCompany,
} from "../../services/companiesService";
import AddAppModal from "./AddAppModal/AddAppModal";
import functions from "../../functions";
import UsersCard from "./UsersCard/UsersCard";
import CardDetailsForm from "./CardDetailsForm/CardDetailsForm";
export default ({ apps, ...props }) => {
  let [loading, setLoading] = useState(false);
  let [saving, setSaving] = useState(false);
  let [data, setData] = useState({});
  let [addApp, setAddApp] = useState(false);
  let [appsConnected, setAppsConnected] = useState([]);
  let [contractTotal, setContract] = useState(0);
  let columns = [
    {
      key: "name",
      dataIndex: "name",
      title: "Name",
      fixed: "left",
      align: "left",
      width: "150px",
      render: (name, item) => {
        return <Link to={`/apps/${item.id}/details`}> {name}</Link>;
      },
    },
    {
      key: "contract_value",
      dataIndex: "contract_value",
      title: "Contract Value",

      render: (_, item) => {
        return (
          functions.styleAsNumber(item.main_information.contract_value) || ""
        );
      },
    },
    {
      key: "users",
      dataIndex: "users",
      title: "Users",
      align: "left",
      render: (users) => {
        return users && users.length ? <UsersCard data={users} /> : null;
      },
    },
    {
      key: "account_status",
      dataIndex: "account_status",
      render: (_, item) =>
        item.main_information.account_status &&
        item.main_information.account_status === "1" ? (
          <Tag color="green">ACTIVE</Tag>
        ) : (
          <Tag color="volcano">INACTIVE</Tag>
        ),
    },
    {
      key: "id",
      dataIndex: "id",
      title: "",
      align: "left",
      render: (id) => {
        return (
          <Popconfirm
            onConfirm={() =>
              removeAppFromCompany({ app: id, company: data.id })
            }
            title="Are you sure you want to delete this app from company?"
          >
            <Button
              size="small"
              ghost
              shape="circle"
              type="danger"
              icon="delete"
            />
          </Popconfirm>
        );
      },
    },
  ];
  const removeAppFromCompany = async (values) => {
    setSaving(true);
    let { data: response } = await deleteAppFromCompany({ ...values });
    setSaving(false);
    makeRequest();
    if (response && response.message === "success") {
      notification.success({
        message: "Information saved.",
      });
    }
  };

  const makeRequest = async () => {
    setLoading(true);
    let { data: response } = await getCompanyById(props.match.params.id);
    if (response && response.details) {
      setData({ ...response.details[0] });
      setAppsConnected(response.details[0].apps);
      const total = response.details[0].apps.reduce((prev, curr) => {
        return prev + curr.main_information.contract_value;
      }, 0);
      setContract(total);
    }
    setLoading(false);
  };

  useEffect(() => {
    makeRequest();
  }, []);

  return (
    <div className={classes.container}>
      <Row type="flex" justify="start">
        <Button
          disabled={loading}
          style={{ margin: "0px 10px 0 0" }}
          onClick={() => window.history.back()}
        >
          <Icon type="left" />
          Go back
        </Button>
      </Row>
      <CardDetailsForm
        contractTotal={contractTotal}
        data={data}
        loading={loading}
      />
      <Card
        loading={loading}
        title="Apps"
        size="small"
        extra={
          <Button
            disabled={loading || saving}
            onClick={() => setAddApp(true)}
            icon="plus"
            type="primary"
          >
            Add App
          </Button>
        }
      >
        <Table
          scroll={{ x: 800 }}
          size="small"
          columns={columns}
          loading={loading || saving}
          dataSource={appsConnected}
        />
      </Card>
      <AddAppModal
        appsConnected={appsConnected}
        id={data.id}
        updateData={makeRequest}
        visible={addApp}
        close={() => setAddApp(false)}
        apps={apps}
      />
    </div>
  );
};
