import React, { useState, useEffect } from "react";
import moment from "moment";
import classes from "./Apps.module.less";
import AppCard from "./AppCard/AppCard";
import AdvancedFilter from "../UI/AdvancedFilter/AdvancedFilter";
import EventBus from "eventbusjs";
import { Table, Tag } from "antd";
import history from "../../history";
import functions from "../../functions";
import { Link } from "react-router-dom";
import AppImage from "../UI/AppImage/AppImage";
import { getAppsUnderDistribution } from "../../services/appsService";
export default ({ manager }) => {
  let [loading, setLoading] = useState(true);
  let [grid, setGrid] = useState(false);
  let [apps, setApps] = useState([]);
  let [filters, setFilters] = useState({});
  let [pageSize, setPageSize] = useState(10);
  let [current, setCurrent] = useState(1);
  let [total, setTotalItems] = useState(0);
  let [order, setOrder] = useState("date");
  const [sort, setSort] = useState({
    field: "appID",
    order: "desc",
  });
  const getCompletionTag = (completion) => {
    switch (completion) {
      case "COMPLETED":
        return <Tag color="green">Completed</Tag>;
      case "REMOVED":
        return <Tag color="red">Removed</Tag>;
      case "SUSPENDED":
        return <Tag color="volcano">Suspended</Tag>;
      case "ONGOING":
        return <Tag color="orange">In Progress</Tag>;
      case "NEW_APP":
        return <Tag color="purple">New App</Tag>;
      default:
        return <Tag color="volcano">Suspended</Tag>;
    }
  };
  const handleAppAdded = (app) => {
    let appsCopy = [...apps];
    appsCopy.unshift({
      ...app,
      authorized: 0,
    });
    EventBus.dispatch("UPDATE_APPS", this, appsCopy);
    history.push("/apps/" + app.id);
  };
  const getView = ({ grid }) => {
    setGrid(grid);
  };
  let columns = [
    {
      title: "#",
      dataIndex: "appID",
      key: "appID",
      align: "center",
      sorter: true,
      fixed: "left",
      width: "50px",
    },
    {
      title: "Image",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (id) => <AppImage id={id} />,
    },
    {
      title: "App Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (name, elem) => (
        <Link
          to={"/apps/" + elem.id}
          style={{ "text-decoration": "none" }}
          className={classes.link}
        >
          <b style={{ color: "#8dbf40" }}>{name}</b>
        </Link>
      ),
    },
    {
      title: "Total Downloads",
      dataIndex: "total_downloads",
      key: "total_downloads",
      align: "left",
      sorter: true,
      render: (total_downloads) => (
        <span>{functions.styleAsNumber(total_downloads)}</span>
      ),
    },
    {
      title: "7 Days Downloads",
      dataIndex: "current_downloads",
      key: "current_downloads",
      sorter: true,
      align: "left",
      render: (current_downloads) => (
        <span>{functions.styleAsNumber(current_downloads)}</span>
      ),
    },
    {
      title: "Distribution",
      dataIndex: "distribution",
      key: "distribution",
      align: "center",
      render: (distribution, elem) => (
        <span>
          {` ${elem.uploaded_stores_count ? elem.uploaded_stores_count : 0} / ${
            elem.stores_to_upload ? elem.stores_to_upload : 0
          }`}
        </span>
      ),
    },
    {
      title: "Completion",
      dataIndex: "completion",
      key: "completion",
      align: "center",
      render: (completion) => getCompletionTag(completion),
    },
    {
      title: "Last Contact Date",
      dataIndex: "last_contact_date",
      key: "last_contact_date",
      align: "center",
      sorter: true,
      render: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
    },
    {
      title: "Account Manager",
      dataIndex: "account_manager",
      key: "account_manager",
      align: "center",
      render: (a, item) => a || item["main_information.account_manager"],
    },
    {
      title: "Average NPS",
      dataIndex: "npsAvg",
      key: "npsAvg",
      align: "center",
      render: (nps) => (nps !== "NaN" ? nps : "N/A"),
    },
  ];
  const handleChange = (pagination, filters, sorter) => {
    setSort({ field: sorter.columnKey, order: sorter.order });
    setCurrent(pagination.current);
    setPageSize(pagination.pageSize);
    let actSort = {};
    if (sorter && sorter.order) {
      actSort = {
        sort: sorter.order === "ascend" ? "asc" : "desc",
        field: sorter.field,
      };
    } else {
      actSort = sort;
    }
    sendSearchRequest({
      page: pagination.current,
      results: pagination.pageSize,
      ...actSort,
    });
  };
  const getAllFilters = () => {
    let allFilters = "";
    filters.completion && filters.completion.length > 0
      ? (allFilters =
          allFilters + `completion=${filters.completion.join(",")}&`)
      : (allFilters = allFilters);
    let business_form_status =
      filters.business_form_status && filters.business_form_status.length > 0
        ? filters.business_form_status.join(",")
        : null;
    business_form_status
      ? (allFilters =
          allFilters + `business_form_status=${business_form_status}&`)
      : (allFilters = allFilters);
    let app_form_status =
      filters.app_form_status && filters.app_form_status.length > 0
        ? filters.app_form_status.join(",")
        : null;
    app_form_status
      ? (allFilters = allFilters + `app_form_status=${app_form_status}&`)
      : (allFilters = allFilters);
    let copyright_form_status =
      filters.copyright_form_status && filters.copyright_form_status.length > 0
        ? filters.copyright_form_status.join(",")
        : null;
    copyright_form_status
      ? (allFilters =
          allFilters + `copyright_form_status=${copyright_form_status}&`)
      : (allFilters = allFilters);
    let scc_owner =
      filters.scc_owner && filters.scc_owner.length > 0
        ? filters.scc_owner.join(",")
        : null;
    scc_owner
      ? (allFilters = allFilters + `scc_owner=${scc_owner}&`)
      : (allFilters = allFilters);
    let scc_status =
      filters.scc_status && filters.scc_status.length > 0
        ? filters.scc_status.join(",")
        : null;
    scc_status
      ? (allFilters = allFilters + `scc_status=${scc_status}&`)
      : (allFilters = allFilters);
    let icp_status =
      filters.icp_status && filters.icp_status.length > 0
        ? filters.icp_status.join(",")
        : null;
    icp_status
      ? (allFilters = allFilters + `icp_status=${icp_status}&`)
      : (allFilters = allFilters);
    let targeted_store =
      filters.targeted_store && filters.targeted_store.length > 0
        ? filters.targeted_store.join(",")
        : null;
    targeted_store
      ? (allFilters = allFilters + `targeted_store=${targeted_store}&`)
      : (allFilters = allFilters);
    let account_manager =
      filters.account_manager && filters.account_manager.length > 0
        ? filters.account_manager.join(",")
        : null;
    account_manager
      ? (allFilters = allFilters + `account_manager=${account_manager}&`)
      : (allFilters = allFilters);
    let confidential =
      filters.confidential && filters.confidential.length > 0
        ? filters.confidential.join(",")
        : null;
    confidential
      ? (allFilters = allFilters + `confidential=${confidential}&`)
      : (allFilters = allFilters);
    let country =
      filters.country && filters.country.length > 0
        ? filters.country.join(",")
        : null;
    country
      ? (allFilters = allFilters + `country=${country}&`)
      : (allFilters = allFilters);
    let industry =
      filters.industry && filters.industry.length > 0
        ? filters.industry.join(",")
        : null;
    industry
      ? (allFilters = allFilters + `industry=${industry}&`)
      : (allFilters = allFilters);

    let search = filters.search !== "" ? filters.search : null;
    search
      ? (allFilters = allFilters + `name=${search}&`)
      : (allFilters = allFilters);
    manager && manager !== ""
      ? (allFilters = allFilters + `account_manager=${manager}&`)
      : (allFilters = allFilters);

    let telephone = filters.telephone !== "" ? filters.telephone : null;
    telephone
      ? (allFilters = allFilters + `telephone=${telephone}&`)
      : (allFilters = allFilters);

      let revenue_model = filters.revenue_model !== "" ? filters.revenue_model : null;
      revenue_model
        ? (allFilters = allFilters + `revenue_model=${revenue_model}&`)
        : (allFilters = allFilters);

    let appType = filters.appType !== "" ? filters.appType : null;
    appType
      ? (allFilters = allFilters + `appType=${appType}&`)
      : (allFilters = allFilters);
    let serviceTags = filters.serviceTags !== "" ? filters.serviceTags : null;
    serviceTags
      ? (allFilters = allFilters + `serviceTags=${serviceTags.join(",")}&`)
      : (allFilters = allFilters);

    return allFilters;
  };
  const sendSearchRequest = async (values) => {
    let all = getAllFilters();
    setLoading(true);
    let { data: response } = await getAppsUnderDistribution({ ...values, all });
    setLoading(false);
    if (response && response.appsWithData) {
      setApps(response.appsWithData);
      setTotalItems(response.totalResults);
    }
  };
  const search = () => {
    sendSearchRequest({
      page: current,
      results: pageSize,
      ...sort,
    });
  };
  useEffect(() => {
    sendSearchRequest({
      page: current,
      results: pageSize,
      field:
        order === "downloads"
          ? "total_downloads"
          : order === "date"
          ? "createdAt"
          : order,
      order: "desc",
    });
  }, [order]);
  return (
    <>
      <div className={classes.grid}>
        <div>
          <div className={classes.Header}>
            {<h3>Apps ({total})</h3>}
            <AdvancedFilter
              filterApps={(filters) => setFilters({ ...filters })}
              appsView={getView}
              AddApp
              AppStateFilter
              updateAppAdded={handleAppAdded}
              appIds={apps}
              search={search}
              loading={loading}
              setOrder={setOrder}
              completion
            />
          </div>
        </div>

        {grid ? (
          apps.length > 0 ? (
            <div className={classes.AppsGrid}>
              {apps.map((app, index) => (
                <AppCard
                  appId={app.id}
                  key={index}
                  appName={app.name}
                  completion={app.completion}
                  targetStores={app.stores_to_upload}
                  targetStoresCompleted={app.uploaded_stores_count}
                  active={app.active}
                  downloads={app.total_downloads}
                />
              ))}
            </div>
          ) : (
            <div className={classes.NoResults}>
              <span>No results found</span>
            </div>
          )
        ) : (
          <div style={{ padding: 20 }}>
            <Table
              size="small"
              dataSource={apps}
              columns={columns}
              loading={loading}
              onChange={handleChange}
              rowKey={(record) => record.id}
              scroll={{ x: 950 }}
              pagination={{
                total,
                pageSize,
                current,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100", "1000"],
              }}
              style={{ background: "white" }}
            />
          </div>
        )}
      </div>
    </>
  );
};
