import React, { useState } from "react";
import { Tabs } from "antd";
import Accounts from "../AccountsTab";
import PaymentsSection from "../PaymentsSection/PaymentsSection";
import UserAnalyticsSection from "../UserAnalytics/UserAnalytics";
import classes from "./PaymentsAndAnalytics.module.less";
const { TabPane } = Tabs;

export default ({ ...props }) => {
  let [width, setWidth] = useState(window.innerWidth);
  const reportWindowSize = () => {
    setWidth(window.innerWidth);
  };
  window.onresize = reportWindowSize;

  return (
    <div className={classes.container}>
      <Tabs defaultActiveKey="1" tabPosition={width > 700 ? "left" : "top"}>
        <TabPane tab="Accounts" key="1">
          <Accounts {...props} />
        </TabPane>
        <TabPane tab="Analytics" key="2">
          <UserAnalyticsSection {...props} />
        </TabPane>
        <TabPane tab="Monetization" key="3">
          <PaymentsSection {...props} />
        </TabPane>
      </Tabs>
    </div>
  );
};
