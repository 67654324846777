import React, { useEffect, useState } from "react";
import RecordPaymentsDialog from "../../Dialogs/RecordPaymentDialog/RecordPaymentDialog";
import { Button, Table, Col, Row, Popconfirm, Tabs, notification } from "antd";
import functions from "../../../functions";
import classes from "../AppDistribution.module.less";
import Axios from "../../../axios";
const { TabPane } = Tabs;
const InvoicesComponent = ({ ...props }) => {
  const [recordPaymentsDialog, setRecordPaymentsDialog] = useState(false);
  const [payments, setPayments] = useState([]);
  const [paymentsDeleted, setPaymentsDeleted] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const fetchData = () => {
    if (props.appId) {
      Axios.get(`payments/${props.appId}`)
        .then((result) => {
          if (result.data.payments) {
            setPayments(result.data.payments);
            setPaymentsDeleted(result.data.paymentsDeleted);
            setLoading(false);
          }
        })
        .catch((error) => {
        });
    }
  }

  const handlePaymentsDialog = () => {
    setRecordPaymentsDialog(!recordPaymentsDialog)
  };

  const handleUpdatePayment = (paymentInfo) => {
    var appStateCopy = [...payments];
    if (appStateCopy && appStateCopy.length > 0) {
      appStateCopy.push(paymentInfo);
    } else {
      appStateCopy = [];
      appStateCopy.push(paymentInfo);
    }
    setPayments(appStateCopy);
  };

  const confirmDelete = (invoiceId) => {
    setDeleting(true);
    Axios.delete(`payments-invoice/${invoiceId}`)
      .then((result) => {
        if (result.data.message && result.data.message === "success") {
          let newPayments = [...payments];
          const invoiceDeleted = newPayments.filter(e => e.id === invoiceId);
          newPayments = newPayments.filter(e => e.id !== invoiceId)
          setPayments([...newPayments])
          setPaymentsDeleted([...paymentsDeleted, ...invoiceDeleted])
          notification.info({ message: "Invoice deleted." })
        }
      })
      .catch((error) => {
        notification.warn({ message: "An error has oocurred." })
      });
    setDeleting(false)
  }

  const columns = [
    {
      title: "Invoice",
      key: "invoice_number",
      dataIndex: "invoice_number",
      align: "left",
    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
      align: "center",
    },
    {
      title: "Status",
      key: "payment_status",
      dataIndex: "payment_status",
      align: "center",
    },
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      render: (date) => functions.dateFormat(date),
      align: "right",
    },
    {
      title: "Actions",
      key: "invoice_id",
      dataIndex: "invoice_id",
      render: (invoice_id) => (
        <Row type="flex" gutter={8} justify="space-around" align="middle">
          <Col>
            <a
              target="_blank"
              href={`https://go.xero.com/AccountsReceivable/View.aspx?invoiceid=${invoice_id}`}
            >
              Go to xero
            </a>
          </Col>
        </Row>
      ),
      align: "center",
    },
    {
      title: "",
      key: "id",
      dataIndex: "id",
      width: 200,
      render: (id) => (
        <Popconfirm
          title="Are you sure delete this invoice?"
          onConfirm={() => confirmDelete(id)}
        >
          <Button ghost type="danger" shape="circle" icon="delete" />
        </Popconfirm>
      ),
      align: "center",
    },
  ];
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div style={{ position: "relative" }} >
      <Tabs defaultActiveKey="active" loading={loading}>
        <TabPane tab="Active" key="active">
          <Table
            dataSource={payments}
            columns={columns}
            size="small"
            pagination={{ pageSize: 5 }}
            loading={loading || deleting}
            rowKey="invoice_id"
            style={{ marginTop: 10 }}
          />
          <span className={classes.addPaymentButton}>
            <Button
              onClick={handlePaymentsDialog}
              type="primary"
              icon="plus"
              style={{ marginTop: 10 }}
              shape="circle"
              loading={loading || deleting}
            />
          </span>
          <RecordPaymentsDialog
            display={recordPaymentsDialog}
            dialogHandler={handlePaymentsDialog}
            handleUpdatePayment={handleUpdatePayment}
            appId={props.appId}
          />
        </TabPane>
        <TabPane tab="Deleted" key="deleted">
          <Table
            dataSource={paymentsDeleted}
            columns={columns.filter(e => e.key !== "id")}
            size="small"
            pagination={{ pageSize: 5 }}
            loading={loading || deleting}
            rowKey="invoice_id"
            style={{ marginTop: 10 }}
          />
        </TabPane>
      </Tabs>
    </div >
  );
}

export default InvoicesComponent;