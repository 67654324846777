import React, { useState, useEffect } from "react";
import { Typography, List } from "antd";
// import classes from "./CredentialsModal.module.less";
import { Modal } from "antd";
const { Paragraph } = Typography;
export default ({ display, close, orderId, details }) => {
  let [data, setData] = useState([]);
  useEffect(() => {
    let values = Object.entries(details).map(([key, value]) => ({
      key,
      value
    }));
    setData(values);
  }, [details]);

  return (
    <Modal
      width={500}
      title="Specifications"
      visible={display}
      onCancel={close}
      footer={null}
    >
      <List
        bordered
        dataSource={data}
        renderItem={item => (
          <List.Item>
            <div>
              <h3>{item.key}</h3>
              <p>
                {item.value}
                {/* <Paragraph copyable>{item.value}</Paragraph> */}
              </p>
            </div>
          </List.Item>
        )}
      />
    </Modal>
  );
};
