import React from 'react';
import classes from './Dialog.module.css';
import { closeIcon } from '../../../images';

const dialog = (props) => {
	let shouldDisplay = props.display ? classes.Displayed : null;
	return (
		<div className={[classes.Dialog, shouldDisplay].join(" ")}>
			{props.close ? <img src={closeIcon} className={classes.CloseButton} alt="Close Icon" onClick={props.close} /> : null}
			<div className={classes.Backdrop} onClick={props.close}></div>
			<div className={classes.Container} style={{ width: props.width }}>
				{props.children}
			</div>
		</div>
	)
}

export default dialog;