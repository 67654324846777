import React from "react";
import { Form, Row, Col, Input, Checkbox, Radio, Select } from "antd";
import LineCard from "../../../UI/LineCard/LineCard";
import File from "../../../UI/ClientsFile/File";
import { FORM_OPTIONS } from "../../../../constants";
import classes from "./DistributionDetails.module.less";
import Badge from "../../../UI/BadgeNew/BadgeNew";
const { Item } = Form;
const { Option } = Select;

export default ({
  getFieldDecorator,
  isCorporateClient,
  disabled = false,
  data = {},
  submitFileHandler,
  deleteFile,
  modified,
}) => {
  return (
    <LineCard
      className={classes.container}
      title="Distribution Details"
      shadow
      style={{ marginTop: 15 }}
    >
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <>
            <Item label="Apple App Store China Download Link">
              <Badge dot color={modified.includes("itunes_link")}>
                {getFieldDecorator(`itunes_link`, {
                  initialValue: data.itunes_link,
                  rules: [
                    {
                      required: !data.apple_store_published,
                      message: "Required",
                    },
                  ],
                })(<Input disabled={disabled} />)}
              </Badge>
            </Item>
            <Item>
              <Badge
                dot
                color={
                  modified.includes("apple_store_published")
                    ? "red"
                    : "transparent"
                }
              >
                {getFieldDecorator(`apple_store_published`, {
                  initialValue: data.apple_store_published,
                  valuePropName: "checked",
                })(
                  <Checkbox disabled={disabled}>
                    {" "}
                    Please note that this must be the download link for China
                    (e.g. https://itunes.apple.com/cn/app/example). If you don't
                    have an iOS app or it's not yet published in China then
                    please click here.{" "}
                  </Checkbox>
                )}
              </Badge>
            </Item>
          </>

          <Item label="Google Play Link">
            <Badge enabled={modified.includes("google_play_link")}>
              {getFieldDecorator(`google_play_link`, {
                initialValue: data.google_play_link,
              })(<Input disabled={disabled} />)}
            </Badge>
          </Item>
          <Item>
            <Badge enabled={modified.includes("console_screenshot")}>
              {getFieldDecorator(`console_screenshot`, {
                initialValue: data.console_screenshot,
                rules: [{ required: true, message: "Required" }],
              })(
                <File
                  name="console_screenshot"
                  type="Image"
                  disabled={disabled}
                  title="Google Play Developer Console/Dashboard Screenshot"
                  validation={{ fileRequired: false }}
                  submitFile={submitFileHandler}
                  onDelete={() => deleteFile("console_screenshot")}
                  format={["png", "jpg", "jpeg"]}
                />
              )}
            </Badge>
          </Item>
          {isCorporateClient ? (
            <>
              <Item>
                <Badge dot color={modified.includes("statement")}>
                  {getFieldDecorator(`statement`, {
                    initialValue: data.statement,
                    rules: [
                      { required: isCorporateClient, message: "Required" },
                    ],
                  })(
                    <File
                      submitFile={submitFileHandler}
                      disabled={disabled}
                      type="File"
                      title="Authorization Statement"
                      validation={{ fileRequired: true }}
                      onDelete={() => deleteFile("statement")}
                      format={["png", "jpg", "jpeg", "pdf"]}
                    />
                  )}
                </Badge>
              </Item>
              <p>
                Please download{" "}
                <a
                  href="https://www.appinchina.co/clients/ApplicationAuthorizationStatementFormat.docx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  this template
                </a>{" "}
                and replace the sections in bold with the correct details. Then
                sign and stamp the statement (if you have a company stamp / chop
                available) and send us scanned version (PDF, JPG or PNG).{" "}
              </p>
            </>
          ) : null}
          <Item label="Who is the target audience of your app?">
            <Badge enabled={modified.includes("target_audience")}>
              {getFieldDecorator(`target_audience`, {
                initialValue: data.target_audience,
              })(<Input disabled={disabled} />)}
            </Badge>
          </Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Item label="Please select your Revenue Model">
            <Badge enabled={modified.includes("revenue_model")}>
              {getFieldDecorator(`revenue_model`, {
                initialValue: data.revenue_model
                  ? data.revenue_model.split(",")
                  : null,
              })(
                <Select
                  disabled={disabled}
                  style={{ width: "100%" }}
                  mode="multiple"
                >
                  {FORM_OPTIONS.revenue_model.map((it) => (
                    <Option key={it.value} value={it.value} label={it.label}>
                      {it.label}
                    </Option>
                  ))}
                </Select>
              )}
            </Badge>
          </Item>
          <Item label="Is this APK also usable on tablet devices?">
            <Badge enabled={modified.includes("tablet_devices")}>
              {getFieldDecorator(`tablet_devices`, {
                initialValue: data.tablet_devices,
                rules: [{ required: true, message: "Required" }],
              })(
                <Radio.Group disabled={disabled} name="tablet_devices">
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              )}
            </Badge>
          </Item>
          <Item label="Is your app free, freemium, or paid?">
            <Badge enabled={modified.includes("app_cost")}>
              {" "}
              {getFieldDecorator(`app_cost`, {
                initialValue: data.app_cost,
                rules: [{ required: true, message: "Required" }],
              })(
                <Radio.Group disabled={disabled} name="app_cost">
                  <Radio value={"FREE"}>Free</Radio>
                  <Radio value={"FREEMIUM"}>Freemium</Radio>
                  <Radio value={"PAID"}>Paid</Radio>
                </Radio.Group>
              )}
            </Badge>
          </Item>
          <Item label="Does your app include advertising?">
            <Badge enabled={modified.includes("advertising")}>
              {getFieldDecorator(`advertising`, {
                initialValue: data.advertising,
                rules: [{ required: true, message: "Required" }],
              })(
                <Radio.Group disabled={disabled} name="advertising">
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              )}
            </Badge>
          </Item>
          <Item label="Does your app require an internet connection in order to function?">
            <Badge enabled={modified.includes("internet")}>
              {getFieldDecorator(`internet`, {
                initialValue: data.internet,
                rules: [{ required: true, message: "Required" }],
              })(
                <Radio.Group disabled={disabled} name="internet">
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              )}
            </Badge>
          </Item>
          <Item label="Does the app support IPv6?">
            <Badge enabled={modified.includes("support_ipv6")}>
              {getFieldDecorator(`support_ipv6`, {
                initialValue: data.support_ipv6,
                rules: [{ required: true, message: "Required" }],
              })(
                <Radio.Group disabled={disabled} name="support_ipv6">
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              )}
            </Badge>
          </Item>
        </Col>
      </Row>
    </LineCard>
  );
};
