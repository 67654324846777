import React, { useState } from "react";
import { Table, Input, Tag, Button, Checkbox } from "antd";
import moment from "moment";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import classes from "./HistoryTable.module.less";
import EditModal from "./EditHistoryModal/EditHistoryModal";

export default ({ data, updateRow, updateCompleteRow }) => {
  let [visible, setVisible] = useState(false);
  let [history, setHistory] = useState({});
  let columnsHistory = [
    {
      key: "date",
      dataIndex: "date",
      title: "Date",
      render: (date) => moment(date).format("YYYY-MM-DD HH:mm"),
    },
    {
      key: "status",
      dataIndex: "status",
      title: "Status",
      render: (distribution_status) =>
        distribution_status ? STATUS[distribution_status] : "",
    },
    {
      key: "version_number",
      dataIndex: "version_number",
      title: "Version",
    },
    {
      key: "user",
      dataIndex: "user",
      title: "User",
    },
    {
      key: "description",
      dataIndex: "description",
      title: "Description",
      render: (description, item) =>
        description ? (
          <Ellipsis length={30} tooltip>
            {description}
          </Ellipsis>
        ) : (
          <Ellipsis length={30} tooltip>
            {item.client_notes}
          </Ellipsis>
        ),
    },
    {
      key: "internal_notes",
      dataIndex: "internal_notes",
      title: "Internal Notes",
      render: (notes) => (
        <Ellipsis length={30} tooltip>
          {notes}
        </Ellipsis>
      ),
    },
    {
      dataIndex: "id",
      key: "id",
      title: "",
      render: (id, item) => (
        <Button
          onClick={() => {
            setVisible(true);
            setHistory(item);
          }}
          icon="edit"
        >
          Edit
        </Button>
      ),
    },
  ];
  let columnsAttachments = [
    {
      dataIndex: "file_name",
      key: "file_name",
      title: "File Name",
    },
    {
      dataIndex: "file_size",
      key: "file_size",
      title: "File Size (bytes)",
    },
    {
      dataIndex: "url",
      key: "url",
      title: "",
      render: (url) =>
        url ? (
          <a
            rel="noopener noreferrer"
            href={`https://appinchina.space/download/${url}`}
            target="_blank"
          >
            <Button icon="cloud-download">Download</Button>
          </a>
        ) : null,
    },
  ];

  const STATUS = {
    NOT_SUBMITTED: <Tag color="gold">Not Submitted</Tag>,
    SUBMITTED_TO_STORE: <Tag color="cyan">Submitted to Store</Tag>,
    PUBLISHED: <Tag color="green">Published</Tag>,
    REJECTED: <Tag color="blue">Rejected</Tag>,
    RECLAIM: <Tag color="magenta">Reclaim</Tag>,
    PAUSED: <Tag color="purple">Paused</Tag>,
    SUSPENDED: <Tag color="volcano">Suspended</Tag>,
    REMOVED: <Tag color="red">Removed</Tag>,
  };

  return (
    <div className={classes.container}>
      <div className={classes.Form}>
        <div>
          <label>Distribution Status:</label>
          <div>
            {data.distribution_status ? STATUS[data.distribution_status] : ""}
          </div>
        </div>
        <div>
          <label>Live Version:</label>
          <div>
            <Input
              value={data.version}
              onChange={(event) =>
                updateRow({
                  field: "version",
                  value: event.target.value,
                  id: data.id,
                })
              }
            />
          </div>
        </div>
        <div>
          <label>Version Being Submitted:</label>
          <div>
            <Input
              value={data.version_being_submitted}
              onChange={(event) =>
                updateRow({
                  field: "version_being_submitted",
                  value: event.target.value,
                  id: data.id,
                })
              }
            />
          </div>
        </div>
        <div>
          <label>User:</label>
          <div>
            <Input.Password
              value={data.user}
              onChange={(event) =>
                updateRow({
                  field: "user",
                  value: event.target.value,
                  id: data.id,
                })
              }
            />
          </div>
        </div>
        <div>
          <label>Password:</label>
          <div>
            <Input.Password
              value={data.password}
              onChange={(event) =>
                updateRow({
                  field: "password",
                  value: event.target.value,
                  id: data.id,
                })
              }
            />
          </div>
        </div>
        <div>
          <label>Link:</label>
          <div>
            <Input
              value={data.link}
              onChange={(event) =>
                updateRow({
                  field: "link",
                  value: event.target.value,
                  id: data.id,
                })
              }
            />
          </div>
        </div>
        <div className={classes.storeHiddenCheckbox}>
          <Checkbox
            checked={data.hidden_clients}
            onChange={(event) =>
              updateRow({
                field: "hidden_clients",
                value: event.target.checked,
                id: data.id,
              })
            }
          >
            Hide this store on client's dashboard
          </Checkbox>
        </div>
      </div>

      {data.distribution_histories && data.distribution_histories.length > 0 ? (
        <div>
          <Table
            rowKey={(record) => record.id}
            dataSource={data.distribution_histories}
            columns={columnsHistory}
            size="small"
            expandedRowRender={(record) => {
              return (
                <div>
                  <h3>Attachments</h3>
                  <Table
                    size="small"
                    dataSource={record.history_attachments}
                    pagination={
                      record.history_attachments &&
                      record.history_attachments.length > 5
                        ? { pageSize: 5 }
                        : false
                    }
                    columns={columnsAttachments}
                  />
                  <h3>Editions</h3>
                  <Table
                    size="small"
                    dataSource={record.distribution_history_editions}
                    pagination={
                      record.distribution_history_editions &&
                      record.distribution_history_editions.length > 5
                        ? { pageSize: 5 }
                        : false
                    }
                    style={{ fontWeight: 300 }}
                    columns={columnsHistory}
                    expandedRowRender={(record) => {
                      return (
                        <div>
                          <h3>Edition Attachments</h3>
                          <Table
                            size="small"
                            dataSource={record.history_attachments_editions}
                            pagination={
                              record.history_attachments_editions &&
                              record.history_attachments_editions.length > 5
                                ? { pageSize: 5 }
                                : false
                            }
                            columns={columnsAttachments}
                          />
                        </div>
                      );
                    }}
                  />
                </div>
              );
            }}
          />
          <EditModal
            data={history}
            visible={visible}
            store={data.id}
            updateCompleteRow={updateCompleteRow}
            close={() => setVisible(false)}
          />
        </div>
      ) : null}
    </div>
  );
};
