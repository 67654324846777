import React, { Fragment, useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import classes from "./Editor.module.less";
import Axios from "axios";
import { Button, Form, Input, notification, Upload, Icon } from "antd";

export default Form.create()(
  ({ form, sendEmail, previous, initialValues = {} }) => {
    const [emailContent, setEmailContent] = useState(EditorState.createEmpty());
    const [uploadingImg, setUploadingImg] = useState(false);
    const { getFieldDecorator } = form;

    const uploadImage = async ({ file, onSuccess, onProgress, onError }) => {
      const timeStamp = new Date().getTime();
      const formData = new FormData();
      const result = `${timeStamp}/${file.name}`;
      formData.set("uploads[]", file);
      formData.set("name", file.name);
      formData.set("timestamp", timeStamp);
      setUploadingImg(true);
      let { data } = await Axios.post(
        "https://appinchina.space/upload",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onProgress({
              percent: percentCompleted,
            });
          },
        }
      );
      setUploadingImg(false);
      if (data) {
        onSuccess(result);
      } else {
        onError("error");
      }
    };
    const uploadProps = {
      listType: "picture",
    };
    const resetEmailContent = () => {
      let html = "";
      let contentBlock = htmlToDraft(html);
      if (contentBlock) {
        let contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        let editorState = EditorState.createWithContent(contentState);
        setEmailContent(editorState);
      }
    };

    const handleSubmit = () => {
      form.validateFields((err, values) => {
        if (!err) {
          let content = draftToHtml(
            convertToRaw(emailContent.getCurrentContent())
          );
          sendEmail({
            ...values,
            content,
          });
        } else {
          notification.error({
            message: "Fill all the required fields.",
            placement: "bottomRight",
          });
        }
      });
    };

    const uploadImageCallBack = (file) => {
      return new Promise((resolve, reject) => {
        const timeStamp = new Date().getTime();
        const formData = new FormData();
        formData.set("uploads[]", file);
        formData.set("name", file.name);
        formData.set("timestamp", timeStamp);
        Axios.post("https://appinchina.space/upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(({ data }) => {
            if (data == "success") {
              const result = `https://appinchina.space/download/${timeStamp}/${file.name}`;
              resolve({ data: { link: result } });
            }
          })
          .catch((error) => reject(error));
      });
    };
    return (
      <Fragment>
        <Form style={{ marginTop: 20 }} className={classes.container}>
          <b className={classes.labelModal}>Subject:</b>
          <Form.Item>
            {getFieldDecorator("subject", {
              rules: [
                {
                  required: true,
                  message: "Required!",
                },
              ],
              initialValue: initialValues.subject,
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Only images, documents and .zip compressed files are allowed to be attached">
            {getFieldDecorator("attachments", {
              rules: [],
            })(
              <Upload
                onRemove={(file) => setUploadingImg(false)}
                accept=".doc,.docx,.ppt,.key,.pages,.pdf,.jpg,.jpeg,.png,.svg,.gif,.xls,.xlsx,.numbers,.zip"
                {...uploadProps}
                customRequest={uploadImage}
              >
                <Button loading={uploadingImg}>
                  <Icon type={uploadingImg ? "loading" : "upload"} />
                  {uploadingImg
                    ? "Uploading File"
                    : " Click to Upload New File(s)"}
                </Button>
              </Upload>
            )}
          </Form.Item>
          <div className={classes.editor}>
            <Editor
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              editorState={emailContent}
              toolbar={{
                image: {
                  previewImage: true,
                  uploadCallback: uploadImageCallBack,
                },
              }}
              onEditorStateChange={(editorState) =>
                setEmailContent(editorState)
              }
            />
          </div>
        </Form>
        <div className={classes.buttons} className={classes.flex}>
          <Button icon="step-backward" type="primary" onClick={previous}>
            Previous Step
          </Button>
          <Button icon="undo" onClick={resetEmailContent}>
            Reset Content
          </Button>
          <Button
            icon="mail"
            type="primary"
            onClick={handleSubmit}
            // style={{ marginLeft: "16px" }}
          >
            Send Mail
          </Button>
        </div>
      </Fragment>
    );
  }
);
