import React, { useState, useEffect } from "react";
import classes from "./Renewals.module.less";
import AppCard from "../Apps/AppCard/AppCard";
import moment from "moment";
import {
  Icon,
  Button,
  Popover,
  Checkbox,
  Radio,
  Table,
  Tag,
  Input,
  Switch,
  Row,
} from "antd";
import functions from "../../functions";
import { Link } from "react-router-dom";
import AppImage from "../UI/AppImage/AppImage";
import { getOverdueApps } from "../../services/appsService";
const RadioGroup = Radio.Group;
export default () => {
  let [loading, setLoading] = useState(false);
  let [switchVal, setSwitch] = useState(true);
  let [filteredApps, setFilteredApps] = useState(false);
  let [search, setSearch] = useState("");
  let [grid, setGrid] = useState(false);
  let [order, setOrder] = useState("date");
  let [actualDate, setActualDate] = useState(moment().format("YYYY-MM-DD"));
  let [filters, setFilters] = useState({
    SUSPENDED: true,
    ONGOING: true,
    NEW_APP: true,
  });
  let [pageSize, setPageSize] = useState(10);
  let [current, setCurrent] = useState(1);
  let [total, setTotalItems] = useState(0);
  let [sort, setSort] = useState({
    field: "date",
    order: "desc",
  });
  let columns = [
    {
      title: "#",
      dataIndex: "appID",
      key: "appID",
      align: "center",
      sorter: true,
      fixed: "left",
      width: "50px",
    },
    {
      title: "Image",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (id) => <AppImage id={id} />,
    },
    {
      title: "App Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (name, elem) => (
        <Link
          to={"/apps/" + elem.id}
          style={{ "text-decoration": "none" }}
          className={classes.link}
        >
          <b style={{ color: "#8dbf40" }}>{name}</b>
        </Link>
      ),
    },
    {
      title: "Contract Value",
      dataIndex: "contract_value",
      key: "contract_value",
      align: "center",
      sorter: true,
      render: (contract_value) =>
        contract_value ? (
          <span>{functions.styleAsNumber(contract_value)}</span>
        ) : (
          <Tag>Not Set</Tag>
        ),
    },
    {
      title: "Next Payment Date",
      dataIndex: "next_payment_date",
      key: "next_payment_date",
      align: "center",
      sorter: true,
      render: (next_payment_date) =>
        next_payment_date ? (
          <span>{moment(next_payment_date).format("YYYY-MM-DD")}</span>
        ) : (
          <Tag>Not Set</Tag>
        ),
    },
    {
      title: "Renewal Date",
      dataIndex: "renewal_date",
      key: "renewal_date",
      align: "center",
      sorter: true,
      render: (renewal_date) =>
      renewal_date ? (
          <span>{moment(renewal_date).format("YYYY-MM-DD")}</span>
        ) : (
          <Tag>Not Set</Tag>
        ),
    },
    {
      title: "Completion",
      dataIndex: "completion",
      key: "completion",
      align: "center",
      render: (completion) => getCompletionTag(completion),
    },
  ];
  const getCompletionTag = (completion) => {
    switch (completion) {
      case "COMPLETED":
        return <Tag color="green">Completed</Tag>;
      case "REMOVED":
        return <Tag color="red">Removed</Tag>;
      case "SUSPENDED":
        return <Tag color="volcano">Suspended</Tag>;
      case "ONGOING":
        return <Tag color="orange">In Progress</Tag>;
      case "NEW_APP":
        return <Tag color="purple">New App</Tag>;
      default:
        return <Tag color="volcano">Suspended</Tag>;
    }
  };

  const handleCheckbox = (value) => {
    const stateCopy = { ...filters };
    stateCopy[value] = !stateCopy[value];
    setFilters(stateCopy);
  };
  const handleChange = (pagination, filters, sorter) => {
    setSort({ field: sorter.columnKey, order: sorter.order });
    setCurrent(pagination.current);
    setPageSize(pagination.pageSize);
    let actSort = {};
    if (sorter && sorter.order) {
      actSort = {
        sort: sorter.order === "ascend" ? "asc" : "desc",
        field: sorter.columnKey,
      };
    } else {
      actSort = sort;
    }
    searchRequest({
      page: pagination.current,
      results: pagination.pageSize,
      ...actSort,
    });
  };
  let content_checkbox = (
    <>
      <Checkbox
        onChange={() => handleCheckbox("NEW_APP")}
        checked={filters.NEW_APP}
      >
        New App
      </Checkbox>
      <br />
      <Checkbox
        onChange={() => handleCheckbox("SUSPENDED")}
        checked={filters.SUSPENDED}
      >
        Suspended
      </Checkbox>
      <br />
      <Checkbox
        onChange={() => handleCheckbox("ONGOING")}
        checked={filters.ONGOING}
      >
        Ongoing
      </Checkbox>
      <br />
    </>
  );
  let content_radio = (
    <RadioGroup onChange={(val) => setOrder(val)} value={order}>
      <Radio value={"date"}>Date</Radio>
      <br />
      <Radio value={"name"}>Name</Radio>
      <br />
      <Radio value={"downloads"}>Downloads</Radio>
    </RadioGroup>
  );
  const searchRequest = async (values) => {
    setLoading(true);
    let all = `date=${actualDate} ${
      search != "" ? `&name=${search}` : ""
    } ${`&account_status=${switchVal ? 1 : 0}`}`;
    let completion = "&completion=";
    Object.entries(filters).map((e) => {
      if (e[1]) {
        if (e[0] === "ONGOING") {
          completion = completion + `ONGOING,COMPLETED,`;
        } else {
          completion = completion + `${e[0]},`;
        }
      }
    });
    if (completion != "&completion=") {
      all = all + completion;
    }
    let { data } = await getOverdueApps({ ...values, all });
    setLoading(false);
    if (data && data.apps) {
      setTotalItems(data.totalResults);
      setFilteredApps(data.apps);
    }
  };
  useEffect(() => {
    searchRequest({
      page: current,
      results: pageSize,
      ...sort,
    });
  }, []);
  useEffect(() => {
    searchRequest({
      page: current,
      results: pageSize,
      ...sort,
    });
  }, [filters, switchVal]);

  return (
    <>
      <div className={classes.grid}>
        <div>
          <div className={classes.Header}>
            {<h3>Apps ({total || 0})</h3>}
            <div className={classes.Container}>
              <div className={classes.SearchContainer}>
                <Input.Search
                  disabled={loading}
                  allowClear
                  enterButton
                  onSearch={() =>
                    searchRequest({
                      page: current,
                      results: pageSize,
                      ...sort,
                    })
                  }
                  value={search}
                  placeholder={
                    loading ? "Searching..." : "Search for an app..."
                  }
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className={classes.filterButtons}>
                <Row
                  style={{ width: "100%" }}
                  type="flex"
                  justify="space-between"
                >
                  <span className={classes.switch}>
                    <p className={classes.label}>
                      {switchVal ? "Active Apps" : "Inactive Apps"}
                    </p>
                    <div style={{ width: "50px" }}>
                      <Switch
                        disabled={loading}
                        checked={switchVal}
                        onChange={(checked) => setSwitch(checked)}
                      />
                    </div>
                  </span>
                  <Button disabled={loading} onClick={() => setGrid(!grid)}>
                    <Icon type={grid ? "bars" : "appstore-o"} />
                  </Button>
                  <div>
                    <Popover content={content_checkbox} placement="bottomRight">
                      <Button disabled={loading}>
                        <Icon type="filter" />
                      </Button>
                    </Popover>
                  </div>
                  {grid ? (
                    <div>
                      <Popover content={content_radio} placement="bottomRight">
                        <Button>
                          <Icon type="sort-ascending" />
                        </Button>
                      </Popover>
                    </div>
                  ) : null}
                </Row>
              </div>
            </div>
          </div>
        </div>

        {grid ? (
          filteredApps.length ? (
            <div className={classes.AppsGrid}>
              {filteredApps.map((app, index) => (
                <AppCard
                  appId={app.id}
                  key={index}
                  appName={app.name}
                  completion={app.completion}
                  targetStores={app.stores_to_upload}
                  targetStoresCompleted={app.uploaded_stores_count}
                  active={app.active}
                  downloads={app.total_downloads}
                />
              ))}
            </div>
          ) : (
            <div className={classes.NoResults}>
              <span>No results found</span>
            </div>
          )
        ) : (
          <div style={{ padding: 20 }}>
            <Table
              size="small"
              dataSource={filteredApps}
              columns={columns}
              scroll={{ x: 600 }}
              loading={loading}
              onChange={handleChange}
              rowKey={(record) => record.id}
              pagination={{
                total,
                pageSize,
                current,
                showSizeChanger: true,
                pageSizeOptions: ["10"],
              }}
              style={{ background: "white" }}
            />
          </div>
        )}
      </div>
    </>
  );
};
