import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "antd";
import AppCard from "./AppsCard/AppsCard";
import NewAppModal from "./NewAppModal/NewAppModal";

const AppsLinked = ({ client, getFieldDecorator, options, form }) => {
  let [displayModal, setDisplayModal] = useState(false);
  let [appsLinked, setApps] = useState([]);
  let appUsers = form.getFieldValue(`apps`);
  if (appUsers && appUsers.length > 0) {
    let appIDs = appUsers.map(({ id }) => id);
    options = options.filter(app => !appIDs.includes(app.id));
  }
  useEffect(() => {
    setApps(client.apps);
  }, [client]);

  return (
    <Card style={{ textAlign: "left" }}>
      {appsLinked ? (
        <AppCard
          apps={appsLinked}
          getFieldDecorator={getFieldDecorator}
          options={options}
          clientId={client.id}
          form={form}
        />
      ) : null}
      <Row type="flex" justify="end">
        <Button
          type="primary"
          onClick={() => setDisplayModal(true)}
          icon="plus"
        >
          Add App
        </Button>
      </Row>
      <NewAppModal
        appForm={form}
        updateApps={setApps}
        close={() => setDisplayModal(false)}
        display={displayModal}
        options={options}
      />
    </Card>
  );
};

export default AppsLinked;
