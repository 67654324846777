import React, { Component, Fragment } from "react";
import classes from "./File.module.less";
import { imgIcon, fileIcon, videoIcon } from "../../../images";
import { Modal, Progress, Icon, notification } from "antd";
import Axios from "axios";
import { QRCode } from "react-qr-svg";
const AppInfoParser = require("app-info-parser");

const confirm = Modal.confirm;

class File extends Component {
  state = {
    files: [],
    previewFile: "",
    dialogPreview: false,
    percent: null,
    rendered: false,
  };

  constructor(props) {
    super(props);
    this.divRef = React.createRef();
  }

  calculateHeight = () => {
    let { validation } = this.props;
    if (!validation) return null;
    let { resolution } = validation;
    if (!resolution) return {};
    let { width, height } = resolution;
    let ratio = height / width;
    let containerWidth =
      !!this.divRef && !!this.divRef.current && this.divRef.current.offsetWidth;
    return containerWidth ? { height: containerWidth * ratio } : {};
  };
  componentDidUpdate = () => {
    if (!this.props.value && this.state.files && this.state.files.length)
      this.setState({
        files: [],
        previewFile: "",
      });
  };
  getDeleteButton = () => (
    <span className={classes.icon} onClick={(event) => this.showConfirm(event)}>
      <Icon
        type="delete"
        theme="filled"
        style={{ zIndex: 1000, cursor: "pointer" }}
      />
    </span>
  );

  showConfirm = (event) => {
    event.persist();
    let { onDelete } = this.props;
    confirm({
      title: "Do you want to remove this file?",
      onOk() {
        onDelete();
      },
    });
  };

  renderImagePreview = () => {
    let { value, previewWidth, previewHeight, previewValue } = this.props;
    // console.log("previewValue", previewValue);
    if (previewValue || (value && previewWidth && previewHeight)) {
      return (
        <img
          src={
            previewValue
              ? previewValue
              : "https://appinchina.space/uploads/" + value
          }
          className={classes.preview}
        />
      );
    } else {
      return null;
    }
  };

  calculateHeight = () => {
    let { previewWidth, previewHeight } = this.props;
    if (previewWidth && previewHeight) {
      let ratio = previewHeight / previewWidth;
      let containerWidth =
        !!this.divRef &&
        !!this.divRef.current &&
        this.divRef.current.offsetWidth;
      return containerWidth ? { height: containerWidth * ratio } : {};
    }
    return null;
  };

  render() {
    let {
      modified,
      value,
      source,
      valid,
      disabled,
      error,
      validation,
      validated,
      customIcon,
    } = this.props;
    if (source) {
      value = source;
    }
    let { files, percent } = this.state;
    let modifiedClass = modified ? classes.modified : "";
    let hasFiles = value || (files && files.length) ? classes.HasFiles : null;
    let validityClass =
      !disabled && !valid && (value || validation.fileRequired) && validated
        ? classes.Invalid
        : "";
    let disabledClass = disabled ? classes.Disabled : "";
    let customError = value ? error : "Required";
    let iconApp = customIcon;

    if (!this.state.rendered) {
      this.setState({ rendered: true });
    }
    return (
      <Fragment>
        <div
          className={[
            classes.Container,
            hasFiles,
            validityClass,
            modifiedClass,
            disabledClass,
          ].join(" ")}
          ref={this.divRef}
          style={
            this.props.name == "preview_video"
              ? { height: 250 }
              : this.calculateHeight()
          }
        >
          <input
            type="file"
            name={this.props.name}
            onChange={(event) => {
              this.handleFileChange(event);
              event.target.value = null;
            }}
            accept={this.props.accept}
          />
          <div className={classes.inputContainer}>
            {percent && percent !== 100 ? (
              <div className={classes.progressContainer}>
                <Progress type="circle" percent={percent} width={60} />
              </div>
            ) : (
              <Fragment>
                {this.renderImagePreview()}
                {this.getVideoPreview()}
                <img
                  src={iconApp && value ? iconApp : this.getIcon()}
                  alt="File input"
                  className={iconApp ? classes.CustomIcon : classes.Icon}
                />
                <p>{this.props.title}</p>
              </Fragment>
            )}

            {this.props.value && !(percent && percent !== 100) ? (
              <div className={classes.icons}>
                {this.getQrCode()}
                {this.getPreviewElement()}
                {this.getDownloadButton()}
                {!this.props.disabled ? this.getDeleteButton() : null}
              </div>
            ) : null}

            {this.props.disabled ? (
              <div className={classes.DisabledFilter} />
            ) : null}
          </div>
        </div>
        {!disabled &&
        !valid &&
        (value || validation.fileRequired) &&
        validated ? (
          <p className={classes.textError}>{customError}</p>
        ) : null}
      </Fragment>
    );
  }

  displayQrModal = () => {
    Modal.info({
      title: "The file can be downloaded with the following QR code",
      content: (
        <QRCode
          bgColor="#FFFFFF"
          fgColor="#000000"
          level="Q"
          style={{ width: 276, margin: "20px 0px 0px", display: "block" }}
          value={`https://appinchina.space/uploads/${this.props.value}`}
        />
      ),
      onOk() {},
    });
  };

  getQrCode = () => {
    // console.log("this.props.format", this.props.format);
    return this.props.value &&
      this.props.format &&
      this.props.format.includes("apk") ? (
      <span className={classes.icon} onClick={this.displayQrModal}>
        <Icon
          type="qrcode"
          style={{ color: "#757575", zIndex: 1000, cursor: "pointer" }}
        />
      </span>
    ) : null;
  };
  getVideoPreview = () => {
    let videoExtensions = /(\.mp4|\.mov)$/i;
    let content = null;
    if (videoExtensions.exec(this.props.value)) {
      content = (
        <video
          className={classes.video}
          width="320"
          height="240"
          autoplay="autoplay"
          loop="loop"
          muted
        >
          <source
            src={`https://appinchina.space/uploads/${this.props.value}`}
            type="video/mp4"
          />
          <source
            src={`https://appinchina.space/uploads/${this.props.value}`}
            type="video/mov"
          />
          Your browser does not support the video tag.
        </video>
      );
    }
    return content;
  };

  getPreviewElement = () => {
    let fileExtensions = /(\.pdf)$/i;
    let imageExtensions = /(\.jpg|\.png|\.jpeg|\.svg\.jpg)$/i;
    let content = null;
    if (imageExtensions.exec(this.props.value)) {
      content = (
        <img
          alt="example"
          style={{ width: "100%" }}
          src={`https://appinchina.space/uploads/${this.props.value}`}
        />
      );
    } else if (fileExtensions.exec(this.props.value)) {
      content = (
        <object
          data={`https://appinchina.space/uploads/${this.props.value}`}
          type="application/pdf"
          width="100%"
          height="650px"
        >
          <p>
            <b>Example fallback content</b>: This browser does not support PDFs.
            Please download the PDF to view it:{" "}
            <a
              href={`https://appinchina.space/download/${this.props.value}`}
              download
            >
              Download PDF
            </a>
            .
          </p>
        </object>
      );
    }

    return content ? (
      <Fragment>
        <span className={classes.icon}>
          <Icon
            type="eye"
            theme="filled"
            style={{ zIndex: 1000, cursor: "pointer" }}
            onClick={this.dialogHandler}
          />
        </span>
        <Modal
          visible={this.state.dialogPreview}
          onCancel={this.dialogHandler}
          footer={null}
        >
          {content}
        </Modal>
      </Fragment>
    ) : null;
  };

  dialogHandler = () => {
    this.setState((prevState) => ({
      dialogPreview: !prevState.dialogPreview,
    }));
  };

  getDownloadButton = () => {
    return this.props.value ? (
      <a
        rel="noopener noreferrer"
        href={`https://appinchina.space/download/${this.props.value}`}
        target="_blank"
        download
      >
        <span className={classes.icon}>
          <Icon
            type="cloud-download"
            style={{ color: "#757575", zIndex: 1000, cursor: "pointer" }}
          />
        </span>
      </a>
    ) : null;
  };

  getIcon = () => {
    switch (this.props.type) {
      case "Image":
        return imgIcon;
      case "Video":
        return videoIcon;
      default:
        return fileIcon;
    }
  };
  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: "bottomLeft",
    });
  };

  handleFileSubmit = (event, fieldToUpdate, timeStamp, file) => {
    event.persist();
    this.setState({ percent: 1 });
    const formData = new FormData();

    formData.set("uploads[]", file);
    formData.set("name", file.name);
    formData.set("timestamp", timeStamp);
    Axios.post("https://appinchina.space/upload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        this.setState({
          percent: percentCompleted > 0 ? percentCompleted - 1 : 1,
          rendered: false,
        });
      },
    }).then(({ data }) => {
      if (data === "success") {
        setTimeout(() => {
          this.setState({
            percent: 100,
            rendered: false,
          });
          if (this.props.submitFile) {
            // console.log("EVENT 2", event.target.files);
            this.props.submitFile(fieldToUpdate, event, file);
          }
        }, 1000);
      }
    });
  };

  readAPK = (event, file) => {
    const parser = new AppInfoParser(file);
    parser.parse().then((pkgInfo) => {
      if (pkgInfo && this.props.package_name.value !== pkgInfo.package) {
        this.openNotificationWithIcon("error", this.props.package_name.message);
      }
    });
  };

  validateFormat = (event, file) => {
    let fileFormat = !!file.name.split(".") ? file.name.split(".").pop() : "";
    if (this.props.format.some((it) => it.includes(fileFormat.toLowerCase()))) {
      if (
        this.props.format == "apk" &&
        this.props.package_name &&
        this.props.package_name.value != ""
      ) {
        this.readAPK(event, file);
      }
      return true;
    } else {
      this.openNotificationWithIcon("warning", "Invalid format.");
      return false;
    }
  };

  validateResolution = (event, file) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        let width = img.naturalWidth,
          height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src);
        let { resolution } = this.props.validation;
        if (
          (parseInt(width) === parseInt(resolution.width) &&
            parseInt(height) === parseInt(resolution.height)) ||
          (parseInt(height) === parseInt(resolution.width) &&
            parseInt(width) === parseInt(resolution.height))
        ) {
          resolve(true);
        } else {
          this.openNotificationWithIcon(
            "warning",
            `Please upload an image with a valid resolution ${resolution.width}x${resolution.height}px.`
          );
          resolve(false);
        }
      };
    });
  };
  validateSize = (event, file) => {
    let fileSize = file.size;
    let { size } = this.props.validation;
    if (fileSize <= size) {
      return true;
    } else {
      this.openNotificationWithIcon(
        "warning",
        `Please upload an image with a max size of  ${(+size / 1000000).toFixed(
          0
        )}MB`
      );
      return false;
    }
  };

  handleFileChange = async (event) => {
    event.persist();
    let valid = true;
    if (event.target.files.length > 0) {
      const [file] = event.target.files;
      if (this.props.format) {
        valid = valid && this.validateFormat(event, file);
      }
      if (this.props.validation.size) {
        valid = valid && this.validateSize(event, file);
      }
      if (this.props.validation.resolution) {
        let resolutionValid = await this.validateResolution(event, file);
        valid = valid && resolutionValid;
      }
      if (valid) {
        const timeStamp = new Date().getTime();
        const fieldToUpdate = {
          name: event.target.name,
          value: `${timeStamp}/${file.name}`,
        };
        if (this.props.handleChange) {
          this.props.handleChange(event, file, () =>
            this.handleFileSubmit(event, fieldToUpdate, timeStamp, file)
          );
        } else {
          this.handleFileSubmit(event, fieldToUpdate, timeStamp, file);
        }
      }
    }
  };
}
export default File;
