import React, { useState, useEffect } from "react";
import { defaultApp } from "../../../images";
import classes from "./AppImage.module.less";
import axios from "../../../axios";
import { Spin } from "antd";

async function getImage(id) {
  let response = null;
  try {
    response = await axios.get(`/image/${id}`);
  } catch (error) {
    console.log("error", error);
  }
  return response;
}

export default ({ id, size }) => {
  let [image, setImage] = useState(-1);

  const getData = async () => {
    setImage(-1);
    let response = await getImage(id);
    setImage(!!response && !!response.data && response.data.image);
  };

  useEffect(() => {
    getData();
  }, [id]);

  if (image === -1) {
    return (
      <div
        className={[classes.imgApp, size === "small" ? classes.small : ""].join(
          " "
        )}
      >
        <Spin />
      </div>
    );
  }

  let imageData = image ? image : defaultApp;
  imageData = /uploads|static|data:image|googleusercontent/.test(imageData)
    ? imageData
    : `data:image/png;base64,${imageData}`;
  return (
    <img
      src={imageData.replace("appinchina.xyz","appinchina.space")}
      alt=""
      className={[classes.imgApp, size === "small" ? classes.small : ""].join(
        " "
      )}
    />
  );
};
