import React, { useEffect, useState } from "react";
import { Spin, Table } from "antd";
import { getAllFeedbacks } from "../../services/feedbacksService";
import classes from "./UserFeedback.module.less";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import moment from "moment";
import { sortByProperty } from "../../utils/sorters";

export default () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    try {
      const { data } = await getAllFeedbacks();
      if (!data.error) {
        setLoading(false);
        setTableData(data.data.sort(sortByProperty("createdAt", "ascend")));
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
      align: "center",
      fixed: "left",
      width: "100px",
      sorter: sortByProperty("createdAt", "ascend"),
      render: (date) => moment(date).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      sortDirections: ["descend", "ascend"],
      align: "center",
      sorter: sortByProperty("rating", "ascend"),
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
      render: (comments) => (
        <Ellipsis length={30} tooltip>
          {comments}
        </Ellipsis>
      ),
    },
    {
      title: "User Email",
      dataIndex: "user",
      key: "user",
      render: (user) => user.email,
    },
  ];
  return loading ? (
    <Spin
      tip="Loading..."
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  ) : (
    <div className={classes.container}>
      <h2>Feedbacks Submitted</h2>
      <Table
        rowKey={(record) => record.user.user_id}
        dataSource={tableData}
        columns={columns}
        size="small"
        pagination={{ pageSize: 10 }}
        scroll={{ x: 700 }}
      />
    </div>
  );
};
