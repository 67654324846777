import React, { useEffect, useState } from "react";
import {
  Select,
  Row,
  Col,
  Spin,
  Modal,
  notification,
  Input,
  Button
} from "antd";
import {
  getMailChimpService,
  getSingleList,
  newMember,
  deleteListMember,
  searchMailChimpUser,
  synchronizeMailchimpList
} from "../../services/mailChimpService";
import AddNewMember from "./AddNewMember";
import MailChimpMembers from "./MailChimpMembers";
const { Option } = Select;
const { Search } = Input;

export default () => {
  const DEFAULT_LIST = { id: "f03043a2c2", name: "AppInChina - Dashboard List" };
  const [lists, setLists] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(false);
  const [isAsync, setIsAsync] = useState(true);
  const [synchronizing, setSynchronizing] = useState(false);
  const [user, setUser] = useState("");
  const [listInfo, setListInfo] = useState(DEFAULT_LIST.id);
  const [listName, setListName] = useState(DEFAULT_LIST.name)
  const [pageTotalItems, setPageTotalItems] = useState(15);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    try {
      const { data } = await getMailChimpService();
      if (!data.error) {
        setLoading(false);
        setLists(data.lists);
        setPageTotalItems(data.total_items);
        setTableData(data.members);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleList = async (id, page = 1) => {
    try {
      setListInfo(id);
      setLoadingTable(true);      
      const { data } = await getSingleList(id, page);
      if (!data.error) {
        setLoading(false);
        setLoadingTable(false);
        setPageTotalItems(data.total_items);
        setTableData(data.members);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const addMemberToList = async (values, callback) => {
    try {
      const { data } = await newMember(values);
      if (!data.error) {
        callback();
        handleList(listInfo.id);
        notification.success({
          placement: "bottomRight",
          message: "Member successfully added."
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteMember = async values => {
    try {
      Modal.confirm({
        title: "Are your sure you want to delete this member from the list ? ",
        async onOk() {
          await deleteListMember({
            ...values,
            list_id: listInfo.id
          });
          handleList(listInfo.id);
          notification.success({
            placement: "bottomRight",
            message: "Member successfully deleted."
          });
        }
      });
    } catch (error) {}
  };

  const paginationHandler = page => {
    try {
      setIsAsync(true);
      handleList(listInfo, page);
    } catch (error) {
      console.log(error);
    }
  };

  const searchUser = async value => {
    try {
      setLoadingTable(true);
      setIsAsync(false);
      const { data } = await searchMailChimpUser(value, listInfo);
      if (!data.error) {
        setPageTotalItems(data.result.length);
        setTableData(data.result);
        setLoadingTable(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const synchronizeWithDashboard = async () => {
    try {
      setListInfo("f03043a2c2");
      setSynchronizing(true);
      const { data } = await synchronizeMailchimpList();
      if (data.message === "success") {
        setSynchronizing(false);
        await handleList("f03043a2c2");
      }
    } catch (error) {
      setSynchronizing(false);
    }
  };

  const reset = () => {
    setIsAsync(true);
    handleList(DEFAULT_LIST.id);
    setUser("");
  };

  const offset = listInfo !== "f03043a2c2" ? { offset: 4 } : {}

  return loading ? (
    <Spin
      tip="Loading..."
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    />
  ) : (
    <div style={{ padding: 10 }}>
      <Row gutter={16}>
        <Col style={{ marginTop: 10 }} xs={12} lg={6}>
          <Search
            onSearch={searchUser}
            value={user}
            onChange={event => setUser(event.target.value)}
          />
        </Col>
        <Col style={{ marginTop: 10 }} xs={12} lg={3}>
          <Button
            disabled={user.length === 0}
            type="primary"
            onClick={reset}
            style={{
              width: "100%"
            }}
          >
            Reset
          </Button>
        </Col>
        <Col 
          style={{ marginTop: 10 }} 
          xs={{ 
            span: 12,
            offset: 0
          }} 
          lg={{
            span: 6,
            ...offset
          }}
        >
          <Select
            style={{ width: "100%" }}
            onChange={(value) => handleList(value, 1)}
            value={listInfo}
          >
            {lists.map(({ id, name }) => (
              <Option value={id} key={id} onClick={() => setListName(name)}>
                {name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col style={{ marginTop: 10 }} xs={12} lg={5}>
          <AddNewMember createMember={addMemberToList} listInfo={listInfo} listName={listName}/>
        </Col>
        {listInfo === "f03043a2c2" ? (
          <Col style={{ marginTop: 10 }} xs={12} lg={4}>
            <Button
              style={{ width: "100%" }}
              onClick={synchronizeWithDashboard}
              loading={synchronizing}
            >
              Synchronize
            </Button>
          </Col>
        ) : null}
      </Row>
      <MailChimpMembers
        dataSource={tableData}
        deleteMember={deleteMember}
        paginationHandler={paginationHandler}
        pageTotalItems={pageTotalItems}
        loading={loadingTable}
        isAsync={isAsync}
      />
    </div>
  );
};
