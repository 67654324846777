import React, { useState, useEffect } from "react";
import classes from "./CardAccount.module.less";
import { Form, Button, Select, Switch, Modal } from "antd";
import LineCard from "../../UI/LineCard/LineCard";

const CardAccount = ({
  form,
  account,
  onAdd,
  deleteItem,
  handleSubmit,
  updateAccount,
  ...props
}) => {
  const { getFieldDecorator } = form;
  const Option = Select.Option;
  const { confirm } = Modal;
  const [data, setData] = useState({});
  useEffect(() => {
    setData(account);
  }, [account]);
  const showConfirm = value => {
    confirm({
      title: "Are you sure you want to delete this Account?.",
      onOk() {
        deleteAccount(value);
      },
      onCancel() {}
    });
  };

  const deleteAccount = item => {
    deleteItem(item);
  };
  return (
    <LineCard className={classes.container} title={account.name}>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <table>
          <tr>
            <td>Home</td>
            <td className={classes.switchContainer}>
              <Form.Item>
                {getFieldDecorator("home_enabled", {
                  valuePropName: "checked",
                  initialValue:
                    account.permissions && account.permissions.home_enabled
                      ? account.permissions.home_enabled
                      : false
                })(
                  <Switch
                    onChange={value =>
                      updateAccount({
                        ...data,
                        permissions: {
                          ...data.permissions,
                          home_enabled: value
                        }
                      })
                    }
                  />
                )}
              </Form.Item>
            </td>
          </tr>
          <tr>
            <td>Chat</td>
            <td className={classes.switchContainer}>
              <Form.Item>
                {getFieldDecorator("chat_enabled", {
                  valuePropName: "checked",
                  initialValue:
                    account.permissions && account.permissions.chat_enabled
                      ? account.permissions.chat_enabled
                      : false
                })(
                  <Switch
                    onChange={value =>
                      updateAccount({
                        ...data,
                        permissions: {
                          ...data.permissions,
                          chat_enabled: value
                        }
                      })
                    }
                  />
                )}
              </Form.Item>
            </td>
          </tr>
          <tr>
            <td>Ticket</td>
            <td className={classes.switchContainer}>
              <Form.Item>
                {getFieldDecorator("tickets_enabled", {
                  valuePropName: "checked",
                  initialValue:
                    account.permissions && account.permissions.tickets_enabled
                      ? account.permissions.tickets_enabled
                      : false
                })(
                  <Switch
                    onChange={value =>
                      updateAccount({
                        ...data,
                        permissions: {
                          ...data.permissions,
                          tickets_enabled: value
                        }
                      })
                    }
                  />
                )}
              </Form.Item>
            </td>
          </tr>
          <tr>
            <td colSpan={2} />
          </tr>
        </table>
      </Form>
      <div className={classes.delete}>
        <Button
          type="danger"
          onClick={() => showConfirm(data)}
          ghost
          icon="delete"
        >
          Remove Access
        </Button>
      </div>
    </LineCard>
  );
};

export default Form.create()(CardAccount);
