import React from "react";
import { Modal } from "antd";
import ScrollTable from "../../../UI/ScrollTable/ScrollTable";
import { Link } from "react-router-dom";
export default ({ display, setDisplay, apps }) => {
  return (
    <Modal
      visible={display}
      onCancel={() => setDisplay(false)}
      centered={true}
      footer={null}
    >
      <ScrollTable
        headers={["App", ""]}
        data={apps.map((it) => ({
          app: it.name,
          link: (
            <Link target={"blank"} to={`/apps/${it.id}`}>
              Go to app
            </Link>
          ),
        }))}
        keys={["app", "link"]}
      />
    </Modal>
  );
};
