import axios from "../axios";

export async function getOperationsProcedures() {
  let response = null;
  try {
    response = await axios.get("/operations-procedures");
  } catch (error) {
    console.log("error", error);
  }
  return response;
}

export async function newOperationProcedure(values) {
  let response = null;
  try {
    response = await axios.post("/operations-procedures", { ...values });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
export async function newOperationFiles(values) {
  let response = null;
  try {
    response = await axios.post("/operations-file", {
      ...values,
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}

export async function newOperationFileAttachment(values) {
  let response = null;
  try {
    response = await axios.post("/operation-files", {
      ...values,
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}

export async function updateCategoryPositions(data) {
  let response = null;
  try {
    response = await axios.put("/category-position", {
      data,
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
export async function advancedSearch({ search }) {
  let response = null;
  try {
    response = await axios.get(`/operations-search?search=${search}`);
  } catch (error) {
    console.log("error", error);
  }
  return response;
}

export async function updateVersionsPositions(data) {
  let response = null;
  try {
    response = await axios.put("/version-position", {
      data,
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}

export async function updateCategoryTitle(categoryId, newCategory) {
  let response = null;
  try {
    response = await axios.put("/update-title-category", {
      categoryId,
      newCategory,
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
