import React from "react";
import { Table, Icon } from "antd";
import moment from "moment";

export default ({
  dataSource,
  deleteMember,
  paginationHandler,
  pageTotalItems,
  loading,
  isAsync,
}) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: "100px",
      render: (_, item) =>
        item.merge_fields &&
        (item.merge_fields.FNAME || item.merge_fields.LNAME)
          ? `${item.merge_fields.FNAME} ${item.merge_fields.LNAME}`
          : "-----",
    },
    {
      title: "Email Address",
      dataIndex: "email_address",
      key: "email_address",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Date",
      dataIndex: "timestamp_opt",
      key: "timestamp_opt",
      render: (date) => (date ? moment(date).format("YYYY-MM-DD") : "-"),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <Icon type="delete" onClick={() => deleteMember({ member_id: id })} />
      ),
    },
  ];

  return (
    <Table
      loading={loading}
      style={{ marginTop: 20 }}
      rowKey={(record) => record.id}
      pagination={{
        pageSize: 15,
        onChange: isAsync ? paginationHandler : () => {},
        total: pageTotalItems,
      }}
      size="small"
      dataSource={dataSource}
      columns={columns}
      scroll={{ x: 700 }}
    />
  );
};
