import axios from "../axios";

export const getKPI = async ({ start, end }) => {
  let response = null;
  try {
    response = await axios.get(
      `/kpi?${start && end ? `start=${start}&end=${end}` : ""}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
export const getKPIChart = async ({ start, end }) => {
  let response = null;
  try {
    response = await axios.get(
      `/kpi-chart?${start && end ? `start=${start}&end=${end}` : ""}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getKPIPerManager = async (manager) => {
  let response = null;
  try {
    response = await axios.get(`/kpi-manager?manager=${manager}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getKPIPerVersion = async ({ start, end, appId }) => {
  let response = null;
  try {
    response = await axios.get(`/kpi-version?appId=${appId}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
export const getUpdatesCompletedChart = async ({ start, end }) => {
  let response = null;
  try {
    response = await axios.get(
      `/kpi-updates-completed?${
        start && end ? `start=${start}&end=${end}` : ""
      }`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
