import axios from "../axios";

export async function generateReport(paymentsId, appId, end, start, appName) {
  let response = null;
  try {
    response = await axios.post("/generate-statement", {
      ...paymentsId,
      appId,
      end,
      start,
      appName
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
