import React from "react";
import classes from "./ScrollTable.module.css";

export default ({ data, headers, keys, styles }) => (
  <table className={classes.fixed_header}>
    <thead>
      <tr>
        {headers.map((item, index) => (
          <th key={index} style={styles ? styles[index] : {}}>
            {item}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr key={index * 1228}>
          {keys.map((key, index) => (
            <td key={index} style={styles ? styles[index] : {}}>
              {item[key]}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);
