import React, { useState, useEffect } from "react";
import Axios from "../../axios";
import classes from "./Clients.module.less";
import { Link } from "react-router-dom";
import { Table, Button, Col, Row, Input, Alert } from "antd";
import GridApps from "./GridApps/GridApps";
import NewUserModal from "./NewUserModal/NewUserModal";
import { getAsyncClients } from "../../services/clientService";
const { Search } = Input;
export default () => {
  let [loading, setLoading] = useState(false);
  let [modal, setModal] = useState(false);
  let [data, setData] = useState([]);
  let [pageSize, setPageSize] = useState(10);
  let [current, setCurrent] = useState(1);
  let [total, setTotalItems] = useState(0);
  let [search, setSearch] = useState("");
  let [showAlert, setShowAlert] = useState(false);
  const [sort, setSort] = useState({
    columnKey: "email",
    order: "desc",
  });
  let columns = [
    {
      key: "email",
      dataIndex: "email",
      title: "Email",
      render: (email, item) => (
        <Link to={"/clients/" + item.id} style={{ "text-decoration": "none" }}>
          <b style={{ color: "#8dbf40" }}>{email}</b>
        </Link>
      ),
      width: "250px",
      fixed: "left",
      sorter: true,
    },
    {
      key: "first_name",
      dataIndex: "first_name",
      title: "First Name",
      sorter: true,
    },
    { key: "last_name", dataIndex: "last_name", title: "Last Name" },
    {
      key: "apps",
      dataIndex: "apps",
      title: "Apps Linked",
      // width: "150px",
      render: (apps) =>
        apps && apps.length > 0 ? <GridApps data={apps} /> : null,
    },
  ];
  const downloadAsCSV = async () => {
    try {
      const {
        data: { clientsList },
      } = await Axios.get("/export_to_csv");
      const clients = clientsList.map((it) => [
        it.name.replace(/"/g, ""),
        it.email.replace(/"/g, ""),
        `"${it.company.replace(/"/g, "")}"`,
        `"${it.position.replace(/"/g, "")}"`,
        `"${it.location.replace(/"/g, "")}"`,
        it.apps.replace(/"/g, ""),
      ]);
      const rows = clients.map((it) => [...Object.values(it)]);
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");
      const link = document.createElement("a");
      link.setAttribute("href", encodeURI(csvContent));
      link.setAttribute("download", "users.csv");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (pagination, filters, sorter) => {
    setSort({ columnKey: sorter.columnKey, order: sorter.order });
    setCurrent(pagination.current);
    setPageSize(pagination.pageSize);
    let actSort = {};
    if (sorter && sorter.order) {
      actSort = {
        sort: sorter.order === "ascend" ? "asc" : "desc",
        field: sorter.field,
      };
    } else {
      actSort = sort;
    }
    sendSearchRequest({
      page: pagination.current,
      results: pagination.pageSize,
      search,
      ...actSort,
    });
  };
  const sendSearchRequest = async (values) => {
    setLoading(true);
    let { data: response } = await getAsyncClients({ ...values });
    setLoading(false);
    if (response && response.tableData) {
      setData(response.tableData);
      setTotalItems(response.totalResults);
    }
  };
  useEffect(() => {
    sendSearchRequest({ search });
  }, []);

  return (
    <div className={classes.container}>
      <Row type="flex" justify="space-between" gutter={8}>
        <Col xs={24} sm={24} md={12} lg={9} xl={11}>
          <Search
            placeholder={loading ? "Searching..." : "Search User..."}
            enterButton
            disabled={loading}
            loading={loading}
            allowClear
            style={{ marginBottom: 20, width: "100%" }}
            onSearch={(value) => {
              setSearch(value);
              sendSearchRequest({
                search: value,
              });
              setCurrent(1);
              setShowAlert(true);
            }}
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={3} xl={2}>
          <Button
            style={{ marginBottom: 20, width: "100%" }}
            onClick={() => {
              sendSearchRequest({ search: "" });
              setShowAlert(false);
            }}
          >
            Reset
          </Button>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
          <Button
            style={{ marginBottom: 20, width: "100%" }}
            icon="download"
            disabled={loading}
            onClick={() => downloadAsCSV()}
          >
            Download as CSV
          </Button>
        </Col>
        <Col xs={24} sm={12} md={12} lg={5} xl={4}>
          <Button
            disabled={loading}
            onClick={() => setModal(true)}
            style={{ width: "100%" }}
            type="primary"
            icon="plus"
          >
            Add New
          </Button>
        </Col>
      </Row>
      {showAlert ? (
        <Alert
          message={`${total} Results`}
          type="info"
          showIcon
          style={{ marginBottom: 10, textAlign: "left" }}
        />
      ) : null}
      <h3>Users ({total})</h3>
      <Table
        scroll={{ x: 650 }}
        size="small"
        loading={loading}
        columns={columns}
        dataSource={data}
        rowKey={(record) => record.id}
        onChange={handleChange}
        pagination={{
          total,
          pageSize,
          current,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20"],
        }}
      />
      <NewUserModal visible={modal} close={() => setModal(false)} />
    </div>
  );
};
