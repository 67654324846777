import React, { Component, Fragment } from "react";
import { Tabs } from "antd";
import Overdue from "./Overdue";
import DueIn from "./DueIn";
const TabPane = Tabs.TabPane;
class SalesHome extends Component {
  state = {
    activeTab: "overdue"
  };
  callback = key => {
    this.props.history.push(`/sales/${key}`);
  };

  componentWillMount() {
    let section = this.props.match.params.section
      ? this.props.match.params.section
      : "overdue";
    this.setState({
      activeTab: section
    });
    this.props.history.push(`/sales/${section}`);
  }

  render() {
    return (
      <Fragment>
        <Tabs defaultActiveKey={this.state.activeTab} onChange={this.callback}>
          <TabPane tab="Overdue" key="overdue">
            <Overdue {...this.props} />
          </TabPane>
          <TabPane tab="Due in..." key="due-month">
            <DueIn {...this.props} />
          </TabPane>
        </Tabs>
      </Fragment>
    );
  }
}
export default SalesHome;
