import axios from "../axios";

export const getStores = async () => {
  let response = null;
  try {
    response = await axios.get("/stores");
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
