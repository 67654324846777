import React, { useState, useEffect } from "react";
import {
  getPaymentsAlerts,
  getAlertsCharts,
} from "../../services/paymentsAlerts";
import { Link } from "react-router-dom";
import Chart from "./Chart";
import Spin from "../UI/LoadingSpin/LoadingSpin";
import { Table, Modal, Button, } from "antd";
import SumaryChart from "./SummaryChart";
import AlertsTable from "./alerts"

export default () => {
  const [fetchingChart, setFetchingChart] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data } = await getPaymentsAlerts();
      setTableData(data.result);
      setLoading(false);
    } catch (error) { }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "App Name",
      dataIndex: "name",
      key: "name",
      render: (name, record) => {
        return (
          <Link to={`/apps/${record.app_id}/details`}>
            {name}
          </Link>
        );
      },
    },
    {
      title: "Past 7 days average transaction amount (Last Hour)",
      dataIndex: "average",
      key: "average",
    },
    {
      title: "Average transaction amount (Last Hour)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Percentage Change",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        const { amount, average } = record;
        const status = average ? (100 * ((amount - average) / average)).toFixed(2) : 0;
        const color = status < -30 ? "red" : "green";
        const sign = amount > average ? "+" : "";
        return (
          <span style={{ color }}>
            {status !== 0 ? `${sign} ${status}%` : `0.0 %`}
          </span>
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        return (
          <Button type="primary" onClick={() => handleRow(record)}>
            Details
          </Button>
        );
      },
    },
  ];


  const handleRow = async (record) => {
    try {
      setDisplay(true);
      setFetchingChart(true);
      const { data } = await getAlertsCharts(record.app);
      setChartData(data.result);
      setFetchingChart(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <SumaryChart />
      <Table
        rowKey="app"
        columns={columns}
        dataSource={tableData}
        loading={loading}
      />
      <AlertsTable />
      <Modal
        onCancel={() => {
          setDisplay(false);
          setChartData(null);
        }}
        width="50vw"
        centered={true}
        footer={null}
        visible={display}
      >
        {fetchingChart ? (
          <Spin />
        ) : chartData ? (
          <Chart data={chartData} />
        ) : null}
      </Modal>
    </div>
  );
};
