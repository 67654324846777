import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import classes from "./AppHistory.module.less";
import AppFormTable from "./AppFormTable/AppFormTable";
import DocumentsTable from "./DocumentsTable/DocumentsTable";
import ApkTable from "./ApkTable/ApkTable";
const { TabPane } = Tabs;
export default ({
  appId,
  getHistory,
  appForms = [],
  documents = [],
  apk_reclaim = [],
  loadingHistory = false,
  ...props
}) => {
  let [key, setKey] = useState("app_form");
  let [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    getHistory();
  }, [appId]);
  const reportWindowSize = () => {
    setWidth(window.innerWidth);
  };
  window.onresize = reportWindowSize;

  return (
    <div className={classes.container}>
      <Tabs
        activeKey={key}
        onChange={(e) => setKey(e)}
        tabPosition={width > 700 ? "left" : "top"}
        style={{ height: "100%" }}
      >
        <TabPane tab="App Form Submissions" key="app_form">
          <AppFormTable
            appId={appId}
            loading={loadingHistory}
            appForms={appForms}
          />
        </TabPane>
        <TabPane tab="Documents" key="documents">
          <DocumentsTable
            appId={appId}
            loading={loadingHistory}
            documents={documents}
          />
        </TabPane>
        <TabPane tab="Apk Reclaim" key="apk">
          <ApkTable
            appId={appId}
            loading={loadingHistory}
            apk_reclaim={apk_reclaim}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};
