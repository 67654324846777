import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Row, Icon, Upload } from "antd";
import { newOperationFileAttachment } from "../../../services/sopServices";
import LoadingSpin from "../../UI/LoadingSpin/LoadingSpin";
import Axios from "axios";
export default Form.create({ name: "newAttachment" })(
  ({ form, actualData, updateData, operationId, previousFiles, ...props }) => {
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [uploadingImg, setUploadingImg] = useState(false);
    useEffect(() => {}, []);

    const handleSubmit = () => {
      form.validateFields((err, values) => {
        if (!err) {
          let operation_file_attachments = values.operation_file_attachments.fileList
            .filter((it) => !!it.response)
            .map(({ name, size, response }) => ({
              file_name: name,
              file_size: size,
              file_url: response,
            }))
            .concat(
              actualData.map(({ file_name, file_size, file_url }) => ({
                file_name,
                file_size,
                file_url,
              }))
            );
          console.log("actualData", actualData);
          console.log(
            "values.operation_file_attachments.fileList",
            values.operation_file_attachments.fileList
          );
          console.log("operation_file_attachments", operation_file_attachments);
          let data = { ...values, operation_file_attachments };
          makeRequest(data);
        }
      });
    };
    const makeRequest = async (values) => {
      setSaving(true);
      let { data } = await newOperationFileAttachment({
        ...values,
        operationId,
      });
      setSaving(false);
      if (data && data.newAttachment) {
        updateData(data.newAttachment);
      }
      props.close();
      form.resetFields();
    };
    const uploadImage = async ({ file, onSuccess, onProgress, onError }) => {
      const timeStamp = new Date().getTime();
      const formData = new FormData();
      const result = `${timeStamp}/${file.name}`;
      formData.set("uploads[]", file);
      formData.set("name", file.name);
      formData.set("timestamp", timeStamp);
      setUploadingImg(true);
      let { data } = await Axios.post(
        "https://appinchina.space/upload",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onProgress({
              percent: percentCompleted,
            });
          },
        }
      );
      setUploadingImg(false);
      if (data) {
        onSuccess(result);
      } else {
        onError("error");
      }
    };
    const uploadProps = {
      listType: "picture",
    };

    const validateFields = () => {
      let values = Object.entries(form.getFieldsValue()).map((it) => it[1]);
      if (
        values.findIndex((e) => e === undefined) === -1 &&
        form.getFieldValue("operation_file_attachments") &&
        form.getFieldValue("operation_file_attachments").fileList &&
        form.getFieldValue("operation_file_attachments").fileList.length > 0
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };

    let mapCurrentFiles = (files) => {
      // console.log("files", files);
      return files
        ? files.map((it, index) => ({
            uid: it.id || index,
            name: it.file_name,
            status: "done",
            url: "https://appinchina.space/uploads/" + it.file_url,
            thumbUrl: "https://appinchina.space/uploads/" + it.file_url,
          }))
        : [];
    };

    useEffect(() => {
      validateFields();
    }, [form]);

    const { getFieldDecorator } = form;

    return loading ? (
      <LoadingSpin />
    ) : (
      <div>
        <Modal
          title="Attachments"
          visible={props.visible}
          footer={null}
          onCancel={props.close}
          destroyOnClose={true}
          confirmLoading={saving}
        >
          <Form hideRequiredMark={true}>
            <Form.Item>
              {getFieldDecorator(`operation_file_attachments`, {
                rules: [{ required: true, message: "Required" }],
              })(
                <Upload
                  onRemove={(file) => setUploadingImg(false)}
                  disabled={saving}
                  {...uploadProps}
                  customRequest={uploadImage}
                  defaultFileList={mapCurrentFiles(actualData)}
                >
                  <Button loading={uploadingImg}>
                    <Icon type={uploadingImg ? "loading" : "upload"} />
                    {uploadingImg
                      ? "Uploading File"
                      : " Click to Upload New File(s)"}
                  </Button>
                </Upload>
              )}
            </Form.Item>

            <Row type="flex" justify="center" align="middle">
              <Button
                loading={saving}
                disabled={disabled || uploadingImg}
                type="primary"
                onClick={() => handleSubmit()}
                style={{ marginTop: 10 }}
              >
                Save
              </Button>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
);
