import React, { useEffect, useState } from "react";
import classes from "../Badge.module.less";
import moment from "moment";
import { Link } from "react-router-dom";
import { getAllBadge } from "../../../services/badgeTracking";
import functions from "../../../functions";
import { Table, DatePicker, Input, Row, Col } from "antd";

const { Search } = Input;
const { RangePicker } = DatePicker;

const Badge = () => {
  let [data, setData] = useState([]);
  let [start_date, setStartDate] = useState("");
  let [end_date, setEndDate] = useState("");
  let [loading, setLoading] = useState(false);
  let [search, setSearch] = useState("");

  const getData = async (start, end, app) => {
    setLoading(true);
    let { data } = await getAllBadge(start, end, app);
    setLoading(false);
    if (data && data.table) {
      setData(data.table);
    }
  };

  const onChangeDate = (_, [start, end]) => {
    setStartDate(start);
    setEndDate(end);
    getData(start, end);
  };

  useEffect(() => {
    getData(
      moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD")
    );
    setStartDate(
      moment()
        .startOf("month")
        .format("YYYY-MM-DD")
    );
    setEndDate(moment().format("YYYY-MM-DD"));
  }, []);
  let columns = [
    {
      title: "App",
      dataIndex: "app",
      key: "app",
      render: (_, app) => (
        <Link to={`/badge-tracking/script-tracking/${app.app}`}>
          {app.name}
        </Link>
      ),
    },
    {
      title: "Views",
      dataIndex: "views",
      key: "views",
      render: (views) => functions.styleAsNumber(views),
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      render: (clicks) => functions.styleAsNumber(clicks),
    },
    {
      title: "Click Rate",
      dataIndex: "click_rate",
      key: "click_rate",
      render: (_, val) => `${(val.clicks / val.views) * 100}%`,
    },
  ];
  return (
    <div className={classes.container}>
      <Row type="flex" gutter={16} style={{ marginBottom: 20 }}>
        <Col xs={24} sm={12}>
          <Search
            allowClear
            enterButton={false}
            style={{ width: "100%" }}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
          />
        </Col>
        <Col xs={24} sm={12}>
          <RangePicker
            value={
              start_date !== "" && end_date !== ""
                ? [moment(start_date), moment(end_date)]
                : [moment().startOf("month"), moment()]
            }
            style={{ width: "100%" }}
            onChange={onChangeDate}
            format={"YYYY-MM-DD"}
            disabled={loading}
          />
        </Col>
      </Row>
      <Table
        loading={loading}
        dataSource={data.filter((e) =>
          e.name.toLowerCase().includes(search.toLowerCase())
        )}
        size="small"
        columns={columns}
      />
    </div>
  );
};
export default Badge;
