import React from 'react';
import classes from './Preloader.module.css';

const preloader = (props) => (
	<div
		className={[classes.Loader,
		(props.inverse ? classes.Inverse : null),
		(props.dark ? classes.Dark : null),
		].join(' ')}
		style={{ fontSize: props.size }}>
		Loading...
	</div>
)

export default preloader;