import React from "react";
import { Spin } from "antd";
const LoadingSpin = ({ text, style }) => {
  return (
    <Spin
      tip={text ? text : "Loading..."}
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        ...style
      }}
    />
  );
};

export default LoadingSpin;
