import React, { Fragment, useState, useEffect } from "react";
import { Button, notification, Spin, Input, Row, Col } from "antd";
import {
  getSdks,
  deleteSdk,
  createSdkService,
  updateSdkService,
  searchForSdks,
  testSdk,
} from "../../services/sdksService";
import SdksModal from "./SdksModal";
import SdksTable from "./SdksTable";
import classes from "./SdksSection.module.less";

export default () => {
  const [visible, setVisible] = useState(false);
  const [sdkInfo, setSdkInfo] = useState({});
  const [isCreate, setIsCreate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [bulkTesting, setBulkTesting] = useState(false);
  const [bulkTestResults, setBulkTestResults] = useState(0);
  const [searchingSDKs, setSearchingSDKs] = useState(false);
  const [data, setData] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    try {
      setLoading(true);
      const { data } = await getSdks();
      setLoading(false);
      if (!data.error) {
        setData(data.sdks);
        setFilter("");
      }
    } catch (error) {}
  };

  const editSdkHandler = (values) => {
    setIsCreate(false);
    setSdkInfo(values);
    setVisible(true);
  };

  const deleteSdkHandler = (id) => {
    let index = data.findIndex((it) => it.id === id);
    let newData = [...data];
    newData.splice(index, 1);
    setData(newData);
    deleteSdk(id);
  };

  const bulkTest = async () => {
    let currentBulkRequests = 0;
    let length = data.length;
    let resultsRetrieved = 0;
    setBulkTesting(true);
    setBulkTestResults(0);
    let interval = setInterval(async () => {
      if (resultsRetrieved === length) {
        clearInterval(interval);
        setTimeout(() => {
          setBulkTesting(false);
          fetch();
        }, 7000);
      } else if (currentBulkRequests < 10) {
        setBulkTestResults(resultsRetrieved);
        resultsRetrieved += 1;
        if (data[resultsRetrieved].test_endpoint) {
          currentBulkRequests += 1;
          await testSdkHandler(data[resultsRetrieved]);
          currentBulkRequests -= 1;
        }
      }
    }, 50);
  };

  const testSdkHandler = async (values) => {
    if (!values.test_endpoint) {
      notification.warning({
        placement: "bottomRight",
        message: "Please set the endpoint url before executing test.",
      });
      return;
    }
    let index = data.findIndex((it) => it.id === values.id);
    let newData = [...data];
    newData[index].testing = true;
    setData(newData);
    let result = await testSdk(values);
    index = data.findIndex((it) => it.id === values.id);
    newData = [...data];
    if (result && result.data && result.data.sdk) {
      newData[index] = {
        ...result.data.sdk,
        testing: false,
      };
    } else {
      notification.error({
        placement: "bottomRight",
        message: "There was an error while testing the SDK",
      });
      newData[index].testing = false;
    }
    setData(newData);
  };

  const openCreateSdkModal = () => {
    setSdkInfo({});
    setIsCreate(true);
    setVisible(true);
  };

  const updateSdk = async (values) => {
    try {
      setModalLoading(true);
      const result = await updateSdkService(values);
      setModalLoading(false);
      if (!result.data.error) {
        setData(data.map((it) => (it.id === values.id ? result.data.sdk : it)));
        setLoading(false);
        setVisible(false);
        notification.success({
          placement: "bottomRight",
          message: "Sdk successfully updated.",
        });
      }
    } catch (error) {}
  };

  const createSdk = async (values) => {
    try {
      setModalLoading(true);
      const result = await createSdkService(values);
      setModalLoading(false);
      if (!result.data.error) {
        setData([
          {
            ...result.data.sdk,
            works_in_china: result.data.sdk.hasOwnProperty("works_in_china")
              ? result.data.sdk.works_in_china
              : null,
          },
          ...data,
        ]);
        setSdkInfo({});
        setLoading(false);
        setVisible(false);
        notification.success({
          placement: "bottomRight",
          message: "Sdk successfully added.",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchForSDKs = async () => {
    try {
      setSearchingSDKs(true);
      const response = await searchForSdks();
      let sdks = (!!response && !!response.data && response.data.sdks) || [];
      setSearchingSDKs(false);
      if (sdks) {
        setData(sdks);
        setFilter("");
        notification.success({
          placement: "bottomRight",
          message: "List updated with the SDKs submitted by clients.",
        });
      }
    } catch (error) {
      notification.error({
        placement: "bottomRight",
        message: "Error while updating list.",
      });
      console.log(error);
    }
  };

  const filterSdks = (e) => {
    const { value } = e.target;
    setFilter(value);
  };

  const tableData = () => {
    return data.filter((it) =>
      it.name.toLowerCase().includes(filter.toLowerCase())
    );
  };

  return loading ? (
    <Spin
      tip="Loading..."
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  ) : (
    <Fragment>
      {visible ? (
        <SdksModal
          visible={visible}
          isCreate={isCreate}
          closeModal={() => setVisible(false)}
          initialValues={sdkInfo || {}}
          updateSdk={updateSdk}
          createSdk={createSdk}
          modalLoading={modalLoading}
          sdkList={data}
        />
      ) : null}
      <div className={classes.container}>
        <Row gutter={16} type="flex" justify="space-between">
          <Col xs={24} sm={24} md={10} lg={12}>
            <Input
              onChange={filterSdks}
              placeholder="Filter by Name"
              disabled={searchingSDKs || bulkTesting}
            />
          </Col>
          <Col className={classes.buttons} xs={24} sm={24} md={14} lg={12}>
            <Button
              icon={bulkTesting ? "loading" : "sync"}
              onClick={bulkTest}
              disabled={searchingSDKs || bulkTesting}
            >
              Test Every SDK
            </Button>
            <Button
              icon={searchingSDKs ? "loading" : "sync"}
              onClick={searchForSDKs}
              disabled={searchingSDKs || bulkTesting}
            >
              Synchronize SDK list
            </Button>
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <Button
            icon="plus"
            type="primary"
            onClick={openCreateSdkModal}
            disabled={searchingSDKs || bulkTesting}
          >
            Add New
          </Button>
        </Row>
        {bulkTesting ? (
          <Spin
            tip={
              <p>
                Testing endpoints <br />({bulkTestResults}/{data.length}{" "}
                completed)
              </p>
            }
            style={{
              display: "flex",
              height: "400px",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        ) : (
          <SdksTable
            setSdkInfo={editSdkHandler}
            dataSource={tableData()}
            testSdk={testSdkHandler}
            deleteSdk={deleteSdkHandler}
          />
        )}
      </div>
    </Fragment>
  );
};
