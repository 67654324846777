import React from "react";
import classes from "./AttachmentCard.module.less";
import { Table, Button, Modal } from "antd";
import moment from "moment";
import { deleteDocumentAttachment } from "../../../services/appDistributionService";
import PreviewButton from "../../UI/PreviewButton/PreviewButton";
const { confirm } = Modal;
export default ({ data, deleteHandler, title = "Attachments" }) => {
  let columns = [
    {
      dataIndex: "date",
      key: "date",
      title: "Date",
      render: (date) => moment(date).format("YYYY-MM-DD HH:mm"),
    },
    { dataIndex: "user", key: "user", title: "Uploader" },
    { dataIndex: "file_name", key: "file_name", title: "File Name" },
    { dataIndex: "file_size", key: "file_size", title: "File Size (Bytes)" },
    {
      dataIndex: "file_url",
      key: "file_url",
      title: "",
      render: (file_url, item) =>
        file_url ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <a
              rel="noopener noreferrer"
              href={`https://appinchina.space/download/${file_url}`}
              target="_blank"
            >
              <Button
                icon="cloud-download"
                shape="circle"
                style={{ position: "relative", top: "4px" }}
              ></Button>
            </a>
            <PreviewButton fileName={file_url} />
            <Button
              style={{ position: "relative", top: "4px" }}
              onClick={() => handleDelete(item.id)}
              icon="delete"
              shape="circle"
              type="danger"
            />
          </div>
        ) : (
          "No file attached"
        ),
    },
  ];
  const handleDelete = (id) => {
    confirm({
      title: "Are you sure you want to delete this attachment?",
      async onOk() {
        await deleteDocumentAttachment(id);
        deleteHandler(id);
      },
    });
  };
  return (
    <div className={classes.container}>
      <h3>{title}</h3>
      <Table
        columns={columns}
        pagination={data && data.length > 5 ? { pageSize: 5 } : false}
        size="small"
        dataSource={data}
      />
    </div>
  );
};
