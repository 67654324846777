import React, { useState, Fragment } from "react";
import functions from "../../functions";
import { Button, Select, Row, Col } from "antd";
import classes from "../UI/AdvancedFilter/AdvancedFilter.module.css";
import {
  LIST_INDUSTRIES,
  LIST_COUNTRIES,
  APP_DETAILS_OPTIONS,
} from "../../constants";
import ManagerSelect from "../UI/ManagerSelect/ManagerSelect";

const Option = Select.Option;
const AdvancedFilter = ({ filterApps, clear }) => {
  const [filters, setFilters] = useState({
    suspended: true,
    ongoing: true,
    completed: true,
    delayed: true,
    newApp: true,
    business_form_status: [],
    app_form_status: [],
    copyright_form_status: [],
    scc_owner: [],
    scc_status: [],
    targeted_store: [],
    account_manager: [],
    confidential: [],
    country: [],
    industry: [],
    search: "",
    order: "date",
  });

  const handleAdvancedSearch = (option, name) => {
    setFilters({
      ...filters,
      [name]: option,
    });
  };

  const clearFilter = () => {
    setFilters({
      suspended: true,
      ongoing: true,
      completed: true,
      delayed: true,
      newApp: true,
      business_form_status: [],
      app_form_status: [],
      copyright_form_status: [],
      scc_owner: [],
      scc_status: [],
      targeted_store: [],
      account_manager: [],
      confidential: [],
      country: [],
      industry: [],
      search: "",
      order: "date",
    });
    clear();
  };

  return (
    <div style={{ marginTop: "15px" }}>
      <Fragment>
        <div
          className={[classes.filtercontainer, classes.advancedsearch].join(
            " "
          )}
        >
          <span>
            <b>Country</b>
            <Select
              showSearch
              mode="multiple"
              value={filters.country}
              style={{ width: "100%" }}
              optionFilterProp="children"
              onChange={(value) => {
                handleAdvancedSearch(value, "country");
              }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {[...LIST_COUNTRIES, { code: "null", name: "Not Set" }].map(
                (it) => (
                  <Option key={it.code} value={it.code} label={it.name}>
                    {it.name}
                  </Option>
                )
              )}
            </Select>
          </span>
          <span>
            <b>Category</b>
            <Select
              showSearch
              mode="multiple"
              value={filters.industry}
              style={{ width: "100%" }}
              optionFilterProp="children"
              onChange={(value) => {
                handleAdvancedSearch(value, "industry");
              }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {[...LIST_INDUSTRIES, { code: "null", name: "Not Set" }].map(
                (it) => (
                  <Option key={it.code} value={it.code} label={it.name}>
                    {it.name}
                  </Option>
                )
              )}
            </Select>
          </span>
          {[
            "business_form_status",
            "app_form_status",
            "copyright_form_status",
            "SCC_status",
            "SCC_owner",
            "targeted_store",
            "ACCOUNT_MANAGER",
            "CONFIDENTIAL",
          ].map((name) => (
            <span>
              <b>
                {name == "SCC_status" || name == "SCC_owner"
                  ? functions.splitAndCapitalize(name, false)
                  : functions.splitAndCapitalize(name)}
              </b>
              {name === "ACCOUNT_MANAGER" ? (
                <ManagerSelect
                  accountManager={filters[name.toLowerCase()]}
                  setAccountManager={(value) =>
                    handleAdvancedSearch([value], name.toLowerCase())
                  }
                />
              ) : (
                <Select
                  mode="multiple"
                  value={filters[name.toLowerCase()]}
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    handleAdvancedSearch(value, name.toLowerCase());
                  }}
                >
                  {[
                    ...APP_DETAILS_OPTIONS[name],
                    { value: "null", label: "Not Set" },
                  ].map((it) => (
                    <Option key={it.value} value={it.value} label={it.label}>
                      {it.label}
                    </Option>
                  ))}
                </Select>
              )}
            </span>
          ))}
          <span style={{ marginTop: "18px" }}>
            <Row gutter={16}>
              <Col span={12}>
                <Button
                  onClick={() =>
                    filterApps({
                      ...filters,
                    })
                  }
                  icon="search"
                  type="primary"
                  style={{ width: "100%" }}
                >
                  Search
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  onClick={clearFilter}
                  ghost
                  type="primary"
                  style={{ width: "100%" }}
                >
                  Clear All
                </Button>
              </Col>
            </Row>
          </span>
        </div>
      </Fragment>
    </div>
  );
};
export default AdvancedFilter;
