import axios from "../axios";

export async function undoLastAppForm(app_id) {
  let response = null;
  try {
    response = await axios.get(`/undo-last-app-form/${app_id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function updateRemark(values) {
  let response = null;
  try {
    response = await axios.put(`/remarks`, { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function getAppDetails(id) {
  let response = null;
  try {
    response = await axios.get(`/apps/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function updateApp(values) {
  let response = null;
  try {
    response = await axios.put(`/apps`, values);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function updateAppInformation(values) {
  console.log(values);
  let response = null;
  try {
    response = await axios.put(`/app-information`, values);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function getDistributionDetails(id) {
  let response = null;
  try {
    response = await axios.get(`/distribution/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function updateDistributionInfo(id, data, main_information) {
  let response = null;
  try {
    response = await axios.put(`/distribution`, {
      appId: id,
      data,
      main_information,
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function updateDistributionHistory(id, data) {
  let response = null;
  try {
    response = await axios.put(`/distribution-history`, {
      id,
      data,
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function createDistributionEvent(data) {
  let response = null;
  try {
    response = await axios.post(`/distribution`, {
      ...data,
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function createDistributionEventMultiple(data) {
  let response = null;
  try {
    response = await axios.post(`/distribution-multiple`, {
      ...data,
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function updateMultipleVersions(data) {
  let response = null;
  try {
    response = await axios.post(`/versions-multiple`, {
      ...data,
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function getAppDocuments(id) {
  let response = null;
  try {
    response = await axios.get(`/app-documents/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function getDocsCategories() {
  let response = null;
  try {
    response = await axios.get(`/docs-categories`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function newAppDocument(values) {
  let response = null;
  try {
    response = await axios.post(`/app-document`, { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function deleteDocumentAttachment(id) {
  let response = null;
  try {
    response = await axios.delete(`/app-document`, {
      data: {
        id,
      },
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function getSalesTags() {
  let response = null;
  try {
    response = await axios.get(`/tags`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}