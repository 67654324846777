import React, { useState, Fragment } from "react";
import classes from "./NewWechatAccount.module.less";
import { Modal, Button, Input, Form, notification } from "antd";
import { newWechatAccount } from "../../../../services/TicketsServices";
import AsyncSearchApp from "../../../UI/AsyncSearch/AsyncSearch";

const NewWechatAccount = ({ form, redirect }) => {
  const { getFieldDecorator } = form;
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        newWechatAccountRequest(values);
      }
    });
  };
  const newWechatAccountRequest = async (values) => {
    setLoading(true);
    let { data } = await newWechatAccount(values);
    setLoading(false);
    if (data && data.message === "success") {
      notification.info({
        message: "New Wechat Account added",
        placement: "bottomLeft",
        duration: 2,
      });
      setModalOpen(false);
      form.resetFields();
      redirect({ id: data.account, name: values.name });
    } else {
      notification.warning({
        message: `${data.error}`,
        placement: "bottomLeft",
        duration: 2,
      });
    }
  };

  return (
    <Fragment>
      <div className={classes.button}>
        <Button
          onClick={() => setModalOpen(true)}
          type="primary"
          icon="plus"
          ghost
        >
          Add New Wechat Account
        </Button>
      </div>
      <Modal
        loading={loading}
        title="New Wechat Account"
        visible={modalOpen}
        onOk={handleSubmit}
        okButtonProps={{
          loading,
          disabled: loading,
        }}
        onCancel={() => {
          form.resetFields();
          setModalOpen(false);
        }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <Form layout="vertical" onSubmit={handleSubmit}>
          <Form.Item label="Name">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Please input name.",
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Client Id">
            {getFieldDecorator("client_id", {
              onChange: (e) => {
                form.setFieldsValue({
                  wechat_id: `/wechat/${e.target.value}`,
                });
              },
              rules: [
                {
                  required: true,
                  message: "Please input Client Id.",
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Wechat EndPoint">
            <Input
              disabled
              value={`${
                process.env.REACT_APP_TICKETS_URL
              }/api/wechat/${form.getFieldValue("client_id") || ""}`}
            />
          </Form.Item>
          <Form.Item label="Client Secret">
            {getFieldDecorator("client_secret", {
              rules: [
                {
                  required: true,
                  message: "Please input Client Secret.",
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Apps">
            {getFieldDecorator("apps")(
              <AsyncSearchApp
                target="apps"
                multiple={true}
                placeholder="Select an app..."
                change={(value) => form.setFieldsValue({ apps: value })}
                labelInValue={true}
              />
            )}
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default Form.create()(NewWechatAccount);
