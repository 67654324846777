import React, { useState, useEffect } from "react";
import classes from "./IssueTypes.module.less";
import { Table, Button, Modal } from "antd";
import NewIssueType from "../IssueTypes/NewIssueType/NewIssueType";
const IssueTypes = ({ form, updateAccount, issues, idAccount, ...props }) => {
  const { confirm } = Modal;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getData = () => {
    setLoading(true);
    setData(issues);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    updateAccount(data);
  }, [data]);
  const columns = [
    {
      title: "Issue Type",
      dataIndex: "description",
      key: "description",
      align: "left"
    },
    {
      title: "Actions",
      dataIndex: "delete",
      key: "delete",
      align: "right",
      render: (_, item) => (
        <Button
          type="danger"
          onClick={() => showConfirm(item)}
          ghost
          icon="delete"
        >
          Remove
        </Button>
      )
    }
  ];

  const deleteIssue = item => {
    let result = data.filter(e => e !== item);

    setData(result);
  };
  const showConfirm = value => {
    confirm({
      title: "Are you sure you want to delete this Issue?.",
      onOk() {
        deleteIssue(value);
      },
      onCancel() {}
    });
  };
  return (
    <div className={classes.container}>
      <Table
        loading={loading}
        size={"small"}
        columns={columns}
        pagination={data.length > 10 ? { pageSize: 12 } : false}
        dataSource={data}
      />
      <div className={classes.add}>
        <NewIssueType onAdd={e => setData([...data, e])} {...props} />
      </div>
    </div>
  );
};

export default IssueTypes;
