import React, { useState } from "react";
import { Button, Card, Row, message } from "antd";
import AddExistingUser from "./AddExistingUser/AddExistingUser";
import AddNewUser from "./AddNewUser/AddNewUser";
import { CopyToClipboard } from "react-copy-to-clipboard";
import UserTable from "./UserTable/UserTable";
import { Link } from "react-router-dom";
const ClientsLinked = ({ app, setApp, form }) => {
  let [displayModal, setDisplayModal] = useState(false);
  let [displayNew, setDisplayNew] = useState(false);

  return (
    <Card style={{ textAlign: "left" }}>
      <UserTable app={app} setApp={setApp} data={app.users} />
      <Row type="flex" justify="end">
        <CopyToClipboard
          onCopy={() => message.success("Copied!")}
          text={app.users.map((client) => client.email).join(";")}
        >
          <Button icon="copy">Copy Emails</Button>
        </CopyToClipboard>
        <Button
          style={{ marginLeft: 10 }}
          type="primary"
          onClick={() => setDisplayModal(true)}
          icon="usergroup-add"
        >
          Add Existing User
        </Button>
      </Row>
      <AddExistingUser
        appForm={form}
        close={() => setDisplayModal(false)}
        display={displayModal} 
        setApp={setApp}
        app={app}
      />
      <AddNewUser
        appForm={form}
        close={() => setDisplayNew(false)}
        display={displayNew}
      />
    </Card>
  );
};

export default ClientsLinked;
