import React, { useState, Fragment, useEffect } from "react";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import {
  Button,
  Select,
  notification,
  Spin,
  Tooltip,
  Modal,
  Row,
  Input,
} from "antd";
import Axios from "axios";
import {
  deleteHelpArticle,
  updateHelpArticle,
} from "../../../services/helpService";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import classes from "./Editor.module.less";
import AttachmentModal from "../NewAttachmentModal/NewAttachmentModal";
import File from "../../UI/File/File";
const { Option } = Select;
const confirm = Modal.confirm;
export default ({
  article,
  loading,
  updateVersions,
  categories,
  updateCategories,
  actualCategory,
  updateActualTitle,
}) => {
  const [contentState, setContentState] = useState(null);
  const [editable, setEditable] = useState(true);
  const [load, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [category, setCategory] = useState(null);
  const [title, setTitle] = useState(null);
  useEffect(() => {
    if (article && article.id) {
      setAttachments(article.help_articles_attachments);
      setCategory(actualCategory);
      setTitle(article.title);
      setContentState(
        EditorState.createWithContent(convertFromRaw(article.content))
      );
    }
  }, [article]);
  const saveDoc = async () => {
    try {
      setLoading(true);
      const { data } = await updateHelpArticle(article.id, {
        text: JSON.stringify(convertToRaw(contentState.getCurrentContent())),
      });
      if (!data.error) {
        setLoading(false);
        setEditable(true);
        notification.success({
          placement: "bottomLeft",
          message: "Information Saved.",
        });
      }
    } catch (error) {}
  };

  const updateTitle = async () => {
    try {
      setLoading(true);
      const { data } = await updateHelpArticle(article.id, {
        title,
      });
      if (!data.error) {
        setLoading(false);
        setEditable(true);
        notification.success({
          placement: "bottomLeft",
          message: "Information Saved.",
        });
        await updateActualTitle();
      }
    } catch (error) {}
  };

  const saveAllInformation = () => {
    updateTitle();
    saveDoc();
  };

  const deleteDoc = async () => {
    try {
      setLoading(true);
      const { data } = await deleteHelpArticle(article.id);
      if (!data.error) {
        updateVersions();
        setLoading(false);
        notification.success({
          placement: "bottomLeft",
          message: "Information Saved.",
        });
      }
    } catch (error) {}
  };

  const cancelEdit = () => {
    setContentState(
      EditorState.createWithContent(convertFromRaw(article.content))
    );
    setTitle(article.title);
    setEditable(true);
    notification.success({
      placement: "bottomLeft",
      message: "Discarded changes.",
    });
  };
  const showConfirmDeleteVersion = () => {
    confirm({
      title: "Are you sure you want to delete this article?",
      onOk() {
        deleteDoc();
      },
      onCancel() {},
    });
  };

  const updateCategory = async (val) => {
    try {
      const { data } = await updateHelpArticle(article.id, {
        title,
        FK_Help_Category: val,
      });
      if (data.message == "success") {
        notification.success({
          placement: "bottomRight",
          message: "Category updated.",
        });
        setCategory(val);
        await updateCategories();
      }
    } catch (error) {
      notification.error({
        placement: "bottomRight",
        message: "An error has ocurred",
      });
    }
  };
  const confirmUpdateCategory = (val) => {
    confirm({
      title: "Are you sure you want to update Category?",
      onOk() {
        updateCategory(val);
      },
      onCancel() {},
    });
  };

  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const timeStamp = new Date().getTime();
      const formData = new FormData();
      formData.set("uploads[]", file);
      formData.set("name", file.name);
      formData.set("timestamp", timeStamp);
      Axios.post("https://appinchina.space/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(({ data }) => {
          if (data == "success") {
            const result = `https://appinchina.space/download/${timeStamp}/${file.name}`;
            resolve({ data: { link: result } });
          }
        })
        .catch((error) => reject(error));
    });
  };

  const previewButtons = () => (
    <Row className={classes.actions} type="flex" justify="end">
      <Button icon="edit" onClick={() => setEditable(!editable)} />
      <Button
        icon="paper-clip"
        onClick={() => {
          setModal(true);
        }}
      />
      <Tooltip placement="topLeft" title="Delete this article">
        <Button
          icon="delete"
          type="danger"
          onClick={() => showConfirmDeleteVersion()}
        />
      </Tooltip>
      <AttachmentModal
        articleId={article.id}
        visible={modal}
        close={() => setModal(false)}
        actualData={attachments}
        updateData={setAttachments}
      />
    </Row>
  );

  const editButtons = () => (
    <Row className={classes.actions} type="flex" justify="end">
      <Input
        style={{ width: 350, margin: 10, marginTop: 0 }}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      {categories ? (
        <Select
          value={category}
          onChange={(val) => confirmUpdateCategory(val)}
          style={{ width: 250, marginBottom: 10 }}
        >
          {categories.map((it) => (
            <Option key={it.id} value={it.id}>
              {it.category}
            </Option>
          ))}
        </Select>
      ) : null}
      <Button icon="close" ghost type="danger" onClick={cancelEdit}>
        Cancel
      </Button>
      <Button type="primary" ghost icon="save" onClick={saveAllInformation}>
        Save
      </Button>
    </Row>
  );

  return (
    <div className={classes.container}>
      {contentState ? (
        <Fragment>
          {!editable ? editButtons() : previewButtons()}
          {loading || load ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                justifyItems: "center",
              }}
            >
              <Spin />
            </div>
          ) : (
            <div>
              <Editor
                toolbarHidden={editable}
                readOnly={editable}
                editorState={contentState}
                onEditorStateChange={(contentState) =>
                  setContentState(contentState)
                }
                toolbar={{
                  image: {
                    previewImage: true,
                    uploadCallback: uploadImageCallBack,
                  },
                }}
              />
              <div className={classes.attachments}>
                {attachments
                  ? attachments.map((it) => (
                      <div key={it.id}>
                        <File
                          key={it.id}
                          validation={{}}
                          value={it.file_url}
                          title={it.file_name}
                          disabled
                        />
                      </div>
                    ))
                  : []}
              </div>
            </div>
          )}
        </Fragment>
      ) : null}
    </div>
  );
};
