import React, { useState } from "react";
import Axios from "../../axios";
import { Modal, Form, Input, notification, Row, Col } from "antd";

const { Item } = Form;

export default Form.create()(
  ({ visible, form, setModalVisible, addServer, match }) => {
    let [loading, setLoading] = useState(false);

    const submit = () => {
      form.validateFields((err, values) => {
        if (!err) {
          setLoading(true);

          try {
            setLoading(true);
            Axios.post(`/aic-pay/server/${match.params.id}`, values)
              .then((response) => {
                setLoading(false);
                console.log("response.data POST ", response.data);
                if (response.data && response.data.message === "success") {
                  addServer(response.data.item);
                }
              })
              .catch((err) => {
                throw new Error("An error has ocurred.");
              });
          } catch (e) {
            setLoading(false);
            notification.error({
              message: e.message,
              placement: "bottomLeft",
            });
          }

          setLoading(false);
          setModalVisible(false);
          //setServers(response.data.servers)
        }
      });
    };

    const { getFieldDecorator } = form;
    return (
      <Modal
        visible={visible}
        onCancel={() => setModalVisible(false)}
        okText="Submit"
        onOk={submit}
        confirmLoading={loading}
      >
        <Form>
          <Row gutter={16}>
            <Col span={24}>
              <Item label="Server Name">
                {getFieldDecorator("name", {
                  rules: [{ required: true, message: "Required" }],
                })(<Input />)}
              </Item>
              <p>
                <i>Choose a name to identify the client.</i>
              </p>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Item label="Data Endpoint">
                {getFieldDecorator("data_endpoint", {
                  rules: [{ required: true, message: "Required" }],
                })(<Input placeholder="" />)}
              </Item>
              <p>
                <i>
                  Custom server used to obtain monetization and analytics data.{" "}
                  <b>Please use https</b>.
                </i>
              </p>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Item label="Actions Endpoint">
                {getFieldDecorator("actions_endpoint", {
                  rules: [{ required: true, message: "Required" }],
                })(<Input placeholder="" />)}
              </Item>
              <p>
                <i>
                  Custom server used to perform refunds and manage renewals.
                </i>{" "}
                <b>Please use https</b>.
              </p>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
);
