import React, { useState, useEffect } from "react";
import classes from "./Projects.module.less";
import { Row, Button, Table } from "antd";
import GridApps from "../UI/GridApps/GridApps";
import { Link } from "react-router-dom";
import { getAllProjects } from "../../services/projectsService";
import NewProjectModal from "./NewProjectModal/NewProjectModal";
import moment from "moment";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
export default () => {
  let [loading, setLoading] = useState(false);
  let [data, setData] = useState([]);
  let [page, setPage] = useState(1);
  let [newCompany, setNewCompany] = useState(false);
  let columns = [
    {
      key: "title",
      dataIndex: "title",
      title: "Title",
      align: "left",
      width: "200px",
      render: (title, item) => (
        <div className={classes.avatar}>
          <Link to={`/projects/${item.id}`}>{title}</Link>
        </div>
      ),
    },
    {
      key: "date",
      dataIndex: "date",
      title: "Date",
      width: "100px",
      render: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
    },
    {
      key: "apps",
      dataIndex: "apps",
      title: "Apps",
      width: "275px",
      render: (apps) => {
        return apps ? <GridApps data={apps} /> : null;
      },
    },
    {
      key: "description",
      dataIndex: "description",
      title: "Description",
      width: "250px",
      render: (description) =>
        description ? (
          <div>
            <Ellipsis length={60} tooltip>
              {description}
            </Ellipsis>
          </div>
        ) : (
          ""
        ),
    },
  ];

  const makeRequest = async () => {
    setLoading(true);
    let { data: response } = await getAllProjects(page);
    setLoading(false);
    if (response && response.projects) {
      setData(response.projects);
    }
  };
  useEffect(() => {
    makeRequest();
  }, []);
  return (
    <div className={classes.container}>
      <Row type="flex" justify="space-between">
        <h3>Projects</h3>
        <Button
          disabled={loading}
          onClick={() => setNewCompany(true)}
          icon="plus"
          type="primary"
        >
          Add New Project
        </Button>
      </Row>
      <div className={classes.table}>
        <Table
          loading={loading}
          pagination={{ pageSize: 10 }}
          columns={columns}
          dataSource={data}
          size="small"
          rowKey={(record) => record.id}
        />
      </div>
      <NewProjectModal
        visible={newCompany}
        data={data}
        updateData={setData}
        close={() => setNewCompany(false)}
      />
    </div>
  );
};
