import React, { useState } from "react";
import { Icon, Menu, Drawer } from "antd";
import classes from "./SideMenu.module.less";
import history from "../../../history";
import { logoGreen } from "../../../images";

const sideMenu = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  let handleClick = (e) => {
    console.log("history", history);
    history.push(e.key);
  };

  let getSelectedKey = () => {
    return (
      "/" +
      (history.location.pathname.split("/").length > 1
        ? history.location.pathname.split("/").slice(1, 2)
        : "")
    );
  };
  let MenuContent = () => (
    <Menu
      onClick={handleClick}
      defaultSelectedKeys={[getSelectedKey()]}
      mode="inline"
      className={classes.menu}
    >
      <Menu.Item key="/">
        <Icon type="home" /> Home
      </Menu.Item>
      <Menu.Item key="/apps-details">
        <Icon type="mobile" /> Apps
      </Menu.Item>
      <Menu.Item key="/clients">
        <Icon type="team" /> Users
      </Menu.Item>
      <Menu.Item key="/companies">
        <Icon type="bank" /> Companies
      </Menu.Item>
      <Menu.Item key="/projects">
        <Icon type="laptop" /> Projects
      </Menu.Item>
      <Menu.Item key="/search">
        <Icon type="search" /> AICON
      </Menu.Item>
      <Menu.Item key="/apk-inspector">
        <Icon type="android" /> APK Inspector
      </Menu.Item>
      <Menu.Item key="/sales">
        <Icon type="credit-card" /> Renewals
      </Menu.Item>
      <Menu.Item key="/badge-tracking/page-tracking">
        <Icon type="tag" /> AIC Badge
      </Menu.Item>
      <Menu.Item key="/documentation">
        <Icon type="file" /> Documentation
      </Menu.Item>
      <Menu.Item key="/payments-alerts">
        <Icon type="alert" />
        Payments Alerts
      </Menu.Item>
      <Menu.Item key="/kpi">
        <Icon type="bar-chart" />
        Operations KPI
      </Menu.Item>
      <Menu.Item key="/bulletin">
        <Icon type="container" /> Bulletin
      </Menu.Item>
      <Menu.Item key="/mailchimp">
        <Icon type="solution" /> Mailchimp
      </Menu.Item>
      <Menu.Item key="/feedbacks">
        <Icon type="like" /> User Feedback
      </Menu.Item>
      <Menu.Item key="/tickets">
        <Icon type="customer-service" /> Customer Support
      </Menu.Item>
      <Menu.Item key="/sdks">
        <Icon type="build" /> SDKs
      </Menu.Item>
      <Menu.Item key="/mailing">
        <Icon type="mail" />
        Mailing
      </Menu.Item>
    </Menu>
  );
  return (
    <div className={classes.container}>
      <div className={classes.imgContainer}>
        <Icon
          type="menu"
          onClick={() => setDrawerOpen(true)}
          className={classes.menuIcon}
        />
      </div>
      <div className={classes.sideMenu}>
        <MenuContent />
      </div>
      <Drawer
        placement="left"
        closable={true}
        title={<img src={logoGreen} className={classes.drawerLogo} />}
        onClose={() => setDrawerOpen(false)}
        visible={drawerOpen}
      >
        <div className={classes.drawerMenu}>
          <MenuContent />
        </div>
      </Drawer>
    </div>
  );
};

export default sideMenu;
