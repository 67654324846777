import React, { useState } from "react";
import {
  Modal,
  Form,
  Button,
  Row,
  Input,
  Select,
  notification,
  Upload,
  Icon,
} from "antd";
import Axios from "axios";
import { DISTRIBUTION_STATUS } from "../../../../constants";
import { updateDistributionHistory } from "../../../../services/appDistributionService";
export default Form.create()(
  ({ form, data = {}, updateCompleteRow, store, ...props }) => {
    const [saving, setSaving] = useState(false);
    const [uploadingImg, setUploadingImg] = useState(false);
    const uploadImage = async ({ file, onSuccess, onProgress, onError }) => {
      const timeStamp = new Date().getTime();
      const formData = new FormData();
      const result = `${timeStamp}/${file.name}`;
      formData.set("uploads[]", file);
      formData.set("name", file.name);
      formData.set("timestamp", timeStamp);
      setUploadingImg(true);
      let { data } = await Axios.post(
        "https://appinchina.space/upload",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onProgress({
              percent: percentCompleted,
            });
          },
        }
      );
      setUploadingImg(false);
      if (data) {
        onSuccess(result);
      } else {
        onError("error");
      }
    };
    const uploadProps = {
      listType: "picture",
    };

    const { Option } = Select;
    const handleSubmit = () => {
      form.validateFields(async (err, values) => {
        if (!err) {
          try {
            let files =
              values.files !== undefined
                ? values.files
                : mapCurrentFiles(data.history_attachments);

            let history_attachments =
              files && files.fileList
                ? files.fileList.map(({ name, size, response }) => ({
                    file_name: name,
                    file_size: size,
                    url: response,
                  }))
                : files.map(({ name, size, response }) => ({
                    file_name: name,
                    file_size: size,
                    url: response,
                  }));
            let format = { ...values, history_attachments };
            makeRequest(format);
          } catch (error) {
            notification.error({
              message: "An error has ocurred.",
              placement: "bottomRight",
            });
          }
        }
      });
    };
    const makeRequest = async (values) => {
      setSaving(true);
      let { data: response } = await updateDistributionHistory(data.id, values);
      setSaving(false);
      if (response && response.message === "success") {
        await updateCompleteRow();
        notification.success({
          message: "Information saved",
          placement: "bottomRight",
        });
        form.resetFields();
        props.close();
      } else {
        notification.error({
          message: "An error has ocurred.",
          placement: "bottomRight",
        });
      }
    };
    let mapCurrentFiles = (files) => {
      return files
        ? files.map((it, index) => ({
            uid: it.id || index,
            name: it.file_name,
            response: it.url,
            size: it.file_size,
            status: "done",
            url: "https://appinchina.space/uploads/" + it.url,
            thumbUrl: "https://appinchina.space/uploads/" + it.url,
          }))
        : [];
    };
    const { getFieldDecorator } = form;
    return (
      <div>
        <Modal
          title="Updating Distribution History"
          visible={props.visible}
          footer={null}
          onCancel={props.close}
          confirmLoading={saving}
          destroyOnClose={true}
        >
          <Form hideRequiredMark={true}>
            <Form.Item label="Description">
              {getFieldDecorator(`description`, {
                initialValue: data.description,
              })(<Input.TextArea />)}
            </Form.Item>
            <Form.Item label="Status">
              {getFieldDecorator(`status`, {
                initialValue: data.status,
                rules: [{ required: true, message: "Required" }],
              })(
                <Select disabled>
                  {DISTRIBUTION_STATUS.map((it) => (
                    <Option key={it.value} value={it.value} label={it.label}>
                      {it.label}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Internal Notes">
              {getFieldDecorator(`internal_notes`, {
                initialValue: data.internal_notes,
              })(<Input.TextArea />)}
            </Form.Item>
            <Form.Item label="Version Number">
              {getFieldDecorator(`version_number`, {
                initialValue: data.version_number,
              })(<Input />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(
                `files`,
                {}
              )(
                <Upload
                  onRemove={(file) => setUploadingImg(false)}
                  disabled={saving}
                  {...uploadProps}
                  customRequest={uploadImage}
                  defaultFileList={mapCurrentFiles(data.history_attachments)}
                >
                  <Button loading={uploadingImg}>
                    <Icon type={uploadingImg ? "loading" : "upload"} />
                    {uploadingImg
                      ? "Uploading File"
                      : " Click to Upload New File(s)"}
                  </Button>
                </Upload>
              )}
            </Form.Item>
            <Row type="flex" justify="center" align="middle">
              <Button
                loading={saving}
                disabled={uploadingImg}
                type="primary"
                onClick={() => handleSubmit()}
                style={{ marginTop: 10 }}
              >
                Save History
              </Button>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
);
