import React, { PureComponent } from "react";
import classes from "./AppLocalization.module.less";
import ApkTesting from "./ApkTesting/ApkTesting";
import Translation from "./Translation/Translation";

class AppDetails extends PureComponent {
  render() {
    return (
      <div className={classes.container}>
        <h2>APKs Submitted</h2>
        <ApkTesting appId={this.props.match.params.id} />
        <h2>Translation</h2>
        <Translation appId={this.props.match.params.id} />
      </div>
    );
  }
}

export default AppDetails;
