import React from "react";
import classes from "./AppPendingCard.module.css";
import { Icon, Button } from "antd";
import Tooltip from "../../UI/Tooltip/Tooltip";
import AppImage from "../../UI/AppImage/AppImage";

export default ({
  appId,
  appName,
  customer,
  clients,
  viewDetails,
  deleteApp,
  authorizeApp,
}) => {
  return (
    <div className={classes.Card}>
      <div className={classes.info}>
        <div className={classes.img_container}>
          <AppImage id={appId} />
        </div>
        <div>
          <span>{appName}</span>
          <span>{customer}</span>
        </div>
      </div>
      <div className={classes.dist_details}>
        <span className={classes.flex}>
          {clients && clients.length ? (
            <span
              className={classes.flex}
              style={{ background: "rgb(140, 180, 245)" }}
            >
              {clients[0].email}
            </span>
          ) : null}
          {clients && clients.length > 1 ? (
            <Tooltip
              content={
                <span className={classes.clientsList}>
                  {clients.slice(1).map((it, index) => (
                    <span key={index}>{it.email}</span>
                  ))}
                </span>
              }
            >
              <span
                className={classes.flex}
                style={{ background: "rgb(140, 180, 245)" }}
              >
                +{clients.length - 1}
              </span>
            </Tooltip>
          ) : null}
        </span>
        <span className={classes.controls}>
          <Button onClick={() => deleteApp(appId)} ghost type="danger">
            <Icon type="delete" />
          </Button>
          <Button onClick={() => authorizeApp(appId)} ghost type="primary">
            <Icon type="check" />
          </Button>
          <Button onClick={() => viewDetails(appId)} ghost type="primary">
            <Icon type="eye" />
          </Button>
        </span>
      </div>
    </div>
  );
};
