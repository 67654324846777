import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Row, Input, Tag } from "antd";
import { advancedSearch } from "../../../services/sopServices";
import Results from "./Results/Results";
import classes from "./AdvancedSearchModal.module.less";
export default Form.create({ name: "advancedSearch" })(
  ({ form, close, visible, onSelect }) => {
    const [searching, setSearching] = useState(false);
    const [results, setResults] = useState([]);
    const [total, setTotal] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [displayResults, setDisplayResults] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [modalWidth, setModalWidth] = useState("50%");
    const handleSubmit = () => {
      form.validateFields((err, values) => {
        if (!err) {
          makeRequest(values);
        }
      });
    };
    const getParseContent = (data, search) => {
      try {
        let result = data.map(({ operation_files, ...item }) => {
          let results = [];
          operation_files.map((it) => {
            let text = JSON.parse(it.text || it.content);
            let find = text.blocks.filter((e) => {
              return (
                e.text.toLowerCase().indexOf(search.toLowerCase()) > -1 &&
                e.text !== ""
              );
            });
            if (find.length > 0) {
              results.push(find.map(({ text }) => text));
            }
          });
          return {
            ...item,
            operation_files: results,
          };
        });
        return result;
      } catch (error) {
        console.log(error);
        return [];
      }
    };
    const makeRequest = async (values) => {
      setSearching(true);
      let { data } = await advancedSearch({
        ...values,
      });
      setSearchValue(values.search);
      setSearching(false);
      if (data && data.results) {
        setDisplayResults(true);
        let parseVersions = getParseContent(data.results, values.search);
        setResults(parseVersions);
        setTotal(data.results.length);
      }
    };
    const resetFields = () => {
      setDisplayResults(false);
      form.resetFields();
      setSearchValue("");
      setResults([]);
    };
    const { getFieldDecorator } = form;
    const reportWindowSize = () => {
      setWidth(window.innerWidth);
    };
    window.onresize = reportWindowSize;
    useEffect(() => {
      if (width <= 550) {
        setModalWidth("100%");
      } else if (width > 550 && width <= 800) {
        setModalWidth("80%");
      } else if (width > 800 && width <= 1000) {
        setModalWidth("70%");
      } else if (width > 1000) {
        setModalWidth("50%");
      }
    }, [width]);
    return (
      <div>
        <Modal
          title="Advanced Search"
          visible={visible}
          footer={null}
          onCancel={() => {
            resetFields();
            close();
          }}
          width={modalWidth}
          destroyOnClose={true}
          confirmLoading={searching}
        >
          {displayResults ? (
            <>
              <h3>
                Results for{" "}
                <b className={classes.searchValue}>"{searchValue}"</b> ({total}
                ):
              </h3>
              <Results
                searchAgain={() => {
                  resetFields();
                }}
                searchValue={searchValue}
                data={results}
                onSelect={onSelect}
                close={close}
              />
            </>
          ) : (
            <Form hideRequiredMark={true}>
              <Form.Item>
                {getFieldDecorator(`search`, {
                  rules: [{ required: true, message: "Required" }],
                })(<Input.Search onSearch={() => handleSubmit()} />)}
              </Form.Item>
              <Row type="flex" justify="center" align="middle">
                <Button
                  loading={searching}
                  disabled={searching}
                  type="primary"
                  onClick={() => handleSubmit()}
                  style={{ marginTop: 10 }}
                >
                  Search
                </Button>
              </Row>
            </Form>
          )}
        </Modal>
      </div>
    );
  }
);
