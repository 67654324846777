import Axios from "../axios";

export const getMailChimpService = async () => {
  let response = null;
  try {
    response = await Axios.get("/mailchimp-lists");
  } catch (error) {
    console.log("error", error);
  }
  return response;
};

export const getSingleList = async (id, page) => {
  let response = null;
  try {
    response = await Axios.get(`/mailchimp-lists/${id}/${page}`);
  } catch (error) {
    console.log("error", error);
  }
  return response;
};

export const newMember = async values => {
  let response = null;
  try {
    response = await Axios.post(`/mailchimp-lists`, {
      ...values
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
};

export const deleteListMember = async values => {
  let response = null;
  try {
    response = await Axios.delete(`/mailchimp-lists`, {
      data: { ...values }
    });
  } catch (error) {
    console.log("error", error);
  }
  return response;
};

export const searchMailChimpUser = async (user, list) => {
  let response = null;
  try {
    response = await Axios.get(`/mailchimp-user/${list}/${user}`);
  } catch (error) {
    console.log("error", error);
  }
  return response;
};

export const synchronizeMailchimpList = async () => {
  let response = null;
  try {
    response = await Axios.get(`/synchronize-list`);
  } catch (error) {
    console.log("error", error);
  }
  return response;
};
