import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Button,
  Row,
  Input,
  Alert,
  notification,
  Tooltip,
  Checkbox,
  Icon,
} from "antd";
import { newClient } from "../../../services/clientService";
import classes from "./NewUsersModal.module.less";
export default Form.create()(({ form, updateData, ...props }) => {
  let [saving, setSaving] = useState(false);
  let [error, setError] = useState(null);
  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (!err) {
        makeRequest(values);
      }
    });
  };
  const makeRequest = async (values) => {
    setSaving(true);
    let { data: response } = await newClient({ ...values });
    setSaving(false);

    if (response && response.error) {
      setError(response.error);
    } else if (response && response.message === "success" && response.client) {
      setError(null);
      notification.success({ message: "New user registered." });
      form.resetFields();
      props.close();
    }
  };

  useEffect(() => {}, [form]);

  const { getFieldDecorator } = form;
  return (
    <div>
      <Modal
        title="New User"
        visible={props.visible}
        footer={null}
        destroyOnClose={true}
        onCancel={props.close}
        confirmLoading={saving}
      >
        <Form hideRequiredMark={true}>
          <Form.Item label="Email">
            {getFieldDecorator(`email`, {
              rules: [
                {
                  type: "email",
                  message: "Please enter a valid email.",
                },
                { required: true, message: "Required" },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="First Name">
            {getFieldDecorator(`first_name`, {})(<Input />)}
          </Form.Item>
          <Form.Item label="Last Name">
            {getFieldDecorator(`last_name`, {})(<Input />)}
          </Form.Item>
          <Form.Item
            className={classes.blocked}
            label={
              <Tooltip title="This will prevent the user from logging into the clients dashboard. Useful for user acquisition promoters.">
                Blocked <Icon type="question-circle" />
              </Tooltip>
            }
          >
            {getFieldDecorator(`blocked`, { valuePropName: "checked" })(
              <Checkbox />
            )}
          </Form.Item>
          <Form.Item label="Position">
            {getFieldDecorator(`position`, {})(<Input />)}
          </Form.Item>
          <Form.Item label="Location">
            {getFieldDecorator(`location`, {})(<Input />)}
          </Form.Item>
          <Row type="flex" justify="center" align="middle">
            <Button
              loading={saving}
              type="primary"
              onClick={() => handleSubmit()}
              style={{ marginTop: 10 }}
            >
              {saving ? "Saving User..." : "Save User"}
            </Button>
          </Row>
        </Form>
        {error ? (
          <Alert message={error} style={{ marginTop: 5 }} type="error" />
        ) : null}
      </Modal>
    </div>
  );
});
