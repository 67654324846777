import axios from "axios";
import EventBus from "eventbusjs";
import Auth from "./auth";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(async (request) => {
  try {
    const auth = new Auth();
    await auth.refreshToken();
    request.headers.Authorization = `Bearer ${localStorage.id_token}`;
  } catch (err) {
    const error = new Error(err);
    return error.message;
  }
  return request;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      EventBus.dispatch("ALERT", this, "Invalid token, please login again");
      EventBus.dispatch("LOGOUT", this);
    }
    return Promise.reject(error);
  }
);

export default instance;

export const chineseInstance = axios.create({
  baseURL: process.env.REACT_APP_TICKETS_URL,
});
chineseInstance.interceptors.request.use((request) => {
  try {
    request.headers.Authorization = `Bearer ${localStorage.id_token}`;
  } catch (err) {
    const error = new Error(err);
    return error.message;
  }
  return request;
});

export const publicInstance = axios.create({
  baseURL: process.env.REACT_APP_OPEN_URL,
});

export const appInChinaPay = axios.create();

appInChinaPay.interceptors.request.use(async (request) => {
  try {
    const auth = new Auth();
    await auth.refreshToken();
    request.headers.authorization = `Bearer ${localStorage.id_token}`;
  } catch (err) {
    const error = new Error(err);
    return error.message;
  }
  return request;
});
