import React, { Component } from "react";
import Axios from "../../axios";
import LineCard from "../UI/LineCard/LineCard";
import Input from "../UI/Input/Input";
import classes from "./CopyrightForm.module.css";
import Preloader from "../UI/Preloader/Preloader";
import FilePreview from "../UI/FilePreview/FilePreview";
import { Button, Icon } from "antd";
import DownloadAsZip from "../DownloadZip/downloadZip";

class CopyrightForm extends Component {
  state = {
    copyrightForm: {},
    loading: true
  };

  componentDidMount() {
    Axios.get(`/copyright-information/${this.props.match.params.id}`).then(
      result => {
        this.setState({
          copyrightForm: result.data.copyright_form,
          loading: false
        });
      }
    );
  }

  render() {
    const { loading, copyrightForm } = this.state;
    let data = !loading
      ? {
          ...copyrightForm
        }
      : {};
    return !loading && copyrightForm ? (
      data.id ? (
        <div className={classes.linecard}>
          <div style={{ margin: "10px 0px ", display: "flex" }}>
            <Button
              style={{ margin: "0px 10px 0 0" }}
              type="ghost"
              onClick={() => window.history.back()}
            >
              <Icon type="left" />
              Go back
            </Button>
            <DownloadAsZip data={copyrightForm} form={"CopyrightForm"} />
          </div>
          {
            <div>
              <LineCard title="Copyright Form Information" margin="20px">
                <div className={classes.container}>
                  <Input
                    placeholder="Name"
                    value={data.appname_en}
                    disabled={true}
                  />
                  <Input
                    placeholder="Short Name"
                    value={data.app_short_name}
                    disabled={true}
                  />
                  <Input
                    placeholder="App Version"
                    value={data.version}
                    disabled={true}
                  />
                  <Input
                    placeholder="Completion Date"
                    value={data.completion_date}
                    disabled={true}
                  />
                  <Input
                    placeholder="Publication Date"
                    value={data.date_publication}
                    disabled={true}
                  />
                  <Input
                    placeholder="Name Company"
                    value={data.name_company}
                    disabled={true}
                  />
                  <Input
                    placeholder="Phone Number"
                    value={data.phone_number}
                    disabled={true}
                  />
                  <Input
                    placeholder="Nationality"
                    value={data.nationality}
                    disabled={true}
                  />
                  <Input
                    placeholder="State City"
                    value={data.state_city}
                    disabled={true}
                  />
                  <Input
                    placeholder="Postal Code"
                    value={data.postal_code}
                    disabled={true}
                  />
                  <Input
                    placeholder="Programming Language"
                    value={data.programming_language}
                    disabled={true}
                  />
                  <Input
                    placeholder="Lines of Code"
                    value={data.lines_code}
                    disabled={true}
                  />
                  <FilePreview
                    fileName={data.business_license}
                    placeholder="Business License"
                  />
                  <FilePreview
                    fileName={data.notarization_letter}
                    placeholder="Notarization Letter"
                  />
                  <FilePreview
                    fileName={data.operation_manual}
                    placeholder="Operation Manual"
                  />
                  <FilePreview
                    fileName={data.source_code}
                    placeholder="Source Code"
                  />
                  <FilePreview
                    fileName={data.development_statement}
                    placeholder="Development Statement"
                  />
                  <FilePreview
                    fileName={data.passport}
                    placeholder="Passport"
                  />
                  <Input
                    placeholder="Hardware Requirements"
                    value={data.hardware_requirements}
                    type="textarea"
                    disabled={true}
                  />
                  <Input
                    placeholder="Software Requirements"
                    value={data.software_requirements}
                    type="textarea"
                    disabled={true}
                  />
                  <Input
                    placeholder="Functionality"
                    value={data.functionality_description}
                    type="textarea"
                    disabled={true}
                  />
                  <Input
                    placeholder="Published"
                    value={
                      data.published === false
                        ? "No"
                        : data.published === true
                        ? "Yes"
                        : ""
                    }
                    disabled={true}
                  />
                  <Input
                    placeholder="Estimated Release Date"
                    value={data.estimated_release_date}
                    type="textarea"
                    disabled={true}
                  />
                </div>
              </LineCard>
            </div>
          }{" "}
        </div>
      ) : (
        <div className={classes.NoResults}>
          <span>
            <b>Information not found</b>
            <Button
              onClick={() => window.history.back()}
              type="ghost"
              width="100px"
              margin="auto"
            >
              Go back
            </Button>
          </span>
        </div>
      )
    ) : (
      <div className={classes.preloadercontainer}>
        <Preloader size={3} />
      </div>
    );
  }
}

export default CopyrightForm;
