import React, { useState, useEffect } from "react";
import { Form, Row, Col, Popover, Icon, Input, Checkbox } from "antd";
import LineCard from "../../../UI/LineCard/LineCard";
import classes from "./AppInformation.module.less";
import Badge from "../../../UI/BadgeNew/BadgeNew";
const { Item } = Form;

export default ({
  getFieldDecorator,
  modified,
  disabled = false,
  data = {},
}) => {
  const [showLoginCredentials, setShowLoginCredentials] = useState(true);
  useEffect(() => {
    if (data && data.login_required) {
      setShowLoginCredentials(!data.login_required);
    }
  }, [data]);
  return (
    <LineCard
      className={classes.container}
      title="App Information"
      shadow
      style={{ marginTop: 15 }}
    >
      <Row gutter={16} type="flex" justify="space-around">
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Item
            label={
              <Popover
                placement="bottomLeft"
                content={
                  <p style={{ maxWidth: 400, textAlign: "justify" }}>
                    This is the name that will be displayed on each app store.
                    It can only contain Chinese characters, English letters and
                    numbers, or spaces. No other special characters are allowed.
                  </p>
                }
              >
                App Name <Icon type="question-circle-o" />
              </Popover>
            }
          >
            <Badge enabled={modified.includes("appname_en")}>
              {getFieldDecorator(`appname_en`, {
                initialValue: data.appname_en,
                rules: [
                  { required: true, message: "Required" },
                  {
                    pattern: /^([\u4E00-\uFA29]*[0-9a-zA-Z- ]*)*$/,
                    message: "Your app name cannot contain special characters.",
                  },
                ],
              })(<Input disabled={disabled} />)}
            </Badge>
          </Item>
          <Item
            label={
              <Popover
                placement="bottomLeft"
                content={
                  <p style={{ maxWidth: 400, textAlign: "justify" }}>
                    Please provide 3 preferred categories under which you want
                    your app to be listed. Please separate each category with a
                    comma. <br />
                    Example: Education, Tools, Lifestyle <br />
                    We will upload your app to the preferred categories
                    according to the order that you've provided. However, final
                    category placements will depend on the requirements of each
                    store.
                  </p>
                }
              >
                App Category <Icon type="question-circle-o" />
              </Popover>
            }
          >
            <Badge enabled={modified.includes("app_category")}>
              {getFieldDecorator(`app_category`, {
                initialValue: data.app_category,
                rules: [{ required: true, message: "Required" }],
              })(<Input disabled={disabled} />)}
            </Badge>
          </Item>

          <Item
            label={
              <Popover
                placement="bottomLeft"
                content={
                  <p style={{ maxWidth: 400, textAlign: "justify" }}>
                    Please provide 5 keywords that describe your app, each
                    separated with a comma. <br /> These keywords will be used
                    to improve the visibility of your app on the Chinese app
                    stores that allow keywords to be submitted.
                  </p>
                }
              >
                Keywords <Icon type="question-circle-o" />
              </Popover>
            }
          >
            <Badge enabled={modified.includes("app_tags")}>
              {getFieldDecorator(`app_tags`, {
                initialValue: data.app_tags,
                rules: [{ required: true, message: "Required" }],
              })(<Input disabled={disabled} />)}
            </Badge>
          </Item>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <>
                <Item
                  label={
                    <Popover
                      placement="bottomLeft"
                      content={
                        <p
                          style={{
                            maxWidth: 400,
                            textAlign: "justify",
                          }}
                        >
                          Necessary if your app requires an account login. If
                          there are more mandatory steps to log into your app,
                          please leave instructions in the Notes section below.
                        </p>
                      }
                    >
                      Test Username <Icon type="question-circle-o" />
                    </Popover>
                  }
                >
                  <Badge enabled={modified.includes("test_user")}>
                    {getFieldDecorator(`test_user`, {
                      initialValue: data.test_user,
                      rules: [
                        {
                          required: showLoginCredentials,
                          message: "Required",
                        },
                      ],
                    })(
                      <Input autoComplete="new-username" disabled={disabled} />
                    )}
                  </Badge>
                </Item>
              </>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <>
                <Item
                  label={
                    <Popover
                      placement="bottomLeft"
                      content={
                        <p>Necessary if your app requires an account login. </p>
                      }
                    >
                      Test Password <Icon type="question-circle-o" />
                    </Popover>
                  }
                >
                  <Badge
                    enabled={
                      modified.includes("test_password") ? "red" : "transparent"
                    }
                  >
                    {getFieldDecorator(`test_password`, {
                      initialValue: data.test_password,
                      rules: [
                        {
                          required: showLoginCredentials,
                          message: "Required",
                        },
                      ],
                    })(
                      <Input disabled={disabled} autoComplete="new-password" />
                    )}
                  </Badge>
                </Item>
              </>
            </Col>
          </Row>
          <Col span={24}>
            <Item>
              <Badge enabled={modified.includes("login_required")}>
                {getFieldDecorator(`login_required`, {
                  initialValue: data.login_required,
                  onChange: (event) =>
                    setShowLoginCredentials(!event.target.checked),
                  valuePropName: "checked",
                })(
                  <Checkbox disabled={disabled}>
                    {" "}
                    The app doesn't require to log in.{" "}
                  </Checkbox>
                )}
              </Badge>
            </Item>
          </Col>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <>
            <Item
              label={
                <Popover
                  placement="bottomLeft"
                  content={
                    <p style={{ maxWidth: 400, textAlign: "justify" }}>
                      Length: 8 to 15 Chinese characters. <br />
                      English descriptions can be approved by the app stores but
                      they are not recommended. <br />
                      If you would like AppInChina to translate your text into
                      Chinese then please enter it in English.
                    </p>
                  }
                >
                  App One-Sentence Description (Chinese or English){" "}
                  <Icon type="question-circle-o" />
                </Popover>
              }
            >
              <Badge enabled={modified.includes("appdesc_short")}>
                {getFieldDecorator(`appdesc_short`, {
                  initialValue: data.appdesc_short,
                  rules: [{ required: true, message: "Required" }],
                })(<Input.TextArea disabled={disabled} rows={5} />)}
              </Badge>
            </Item>
          </>
          <>
            <Item
              label={
                <Popover
                  placement="bottomLeft"
                  content={
                    <p style={{ maxWidth: 400, textAlign: "justify" }}>
                      Length: 50 to 500 Chinese characters.
                      <br />
                      English descriptions can be approved by the app stores but
                      they are not recommended.
                      <br />
                      If you would like AppInChina to translate your text into
                      Chinese then please enter it in English.
                    </p>
                  }
                >
                  App Full-Length Description (Chinese or English){" "}
                  <Icon type="question-circle-o" />
                </Popover>
              }
            >
              <Badge enabled={modified.includes("appdesc_long")}>
                {getFieldDecorator(`appdesc_long`, {
                  initialValue: data.appdesc_long,
                  rules: [{ required: true, message: "Required" }],
                })(<Input.TextArea disabled={disabled} rows={5} />)}
              </Badge>
            </Item>
          </>
        </Col>
        <Col span={24}>
          <Item label="Release Notes">
            <Badge enabled={modified.includes("new_features")}>
              {getFieldDecorator(`new_features`, {
                initialValue: data.new_features,
                rules: [{ required: true, message: "Required" }],
              })(<Input.TextArea disabled={disabled} rows={4} />)}
            </Badge>
          </Item>
          <Item
            label={
              <Popover
                placement="bottomLeft"
                content={
                  <p style={{ maxWidth: 400, textAlign: "justify" }}>
                    Please provide all the user permissions your app requires
                    and a short explanation why they are needed. Some app stores
                    require this information for correct app distribution.
                  </p>
                }
              >
                App Permissions <Icon type="question-circle-o" />
              </Popover>
            }
          >
            <Badge enabled={modified.includes("app_permissions")}>
              {getFieldDecorator(`app_permissions`, {
                initialValue: data.app_permissions,
                rules: [{ required: true, message: "Required" }],
              })(<Input.TextArea disabled={disabled} rows={6} />)}
            </Badge>
          </Item>
        </Col>
      </Row>
    </LineCard>
  );
};
