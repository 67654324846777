import axios from "../axios";

export const getAllBulletin = async () => {
  try {
    return await axios.get("/bulletin");
  } catch (error) {
    return null;
  }
};

export const getBulletinById = async id => {
  try {
    return await axios.get(`/bulletin/${id}`);
  } catch (error) {
    return null;
  }
};

export const updateBulletin = async data => {
  try {
    return await axios.put("/bulletin", {
      ...data
    });
  } catch (error) {
    return null;
  }
};

export const createNewBulletin = async data => {
  try {
    return await axios.post("/bulletin", {
      ...data
    });
  } catch (error) {
    return null;
  }
};
