import React, { useState } from "react";
import { List, Button } from "antd";
export default ({ data }) => {
  let [load, setLoad] = useState(true);
  let set = new Set();

  data.forEach((user) => set.add(user.email));

  let arr = Array.from(set);
  let length = arr.length;
  arr = length > 3 ? (load ? arr.slice(0, 3) : arr) : arr;
  const loadMore =
    load && length > 3 ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button icon="plus" type="link" onClick={() => setLoad(false)}>
          {length - 3} more
        </Button>
      </div>
    ) : null;
  return (
    <List
      dataSource={arr}
      loadMore={loadMore}
      renderItem={(item) => (
        <List.Item key={item}>
          <List.Item.Meta title={item} />
        </List.Item>
      )}
    />
  );
};
