import React, { useState, useEffect } from "react";
import { Modal } from "antd";
export default ({ display, close, paypalDetails }) => {
  let [data, setData] = useState("");
  useEffect(() => {
    setData(paypalDetails);
  }, [paypalDetails]);

  return (
    <Modal
      width={500}
      title="Paypal Details"
      visible={display}
      onCancel={close}
      footer={null}
    >
      <p>{data}</p>
    </Modal>
  );
};
