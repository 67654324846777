import React, { Component } from "react";
import Axios from "../../axios";
import LineCard from "../UI/LineCard/LineCard";
import Input from "../UI/Input/Input";
import classes from "./CompanyForm.module.css";
import Preloader from "../UI/Preloader/Preloader";
import FilePreview from "../UI/FilePreview/FilePreview";
import { Button, Icon } from "antd";
import DownloadAsZip from "../DownloadZip/downloadZip";

class CompanyForm extends Component {
  state = {
    clientForms: {},
    loading: true
  };

  componentDidMount() {
    Axios.get(`/business-information/${this.props.match.params.id}`).then(
      result => {
        this.setState({
          clientForms: result.data.company_form,
          loading: false
        });
      }
    );
  }

  render() {
    const { loading, clientForms } = this.state;
    let data =
      !loading && clientForms
        ? {
            id: clientForms.id,
            email: clientForms.account_email,
            password: clientForms.account_password,
            phone: clientForms.account_phone,
            bank_permit: clientForms.bank_permit,
            business_license: clientForms.business_license,
            company_address: clientForms.company_address,
            company_name: clientForms.company_name,
            company_url: clientForms.company_url,
            contact_name: clientForms.contact_name,
            license_number: clientForms.license_number,
            mobile: clientForms.mobile,
            special_notes: clientForms.special_notes,
            title: clientForms.data,
            id_hand_front: clientForms.id_hand_front,
            id_regular_front: clientForms.id_regular_front,
            id_hand_back: clientForms.id_hand_back,
            id_regular_back: clientForms.id_regular_back,
            passport_hand_front: clientForms.passport_hand_front,
            passport_regular_front: clientForms.passport_regular_front,
            passport_hand_back: clientForms.passport_hand_back,
            passport_regular_back: clientForms.passport_regular_back
          }
        : {};
    return !loading ? (
      data.id ? (
        <div className={classes.linecard}>
          <div style={{ margin: "10px 0px ", display: "flex" }}>
            <Button
              style={{ margin: "0px 10px 0 0" }}
              type="ghost"
              onClick={() => window.history.back()}
            >
              <Icon type="left" />
              Go back
            </Button>
            <DownloadAsZip data={data} form={"CompanyForm"} />
          </div>
          {
            <div>
              <LineCard title="Company Form Information" margin="20px">
                <div className={classes.container}>
                  <Input
                    name="account_email"
                    placeholder="Email"
                    value={data.email}
                    disabled={true}
                  />
                  <Input
                    name="account_password"
                    placeholder="Password"
                    value={data.password}
                    disabled={true}
                  />
                  <Input
                    name="account_phone"
                    placeholder="Phone"
                    value={data.phone}
                    disabled={true}
                  />
                  <Input
                    name="company_address"
                    placeholder="Company Address"
                    value={data.company_address}
                    disabled={true}
                  />
                  <Input
                    name="company_name"
                    placeholder="Company Name"
                    value={data.company_name}
                    disabled={true}
                  />
                  <Input
                    name="company_url"
                    placeholder="Company Url"
                    value={data.company_url}
                    disabled={true}
                  />
                  <Input
                    name="contact_name"
                    placeholder="Contact Name"
                    value={data.contact_name}
                    disabled={true}
                  />
                  <Input
                    name="license_number"
                    placeholder="License Number"
                    value={data.license_number}
                    disabled={true}
                  />
                  <Input
                    name="mobile"
                    placeholder="Mobile"
                    value={data.mobile}
                    disabled={true}
                  />
                  <Input
                    name="special_notes"
                    placeholder="Special Notes"
                    value={data.special_notes}
                    disabled={true}
                  />
                  <Input
                    name="title"
                    placeholder="Title"
                    value={data.title}
                    disabled={true}
                  />
                  <Input
                    name="bank_account"
                    placeholder="Bank Account"
                    value={data.bank_account}
                    disabled={true}
                  />
                  <Input
                    name="company_branch"
                    placeholder="Company Branch"
                    value={data.company_branch}
                    disabled={true}
                  />
                  <FilePreview
                    fileName={data.business_license}
                    placeholder="Business License"
                  />
                  <FilePreview
                    fileName={data.bank_permit}
                    placeholder="Bank Permit"
                  />
                  <FilePreview
                    fileName={data.id_hand_front}
                    placeholder="Hand Front"
                  />
                  <FilePreview
                    fileName={data.id_regular_front}
                    placeholder="Regular Front"
                  />
                  <FilePreview
                    fileName={data.id_hand_back}
                    placeholder="Hand Back"
                  />
                  <FilePreview
                    fileName={data.id_regular_back}
                    placeholder="Regular Back"
                  />
                  <FilePreview
                    fileName={data.passport_hand_back}
                    placeholder="Passport Hand Back"
                  />
                  <FilePreview
                    fileName={data.passport_hand_front}
                    placeholder="Passport Hand Front"
                  />
                  <FilePreview
                    fileName={data.passport_regular_back}
                    placeholder="Passport Regular Back"
                  />
                  <FilePreview
                    fileName={data.passport_regular_front}
                    placeholder="Passport Regular Front"
                  />
                </div>
              </LineCard>
            </div>
          }{" "}
        </div>
      ) : (
        <div className={classes.NoResults}>
          <span>
            <b>Information not found</b>
            <Button
              onClick={() => window.history.back()}
              type="Ghost"
              width="100px"
              margin="auto"
            >
              Go back
            </Button>
          </span>
        </div>
      )
    ) : (
      <div className={classes.preloadercontainer}>
        <Preloader size={3} />
      </div>
    );
  }
}

export default CompanyForm;
