import React, { Fragment, useState } from "react";
import {
  Modal,
  Button,
  Icon,
  Form,
  Checkbox,
  Row,
  Col,
  Typography,
  Radio,
  notification
} from "antd";
import Axios from "../../../axios";
import {
  LIST_INDUSTRIES,
  LIST_COUNTRIES,
  APP_DETAILS_OPTIONS
} from "../../../constants";
const { Paragraph } = Typography;

const headers = {
  name: "App Name",
  business_form_status: "Bussiness Info",
  app_form_status: "App Info",
  copyright_form_status: "SCC",
  confidential: "NDA",
  company_name: "Company Name",
  revenue_model: "Revenue Model",
  contract_value: "Contract Value",
  contract_signed_date: "Contract Start Date",
  invoice_link: "Invoice Link",
  sales_note: "Sales Note",
  agreement_file: "Agreement File",
  contract_aniversary: "Contract Anniversary",
  scc_owner: "SCC Owner",
  scc_file: "File For SCC",
  app_store_accounts: "App Stores Accounts",
  email: "Email",
  password: "Password",
  telephone: "Telephone",
  app_public_name: "App Public Name",
  stores_to_upload: "Stores to upload",
  completion: "Completion",
  country: "Country",
  google_play_link: "Google Play Link",
  creation_date: "Creation Date",
  package_name: "Package Name",
  amount_updates: "Number of Updates",
  updates_left: "Updates Left",
  account_manager: "Account Manager",
  industry: "Google Play Category",
  account_status: "App Status",
  clients_linked: "Clients linked"
};

const values = {
  copyright_form_status: APP_DETAILS_OPTIONS["business_form_status"],
  app_form_status: APP_DETAILS_OPTIONS["app_form_status"],
  copyright_form_status: APP_DETAILS_OPTIONS["copyright_form_status"],
  confidential: APP_DETAILS_OPTIONS["CONFIDENTIAL"],
  scc_owner: APP_DETAILS_OPTIONS["SCC_owner"],
  app_store_accounts: APP_DETAILS_OPTIONS["app_store_accounts"],
  completion: APP_DETAILS_OPTIONS["completion"],
  account_manager: APP_DETAILS_OPTIONS["ACCOUNT_MANAGER"],
  industry: LIST_INDUSTRIES,
  country: LIST_COUNTRIES,
  account_status: [
    { value: 0, label: "App Inactive" },
    { value: 1, label: "App Active" }
  ]
};

export default Form.create()(({ form, apps }) => {
  const [show, setShow] = useState(false);
  const { getFieldDecorator } = form;
  const [appState, setAppState] = useState(2);

  const handleSubmit = async () => {
    try {
      const fields = form.getFieldsValue();
      const { data } = await Axios.post("/export_apps", {
        fields: fields.forms || {},
        apps: apps.map(app => app.id)
      });
      let filterData;
      if (appState == 0 || appState == 1) {
        filterData = data.values.filter(
          ({ name, main_information }) =>
            main_information["account_status"] == appState
        );
      } else {
        filterData = data.values;
      }

      const csvData = filterData
        .map(({ name, main_information, clients_linked }) => ({
          name: name,
          ...main_information,
          clients_linked: `"${clients_linked}"`
        }))
        .map(item => {
          return Object.entries(item).reduce((prev, [key, value]) => {
            let parseValue =
              values[key] && value !== null
                ? transformValues(key, value)
                : value;
            parseValue
              ? (parseValue = `"${parseValue}"`
                  .replace(/"/g, "")
                  .replace(/,/g, " "))
              : (parseValue = parseValue);

            return {
              ...prev,
              [key]: `"${parseValue}"` || ""
            };
          }, {});
        });
      const fileHeaders = Object.keys(csvData[0])
        .map(selectedValue => headers[selectedValue])
        .join(",");
      exportToCSV(csvData, fileHeaders);
      form.resetFields();
      setShow(false);
    } catch (error) {
      console.log(error);
    }
  };

  const transformValues = (key, value) => {
    let result;
    if (key == "industry" || key == "country") {
      result = values[key].find(item => item.code == value);
      result = result ? (result = result.name) : result;
    } else {
      result = values[key].find(item => item.value == value);
      result = result && result != undefined ? (result = result.label) : value;
    }
    return result;
  };

  const exportToCSV = (data, headers) => {
    const rows = data.map(it => [...Object.values(it)].join(",")).join("\n");
    let csvContent = "data:text/csv;charset=utf-8," + `${headers}\n` + rows;
    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", "apps.csv");
    document.body.appendChild(link);
    link.click();
  };

  const styles = {
    marginTop: 15
  };

  return (
    <Fragment>
      <Modal
        width={560}
        visible={show}
        title="Select the fields you want to export"
        onCancel={() => setShow(false)}
        footer={[
          <Button key="back" onClick={() => setShow(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Export to CSV
          </Button>
        ]}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Radio.Group
            onChange={e => setAppState(e.target.value)}
            value={appState}
          >
            <Radio value={2}>All Apps</Radio>
            <Radio value={1}>Active Apps</Radio>
            <Radio value={0}>Inactive Apps</Radio>
          </Radio.Group>
        </div>

        <Form>
          <Form.Item>
            {getFieldDecorator("forms")(
              <Checkbox.Group style={{ width: "100%" }}>
                <Paragraph>Forms</Paragraph>
                <Row>
                  <Col span={8}>
                    <Checkbox value="business_form_status">
                      Bussiness Info
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="app_form_status">App Info</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="copyright_form_status">SCC</Checkbox>
                  </Col>
                </Row>
                <Paragraph style={styles}>Sales</Paragraph>
                <Row>
                  <Col span={8}>
                    <Checkbox value="confidential">NDA</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="company_name">Company Name</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="revenue_model">Revenue Model</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="contract_value">Contract Value</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="contract_signed_date">
                      Contract Start Date
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="invoice_link">Invoice Link</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="agreement_file">Agreement File</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="contract_aniversary">
                      Contract Anniversary
                    </Checkbox>
                  </Col>
                </Row>
                <Paragraph style={styles}>Legal</Paragraph>
                <Row>
                  <Col span={8}>
                    <Checkbox value="scc_owner">SCC Owner</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="scc_file">File For SCC</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="app_store_accounts">
                      App Stores Accounts
                    </Checkbox>
                  </Col>
                </Row>
                <Paragraph style={styles}>Distribution</Paragraph>
                <Row>
                  <Col span={8}>
                    <Checkbox value="email">Email</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="password">Password</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="telephone">Telephone</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="app_public_name">App Public Name</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="stores_to_upload">
                      Stores to upload
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="completion">Completion</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="country">Country</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="google_play_link">
                      Google Play Link
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="creation_date">Creation Date</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="package_name">Package Name</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="amount_updates">
                      Number of Updates
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="updates_left">Updates Left</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="account_manager">Account Manager</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="industry">Google Play Category</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            )}
          </Form.Item>
        </Form>
      </Modal>
      <Button onClick={() => setShow(true)}>
        <Icon type="download" />
      </Button>
    </Fragment>
  );
});
