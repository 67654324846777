import React, { useState, useEffect } from "react";
import classes from "./AppDistribution.module.less";
import Payments from "./Payments/Payments";
import LoadingSpin from "../UI/LoadingSpin/LoadingSpin";
import { Button, Form, notification, Modal, Card, Typography } from "antd";
import GeneralRemarks from "./GeneralRemarks/GeneralRemarks";
import ClientsLinked from "./ClientsLinked/ClientsLinked";
import SalesSection from "./SalesSection/SalesSection";
import { updateApp } from "../../services/appDistributionService";
import DistributionSection from "./DistributionSection/DistributionSection";
import OperationsDetailsSection from "./OperationsDetailsSection/OperationsDetailsSection";
import Contracts from "./Contracts/Contracts";
const { Paragraph } = Typography;

const confirm = Modal.confirm;
const AppDistribution = ({
  form,
  appInfo,
  updateAppInfo,
  loadingApp,
  ...props
}) => {
  const { getFieldDecorator } = form;
  let [app, setApp] = useState({});
  let [loading, setLoading] = useState(true);
  let [loadingSaving, setLoadingSaving] = useState(false);
  const getApp = (values) => {
    const appData = { ...values };
    appData.main_information.agreement_file = appData.main_information
      .agreement_file
      ? appData.main_information.agreement_file.split(",")
      : [];
    appData.main_information.old_package_names = appData.main_information
      .old_package_names
      ? appData.main_information.old_package_names.split(",")
      : [];
    appData.main_information.revenue_model = appData.main_information
      .revenue_model
      ? appData.main_information.revenue_model.split(",")
      : [];
    appData.main_information.service_tags =
      appData.main_information.service_tags &&
        appData.main_information.service_tags.split
        ? appData.main_information.service_tags.split(",")
        : [];
    setApp(appData);
    setLoading(false);
  };

  const handleAppStatus = (option, name) => {
    if (option === "REMOVED") {
      confirm({
        title: "Are you sure you want to mark this app as Removed?",
        onOk() {
          form.setFieldsValue({ "app.main_information.completion": option });
        },
      });
    } else {
      form.setFieldsValue({ "app.main_information.completion": option });
    }
  };
  const handleGlobalSave = async (values) => {
    setLoadingSaving(true);

    for (const key in values.main_information) {
      if (
        values.main_information[key] &&
        values.main_information[key].hasOwnProperty("_isAMomentObject")
      ) {
        values.main_information[key] = values.main_information[key].format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }
    }
    let { data } = await updateApp({
      id: app.id,
      appStateCopy: {
        ...values,
      },
    });

    if (data && data.message === "success") {
      updateAppInfo({ ...values, index: appInfo.index });
      notification.success({
        message: "App information saved",
        placement: "bottomRight",
      });
    } else {
      notification.error({
        message: "There was an error while connecting to the server.",
        placement: "bottomRight",
      });
    }
    setLoadingSaving(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    form.validateFields((err, values) => {
      console.log("values",values)
      if (!err) {
        let data = { ...values.app };
        let agreement_files =
          app.main_information.agreement_file.length > 0
            ? app.main_information.agreement_file
              .filter((e) => e !== "")
              .join(",")
            : "";
        const old_package_names_values = app.main_information.old_package_names
          ? `${app.main_information.old_package_names.filter(
            (item) => item !== ""
          )}`
          : "";
        data = {
          ...data,
          id: app.id,
          users: app.users,
          information: app.information,
          main_information: {
            ...data.main_information,
            agreement_file: agreement_files,
            old_package_names_values: old_package_names_values,
            revenue_model: `${data.main_information.revenue_model}`,
            service_tags:
              data.main_information.service_tags instanceof Array
                ? data.main_information.service_tags.join(",")
                : data.main_information.service_tags,
          },
          app_forms: app.app_forms,
        };
        confirm({
          title: "Are you sure you want to save all new information?",
          onOk() {
            handleGlobalSave(data);
          },
          onCancel() { },
        });
      } else {
        notification.error({
          message: "Fill all the required fields.",
          placement: "bottomRight",
        });
      }
    });
  };
  const handleFileChange = ({ name, value }) => {
    notification.info({
      message: "Uploading File.",
      placement: "bottomRight",
    });
    const appStateCopy = { ...app };
    if (name === "agreement_file") {
      const length = appStateCopy.main_information.agreement_file.length;
      appStateCopy.main_information.agreement_file[length - 1] = value;
    } else {
      appStateCopy.main_information[name] = value;
    }
    setApp(appStateCopy);
  };
  const onDelete = (value, fileName) => {
    const stateCopy = { ...app };
    if (fileName === "agreement_file") {
      const fileToDelete = value;
      const files = stateCopy.main_information.agreement_file.filter(
        (file) => file !== fileToDelete
      );
      stateCopy.main_information[fileName] = files;
    } else {
      form.setFieldsValue({ "app.main_information.scc_file": "" });
    }
    setApp(stateCopy);
  };

  useEffect(() => {
    if (appInfo && appInfo.id && !loadingApp) {
      getApp(appInfo);
    }
  }, [appInfo, loadingApp]);
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  return (
    <div className={classes.container}>
      <Form
        {...formItemLayout}
        onSubmit={handleSubmit}
        style={{ width: "100%" }}
      >
        {loadingApp || loading ? (
          <LoadingSpin
            style={{ height: "500px" }}
            text="Downloading App Details..."
          />
        ) : (
          <div>
            <Button
              loading={loadingSaving}
              width="200px"
              icon="save"
              type="primary"
              htmlType="submit"
              className={classes.globalSave}
            >
              Save
            </Button>

            <div>
              <Card title="General Remarks">
                <GeneralRemarks
                  app={app}
                  getFieldDecorator={getFieldDecorator}
                  loading={loadingSaving}
                />
              </Card>
              <br />
              <Card title="Sales">
                <SalesSection
                  app={app}
                  getFieldDecorator={getFieldDecorator}
                  updateApp={setApp}
                  loading={loadingSaving}
                  onDelete={onDelete}
                  handleAppStatus={handleAppStatus}
                  handleFileChange={handleFileChange}
                />
              </Card>
              <br />
              <Card title="Contracts">
                <Contracts app={app} />
              </Card>
              <br />
              <Card title="User Accounts">
                <ClientsLinked
                  app={app}
                  loading={loadingSaving}
                  form={form}
                  setApp={setApp}
                  getFieldDecorator={getFieldDecorator}
                />
              </Card>
              <br />
              <Card title="Invoices">
                <Payments appId={props.match.params.id} />
              </Card>
              <br />
              <Card title="Distribution Details">
                <DistributionSection
                  app={app}
                  loading={loadingSaving}
                  getFieldDecorator={getFieldDecorator}
                />
              </Card>
              <br />
              <Card title="Operations Details">
                <OperationsDetailsSection
                  form={form}
                  app={app}
                  loading={loadingSaving}
                  getFieldDecorator={getFieldDecorator}
                />
              </Card>
              <br />
              <Card title="Distribution API">
                <p className={classes.left}>
                  <b>API Key:</b> <Paragraph copyable>{app.key}</Paragraph>
                </p>
              </Card>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default Form.create()(AppDistribution);
