import React from "react";
import classes from "./AppCard.module.css";
import { Link } from "react-router-dom";
import { defaultApp } from "../../../images";
import functions from "../../../functions";
import { MdFileDownload } from "react-icons/md";
import AppImage from "../../UI/AppImage/AppImage";

export default ({
  appId,
  image,
  appName,
  customer,
  active,
  downloads,
  targetStores,
  completion,
  targetStoresCompleted
}) => {
  let getCompletionColor = completion => {
    switch (completion) {
      case "COMPLETED":
        return "#4ac547";
      case "REMOVED":
        return "#d1d1cc";
      case "SUSPENDED":
        return "#d1d1cc";
      case "ONGOING":
        return "#e2cb4a";
      case "NEW_APP":
        return "#8cb4f5";
      default:
        return "#d1d1cc";
    }
  };

  return (
    <Link to={"/apps/" + appId} style={{ textDecoration: "none" }}>
      <div className={classes.Card}>
        <div className={classes.info}>
          <div className={classes.img_container}>
            <AppImage id={appId} />
          </div>
          <div>
            <span>{appName}</span>
            <span>{customer}</span>
          </div>
        </div>
        <div className={classes.dist_details}>
          <span style={{ background: getCompletionColor(completion) }}>{`${
            targetStoresCompleted ? targetStoresCompleted : 0
          } / ${targetStores ? targetStores : 0}`}</span>
          <span style={{ background: "rgb(64, 170, 226)" }}>
            {downloads ? functions.styleAsNumber(downloads) : 0}{" "}
            <MdFileDownload size="1.1em" />
          </span>
        </div>
      </div>
    </Link>
  );
};
