import React, { Component, Fragment } from "react";
import classes from "./File.module.css";
import { imgIcon, fileIcon, videoIcon } from "../../../images";
import { Modal, Progress, Icon, Popconfirm } from "antd";
import Axios from "axios";

const confirm = Modal.confirm;

class File extends Component {
  state = {
    files: [],
    previewFile: "",
    dialogPreview: false,
    percent: null,
  };

  componentDidUpdate = () => {
    if (!this.props.value && this.state.files && this.state.files.length)
      this.setState({
        files: [],
        previewFile: "",
      });
  };
  getDeleteButton = () => {
    let { onDelete } = this.props;
    return (
      <Popconfirm
        placement="top"
        title="Do you want to remove this file?"
        onConfirm={onDelete}
        okText="Yes"
        cancelText="No"
      >
        <span className={classes.icon}>
          <Icon
            type="delete"
            theme="filled"
            style={{ zIndex: 1000, cursor: "pointer" }}
          />
        </span>
      </Popconfirm>
    );
  };

  showConfirm = (event) => {
    event.persist();
    let { onDelete } = this.props;
    confirm({
      title: "Do you want to remove this file?",
      onOk() {
        onDelete();
      },
    });
  };

  render() {
    let {
      modified,
      value,
      source,
      valid,
      disabled,
      error,
      validation,
      validated,
    } = this.props;

    if (source) {
      value = source;
    }
    let { files, percent } = this.state;
    let modifiedClass = modified ? classes.modified : "";
    let hasFiles = value || (files && files.length) ? classes.HasFiles : null;
    let validityClass =
      !disabled &&
      !valid &&
      (value || (!!validation && validation.fileRequired)) &&
      validated
        ? classes.Invalid
        : "";
    let disabledClass = disabled ? classes.Disabled : "";
    let customError = value ? error : "Required";

    return (
      <Fragment>
        <div
          className={[
            classes.Container,
            hasFiles,
            validityClass,
            modifiedClass,
            disabledClass,
          ].join(" ")}
        >
          <input
            type="file"
            name={this.props.name}
            onChange={this.handleFileChange}
          />
          <div>
            {percent && percent !== 100 ? (
              <Progress type="circle" percent={percent} width={60} />
            ) : (
              <Fragment>
                <img
                  src={this.getIcon()}
                  alt="File input"
                  className={classes.Icon}
                />
                <p>{this.props.title}</p>
              </Fragment>
            )}

            {this.props.value && !(percent && percent !== 100) ? (
              <div className={classes.icons}>
                {this.getPreviewElement()}
                {this.getDownloadButton()}
                {!this.props.disabled ? this.getDeleteButton() : null}
              </div>
            ) : null}

            {this.props.disabled ? (
              <div className={classes.DisabledFilter} />
            ) : null}
          </div>
        </div>
        {!disabled &&
        !valid &&
        (value || (!!validation && validation.fileRequired)) &&
        validated ? (
          <p className={classes.textError}>{customError}</p>
        ) : null}
      </Fragment>
    );
  }

  getPreviewElement = () => {
    let fileExtensions = /(\.pdf)$/i;
    let imageExtensions = /(\.jpg|\.png|\.jpeg|\.svg\.jpg)$/i;
    let content = null;
    if (imageExtensions.exec(this.props.value)) {
      content = (
        <img
          alt="example"
          style={{ width: "100%" }}
          src={`https://appinchina.space/uploads/${this.props.value}`}
        />
      );
    } else if (fileExtensions.exec(this.props.value)) {
      content = (
        <object
          data={`https://appinchina.space/uploads/${this.props.value}`}
          type="application/pdf"
          width="100%"
          height="650px"
        >
          <p>
            <b>Example fallback content</b>: This browser does not support PDFs.
            Please download the PDF to view it:{" "}
            <a
              href={`https://appinchina.space/download/${this.props.value}`}
              download
            >
              Download PDF
            </a>
            .
          </p>
        </object>
      );
    }
    return content ? (
      <Fragment>
        <span className={classes.icon}>
          <Icon
            type="eye"
            theme="filled"
            style={{ zIndex: 1000, cursor: "pointer" }}
            onClick={this.dialogHandler}
          />
        </span>
        <Modal
          visible={this.state.dialogPreview}
          onCancel={this.dialogHandler}
          footer={null}
        >
          {content}
        </Modal>
      </Fragment>
    ) : null;
  };

  dialogHandler = () => {
    this.setState((prevState) => ({
      dialogPreview: !prevState.dialogPreview,
    }));
  };

  getDownloadButton = () => {
    return this.props.value ? (
      <a
        rel="noopener noreferrer"
        href={`https://appinchina.space/download/${this.props.value}`}
        target="_blank"
        download
      >
        <span className={classes.icon}>
          <Icon
            type="cloud-download"
            style={{ color: "#757575", zIndex: 1000, cursor: "pointer" }}
          />
        </span>
      </a>
    ) : null;
  };

  getIcon = () => {
    switch (this.props.type) {
      case "Image":
        return imgIcon;
      case "Video":
        return videoIcon;
      default:
        return fileIcon;
    }
  };

  handleFileChange = (event) => {
    event.persist();
    if (event.target.files.length > 0) {
      const [file] = event.target.files;
      const timeStamp = new Date().getTime();
      const formData = new FormData();
      const result = `${timeStamp}/${file.name}`;
      const fieldToUpdate = {
        name: file.name,
        size: file.size,
        value: result,
      };
      this.props.submitFile(fieldToUpdate, event);
      formData.set("uploads[]", file);
      formData.set("name", file.name);
      formData.set("timestamp", timeStamp);
      Axios.post("https://appinchina.space/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.setState({
            percent: percentCompleted,
          });
        },
      });
    }
  };
}
export default File;
