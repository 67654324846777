import React, { useState, useEffect } from "react";
import ScrollTable from "../../UI/ScrollTable/ScrollTable";
import { Card } from "antd";
import { getCategoryStatistics } from "../../../services/welcomePageService";

export default ({ month, year }) => {
  let [loading, setLoading] = useState(true);
  let [categories, setCategories] = useState([]);

  const getData = async () => {
    let { data } = await getCategoryStatistics(month, year);
    setLoading(false);
    setCategories(data.categories);
  };

  useEffect(() => {
    setTimeout(getData, 100);
  }, []);

  return (
    <Card title="Category Statistics" loading={loading}>
      {!loading && !!categories ? (
        <ScrollTable
          headers={["Category", "Popularity"]}
          data={categories.map((it) =>
            it.name ? it : { ...it, name: "Not Set" }
          )}
          keys={["industry", "count"]}
        />
      ) : null}
    </Card>
  );
};
