import React, { useState } from "react";
import classes from "./CardDetailsForm.module.less";
import {
  Card,
  Avatar,
  Input,
  Form,
  Button,
  notification,
  Popconfirm,
} from "antd";
import functions from "../../../functions";
import { updateCompany } from "../../../services/companiesService";
export default Form.create()(({ form, contractTotal, loading, data }) => {
  let [saving, setSaving] = useState(false);
  const updateCompanyRequest = async (values) => {
    setSaving(true);
    let { data: response } = await updateCompany({
      companyId: data.id,
      ...values,
    });
    setSaving(false);
    if (response && response.message === "success") {
      notification.success({
        message: "Information saved.",
      });
    }
  };
  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (!err) {
        updateCompanyRequest(values);
      } else {
        notification.error({
          message: "Fill all the required fields.",
          placement: "bottomRight",
        });
      }
    });
  };
  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
    labelAlign: "left",
  };

  const { getFieldDecorator } = form;

  return (
    <Card
      loading={loading}
      title="Company Details"
      size="small"
      extra={
        <Popconfirm
          title="Are you sure you want to save this information?"
          onConfirm={() => handleSubmit()}
        >
          <Button
            loading={saving}
            // onClick={() => handleSubmit()}
            icon={saving ? "loading" : "save"}
            type="primary"
          >
            Save Changes
          </Button>
        </Popconfirm>
      }
    >
      <Form hideRequiredMark={true} {...formItemLayout}>
        <Form.Item label="Logo">
          {getFieldDecorator(`logo`, {
            initialValue: data.logo,
          })(
            <div className={classes.logo}>
              {data.logo ? (
                <Avatar
                  shape="square"
                  src={`https://appinchina.space/uploads/${data.logo}`}
                  size={64}
                />
              ) : (
                <Avatar shape="square" size={64} icon="bank" />
              )}
            </div>
          )}
        </Form.Item>
        <Form.Item label="Name">
          {getFieldDecorator(`name`, {
            initialValue: data.name,
            rules: [{ required: true, message: "Required" }],
          })(<Input disabled={saving} />)}
        </Form.Item>
        <Form.Item label="Location">
          {getFieldDecorator(`location`, {
            initialValue: data.location,
            rules: [{ required: true, message: "Required" }],
          })(<Input disabled={saving} />)}
        </Form.Item>
        <Form.Item label="Total Value">
          {getFieldDecorator(`total_value`, {
            initialValue: functions.styleAsNumber(contractTotal),
          })(<Input disabled />)}
        </Form.Item>
      </Form>
    </Card>
  );
});
