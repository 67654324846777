import React, { useState, useEffect } from "react";
import { Badge, Icon } from "antd";
import { getTotalNotifications } from "../../../services/notificationsService";
export default () => {
  let [total, setTotal] = useState(0);
  let [loading, setLoading] = useState(false);
  const getData = async () => {
    setLoading(true);
    let { data } = await getTotalNotifications();
    setLoading(false);
    if (data && data.totalResults) {
      setTotal(data.totalResults);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Badge
        count={total}
        overflowCount={50}
        title={`${total} unread notifications`}
      >
        <Icon
          type={loading ? "loading" : "bell"}
          theme="filled"
          style={{ fontSize: "20px" }}
          theme="filled"
        />
      </Badge>
    </>
  );
};
