import React, { useState, useEffect } from "react";
import { Card } from "antd";
import ScrollTable from "../../UI/ScrollTable/ScrollTable";
import { getLocationStatistics } from "../../../services/welcomePageService";
import { LIST_COUNTRIES } from "../../../constants";

export default ({ month, year, handleLocationsReceived }) => {
  let [loading, setLoading] = useState(true);
  let [countries, setCountries] = useState([]);

  const getData = async () => {
    let { data } = await getLocationStatistics(month, year);
    setLoading(false);
    let mappedCountries = mapCountriesData(data.countries);
    setCountries(mappedCountries);
    handleLocationsReceived(mappedCountries);
  };

  useEffect(() => {
    setTimeout(getData, 100);
  }, []);

  const mapCountriesData = (data) => {
    return data.map((it) => ({
      ...it,
      name: LIST_COUNTRIES.find((country) => country.code === it.country)
        ? LIST_COUNTRIES.find((country) => country.code === it.country)["name"]
        : "",
    }));
  };

  return (
    <Card title="Location Statistics" loading={loading}>
      {!loading && !!countries ? (
        <ScrollTable
          headers={["Country", "Popularity"]}
          data={countries.map((it) =>
            it.name ? it : { ...it, name: "Not Set" }
          )}
          keys={["name", "count"]}
        />
      ) : null}
    </Card>
  );
};
