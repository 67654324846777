import React, { useState, Fragment, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Spin,
  notification,
  Tree,
  Icon,
  Row,
  Popconfirm,
} from "antd";
import Editor from "./Editor/Editor";
import Axios from "../../axios";
import moment from "moment";
import classes from "./sop.module.less";
import {
  updateCategoryPositions,
  updateVersionsPositions,
  updateCategoryTitle,
  getOperationsProcedures,
} from "../../services/sopServices";
import { dragArray, sortByField } from "../../functions";
import AdvancedSearchModal from "./AdvancedSearchModal/AdvancedSearchModal";
import NewCategoryModal from "./NewCategoryModal/NewCategoryModal";
import NewDocModal from "./NewDocModal/NewDocModal";
const { TreeNode } = Tree;
const confirm = Modal.confirm;
const { Search } = Input;
const getLatest = (versions) => {
  return versions.length > 0 ? versions[versions.length - 1].content : null;
};
export default Form.create()(({ form }) => {
  const [addModal, setAddModal] = useState(false);
  const [addDocModal, setAddDocModal] = useState(false);
  const [currentMenu, setCurrentMenu] = useState([]);
  const [selectedVersion, setVersion] = useState(null);
  const [content, setContent] = useState(null);
  const [categories, setCategories] = useState(null);
  const [loadingContent, setLoadingContent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [docId, setDocumentId] = useState(null);
  const [category, setCategory] = useState(null);
  const [title, setTitle] = useState(null);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [editTitleCategory, setTitleCategory] = useState(null);
  const [newCategory, setNewCategory] = useState("");
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const { getFieldDecorator } = form;

  useEffect(() => {
    getCategories(true);
  }, []);

  const getSubcategoriesFormat = (arr) => {
    const findChildren = (parents, referenceArray) =>
      parents.map(({ id, parent_category, ...it }) => ({
        ...it,
        id,
        parent_category,
        children: findChildren(
          referenceArray.filter((i) => i.parent_category === id),
          arr
        ),
      }));
    let result = findChildren(
      arr.filter((i) => i.parent_category === null),
      arr
    );
    setCategories(result);
  };
  const getCategories = async (updateMenu = false) => {
    try {
      const { data } = await getOperationsProcedures();
      setLoading(false);
      if (data && data.categories) {
        getSubcategoriesFormat(data.categories);
        setAllCategories(data.categories);
        if (updateMenu && data.categories.length > 0) {
          const menu = data.categories
            .filter((e) => e.parent_category == null)
            .map(({ category }) => category);

          setCurrentMenu([menu[0]]);
        }
      }
    } catch (error) {}
  };

  const createCategory = async (values) => {
    try {
      setLoadingUpload(true);
      const { data } = await Axios.post("/operations-procedures", {
        ...values,
      });
      setLoadingUpload(false);
      return data;
    } catch (error) {}
  };

  const createDocument = async (values) => {
    try {
      setLoadingUpload(true);
      const { data } = await Axios.post("/operations-file", {
        ...values,
      });
      setLoadingUpload(false);
      if (!data.error) {
        return data.result;
      } else {
        throw new Error("Error");
      }
    } catch (error) {
      return null;
    }
  };

  const handleSubmitCategory = () => {
    form.validateFields(
      ["category", "parent_category"],
      async (err, values) => {
        if (!err) {
          const newCategory = await createCategory(values);
          setCurrentMenu([null]);
          getSubcategoriesFormat([
            ...allCategories,
            {
              ...newCategory,
              id: newCategory.PK_Category,
              versions: [],
              position: null,
              parent_category: values.parent_category,
            },
          ]);
          setAllCategories([
            ...allCategories,
            {
              ...newCategory,
              id: newCategory.PK_Category,
              versions: [],
              position: null,
              parent_category: values.parent_category,
            },
          ]);
          setAddModal(false);
          form.resetFields();
          notification.success({
            placement: "bottomLeft",
            message: "Category Created.",
          });
        }
      }
    );
  };

  const handleSubmitDocument = () => {
    form.validateFields(["title", "doc_category"], async (err, values) => {
      if (!err) {
        setLoading(true);
        const document = await createDocument({
          title: values.title,
          FK_Category: values.doc_category,
        });
        if (document) {
          getCategories();
          setCurrentMenu([currentMenu.category]);
          setVersion([document.PK_Operation]);
          setDocumentId(document.PK_Operation);
          setContent([]);
          setLoading(false);
          setAddDocModal(false);
          form.resetFields();
          notification.success({
            placement: "bottomLeft",
            message: "Article Created.",
          });
        }
      }
    });
  };

  const getDocumentVersions = async (id) => {
    try {
      const { data } = await Axios.get(`/get-versions/${id}`);
      return data;
    } catch (error) {}
  };

  const selectVersion = async (id) => {
    setLoadingContent(true);
    const { versions } = await getDocumentVersions(id);
    const parseVersions = versions.map(({ content, ...item }) => ({
      ...item,
      content: JSON.parse(content),
    }));
    setVersion([id]);
    setContent(parseVersions);
    setLoadingContent(false);
  };

  const createNewVersion = async (id, content) => {
    try {
      setLoadingContent(true);
      const { data } = await Axios.post("/new-version", {
        id,
        content: JSON.stringify(content),
      });
      setLoadingContent(false);
      return data;
    } catch (error) {
      return null;
    }
  };

  const addNewVersion = async (callback) => {
    setLoading(true);
    const id = selectedVersion;
    const { versions } = await getDocumentVersions(id);
    const parseVersions = versions.map(({ content, ...item }) => ({
      ...item,
      content: JSON.parse(content),
    }));
    const newContent =
      getLatest(parseVersions) != null
        ? getLatest(parseVersions)
        : {
            entityMap: {},
            blocks: [
              {
                key: "637gr",
                text: "",
                type: "unstyled",
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {},
              },
            ],
          };
    const result = await createNewVersion(id, newContent);
    if (result) {
      const {
        newVersion: { PK_Operation_file },
      } = result;
      const newVersions = [
        ...parseVersions,
        {
          date: moment().format("YYYY-MM-DD HH:mm"),
          id: `${PK_Operation_file}`,
          content: newContent,
        },
      ];
      setContent(newVersions);
      setLoading(false);
      setLoadingContent(false);
      setTimeout(callback(`${PK_Operation_file}`), 100);
    }
  };
  const getAllChildrenCategories = (parent) => {
    let result = [];
    result.push(parent);
    const returnChildren = (referenceArray) =>
      referenceArray.map(({ id, parent_category, children, ...it }) => {
        children.map(({ id }) => result.push(id));
        return returnChildren(children);
      });
    returnChildren(categories.filter((i) => i.id === parent));
    return result.join(",");
  };
  const deleteCategoryRequest = async (id) => {
    try {
      const { data } = await Axios.delete(`/delete-category/${id}`);
      if (!data.error) {
        getCategories();
        notification.success({
          placement: "bottomLeft",
          message: "Category deleted.",
        });
      }
    } catch (error) {}
  };
  const showConfirmDeleteCategory = (id) => {
    confirm({
      title: "Are you sure you want to delete this category?",
      onOk() {
        let ids = getAllChildrenCategories(id);
        deleteCategoryRequest(ids);
      },
      onCancel() {},
    });
  };
  const deleteCategory = (id, versions) => {
    if (versions.length > 0) {
      notification.warning({
        placement: "bottomLeft",
        message:
          "Categories can not be deleted if they have documents, please move them to a different category and then try again",
      });
    } else {
      showConfirmDeleteCategory(id);
    }
  };

  const getCategoryActions = (id, versions, actualCategory) => {
    let idCategory = isCategory(id);
    return idCategory ? (
      <>
        {editTitleCategory && editTitleCategory === id ? (
          <div className={classes.categoryActions}>
            <Input
              defaultValue={actualCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              style={{ width: 250 }}
            />
            <div>
              <Popconfirm
                title="Are you sure you want to update this category title?"
                onConfirm={() => updateCategoryTitleRequest(id, newCategory)}
              >
                <Button icon="save" type="primary" />
              </Popconfirm>
              <Button
                icon="close"
                type="danger"
                onClick={() => setTitleCategory(null)}
              />
            </div>
          </div>
        ) : (
          <div className={classes.buttonActions}>
            <a onClick={() => setTitleCategory(id)}>
              <Icon type="edit" />
            </a>
            <a
              style={{ color: "#F5222D", marginLeft: 5 }}
              onClick={() => deleteCategory(id, versions)}
            >
              <Icon type="delete" />
            </a>
          </div>
        )}
      </>
    ) : null;
  };
  const loop = (data) =>
    data.map((item) => {
      let filterBy = item.category || item.title || "";
      const index = filterBy.toLowerCase().indexOf(searchValue.toLowerCase());
      const beforeStr = filterBy.substr(0, index);
      const afterStr = filterBy.substr(index + searchValue.length);
      const title =
        index > -1 ? (
          <Row
            className={classes.actionsHover}
            type="flex"
            justify="space-between"
          >
            {editTitleCategory && editTitleCategory === item.id ? null : (
              <span>
                {beforeStr}
                <span style={{ background: "#FFFF00" }}>{searchValue}</span>
                {afterStr}
              </span>
            )}
            {getCategoryActions(item.id, item.versions, item.category)}
          </Row>
        ) : (
          <Row
            className={classes.actionsHover}
            type="flex"
            justify="space-between"
          >
            {editTitleCategory && editTitleCategory === item.id
              ? null
              : filterBy}
            {getCategoryActions(item.id, item.versions, item.category)}
          </Row>
        );
      if (item.children && item.children.length > 0) {
        if (item.versions && item.versions.length > 0) {
          return (
            <TreeNode key={item.id} title={title}>
              {loop(item.versions)}
              {loop(item.children)}
            </TreeNode>
          );
        } else
          return (
            <TreeNode key={item.id} title={title}>
              {loop(item.children)}
            </TreeNode>
          );
      } else {
        if (item.versions && item.versions.length > 0) {
          return (
            <TreeNode key={item.id} title={title}>
              {loop(item.versions)}
            </TreeNode>
          );
        } else return <TreeNode key={item.id} title={title} />;
      }
    });
  const getVersionTitle = (id) => {
    return allCategories
      .map(({ versions }) => {
        return versions.filter((e) => e.id === id)[0] || null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i)[0].title;
  };
  const getCategoryTitle = (versionId) => {
    return allCategories
      .map(({ id, category, versions }) => {
        let results = versions.filter((version) => {
          if (version.id === versionId) {
            return true;
          }
          return null;
        });

        return results && results.length > 0 ? category : null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i)[0];
  };
  const isCategory = (categoryId) => {
    let result = allCategories
      .filter(({ id, category, versions }) => {
        return id == categoryId ? true : null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);
    return result && result.length > 0 ? true : false;
  };
  const onSelect = (id) => {
    let isIdCategory = isCategory(id);
    if (isIdCategory) {
      let index = expandedKeys.indexOf(id);
      if (index === -1) {
        setExpandedKeys([...expandedKeys, id]);
      } else {
        setExpandedKeys([...expandedKeys.filter((e) => e !== id)]);
      }
    } else {
      let versionTitle = getVersionTitle(id);
      let categoryTitle = getCategoryTitle(id);
      let idCategory = allCategories.filter(
        (e) => e.category === categoryTitle
      )[0].id;
      setTitle(versionTitle);
      setCategory(idCategory);
      setExpandedKeys([...expandedKeys, idCategory]);
      setDocumentId(id);
      selectVersion(id);
    }
  };

  const filterContent = (e) => {
    const { value } = e.target;
    const expandedKeys = allCategories
      .map(({ id, category, versions }) => {
        let results = versions.filter(({ title }) => {
          if (title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) {
            return true;
          }
          return null;
        });

        return results && results.length > 0 ? id : null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);
    setExpandedKeys(expandedKeys);
    setSearchValue(value);
    setAutoExpandParent(true);
  };
  const orderVersions = (idVersion, pos, prevPos) => {
    let copyData = [...allCategories];
    const categoryKey = allCategories
      .map((category) => {
        let results = category.versions.filter((item) => {
          if (item.id === idVersion) {
            return true;
          }
          return null;
        });

        return results && results.length > 0 ? category.id : null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i)[0];
    copyData = copyData.map((category) => {
      let result = [];
      if (categoryKey === category.id) {
        result = dragArray(category.versions, idVersion, pos, prevPos);
        result = result.map((version, position) => ({
          ...version,
          position,
        }));
        updateVersionsPositionRequest(result);
      } else {
        result = category.versions.map((version, position) => ({
          ...version,
          position,
        }));
      }
      return {
        ...category,
        versions: result,
      };
    });
    updateCategories();
  };
  const updateVersionsPositionRequest = async (copyData) => {
    let { data } = await updateVersionsPositions(copyData);
    if (data && data.message === "success") {
      notification.info({ message: "Position Saved!" });
    }
  };

  const orderCategories = (idCategory, arr, newParent) => {
    let result = arr.map((category, position) => ({
      ...category,
      position,
      parent_category: newParent,
    }));
    updateCategoriesPositionRequest(result);
  };
  const updateCategoriesPositionRequest = async (copyData) => {
    let { data } = await updateCategoryPositions(copyData);
    getCategories();
    if (data && data.message === "success") {
      notification.info({ message: "Position Saved!" });
    }
  };

  const onDrop = (info) => {
    const dropKey = info.node.props.eventKey;
    const dragKey = info.dragNode.props.eventKey;
    const dropPos = info.node.props.pos.split("-");
    const prevPos = info.dragNode.props.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const id = info.dragNodesKeys[info.dragNodesKeys.length - 1];
    let newParent = null;
    //IS CATEGORY
    if (isCategory(id)) {
      const loop = (data, id, callback) => {
        data.forEach((item, index, arr) => {
          if (item.id === id) {
            return callback(item, index, arr);
          }
          if (item.children) {
            return loop(item.children, id, callback);
          }
        });
      };
      const data = [...categories];
      // Find dragObject
      let dragObj;
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1);
        dragObj = item;
      });

      if (!info.dropToGap) {
        // Drop on the content
        let aux = [];
        loop(data, dropKey, (item) => {
          item.children = item.children || [];
          // where to insert
          newParent = dropKey;
          item.children.push(dragObj);
          aux = item.children;
        });
        //CHANGE PARENT CATEGORY
        orderCategories(id, aux, newParent);
      } else if (
        (info.node.props.children || []).length > 0 && // Has children
        info.node.props.expanded && // Is expanded
        dropPosition === 1 // On the bottom gap
      ) {
        loop(data, dropKey, (item) => {
          item.children = item.children || [];
          // where to insert
          item.children.unshift(dragObj);
        });
      } else {
        let ar;
        let i;
        loop(data, dropKey, (item, index, arr) => {
          ar = arr;
          i = index;
        });
        let copyCategories = [...allCategories];
        let categoryFound = copyCategories.filter((e) => e.id === dropKey);
        if (categoryFound.length > 0) {
          newParent = copyCategories.filter((e) => e.id === dropKey)[0]
            .parent_category;
        }
        //PARENT
        if (ar !== undefined) {
          if (dropPosition === -1) {
            ar.splice(i, 0, dragObj);
          } else {
            ar.splice(i + 1, 0, dragObj);
          }

          orderCategories(id, ar, newParent);
        } else {
          if (dropPos.length) {
            orderCategories(id, [{ ...dragObj }], data[+dropPos[1] - 1].id);
          }
        }
      }

      setCategories(data);
    } else {
      //IS VERSION
      orderVersions(
        dragKey,
        +dropPos[+dropPos.length - 1],
        +prevPos[+prevPos.length - 1]
      );
    }
  };
  const menu = () =>
    currentMenu.length > 0 ? (
      categories ? (
        <div className={classes.menuContainer}>
          <div className={classes.menu}>
            <Search placeholder="Search" onChange={(e) => filterContent(e)} />
            <Button
              onClick={() => setAdvancedSearch(true)}
              icon="search"
              className={classes.advancedSearch}
              type="primary"
              ghost
            >
              Advanced Search
            </Button>
            <Tree
              className="draggable-tree"
              draggable
              blockNode
              onSelect={(key, info) => {
                if (key.length > 0) {
                  onSelect(key[0]);
                }
              }}
              selectedKeys={selectedVersion}
              onExpand={(values) => {
                setExpandedKeys(values);
                setAutoExpandParent(false);
              }}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
              onDrop={onDrop}
            >
              {loop(categories)}
            </Tree>
          </div>
          <div className={classes.buttons}>
            <Button
              type="primary"
              icon="plus"
              onClick={() => setAddModal(true)}
              style={{
                width: "100%",
              }}
            >
              Category
            </Button>
            <Button
              type="primary"
              icon="plus"
              onClick={() => setAddDocModal(true)}
              style={{
                width: "100%",
              }}
            >
              Article
            </Button>
          </div>
          <AdvancedSearchModal
            visible={advancedSearch}
            close={() => setAdvancedSearch(false)}
            onSelect={onSelect}
          />
        </div>
      ) : null
    ) : (
      <div className={classes.menu}>
        <p style={{ marginTop: 10 }}>No categories</p>
        <div className={classes.buttons}>
          <Button
            type="primary"
            icon="plus"
            onClick={() => setAddModal(true)}
            style={{
              width: "100%",
            }}
          >
            Category
          </Button>
        </div>
      </div>
    );

  const updateContent = async () => {
    try {
      await getCategories();
      setVersion(null);
    } catch (error) {
      console.log(error);
    }
  };
  const updateCategoryTitleRequest = async (id, newTitle) => {
    let { data } = await updateCategoryTitle(id, newTitle);
    if (data && data.message === "success") {
      notification.info({ message: "Category Saved!" });
      setNewCategory("");
      setTitleCategory(null);
      updateCategories();
    }
  };
  const updateCategories = async () => await getCategories();

  return (
    <Fragment>
      {!loading ? (
        <div className={classes.container}>
          {menu()}
          <div className={classes.content}>
            {selectedVersion ? (
              content ? (
                <Editor
                  docId={docId}
                  addNewVersion={addNewVersion}
                  versions={content}
                  loading={loadingContent}
                  updateVersions={updateContent}
                  updateCategories={updateCategories}
                  categories={allCategories}
                  actualCategory={category}
                  actualTitle={title}
                  updateActualTitle={() => updateCategories()}
                />
              ) : (
                <p>Error</p>
              )
            ) : (
              <div className={classes.flex}>
                <p>Please select an article</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            justifyItems: "center",
          }}
        >
          <Spin />
        </div>
      )}
      <NewCategoryModal
        addModal={addModal}
        handleSubmitCategory={handleSubmitCategory}
        setAddModal={setAddModal}
        loadingUpload={loadingUpload}
        getFieldDecorator={getFieldDecorator}
        categories={sortByField(allCategories, "category")}
      />
      <NewDocModal
        addDocModal={addDocModal}
        handleSubmitDocument={handleSubmitDocument}
        setAddDocModal={setAddDocModal}
        loadingUpload={loadingUpload}
        getFieldDecorator={getFieldDecorator}
        categories={sortByField(allCategories, "category")}
      />
    </Fragment>
  );
});
