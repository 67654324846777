import axios from "../axios";

export async function sendRejectionReasonEmail(html, emails) {
  let response = null;
  try {
    response = await axios.post("/rejection-reason", {
      html,
      emails
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function getRejectionsReasons() {
  let response = null;
  try {
    response = await axios.get("/app-rejection-reasons");
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
