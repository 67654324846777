import React, { useState, useEffect } from "react";
import LineCard from "../UI/LineCard/LineCard";
import {
  Select,
  Modal,
  Typography,
  Input,
  Skeleton,
  notification,
  Row,
  Col,
  Checkbox,
} from "antd";
import Axios from "../../axios";
import { getChineseApps } from "../../services/aicPay";
import ApiKeyDialog from "../Dialogs/ApiKey/ApiKeyDialog";
const { Paragraph, Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;

export default ({ match, server }) => {
  const [apps, setApps] = useState([]);
  const [account, setAccount] = useState(null);
  const [loadingAcc, setLoadingAcc] = useState(true);
  const [loadingApp, setLoadingApp] = useState(true);
  const [loadingChineseApps, setLoadingChineseApps] = useState(false);
  const [paymentsId, setPaymentsId] = useState({});
  const [uploading, setUploading] = useState({
    report: false,
    transfers: false,
    app: false,
  });

  const selectApp = ({ value, platform }) => {
    confirm({
      title: "Please confirm the value change",
      content: "The value will be update immediately in the database.",
      okButtonProps: {
        loading: uploading.app,
      },
      onOk() {
        try {
          setUploading({
            ...uploading,
            app: true,
          });
          setPaymentsId({
            ...paymentsId,
            [platform]: value,
          });
          Axios.post("/chinese-apps", {
            platform: {
              [platform]: value,
            },
            appId: match.params.id,
          })
            .then((response) => {
              if (response.data.message === "success") {
                setUploading({
                  ...uploading,
                  app: false,
                });
                notification.success({
                  message: "Information Updated",
                  placement: "bottomLeft",
                });
              }
            })
            .catch(() => {
              notification.error({
                message: "An error has ocurred",
                placement: "bottomLeft",
              });
            });
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  useEffect(() => {
    getApps();
  }, [server]);

  useEffect(() => {
    getAICPayDetails();
    getAccountDetails();
  }, []);

  const getAICPayDetails = () => {
    Axios.get(`/chinese-apps/${match.params.id}`)
      .then((response) => {
        setPaymentsId(response.data.payments);
        setLoadingApp(false);
      })
      .catch((err) => {
        notification.error({
          message: "Error connecting with Chinese Database",
          placement: "bottomLeft",
        });
      });
  };

  const getApps = async () => {
    try {
      setLoadingChineseApps(true);
      const { apps } = await getChineseApps(server);
      setApps(apps || []);
      setLoadingChineseApps(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getAccountDetails = () => {
    Axios.get(`/account-details/${match.params.id}/`)
      .then((response) => {
        if (response.data.account) {
          const { accData } = response.data.account;
          setAccount(accData);
        } else {
          setAccount(null);
        }
        setLoadingAcc(false);
      })
      .catch((err) => console.log(err));
  };

  const earnPercentaje = (value) => {
    try {
      Axios.post("/earn-percentaje", {
        app: match.params.id,
        value: +value,
      });
      notification.success({
        message: "Information Updated",
        placement: "bottomLeft",
      });
    } catch (error) {
      notification.error({
        message: "An error has ocurred",
        placement: "bottomLeft",
      });
    }
  };

  return (
    <div style={{ textAlign: "left" }}>
      <Skeleton loading={loadingApp || loadingChineseApps}>
        <h2>Payments Platforms </h2>
        <Row gutter={16}>
          <Col span={12}>
            <span>Alipay Account</span>
            <Select
              style={{ width: "100%", marginBottom: 5 }}
              placeholder="Select a app"
              onChange={(value) => selectApp({ value, platform: "alipay_id" })}
              value={paymentsId.alipay_id}
              disabled={!server}
            >
              <Option key={`alipay_null`} value={null}>
                Not Set
              </Option>
              {apps.map(({ app_name, id }) => (
                <Option key={`alipay_${id}`} value={id}>
                  {app_name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={12}>
            <span>Wechat Account</span>
            <Select
              style={{ width: "100%", marginBottom: 5 }}
              placeholder="Select a app"
              onChange={(value) => selectApp({ value, platform: "wechat_id" })}
              value={paymentsId.wechat_id}
              disabled={!server}
            >
              <Option key={`wechat_null`} value={null}>
                Not Set
              </Option>
              {apps.map(({ app_name, id }) => (
                <Option key={`wechat_${id}`} value={id}>
                  {app_name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col span={12}>
            <span>Second Alipay Account</span>
            <Select
              style={{ width: "100%", marginBottom: 5 }}
              placeholder="Select a app"
              onChange={(value) =>
                selectApp({ value, platform: "seccond_alipay_id" })
              }
              value={paymentsId.seccond_alipay_id}
              disabled={!server}
            >
              <Option key={`alipay_null`} value={null}>
                Not Set
              </Option>
              {apps.map(({ app_name, id }) => (
                <Option key={`seccond_alipay_${id}`} value={id}>
                  {app_name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={12}>
            <span> Second Wechat Account</span>
            <Select
              style={{ width: "100%", marginBottom: 5 }}
              placeholder="Select a app"
              onChange={(value) =>
                selectApp({ value, platform: "seccond_wechat_id" })
              }
              value={paymentsId.seccond_wechat_id}
              disabled={!server}
            >
              <Option key={`seccond_wechat_null`} value={null}>
                Not Set
              </Option>
              {apps.map(({ app_name, id }) => (
                <Option key={`seccond_wechat_${id}`} value={id}>
                  {app_name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col span={12}>
            <Checkbox
              onChange={(e) =>
                selectApp({
                  value: e.target.checked,
                  platform: "alipay_auto_renewal",
                })
              }
              checked={!!paymentsId.alipay_auto_renewal}
            >
              Alipay auto-renewal
            </Checkbox>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col span={12}>
            <span>AIC Percentage</span>
            <Input.Search
              defaultValue={paymentsId.payments_percentaje}
              onSearch={earnPercentaje}
              style={{ width: "100%", marginBottom: 5 }}
              enterButton="Save"
            />
          </Col>
          <Col span={12}>
            <span>Monetization API Key</span>
            <div>
              <ApiKeyDialog
                hasId={
                  paymentsId
                    ? paymentsId.wechat_id || paymentsId.alipay_id
                    : false
                }
                appId={match.params.id}
              />
            </div>
          </Col>
        </Row>
      </Skeleton>
      <Skeleton loading={loadingAcc}>
        <h2>Bank Details: </h2>
        {account ? (
          <LineCard>
            <Paragraph>
              <Text strong>Company Name: </Text>
              <Text copyable>{account.company_name}</Text>
            </Paragraph>
            <Paragraph>
              <Text strong>Company Address: </Text>
              <Text copyable>{account.company_address}</Text>
            </Paragraph>
            <Paragraph>
              <Text strong>Bank Name: </Text>
              <Text copyable>{account.bank_name}</Text>
            </Paragraph>
            <Paragraph>
              <Text strong>Bank Address :</Text>
              <Text copyable>{account.bank_address}</Text>
            </Paragraph>
            <Paragraph>
              <Text strong>Account Number: </Text>
              <Text
                copyable={{
                  text: account.account_number,
                }}
              >
                {`********${account.account_number.substr(
                  account.account_number.length - 3
                )}`}
              </Text>
            </Paragraph>
            <Paragraph>
              <Text strong>Swift Code: </Text>
              <Text copyable>{account.swift_code}</Text>
            </Paragraph>
            <Paragraph>
              <Text strong>Preferred Currency: </Text>
              <Text>{account.preferred_currency}</Text>
            </Paragraph>
          </LineCard>
        ) : (
          <p>There is no bank information associated with this account.</p>
        )}
      </Skeleton>
    </div>
  );
};
