import React from "react";
import AppImage from "../../UI/AppImage/AppImage";
import { Row, Col, Tooltip } from "antd";
import { Link } from "react-router-dom";
export default ({ data }) => {
  return (
    <Row style={{ margin: "-10px", top: "5px" }}>
      {data.map((it) => (
        <Col span={6}>
          <Tooltip
            placement="bottomLeft"
            title={`${it.name} ROLE:${it.clients_apps.role}`}
          >
            <Link to={`/apps/${it.id}/details`}>
              <AppImage id={it.id} />
            </Link>
          </Tooltip>
        </Col>
      ))}
    </Row>
  );
};
