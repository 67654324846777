import React from "react";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";

const MultilineChart = ({ chart }) => {
  const cols = {
    date: {
      range: [0, 1]
    }
  };
  return (
    <div>
      <div>
        <Chart height={400} data={chart} scale={cols} forceFit>
          <Legend />
          <Axis name="date" />
          <Axis
            name="duration"
            label={{
              formatter: val => {
                return val % 1 === 0 ? `${val} hours` : "";
              }
            }}
          />
          <Tooltip
            crosshairs={{
              type: "y"
            }}
            custom={true}
          />

          <Geom
            type="line"
            position="date*duration"
            size={2}
            color={"user"}
            tooltip={[
              "date*duration*user",
              (date, duration, user) => {
                let format = `${duration.toString().split(".")[0]} hours with ${
                  duration.toString().split(".")[1]
                    ? parseInt(duration.toString().split(".")[1] * 0.6)
                    : 0
                } minutes`;
                return {
                  name: user,
                  title: date,
                  value: format
                };
              }
            ]}
          />
          <Geom
            type="point"
            position="date*duration"
            size={4}
            shape={"circle"}
            color={"user"}
            style={{
              stroke: "#fff",
              lineWidth: 1
            }}
            tooltip={[
              "date*duration*user",
              (date, duration, user) => {
                let format = `${duration.toString().split(".")[0]} hours with ${
                  duration.toString().split(".")[1]
                    ? parseInt(duration.toString().split(".")[1] * 0.6)
                    : 0
                } minutes`;
                return {
                  name: user,
                  title: date,
                  value: format
                };
              }
            ]}
          />
        </Chart>
      </div>
    </div>
  );
};

export default MultilineChart;
