import React, { useState } from "react";
import { Modal, Form, Button, Row, Input, notification } from "antd";
import File from "../../../UI/File/File";
import { editContract } from "../../../../services/localizationService";
export default Form.create({ name: "editContract" })(
  ({ form, update, addendums, contract = {}, ...props }) => {
    let [savingAddendum, setSaving] = useState(false);
    const handleSubmit = () => {
      form.validateFields((err, values) => {
        if (!err) {
          makeRequest(values);
        }
      });
    };
    const makeRequest = async ({ comments }) => {
      setSaving(true);
      let { data } = await editContract({
        comments,
        id: contract.PK_Addendum,
      });
      setSaving(false);
      if (data && data.message === "success") {
        let newData = addendums.filter(
          (e) => e.PK_Addendum !== contract.PK_Addendum
        );
        notification.info({ message: "Contract edited successfully." });
        update([...newData, { ...contract, comments }]);
      }
      props.close();
      form.resetFields();
    };
    const { getFieldDecorator } = form;
    return (
      <div>
        <Modal
          title="Edit Contract"
          visible={props.visible}
          footer={null}
          onCancel={props.close}
          confirmLoading={savingAddendum}
        >
          <Form hideRequiredMark={true}>
            <Form.Item label="Contract File">
              {getFieldDecorator(`file_url`, {
                initialValue: contract.file_url,
                rules: [{ required: true, message: "Required" }],
              })(
                <File
                  disabled={true}
                  type="File"
                  validation={{ fileRequired: true }}
                  title="Addendum File *"
                  onDelete={() => {
                    form.setFieldsValue({ file_url: null });
                    form.setFieldsValue({ file_name: null });
                  }}
                  submitFile={(file) => {
                    form.setFieldsValue({ file_url: file.value });
                    form.setFieldsValue({ file_name: file.name });
                  }}
                />
              )}
            </Form.Item>
            <Form.Item label="File Name">
              {getFieldDecorator(`file_name`, {
                initialValue: contract.file_name,
                rules: [{ required: true, message: "Required" }],
              })(<Input disabled={true} />)}
            </Form.Item>
            <Form.Item label="Comments">
              {getFieldDecorator(`comments`, {
                initialValue: contract.comments,
                rules: [{ required: true, message: "Required" }],
              })(<Input.TextArea />)}
            </Form.Item>
            <Row type="flex" justify="center" align="middle">
              <Button
                loading={savingAddendum}
                disable={savingAddendum}
                type="primary"
                onClick={() => handleSubmit()}
                style={{ marginTop: 10 }}
              >
                Save Contract
              </Button>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
);
