import React, { useEffect, useState } from "react";
import Axios from "../../axios";
import {
  Select,
  Row,
  Skeleton,
  Col,
  Modal,
  notification,
  Divider,
  Icon,
} from "antd";
import NewServerModal from "./NewServerModal";
const { Option } = Select;
const { confirm } = Modal;

export default ({ onChange, match }) => {
  let [servers, setServers] = useState([]);
  let [appServer, setAppServer] = useState(null);
  let [loading, setLoading] = useState(false);
  let [updating, setUpdating] = useState(false);
  let [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    fetch();
  }, []);
  let fetch = () => {
    try {
      setLoading(true);
      Axios.get(`/aic-pay/servers/${match.params.id}`)
        .then((response) => {
          setLoading(false);
          console.log("response.data", response.data);
          if (response.data && response.data.message === "success") {
            setServers(response.data.servers);
            setAppServer(response.data.appServer);
            onChange(response.data.appServer);
          }
        })
        .catch((err) => {
          throw new Error("An error has ocurred.");
        });
    } catch (e) {
      setLoading(false);
      notification.error({
        message: e.message,
        placement: "bottomLeft",
      });
    }
  };

  const handleNewServer = (newServer) => {
    console.log("adding new server", newServer);
    setServers([...servers, newServer]);
    handleSelection(newServer.id, [...servers, newServer]);
  };

  const handleSelection = (value, newServers) => {
    confirm({
      title: "Do you want to select this server?",
      okButtonProps: {
        loading: updating,
      },
      onOk() {
        try {
          let serverSelected = (newServers ? newServers : servers).find(
            (it) => it.id === value
          );
          setUpdating(true);
          Axios.put(`/aic-pay/server/${match.params.id}`, {
            serverId: value,
          })
            .then((response) => {
              if (response.data.message === "success") {
                setAppServer(serverSelected);
                setUpdating(false);
                onChange(serverSelected);
                notification.success({
                  message: "Information Updated.",
                  placement: "bottomLeft",
                });
              }
            })
            .catch(() => {
              throw new Error("An error has ocurred.");
            });
        } catch (error) {
          setUpdating(false);
          notification.error({
            message: "An error has ocurred.",
            placement: "bottomLeft",
          });
        }
      },
    });
  };

  let addItem = () => {
    setModalVisible(true);
  };

  return (
    <>
      <NewServerModal
        visible={modalVisible}
        setModalVisible={setModalVisible}
        addServer={handleNewServer}
        match={match}
        setAppServer={handleSelection}
      />
      <Skeleton loading={loading}>
        {console.log("appServer", appServer)}
        <Row gutter={16}>
          <Col span={24}>
            <h2>App Monetization/Analytics Server:</h2>
            <Select
              placeholder="Select a server"
              onChange={(value) => {
                handleSelection(value);
              }}
              style={{ width: "100%" }}
              value={appServer ? appServer.id : null}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Divider style={{ margin: "4px 0" }} />
                  <div
                    style={{ padding: "4px 8px", cursor: "pointer" }}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={addItem}
                  >
                    <Icon type="plus" /> Add New Server
                  </div>
                </div>
              )}
            >
              <Option key={null} value={null} disabled>
                Not Set
              </Option>
              {servers.map(({ name, id }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Skeleton>
    </>
  );
};
