import axios from "../axios";

export const getAppsUnderDistribution = async ({
  page = 1,
  results = 10,
  sort = "desc",
  field = "appID",
  all,
}) => {
  let response = null;
  try {
    response = await axios.get(
      `/async-apps?page=${page}&sort=${sort}&field=${field}&results=${results}${
        all !== "" ? `&${all}` : ""
      }`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
export const getPendingApps = async () => {
  let response = null;
  try {
    response = await axios.get(`/pending-apps`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
export const getRemovedApps = async () => {
  let response = null;
  try {
    response = await axios.get(`/removed-apps`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getOverdueApps = async ({
  page = 1,
  results = 10,
  sort = "desc",
  field = "date",
  all,
}) => {
  let response = null;
  try {
    response = await axios.get(
      `/overdue-apps?page=${page}&sort=${sort}&field=${field}&results=${results}${
        all !== "" ? `&${all}` : ""
      }`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
export const getDueInApps = async ({
  page = 1,
  results = 10,
  sort = "desc",
  field = "date",
  all,
}) => {
  let response = null;
  try {
    response = await axios.get(
      `/due-in-apps?page=${page}&sort=${sort}&field=${field}&results=${results}${
        all !== "" ? `&${all}` : ""
      }`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
