import React, { Component } from 'react';
import classes from './Tooltip.module.css';

class Tooltip extends Component {
	state = {
		x: 0,
		y: 0,
	}
	elementWidth = 200

	render() {

		//this gets calculated to adjust the tooltip position if it's overflowing the window (causing a scrollbar)
		let divStyle = {
			left: this.state.x + 'px',
			top: this.state.y + 'px',
			width: this.props.width ? this.props.width : this.elementWidth,
			maxWidth: this.props.maxWidth ? this.props.maxWidth : "unset",
		}
		let iStyle = { left: 'calc(50% - ' + (this.state.offsetLeft + 10) + 'px)' }

		return (
			<span
				className={classes.Container}
				style={this.props.align ? { textAlign: this.props.align } : null}
				ref={(divElement) => this.divElement = divElement}
				onMouseMove={this.updatePosition}>
				<span
					className={classes.Tooltip}
					style={divStyle}>
					<i style={iStyle}></i>
					{this.props.content}
				</span>
				{this.props.children}
			</span>
		)
	}
	componentDidMount() {
		this.updatePosition()
	}
	updatePosition = () => {
		let pos = this.divElement.getBoundingClientRect()
		console.log("pos:", pos)
		this.setState({
			x: pos.x + pos.width / 2,
			y: pos.y + pos.height * 1.5,
		})
		/*
		if(pos.x < 0 && window.innerWidth <= 770){ //mobile version
			this.setState({ offsetLeft: -1 * pos.x })
		}
		else if(pos.x < 340 && window.innerWidth > 770){ //desktop version and overflowing on the left
			this.setState({ offsetLeft: -1 * pos.x + 340 })
		}
		if(pos.x < 0){
			this.setState({ offsetLeft: -1 * pos.x }) //any version and overflowing on the right
		}
		else{
			let offsetLeft = window.innerWidth - pos.x - (this.props.width ? this.props.width : this.elementWidth) - 30
			if(offsetLeft < 30) this.setState({ offsetLeft: offsetLeft })
		}*/
	}
}

export default Tooltip;