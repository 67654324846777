import React, { useEffect, useState } from "react";
import classes from "./AppsPending.module.css";
import AppPendingCard from "./AppPendingCard/AppPendingCard";
import { Modal, Icon, Input, Popover, Button, Radio, notification } from "antd";
import Axios from "../../axios";
import history from "../../history";
import { getPendingApps } from "../../services/appsService";
import LoadingSpin from "../UI/LoadingSpin/LoadingSpin";
const confirm = Modal.confirm;
const RadioGroup = Radio.Group;

export default () => {
  let [loading, setLoading] = useState(false);
  let [search, setSearch] = useState("");
  let [order, setOrder] = useState("date");
  let [data, setData] = useState([]);
  let [filteredApps, setFiltered] = useState([]);
  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: "bottomLeft",
    });
  };

  const handleAuthorizeApp = (actualId) => {
    let data = {
      id: actualId,
    };
    openNotificationWithIcon("info", "Authorizing App...");
    Axios.put("/nonauthorizedapps", data)
      .then((response) => {
        openNotificationWithIcon("success", "App Authorized...");
        if (response.data.message === "success") {
          getData();
        }
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "There was an error while connecting to the server."
        );
      });
  };
  const confirmAuthorizeApp = (id) => {
    confirm({
      title: "Are you sure you want to authorize this app?",
      onOk() {
        handleAuthorizeApp(id);
      },
      onCancel() {},
    });
  };

  const handleDeleteApp = (actualId) => {
    openNotificationWithIcon("info", "Deleting app...");
    Axios.delete("/apps", {
      data: { appId: actualId },
    })
      .then((result) => {
        openNotificationWithIcon("success", "App deleted.");

        if (result.data.message === "success") {
          getData();
        }
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "There was an error while connecting to the server."
        );
      });
  };

  const confirmDeleteApp = (id) => {
    confirm({
      title: "Are you sure, this will remove the app from the database?",
      onOk() {
        handleDeleteApp(id);
      },
      onCancel() {},
    });
  };

  const orderArray = (value) => {
    let result;
    if (value === "date") {
      result = filteredApps.sort((a, b) => b.appID - a.appID);
    } else if (value === "name") {
      result = filteredApps.sort((a, b) =>
        a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
      );
    }
    setFiltered(result);
  };

  const getFilteredApps = (value) => {
    let result = data.filter((app) =>
      app.name.toLowerCase().includes(value.toLowerCase())
    );
    setFiltered(result);
  };
  let content_radio = (
    <RadioGroup
      onChange={(e) => {
        setOrder(e.target.value);
        orderArray(e.target.value);
      }}
      value={order}
    >
      <Radio value="date">Date</Radio>
      <br />
      <Radio value="name">Name</Radio>
    </RadioGroup>
  );
  const getData = async () => {
    setLoading(true);
    let { data } = await getPendingApps();
    setLoading(false);
    if (data && data.result) {
      setData(data.result);
      setFiltered(data.result);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className={classes.grid}>
        <div>
          <div className={classes.Header}>
            {<h3>Non-authorized Apps ({filteredApps.length})</h3>}
            <div className={classes.Container}>
              <div className={classes.SearchContainer}>
                <Input.Search
                  allowClear
                  enterButton
                  onSearch={(val) => getFilteredApps(val)}
                  value={search}
                  disabled={loading}
                  placeholder={loading ? "Searching" : "Search for an app..."}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div>
                <Popover content={content_radio} placement="bottomRight">
                  <Button disabled={loading}>
                    <Icon type="sort-ascending" />
                  </Button>
                </Popover>
              </div>
            </div>
          </div>
        </div>
        {filteredApps.length ? (
          loading ? (
            <LoadingSpin />
          ) : (
            <div className={classes.AppsGrid}>
              {filteredApps.map((app, index) => {
                return (
                  <AppPendingCard
                    appId={app.id}
                    key={index}
                    appName={app.name}
                    clients={app.clients}
                    deleteApp={() => confirmDeleteApp(app.id)}
                    authorizeApp={() => confirmAuthorizeApp(app.id)}
                    active={app.active}
                    viewDetails={() => history.push("/apps/" + app.id)}
                  />
                );
              })}
            </div>
          )
        ) : (
          <div className={classes.NoResults}>
            <span>No results found</span>
          </div>
        )}
      </div>
    </>
  );
};
